import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useGetSet, useMediaDevices, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import ZoomVideo, { LocalAudioTrack, TestMicrophoneReturn } from '@zoom/videosdk';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { notifyError } from 'shared/Toast/Toast';

import { useZoomCall } from 'hooks';
import { useRouteMatch } from 'hooks/useRouteMatch';
import useWidth from 'hooks/useWidth';
import { PathName } from 'utils/enums';

import sound from 'assets/sound_test.mp3';

const Settings = () => {
  const { isMobile } = useWidth();
  const {
    room,
    isOpenSettings,
    toggleIsOpenSettings,
    microphoneId,
    cameraId,
    videoTrack,
    showControls,
    setMicrophoneId,
    setCameraId,
    setVideoTrack,
    isEnableCaptions,
    toggleIsEnableCaptions
  } = useZoomCall();
  const [disabledAudioButton, toggleDisabledAudioButton] = useToggle(false);
  const [volume, setVolume] = useState(0);
  const [getAudioDevices, setAudioDevices] = useGetSet<MediaDeviceInfo[]>([]);
  const [getVideoDevices, setVideoDevices] = useGetSet<MediaDeviceInfo[]>([]);
  const [microphoneTester, setMicrophoneTester] = useState<TestMicrophoneReturn>();
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | null>(null);
  const isZoomCall = useRouteMatch(PathName.ZoomCall)?.isExact;
  const mediaDevices = useMediaDevices();

  const stream = room?.getMediaStream();

  const pointClassName = (size: number) =>
    classNames('leading-2 text-sm transition-all', volume > size ? 'text-blue' : 'text-black/10');

  const handlePlayAudio = () => {
    toggleDisabledAudioButton();
    new Audio(sound).play();
    setTimeout(toggleDisabledAudioButton, 3000);
  };

  const changeMicrophoneId = (deviceId: string) => {
    if (!stream) return setMicrophoneId(deviceId);
    stream
      .switchMicrophone(deviceId)
      .then(() => setMicrophoneId(deviceId))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
        notifyError(err.reason);
      });
  };

  const changeCameraId = (deviceId: string) => {
    if (!stream) return setCameraId(deviceId);
    stream
      .switchCamera(deviceId)
      .then(() => setCameraId(deviceId))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
        notifyError(err.reason);
      });
  };

  const handleUpdatedDevices = () => {
    if (!Object.keys(mediaDevices).length) return;
    ZoomVideo.getDevices()
      .then((devices) => {
        const audioinputs = devices.filter(
          (device) => device.kind === 'audioinput' && device.deviceId !== 'default'
        );
        const videoinputs = devices.filter(
          (device) => device.kind === 'videoinput' && device.deviceId !== 'default'
        );

        if (!cameraId || !isEqual(getVideoDevices(), videoinputs)) {
          changeCameraId(videoinputs[0].deviceId);
        }
        if (!microphoneId || !isEqual(getAudioDevices(), audioinputs)) {
          changeMicrophoneId(audioinputs[0].deviceId);
        }

        setVideoDevices(videoinputs);
        setAudioDevices(audioinputs);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  };

  useEffect(() => {
    if (!microphoneId) return;
    setAudioTrack(ZoomVideo.createLocalAudioTrack(microphoneId));
  }, [microphoneId]);

  useEffect(() => {
    if (!isOpenSettings) return;
    setMicrophoneTester(
      audioTrack?.testMicrophone({
        microphoneId,
        onAnalyseFrequency: setVolume
      })
    );
  }, [audioTrack, microphoneId, isOpenSettings]);

  useEffect(() => {
    if (isOpenSettings || !microphoneTester) return;
    microphoneTester.stop();
    microphoneTester.destroy();
    setMicrophoneTester(undefined);
  }, [microphoneTester, isOpenSettings]);

  useEffect(() => {
    isZoomCall && handleUpdatedDevices();
  }, [isZoomCall, mediaDevices]);

  useEffect(() => {
    !isZoomCall && videoTrack?.stop().finally(() => setVideoTrack(null));
  }, [isZoomCall, videoTrack]);

  const mobileContent = (
    <Common.Modal close={toggleIsOpenSettings} isOpen={isOpenSettings} padding={isMobile} size="sm">
      <p className="mb-2 mt-6 font-bold text-primary-700">Audio</p>
      <div className="mt-4 flex items-center gap-1 text-base">
        <Common.Icon name="microphone" />
        <span className="flex-1">Microphone</span>
        <div className="flex w-8 items-end text-center">
          <span className={pointClassName(2.5)}>●</span>
          <span className={pointClassName(5)}>●</span>
          <span className={pointClassName(7.5)}>●</span>
        </div>
      </div>
      <div className="mt-4 flex items-center gap-1 text-base">
        <Common.Icon name="sound-on-filled" />
        <span className="flex-1">Speaker</span>
        <Common.Button color="white-alt" disabled={disabledAudioButton} onClick={handlePlayAudio}>
          Play test sound
        </Common.Button>
      </div>
      <p className="mb-2 mt-4 font-bold text-primary-700">Accessibility</p>
      <label className="mb-6 flex items-center gap-1">
        <Common.Icon name="caption-filled" />
        <span className="flex-1">Captions</span>
        <Common.Toggle
          checked={isEnableCaptions}
          color="blue"
          size="lg"
          onClick={toggleIsEnableCaptions}
        />
      </label>
      <Common.Button color="white-alt" fullWidthOnMobile onClick={toggleIsOpenSettings}>
        Close
      </Common.Button>
    </Common.Modal>
  );

  const button = (device: MediaDeviceInfo, selectedId: string, cb: (deviceId: string) => void) => (
    <button
      className={classNames('flex items-center gap-2 py-2 text-sm', {
        'font-bold': selectedId === device.deviceId
      })}
      key={device.label}
      onClick={() => cb(device.deviceId)}
    >
      <Common.Icon
        className={classNames('text-green', { 'opacity-0': selectedId !== device.deviceId })}
        name="check-circle"
      />
      <span className="flex-1 text-start">{device.label}</span>

      {device.kind === 'audioinput' && selectedId === device.deviceId && (
        <div className="flex w-8 items-center justify-center gap-[2px] text-center">
          <span
            className={classNames(
              'leading-2 size-[3px] rounded-full bg-blue text-sm transition-all',
              {
                'h-[7px]': volume >= 5
              }
            )}
          ></span>
          <span
            className={classNames(
              'leading-2 size-[3px] rounded-full bg-blue text-sm transition-all',
              {
                '!h-[14px]': volume >= 7.5,
                'h-[7px]': volume >= 2.5
              }
            )}
          ></span>
          <span
            className={classNames(
              'leading-2 size-[3px] rounded-full bg-blue text-sm transition-all',
              {
                'h-[7px]': volume >= 5
              }
            )}
          ></span>
        </div>
      )}
    </button>
  );

  const desktopContent = (
    <Tooltip
      children={
        <>
          <p className="py-2 text-sm text-gray">Microphone</p>
          {getAudioDevices().map((device) => button(device, microphoneId, changeMicrophoneId))}
          <hr className="my-2" />
          <p className="py-2 text-sm text-gray">Camera</p>
          {getVideoDevices().map((device) => button(device, cameraId, changeCameraId))}
          <hr className="my-2" />
          <p className="py-2 text-sm text-gray">Accessibility</p>
          <label className="flex cursor-pointer items-center gap-2">
            <Common.Icon name="caption-filled" />
            <span className="flex-1 text-sm">Captions</span>
            <Common.Toggle
              checked={isEnableCaptions}
              color="blue"
              onClick={toggleIsEnableCaptions}
            />
          </label>
        </>
      }
      className="z-50 flex max-w-[300px] flex-col !rounded-xl !p-4 !text-sm !shadow"
      hidden={!showControls}
      id="settings-tooltip"
      opacity={1}
      variant="light"
      clickable
      noArrow
      openOnClick
    />
  );

  return isMobile ? mobileContent : desktopContent;
};

export default Settings;

export const freePlanCode = 'limited-access';

export const getHint = (ppID?: string) => {
  switch (ppID) {
    case 'bundle-semaglutide-one-month-399':
    case 'bundle-semaglutide-three-months-1197':
    case 'bundle-semaglutide-six-months-2394':
    case 'bundle-semaglutide-twelve-months-4788':
    case 'bundle-semaglutide-one-month':
    case 'bundle-semaglutide-three-month':
    case 'bundle-semaglutide-six-month':
    case 'bundle-tirzepatide-one-month':
    case 'bundle-tirzepatide-three-month':
    case 'bundle-tirzepatide-six-month':
      return '*Restricted dosing; may be additional charge for high doses of medication.';
    case 'wm-tt-one-month':
      return '*At-home testing may not be available in all regions and will incur an additional charge if elected.';
    default:
      break;
  }
};

import { useEffect } from 'react';
import { Common } from '@thecvlb/design-system';

import { DeleteDocumentProps } from './deleteDocument.types';

const DeleteDocument: React.FC<DeleteDocumentProps> = ({
  isOpen,
  onClose,
  onConfirm,
  document,
  isLoading
}) => {
  useEffect(() => {
    const onClickEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isOpen) {
        onConfirm();
      }
    };
    window.document.addEventListener('keyup', onClickEnter);
    return () => {
      window.document.removeEventListener('keyup', onClickEnter);
    };
  }, []);

  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="sm" persist>
      <div className="p-4">
        <h3 className="mb-2 text-xl font-bold">Delete file?</h3>
        <p className="mb-6 text-base font-medium">
          “<span className="break-all font-bold">{document.fileName}</span>” will be deleted. This
          action cannot be undone.
        </p>
        <div className="flex flex-col-reverse gap-3 md:flex-row">
          <Common.Button
            className="grow"
            color="white-alt"
            dataTestId="cancel_btn"
            disabled={isLoading}
            fullWidthOnMobile
            onClick={onClose}
          >
            Cancel
          </Common.Button>
          <Common.Button
            className="grow"
            color="red"
            dataTestId="delete_on_popup_btn"
            disabled={isLoading}
            fullWidthOnMobile
            onClick={onConfirm}
          >
            Delete
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default DeleteDocument;

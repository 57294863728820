import { useSearchParams } from 'react-router-dom';

export const useRemoveQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = (name: string) => {
    searchParams.delete(name);
    setSearchParams(searchParams);
  };

  return [searchParams, removeQueryParams] as const;
};

import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import NewPasswordInput from 'shared/form/NewPasswordInput';

import { NewPasswordFormInput, NewPasswordFormProps } from './newPasswordForm.types';

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit, onCancel }) => {
  const { control, handleSubmit, formState } = useForm<NewPasswordFormInput>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  return (
    <form role="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="mx-auto py-4 md:max-w-[480px] md:py-8">
        <h2 className="mb-8 text-mXl font-bold text-primary-700 md:text-xl">Enter new password</h2>
        <NewPasswordInput
          control={control}
          label="Password"
          name="newPassword"
          placeholder="**********"
        />
      </div>
      <div className="fixed inset-x-4 bottom-4 flex flex-wrap justify-center gap-4 border-gray-200 md:static md:border-t md:px-8 md:py-6">
        <Common.Button
          color="blue"
          dataTestId="save_btn"
          disabled={!formState.isValid}
          fullWidthOnMobile
        >
          Change password
        </Common.Button>
        <Common.Button
          className="hidden md:block"
          color="white-alt"
          dataTestId="discard_btn"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </Common.Button>
      </div>
    </form>
  );
};

export default NewPasswordForm;

import { useParams } from 'react-router-dom';

import { ReducedSignupStepProps } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE } from 'constants/defaults';
import { useGetSelectedItem } from 'hooks/useGetSelectedItem';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

import Heading from '../parts/Heading';

const ITEMS = [
  { label: 'Lose 1-20 lbs for good', value: 'lose_1_20_lbs' },
  { label: 'Lose 21-50 lbs for good', value: 'lose_21_50_lbs' },
  { label: 'Lose over 50 lbs for good', value: 'lose_over_50_lbs' },
  { label: 'Maintain weight and get fit', value: 'maintain_weight_and_get_fit' },
  { label: "I'm not sure yet", value: 'im_not_sure_yet' }
];

const WeightLossGoal: React.FC<ReducedSignupStepProps> = ({ moveToStep }) => {
  const { send, isLoading } = useSubmitOrchestrateForm();

  const { flow } = useParams();

  const { value, setValue } = useGetSelectedItem('weight_loss_goal');

  const handleSelect = (v: string, cb?: () => void) => {
    if (v) {
      setValue(v);
      send(
        'combined',
        [
          { key: 'mif_qa', value: [{ answer: v, question: 'weight_loss_goal' }] },
          {
            key: 'marketing',
            value: {
              'flow-source': 'patient-web',
              onboarding_url: window.location.href,
              prospect_flow: flow,
              prospect_initial_appt_type: DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE,
              prospect_type: 'Weight Management'
            }
          }
        ],
        () => {
          cb?.();
          moveToStep('next');
        },
        '',
        () => cb?.()
      );
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <Heading category="Eligibility" title="What is your weight loss goal?" />
      <RadioGroup
        isLoading={isLoading}
        items={ITEMS}
        selectedItem={value as string}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default WeightLossGoal;

import { ReducedSignupStepProps } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { useGetSelectedItem } from 'hooks/useGetSelectedItem';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';

import Heading from '../parts/Heading';

const ITEMS = [
  {
    label: 'My diet and activity need a lot of work',
    value: 'my_diet_and_activity_need_a_lot_of_work'
  },
  { label: 'I have some healthy habits', value: 'i_have_some_healthy_habits' },
  { label: 'I mostly eat well and stay active', value: 'i_mostly_eat_well_and_stay_active' }
];

const HealthState: React.FC<ReducedSignupStepProps> = ({ moveToStep }) => {
  const { send, isLoading } = useSubmitOrchestrateForm();
  const { value, setValue } = useGetSelectedItem('best_describes_inputs');

  const handleSelect = (answer: string, cb?: () => void) => {
    setValue(answer);
    send('mif_qa', [{ answer, question: 'best_describes_inputs' }], () => {
      moveToStep('next');
      cb?.();
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <Heading category="Eligibility" title="Which best describes you?" />
      <RadioGroup
        isLoading={isLoading}
        items={ITEMS}
        selectedItem={value as string}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default HealthState;

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';

import {
  selectDocumentsAndLinksStatus,
  selectHealthProfileStatus,
  selectLabsAndImagingStatus,
  selectMedicalRecordsStatus,
  selectMyProviderStatus,
  selectPrescriptionDiscountCardStatus,
  selectUser,
  selectUserWithProvider
} from 'store';

import DiscountCardInfo from 'features/DiscountCardInfo';
import MemberPricingBanner from 'features/MemberPricingBanner';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { useAppSelector, useQuery } from 'hooks';
import { useRouteMatch } from 'hooks/useRouteMatch';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { FeatureFlag, PathName } from 'utils/enums';

const MyChart = () => {
  const { firstName, lastName } = useAppSelector(selectUser);
  const patientWithDoctor = useAppSelector(selectUserWithProvider);
  const isDisabledMyProvider = useAppSelector(selectMyProviderStatus);
  const isDisabledDocumentsAndLinks = useAppSelector(selectDocumentsAndLinksStatus);
  const isDisabledHealthProfile = useAppSelector(selectHealthProfileStatus);
  const isDisabledLabsAndImaging = useAppSelector(selectLabsAndImagingStatus);
  const isDisabledMedicalRecords = useAppSelector(selectMedicalRecordsStatus);
  const isDisabledPrescriptionDiscountCard = useAppSelector(selectPrescriptionDiscountCardStatus);

  const isEnabledHealthProfilePageFeature = useFlag(FeatureFlag.HealthProfilePage);
  const isEnabledMedicalRecordsPageFeature = useFlag(FeatureFlag.MedicalRecords);
  const isEnabledPrescriptionDiscountCardFeature = useFlag(FeatureFlag.PrescriptionDiscountCard);
  const isEnabledReportsFeature = useFlag(FeatureFlag.Reports);
  const isEnabledDocumentsPageFeature = useFlag(FeatureFlag.DocumentsPage);

  const isMyChart = useRouteMatch(PathName.MyChart);
  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const { isMobile } = useWidth();
  const { isWeightManagement } = useWeightManagement();

  const tabs = [
    ...(isEnabledMedicalRecordsPageFeature && !isDisabledMedicalRecords
      ? [{ label: 'Records', value: PathName.MedicalRecords }]
      : []),
    ...(isEnabledReportsFeature && !isDisabledLabsAndImaging
      ? [{ label: 'Labs & Imaging', value: PathName.Labs }]
      : []),
    ...[{ label: 'Prescriptions', value: `${PathName.Prescriptions}?tab=Prescriptions` }],
    ...(!isDisabledDocumentsAndLinks && isEnabledDocumentsPageFeature
      ? [{ label: 'Documents', value: PathName.Documents }]
      : []),
    ...(patientWithDoctor && !isDisabledMyProvider
      ? [{ label: 'My provider', value: PathName.MyProvider }]
      : []),
    ...(isEnabledHealthProfilePageFeature && !isDisabledHealthProfile
      ? [{ label: 'Health profile', value: PathName.HealthProfile }]
      : [])
  ];

  const selectedTab = tabs.find((el) => el.value.includes(location.pathname))?.label || '';

  const showDiscountBanner =
    selectedTab === 'Prescriptions' &&
    query.get('tab') === 'Prescriptions' &&
    isEnabledPrescriptionDiscountCardFeature;

  return (
    <FadeWrapper>
      <div
        className="rounded-2xl bg-transparent md:bg-white md:shadow"
        data-testid="my_chart_section"
      >
        {!isMyChart?.isExact && (
          <div className="hidden rounded-t-2xl border-gray-200 bg-white md:block md:border-b md:px-8 md:pt-4">
            <Common.Tabs
              data={tabs}
              defaultSelected={[selectedTab]}
              type={isMobile ? 'pill' : 'line'}
              onChange={(el) => navigate(`${el.value}`)}
            />
          </div>
        )}
        <Outlet />
      </div>
      {selectedTab === 'Labs & Imaging' && isMobile && !isWeightManagement && (
        <MemberPricingBanner />
      )}
      {!isDisabledPrescriptionDiscountCard && showDiscountBanner && (
        <DiscountCardInfo firstName={firstName} lastName={lastName} />
      )}
    </FadeWrapper>
  );
};
export default MyChart;

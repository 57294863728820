import dayjs from 'dayjs';

export const getMinutesToMessage = (minutes: number) => {
  switch (true) {
    case minutes > 15:
      return 'more than 15 minutes';
    case minutes > 0:
      return `${minutes} min`;
    default:
      return 'in a moment';
  }
};

export const getStartTimeToMinutesDiff = (date = '') => {
  return dayjs(date).isValid() ? dayjs(date).diff(dayjs(), 'minutes') : 0;
};

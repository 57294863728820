import { useEffect } from 'react';

export const useFixPopupPadding = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.documentElement.style.paddingRight = '0px';
      });
    }
  }, [isOpen]);
};

import { Common } from '@thecvlb/design-system';

import { ConfirmToRefreshProps } from './confirmToRefresh.types';

const ConfirmToRefresh: React.FC<ConfirmToRefreshProps> = ({ isOpen, onClose }) => {
  return (
    <Common.Modal
      description="We detected an issue with the media stream that we cannot resolve. Please refresh the page to try to fix it."
      isOpen={isOpen}
      size="sm"
      title="Error"
      persist
    >
      <div className="flex justify-end gap-3">
        <Common.Button color="white-alt" onClick={onClose}>
          Cancel
        </Common.Button>
        <Common.Button color="blue" onClick={() => window.location.reload()}>
          Refresh
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default ConfirmToRefresh;

import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from 'modals/modal.types';

import { useFixPopupPadding } from 'hooks/useFixPopupPadding';

import SuccessIcon from 'assets/icons/check-icon.svg?react';

const WelcomeToUnlimited: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  useFixPopupPadding(isOpen);

  return (
    <Common.Modal /*dataTestId="modal"*/ close={onClose} isOpen={isOpen} size="sm">
      <SuccessIcon className="mx-auto mb-4" />
      <h3
        className="mb-4 text-center text-m2xl font-bold text-primary md:text-2xl"
        data-testid="modal_heading"
      >
        Welcome to Unlimited!
      </h3>
      <p className="mb-4 text-center text-base leading-6 text-gray-900">
        You’ve upgraded your plan, and now have access to unlimited appointments, after hours care,
        and more.
      </p>
      <Common.Button
        className="mx-auto"
        color="blue"
        dataTestId="done_btn"
        postIcon="arrow-right"
        fullWidthOnMobile
        onClick={onClose}
      >
        Done
      </Common.Button>
    </Common.Modal>
  );
};

export default WelcomeToUnlimited;

import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat, MessageEvent } from 'utils/enums';

import { AppointmentMessageProps } from './appointmentMessage.types';

const AppointmentMessage: React.FC<AppointmentMessageProps> = ({ self, combined, message }) => {
  const isCreated = message.event === MessageEvent.APPOINTMENT_CREATED;
  const isASAP = !message.appointmentDate;

  const appointmentStatusClassName = classNames('mt-1 font-medium text-gray whitespace-normal', {
    'line-through': !isCreated
  });
  const appointmentDate = isASAP
    ? 'ASAP'
    : dayjs(message.appointmentDate).format(DateFormat.MMM_D_h_mma_z);

  return (
    <div
      className={classNames(
        'w-fit max-w-[270px] rounded-xl px-5 py-3',
        self ? 'bg-secondary-100' : 'bg-gray-100',
        !combined && (self ? 'rounded-br-none' : 'rounded-bl-none')
      )}
    >
      <p className="font-bold text-gray-700">Appointment {isCreated ? 'created' : 'canceled'}</p>
      <p className={appointmentStatusClassName}>{message.appointmentType}</p>
      <p className={appointmentStatusClassName}>{appointmentDate}</p>
      {message.message && !isASAP && (
        <p className="mt-3 whitespace-normal font-medium text-gray-700">{message.message}</p>
      )}
    </div>
  );
};

export default AppointmentMessage;

import classNames from 'classnames';
import { useLottie } from 'lottie-react';

// import PremiumSvg from 'assets/icons/premium.svg?react';
import useWidth from 'hooks/useWidth';

import { FirstAppointmentProps } from './firstAppointment.types';

import premium from 'assets/animations/premium.json';
const options = {
  animationData: premium,
  loop: 1,
  style: { width: 175 }
};

const FirstAppointment: React.FC<FirstAppointmentProps> = ({ className }) => {
  const { isMobile } = useWidth();

  const { View } = useLottie(options);

  return (
    <div className={classNames(className, 'flex flex-col items-center gap-4 text-center')}>
      {/* <PremiumSvg /> */}
      {View}
      <h1
        className="text-mXl font-bold text-primary-700 max-sm:mb-4 md:text-2xl"
        data-testid="your_first_appo_text"
      >
        Your first appointment!
      </h1>
      <p className="px-5 py-4 text-gray-600 max-sm:rounded-2xl max-sm:bg-gray-100 md:p-0 md:text-gray-700">
        {isMobile ? 'Tap' : 'Click'} the button below to book your appointment.
      </p>
    </div>
  );
};

export default FirstAppointment;

import { Common } from '@thecvlb/design-system';

import Collapse from './Collapse';
import { FAQProps } from './faq.types';

const FAQ: React.FC<FAQProps> = ({ isOpen, onClose, type }) => {
  const getContent = () => {
    return [
      ...(type === 'eligible'
        ? [
            {
              title: 'What is L-arginine?',
              content: (
                <>
                  L-arginine is an amino acid involved in the production of nitric oxide, which
                  plays a key role in various bodily functions. As an amino acid — and due to its
                  role in nitric oxide production — L-arginine may help:
                  <ul className="flex flex-col gap-2 py-3 text-mSm md:text-sm">
                    <li className="flex items-center gap-2">
                      <Common.Icon className="flex-none" name="check" />
                      Relax blood vessels and improve circulation — which may be beneficial for
                      conditions such as high blood pressure, atherosclerosis, and peripheral artery
                      disease — as well as improve overall cardiovascular health and lower the risk
                      of heart disease.
                    </li>
                    <li className="flex items-center gap-2">
                      <Common.Icon className="flex-none" name="check" />
                      Enhance oxygen and nutrient delivery to muscles, which may boost exercise
                      performance, reduce muscle soreness, and improve recovery
                    </li>
                    <li className="flex items-center gap-2">
                      <Common.Icon className="flex-none" name="check" />
                      Boost the immune system by enhancing T-cell production
                    </li>
                    <li className="flex items-center gap-2">
                      <Common.Icon className="flex-none" name="check" />
                      Reduce the frequency or severity of migraines when taken with specific
                      medications such as ibuprofen
                    </li>
                    <li className="flex items-center gap-2">
                      <Common.Icon className="flex-none" name="check" />
                      And much more
                    </li>
                  </ul>
                  A small dose of L-arginine compounded with GLP-1 is thus considered to be
                  supportive for patients with a history of risk of, and/or currently experiencing
                  certain conditions where L-arginine may play a beneficial role.
                </>
              )
            },
            {
              title: 'What is custom compounding?',
              content:
                'Custom compounding involves creating medications by altering, mixing, or combining ingredients to tailor them to the specific needs of an individual patient. These compounded medications offer a level of personalization that standard prescription drugs do not provide. Patients should only use compounded medications when prescribed by a licensed health care provider. Compounding pharmacies must use pharmaceutical-grade ingredients sourced from FDA-registered facilities when creating compounded medications.'
            },
            {
              title:
                'Will there be a price change if I select the tirzepatide + L-arginine compounded option?',
              content: 'No, the cost of compounded tirzepatide will remain the same at this time.'
            }
          ]
        : [
            {
              title: 'What did the FDA announce?',
              content:
                'The FDA announced that they are investigating a potential link between the Johnson & Johnson vaccine and a rare blood clotting disorder. They are recommending a pause in the use of the vaccine until they can gather more information.'
            },
            {
              title: 'What is the difference between semaglutide and tirzepatide?',
              content: (
                <>
                  Both semaglutide and tirzepatide are medications used to improve blood sugar
                  control in adults with type 2 diabetes and to aid in weight loss for those who are
                  obese or overweight.
                  <br />
                  <br />
                  Tirzepatide works by activating both GLP-1 and GIP receptors, helping to enhance
                  insulin release and promote weight loss. Semaglutide targets the GLP-1 receptor,
                  which stimulates insulin and inhibits glucagon release — also aiding in appetite
                  control. Both medications are administered through subcutaneous injections using a
                  pen-like device, typically once a week. They also share common side effects.
                  <br />
                  <br />
                  The dosing and titration schedules do differ between the medications. Your
                  provider will ensure you are prescribed the appropriate dose based on your current
                  prescribed dosage of tirzepatide.
                </>
              )
            },
            {
              title: 'Is it safe to switch from tirzepatide to semaglutide?',
              content:
                'Yes, switching from semaglutide to tirzepatide is safe, but it should always be done under the guidance of a licensed healthcare provider.'
            },
            ...(type === 'care'
              ? [
                  {
                    title: 'Is there a cost difference?',
                    content:
                      'As you are currently on a subscription plan that does not include the cost of medication, there will be no difference in subscription cost as a result of this change. While costs can vary based on dosage and titration, compounded semaglutide typically has a lower “out of pocket” cost compared to compounded tirzepatide. Therefore, your overall out-of-pocket cost is expected to decrease going forward.'
                  }
                ]
              : [])
          ])
    ];
  };

  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="sm" title="FAQs">
      {getContent().map((item) => (
        <Collapse key={item.title} title={item.title}>
          {item.content}
        </Collapse>
      ))}
      <Common.Button
        className="mt-6 md:hidden"
        color="white-alt"
        fullWidthOnMobile
        onClick={onClose}
      >
        Close
      </Common.Button>
    </Common.Modal>
  );
};

export default FAQ;

import { useLocation } from 'react-router-dom';

export const useRouteMatch = (path: string) => {
  const location = useLocation();
  return location.pathname.includes(path)
    ? {
        isExact: path === location.pathname
      }
    : null;
};

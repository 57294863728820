import { selectUser } from 'store';

import { PlanCodes, WMDeviceFlow } from 'utils/enums';
import { checkIfUserIsInsuranceUser, checkIfUserIsTTUser, getLatestSurvey } from 'utils/helpers';

import { ActionType } from '../services/wm/wm.types';

import { useAppSelector } from './index';

const useWeightManagement = () => {
  const {
    activePlanCode,
    isAsyncSignup,
    hadOrHasWeightManagement,
    weightManagement,
    activePricePoint,
    wmDeviceFlow
  } = useAppSelector(selectUser);

  // Action types are ordered by priority
  const incompleteSurvey = [
    'first-device-check-in',
    'weekly-device-check-in',
    'monthly-device-check-in',
    'check-in',
    'renewal'
  ]
    .map((type) => getLatestSurvey(weightManagement?.incompleteSurveys, type as ActionType))
    .find(Boolean);

  return {
    hadOrHasWeightManagement,
    isAsyncPlan: isAsyncSignup,
    isWeightManagement: activePlanCode === PlanCodes.WeightManagementMembership,
    incompleteSurvey,
    isTTPatient: checkIfUserIsTTUser(activePricePoint),
    isRWEPatient: wmDeviceFlow === WMDeviceFlow.WMDevices,
    isInsurancePatient: checkIfUserIsInsuranceUser(activePricePoint)
  };
};

export default useWeightManagement;

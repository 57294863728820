import { Common } from '@thecvlb/design-system';

import { Medication } from 'models/medication.types';

interface MedicationCardProps {
  medication: Medication | { drug_name: 'TBD' };
  onClickEdit?: () => void;
}

const MedicationCard: React.FC<MedicationCardProps> = ({ medication, onClickEdit }) => {
  return (
    <div className="flex flex-col rounded-2xl border bg-white shadow">
      <div className="flex flex-col gap-3 p-4">
        <div className="flex flex-col gap-2">
          <span className="text-mSm font-semibold text-gray md:text-sm">Selected medication</span>
          <span className="text-mXl font-bold md:text-2xl">{medication.drug_name}</span>
        </div>
        {'status' in medication && medication.status === 'PA Required' && (
          <span className="text-mSm md:text-sm">
            We'll collaborate with your insurance provider to secure authorization for{' '}
            {medication.drug_name}. If approval isn't possible, we'll work together to find the most
            effective and affordable medication alternative for you.
          </span>
        )}
        {medication.drug_name === 'TBD' && (
          <span className="text-mSm md:text-sm">
            We’ll continue to work with your insurance provider to determine medication coverage. If
            we’re unable to get you approved, together we’ll choose the best medication at the best
            cost to you.
          </span>
        )}
        <Common.Button color="white" onClick={onClickEdit}>
          Edit selection
        </Common.Button>
      </div>
      {'copay' in medication && !!medication.copay && (
        <>
          <div className="h-px w-full bg-gray-300"></div>
          <div className="flex flex-col gap-1 p-4">
            <span className="text-mXs font-semibold md:text-xs">Price</span>
            <span className="text-m2xl font-bold text-primary md:text-2xl">
              ${medication.copay}{' '}
              {'status' in medication && medication.status === 'Covered' && 'copay'}
            </span>
            <span className="text-mSm text-gray md:text-sm">
              You’ll pay your local pharmacy directly.
            </span>
          </div>
        </>
      )}
      <div className="h-px w-full bg-gray-300"></div>
      <div className="flex flex-col gap-2.5 p-4">
        {[
          'Insurance assistance for medication',
          'Insurance prior authorization',
          'Full Medical history assessment',
          'Weight loss related labs (free)',
          'Clinically tailored treatment plan',
          'Rx sent to your pharmacy or shipped',
          'Ongoing consultations to increase medication dosage if needed'
        ].map((a) => (
          <div className="flex gap-2 text-primary-700" key={a}>
            <Common.Icon className="flex-none" name="check" />
            <span className="text-mSm md:text-sm">{a}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicationCard;

import { useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { ImageProps } from './image.types';

const Image: React.FC<ImageProps> = ({ message, mobile, self, combined }) => {
  const [ariaHidden, setAriaHidden] = useState<boolean | undefined>(true);
  const [isBlurImage, setIsBlurImage] = useState<boolean | undefined>(message.isBlur);

  return (
    <div
      className={classNames(
        'relative overflow-hidden rounded-xl',
        !combined && !message.message && (self ? 'rounded-br-none' : 'rounded-bl-none')
      )}
    >
      <div
        className={classNames({
          'backdrop-filter-animation': isBlurImage === undefined,
          'backdrop-filter-sm min-w-[100px]': isBlurImage
        })}
      >
        {ariaHidden ? (
          <img
            alt={message.fileName}
            className={classNames('ml-auto h-[150px] max-w-[270px] object-cover', {
              'md:max-w-none': !mobile
            })}
            src={message.filePath}
            onLoad={() => setAriaHidden(false)}
          />
        ) : (
          <Zoom>
            <img
              alt={message.fileName}
              className={classNames('ml-auto h-[150px] max-w-[270px] object-cover', {
                'md:max-w-none': !mobile
              })}
              src={message.filePath}
            />
          </Zoom>
        )}
      </div>
      <div
        className={classNames('group absolute inset-0', {
          'opacity-100': isBlurImage,
          'pointer-events-none opacity-0': isBlurImage === false,
          'remove-backdrop-filter': isBlurImage === undefined
        })}
      >
        <div className="flex h-full flex-col items-center justify-center gap-3 whitespace-normal px-1 text-center text-mSm text-white">
          <div className="flex flex-wrap justify-center">
            <span>Hidden for&nbsp;</span>
            <span>your privacy</span>
          </div>
          <Common.Button
            className="h-fit border border-white"
            style="pill"
            onClick={() => setIsBlurImage(false)}
          >
            Show
          </Common.Button>
        </div>
      </div>
    </div>
  );
};

export default Image;

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { parsePhoneNumber } from 'utils/helpers';

import { PharmacyCardProps } from './pharmacyCard.types';

import PointSvg from 'assets/images/map/point.svg?react';
import PointOpenSvg from 'assets/images/map/point-open.svg?react';

const PharmacyCard: React.FC<PharmacyCardProps> = ({
  pharmacy,
  onClick,
  isSelected = false,
  isUserPharmacy = false,
  isTransparent = false,
  distance
}) => {
  const handelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(pharmacy.pharmacyId, 'list');
  };

  return (
    <button
      className={classNames('flex w-full items-center gap-1 p-4 text-left', {
        'bg-gray-50': isSelected && !isTransparent
      })}
      onClick={handelClick}
    >
      <div className="flex flex-1 flex-col gap-1">
        <p className="flex w-full items-center gap-1">
          {isUserPharmacy ? <PointOpenSvg className="size-5" /> : <PointSvg className="size-5" />}
          <b className="flex-1">{pharmacy.name}</b>
          {distance && <span className="text-gray">{distance} mi</span>}
        </p>
        <p>
          {pharmacy.address}, {pharmacy.city}, {pharmacy.state} {pharmacy.zipCode}
        </p>
        <p className="text-gray">{parsePhoneNumber(pharmacy.phone)}</p>
      </div>
      <Common.Icon className="flex-none text-gray" name="arrow-right" />
    </button>
  );
};

export default PharmacyCard;

export const getPanelPosition = (parentElement: Element, customOffset = 354) => {
  const { top, left, height } = parentElement.getBoundingClientRect();
  const halfHeight = height / 2;
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const responsiveWidth = `calc(${(left * 100) / windowWidth}vw - ${customOffset}px)`;
  const responsiveHeight = `calc(${(top * 100) / windowHeight}vh - 130px + ${halfHeight}px)`;
  const panelLeft = responsiveWidth;
  const panelTop = responsiveHeight;
  return {
    panelLeft,
    panelTop
  };
};

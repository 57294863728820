import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from '../modal.types';

import CVSImg from 'assets/images/logos/cvs-pharmacy.svg?react';
import RiteImg from 'assets/images/logos/rite-aid.svg?react';
import WalGreensImg from 'assets/images/logos/wall-greens.svg?react';
import WalmartImg from 'assets/images/logos/walmart.svg?react';

const SuccessPayment: React.FC<BaseModalProps> = ({ onClose, isOpen }) => {
  const itemClassName = 'mb-4 text-mSm font-medium md:text-base';

  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="base" zIndex={50}>
      <div className="mx-auto flex flex-col items-center">
        <div className="flex flex-1 flex-col p-4">
          <h4 className="mb-5 text-center text-mBase font-bold text-secondary">
            Member Services: 866-936-7381
          </h4>

          <p className={itemClassName}>
            Present this card to your pharmacist at the time of purchase. The program discount will
            be applied for all non-insurance claims. Discounts available only at participating
            pharmacies. By using the card, the holder agrees to the terms and conditions under which
            it was issued.
          </p>
          <p className={itemClassName}>
            <span className="font-bold">Participant: </span>For questions or comments regarding the
            use of your LifeMD pharmacy discount card, contact us toll-free at 1-866-936-7381.
          </p>
          <p className={itemClassName}>
            <span className="font-bold">Pharmacist: </span>The RxGRP and BIN must be submitted
            online to ProCare Rx to process claims for this program. For questions regarding online
            claims submission call toll-free at 1-888-299-5383.
          </p>
        </div>
        <div className="flex w-full flex-wrap items-center justify-center gap-6 pb-8">
          <WalmartImg />
          <CVSImg />
          <WalGreensImg />
          <RiteImg />
        </div>
        <Common.Button
          className="mb-4 w-full"
          color="blue"
          dataTestId="continue-btn"
          fullWidthOnMobile
          onClick={onClose}
        >
          Close
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default SuccessPayment;

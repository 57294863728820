import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HealthMetrics } from 'services/healthMetrics/healthMetrics.types';

import { HealthMetricsState } from './healthMetrics.types';

const initialState: HealthMetricsState = {
  latestHealthMetrics: {
    socialHistory: [],
    womenHealth: {
      approxStartDate: '',
      name: '0',
      startDate: ''
    }
  }
};

const healthMetricsSlice = createSlice({
  initialState,
  name: 'healthMetrics',
  reducers: {
    setLatestHealthMetrics: (state, action: PayloadAction<HealthMetrics>) => {
      state.latestHealthMetrics = action.payload;
    }
  }
});

export const { setLatestHealthMetrics } = healthMetricsSlice.actions;

export default healthMetricsSlice.reducer;

import { useEffect, useLayoutEffect } from 'react';

export const useDisableScroll = (params: unknown) => {
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  useEffect(() => {
    const persistentScroll = localStorage.getItem('persistentScroll');
    if (persistentScroll === null) return;

    window.scrollTo({ top: Number(persistentScroll) });

    if (Number(persistentScroll) === window.scrollY) localStorage.removeItem('persistentScroll');
  }, [params]);

  const handleDisableScroll = () => {
    localStorage.setItem('persistentScroll', window.scrollY.toString());
  };

  return { handleDisableScroll };
};

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { MyChartTitleProps } from './myChartTitle.types';

const MyChartTitle: React.FC<MyChartTitleProps> = ({ icon, text }) => {
  return (
    <div
      className={classNames(
        '-mx-8 -mt-8 mb-4 hidden items-center justify-center gap-4 px-8 py-6 md:flex',
        {
          'bg-blue-50 text-blue': text === 'Medical records',
          'bg-green-50 text-green': text === 'Documents',
          'bg-orange-50 text-orange': text === 'Prescriptions',
          'bg-secondary-50 text-secondary': text === 'Health profile',
          'bg-violet-50 text-violet': text === 'Labs & imaging',
          'bg-yellow-50 text-yellow': text === 'My provider' || text === 'Preferred provider'
        }
      )}
    >
      <div
        className={classNames('rounded-2xl', {
          'bg-blue-50 text-blue': text === 'Medical records',
          'bg-green-50 text-green': text === 'Documents',
          'bg-orange-50 text-orange': text === 'Prescriptions',
          'bg-secondary-50 text-secondary': text === 'Health profile',
          'bg-violet-50 text-violet': text === 'Labs & imaging',
          'bg-yellow-50 text-yellow': text === 'My provider' || text === 'Preferred provider'
        })}
      >
        <Common.Icon className="size-8" name={icon} />
      </div>
      <h2 className="text-xl font-bold text-gray-700" data-testid="title_in_my_chart">
        {text}
      </h2>
    </div>
  );
};

export default MyChartTitle;

export const AT_LEAST_ONE_NUMBER_OR_SPEC_CHAR = /(?=.*[\d@$!%*?&])/;

export const SECURE_PASSWORD_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NAME_REGEXP = /^[a-z ,.'-]+$/i;

export const POSITIVE_NUMBER_REGEXP = /^[1-9]\d*$/;

export const POSITIVE_DECIMAL_NUMBER_REGEXP = /^[0-9]*\.?[0-9]{0,2}$/;

export const FULL_NAME_REGEXP = /^(?=.*[\s])(?![\s.]+$)[a-zA-Z-\s.]*$/;

export const ADDRESS_REGEXP = /[A-Za-z0-9'.\-\s,]/;

export const URL_REGEX = /(https?:\/\/[^\s]+)/g;

export const AT_LEAST_ONE_LETTER_REGEXP = /.*[A-Za-z]+.*/;

export const MEMBER_ID_REGEXP = /^[a-zA-Z0-9_-]*$/;

export const MM_DD_YYYY_REGEXP = /^\d{2}\/\d{2}\/\d{4}$/;

export const EXP_DATE_REGEX = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

export const INCHES_REGEX = /(\d+\.?\d*)(?:’|′)\s+(\d+\.?\d*)(?:”|″)/;

export const WAIST_REGEX = /(\d+(\.\d+)?)/;

export const HEIGHT_REGEX = /(\d+)\s*ft\s*(\d+(\.\d+)?)\s*in/;

export const LBS_REGEX = /^(\d+)\s*(lbs)?$/;

export const EMAIL_CODE_REGEX = /^[0-9]{6}$/;

export const APPOINTMENT_TYPE_ID_REGEX = /^[0-9a-f]{24}$/;

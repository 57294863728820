import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { POSITIVE_NUMBER_REGEXP } from 'utils/regExp';

const NumberInput = <TFormValues extends FieldValues>({
  name,
  control,
  placeholder,
  label,
  labelDirection,
  defaultValue = '',
  requiredErrorMsg,
  invalidErrorMsg,
  disabled,
  isRequired = true,
  regExp = POSITIVE_NUMBER_REGEXP,
  dataTestId,
  className,
  labelClassName,
  postText,
  preIcon
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: defaultValue,
    name,
    rules: {
      pattern: {
        message: invalidErrorMsg || `${name} is invalid`,
        value: regExp
      },
      required: {
        message: requiredErrorMsg || `${name} is required`,
        value: isRequired
      }
    }
  });

  return (
    <Common.Input
      {...field}
      className={className}
      dataTestId={dataTestId}
      disabled={disabled}
      errors={fieldState?.error}
      helper={fieldState?.error?.message}
      label={label}
      labelClassName={labelClassName}
      labelDirection={labelDirection}
      placeholder={placeholder}
      postText={postText}
      preIcon={preIcon}
      tabIndex={0}
      type="number"
    />
  );
};

export default NumberInput;

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useGetSingleOrderDetailsQuery } from 'services/shop/shop';

import PrescriptionProgress from 'features/ShippingProgress';
import ShopOrderDetails from 'modals/ShopOrderDetails';
import Loader from 'shared/Loader';

import { PathName } from 'utils/enums';

import { PaymentMethodDetails } from './components';

const DUMMY_SHIPPING_DATA = [
  {
    completed: true,
    label: 'Processed',
    order: 0
  },
  {
    completed: false,
    label: 'Shipping',
    order: 1
  },
  {
    completed: false,
    label: 'Delivered',
    order: 2
  }
];

const Order = () => {
  const { id = '' } = useParams<{ id: string }>();

  const { data, isFetching, isError } = useGetSingleOrderDetailsQuery(id, { skip: !id });
  const navigate = useNavigate();
  const [isOpenDetailsPopup, toggleDetailsPopup] = useToggle(false);

  const getAddress = () => {
    if (!data?.data.shippingAddress) {
      return '';
    }
    return `${data?.data.shippingAddress.address} ${data?.data.shippingAddress.city}, ${data?.data.shippingAddress.state}, ${data?.data.shippingAddress.zipCode}`;
  };

  const getPhases = () => {
    return DUMMY_SHIPPING_DATA.map((item) => {
      if (item.label === 'Shipping') {
        item.completed = ['shipping', 'shipped'].includes(
          String(data?.data.shippingStatus?.toLowerCase())
        );
      }
      if (item.label === 'Delivered') {
        item.completed = data?.data.shippingStatus?.toLowerCase() === 'delivered';
      }
      return item;
    });
  };

  useEffect(() => {
    if (isError) {
      navigate(PathName.Dashboard);
    }
  }, [isError]);

  return (
    <div className="flex h-full flex-col gap-6 md:mx-auto md:max-w-[420px]">
      <Loader isVisible={isFetching || isFetching} />
      <ShopOrderDetails
        data={data?.data}
        isOpen={isOpenDetailsPopup}
        onClose={toggleDetailsPopup}
      />
      <div className="flex flex-col items-center gap-8 py-6">
        <div className="flex rounded-full bg-secondary-100 p-8">
          <Common.Icon className="size-12 flex-none text-secondary" name="check" />
        </div>
        <h3 className="text-m2xl font-bold md:text-2xl">Order #{data?.data.orderNumber} placed</h3>
        <div className="mx-auto mb-6 w-full max-w-[310px]">
          <PrescriptionProgress phases={getPhases()} />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h2 className="text-mBase font-bold">Ship to</h2>
          <span>{getAddress()}</span>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="text-mBase font-bold">Payment</h2>
          <PaymentMethodDetails data={data?.data?.invoice?.payment?.paymentMethod} />
        </div>
      </div>
      <div className="flex flex-col gap-3 pt-6 max-md:mt-auto">
        <Common.Button
          className="!w-full"
          color="blue"
          fullWidthOnMobile
          onClick={() => navigate(PathName.WeightManagement)}
        >
          Back To Dashboard
        </Common.Button>
        <Common.Button
          className="!w-full"
          color="white-alt"
          fullWidthOnMobile
          onClick={toggleDetailsPopup}
        >
          View details
        </Common.Button>
      </div>
    </div>
  );
};

export default Order;

/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Outlet, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { selectUser } from 'store';

import { useAppSelector } from 'hooks';
import useFreemium from 'hooks/useFreemium';

import { getSelectedTab, getVisibleTabs } from './myAccount.settings';

const MyAccount: React.FC = () => {
  useTitle('LifeMD - My Account');
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const isFreemium = useFreemium();
  const selectedTab = getSelectedTab();
  const visibleTabs = getVisibleTabs(isFreemium, !!user?.hasPassword);

  return (
    <div
      className="rounded-2xl bg-transparent md:bg-white md:shadow"
      data-testid="my_account_section"
    >
      <div className="hidden border-gray-200 md:block md:border-b md:px-8 md:pt-4">
        <Common.Tabs
          data={visibleTabs}
          defaultSelected={[selectedTab]}
          type="line"
          onChange={(el) => {
            // @ts-ignore
            navigate(el.value.path);
          }}
        />
      </div>
      <Outlet />
    </div>
  );
};
export default MyAccount;

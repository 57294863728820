import { Link } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';

import { useGetDocumentsQuery } from 'services/documents/documents';
import { useGetOrdersQuery } from 'services/orders/orders';
import {
  useGetAdminPrescriptionsQuery,
  useGetMedicalRecordsQuery,
  useGetPrescriptionsQuery
} from 'services/patientChart/patientChart';
import { useGetProviderQuery } from 'services/providers/providers';
import { useGetReportsQuery } from 'services/reports/reports';

import {
  selectDocuments,
  selectDocumentsAndLinksStatus,
  selectHealthProfileStatus,
  selectLabsAndImagingStatus,
  selectMedicalRecordsStatus,
  selectMyProviderStatus,
  selectOrders,
  selectPatientChart,
  selectProvider,
  selectReports,
  selectUser
} from 'store';

import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { FeatureFlag, PathName } from 'utils/enums';

const cardClassName =
  'w-full relative rounded-2xl sm:shadow-sm sm:hover:shadow sm:bg-white flex sm:border-2 sm:border-transparent justify-start items-center sm:flex-col sm:items-start py-4 sm:p-4';
const iconClassName = 'w-5 h-5';
const iconWrapperClassName = 'flex items-center justify-center size-9 rounded-full mr-2 sm:mr-0';
const labelClassName = 'text-mBase sm:text-lg font-semibold sm:font-bold sm:mt-2 sm:mb-1';
const textClassName = 'hidden sm:block text-sm text-gray font-medium';
const arrowRightClassName = 'ml-auto text-gray sm:hidden';

const Grid = () => {
  const logEvent = useAnalytics();
  const isEnabledDocumentsPageFeature = useFlag(FeatureFlag.DocumentsPage);
  const isEnabledHealthProfilePageFeature = useFlag(FeatureFlag.HealthProfilePage);
  const isEnabledMedicalRecordsPageFeature = useFlag(FeatureFlag.MedicalRecords);
  const isEnabledReportsFeature = useFlag(FeatureFlag.Reports);
  const { displayName: providerDisplayName } = useAppSelector(selectProvider);
  const { totalItems: ordersCount } = useAppSelector(selectOrders);
  const reports = useAppSelector(selectReports);
  const isDisabledMyProvider = useAppSelector(selectMyProviderStatus);
  const isDisabledDocumentsAndLinks = useAppSelector(selectDocumentsAndLinksStatus);
  const isDisabledHealthProfile = useAppSelector(selectHealthProfileStatus);
  const isDisabledLabsAndImaging = useAppSelector(selectLabsAndImagingStatus);
  const isDisabledMedicalRecords = useAppSelector(selectMedicalRecordsStatus);
  const {
    userId,
    doctorId,
    hasActiveResults: activeResultsCount,
    hasActiveOrders: activeOrdersCount
  } = useAppSelector(selectUser);
  const hasActiveResults = activeResultsCount > 0;
  const hasActiveOrders = activeOrdersCount > 0;
  const { totalItems: docsCount } = useAppSelector(selectDocuments);
  useGetAdminPrescriptionsQuery(userId);
  useGetPrescriptionsQuery();
  useGetOrdersQuery();
  useGetReportsQuery();
  useGetDocumentsQuery();
  useGetProviderQuery({ providerId: doctorId }, { skip: !doctorId });
  useGetMedicalRecordsQuery(userId);
  const { prescriptions, adminPrescriptions, medicalRecords } = useAppSelector(selectPatientChart);

  return (
    <div className="flex flex-col" data-testid="my_chart_MyChartGrid">
      <div className="sm:grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3">
        {isEnabledHealthProfilePageFeature && !isDisabledHealthProfile && (
          <Link
            className={cardClassName}
            data-testid="health_profile"
            to={PathName.HealthProfile}
            onClick={() => logEvent('home_health_profile_mitem_click')}
          >
            <div className={`bg-secondary-50 ${iconWrapperClassName}`}>
              <Common.Icon className={`text-secondary ${iconClassName}`} name="tag-person" />
            </div>
            <p className={labelClassName}>Health profile</p>
            <Common.Icon className={arrowRightClassName} name="arrow-right" />
            <span className={textClassName} data-testid="update_your_information">
              Update your information.
            </span>
          </Link>
        )}
        {isEnabledMedicalRecordsPageFeature && !isDisabledMedicalRecords && (
          <Link
            className={cardClassName}
            data-testid="medical_records"
            to={PathName.MedicalRecords}
            onClick={() => logEvent('home_medical_records_mitem_click')}
          >
            <div className={`bg-blue-100 ${iconWrapperClassName}`}>
              <Common.Icon className={`text-blue ${iconClassName}`} name="folder-filled" />
            </div>
            <p className={labelClassName}>Medical records</p>
            <Common.Icon className={arrowRightClassName} name="arrow-right" />
            <span className={textClassName} data-testid="records_count">
              {Object.entries(medicalRecords).reduce((a, b) => a + b[1].length, 0)} records
            </span>
          </Link>
        )}
        {!isDisabledMyProvider && !!doctorId && (
          <Link
            className={cardClassName}
            data-testid="my_provider"
            to={PathName.MyProvider}
            onClick={() => logEvent('home_my_provider_mitem_click')}
          >
            <div className={`bg-yellow-50 ${iconWrapperClassName}`}>
              <Common.Icon className={`text-yellow ${iconClassName}`} name="doctor" />
            </div>
            <p className={labelClassName}>My provider</p>
            <Common.Icon className={arrowRightClassName} name="arrow-right" />
            <span className={textClassName} data-testid="my_provider_name">
              {providerDisplayName}
            </span>
          </Link>
        )}
        <Link
          className={cardClassName}
          data-testid="prescriptions"
          to={`${PathName.Prescriptions}?tab=Prescriptions`}
          onClick={() => logEvent('home_prescriptions_mitem_click')}
        >
          <div className={`bg-orange-100 ${iconWrapperClassName}`}>
            <Common.Icon className={`text-orange ${iconClassName}`} name="prescription-filled" />
          </div>
          <p className={labelClassName}>Prescriptions</p>
          <Common.Icon className={arrowRightClassName} name="arrow-right" />
          <span className={textClassName} data-testid="prescriptions_count">
            {prescriptions.filter((e) => !e.isDocMed).length + adminPrescriptions.length} items
          </span>
        </Link>
        {isEnabledDocumentsPageFeature && !isDisabledDocumentsAndLinks && (
          <Link
            className={cardClassName}
            data-testid="documents_and_links"
            to={PathName.Documents}
            onClick={() => logEvent('home_documents_mitem_click')}
          >
            <div className={`bg-green-50 ${iconWrapperClassName}`}>
              <Common.Icon className={`text-green ${iconClassName}`} name="articles" />
            </div>
            <p className={labelClassName}>Documents</p>
            <Common.Icon className={arrowRightClassName} name="arrow-right" />
            <span className={textClassName} data-testid="docs_and_links_count">
              {docsCount} items
            </span>
          </Link>
        )}
        {!isDisabledLabsAndImaging && isEnabledReportsFeature && (
          <Link
            className={
              hasActiveResults || hasActiveOrders
                ? classNames(cardClassName, 'sm:!border-blue-300 sm:!bg-blue-100')
                : cardClassName
            }
            data-testid="labs_and_imaging"
            to={PathName.Labs}
            onClick={() => logEvent('home_labs_mitem_click')}
          >
            <div className={`bg-violet-50 ${iconWrapperClassName}`}>
              <Common.Icon className={`text-violet ${iconClassName}`} name="experiment" />
            </div>
            {(hasActiveResults || hasActiveOrders) && (
              <div className="absolute left-6 top-4 flex size-2 items-center justify-center rounded-full bg-red text-xs text-white sm:hidden" />
            )}
            <p className={labelClassName}>Labs & imaging</p>
            <Common.Icon className={arrowRightClassName} name="arrow-right" />
            <span
              className={
                hasActiveResults || hasActiveOrders
                  ? classNames(textClassName, 'sm:text-blue')
                  : textClassName
              }
              data-testid="pending_items_count"
            >
              {hasActiveResults || hasActiveOrders ? (
                <>
                  {hasActiveResults &&
                    `${activeResultsCount} new ${activeResultsCount === 1 ? 'result' : 'results'}`}
                  {hasActiveResults && hasActiveOrders && ' / '}
                  {hasActiveOrders &&
                    `${activeOrdersCount} new ${activeOrdersCount === 1 ? 'order' : 'orders'}`}
                </>
              ) : (
                `${ordersCount + reports.length} ${
                  ordersCount + reports.length === 1 ? 'item' : 'items'
                }`
              )}
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};
export default Grid;

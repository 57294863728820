import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import { UploadFileReqProps, UploadFileResProps } from './channels.types';

export const channelsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    uploadFile: build.mutation<UploadFileResProps, UploadFileReqProps>({
      query: (body: UploadFileReqProps) => ({
        body,
        method: 'POST',
        url: 'channels/upload-file'
      })
    })
  }),
  reducerPath: 'channelsApi',
  tagTypes: ['Channels']
});

export const { useUploadFileMutation } = channelsApi;

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import AdvantageItem from 'features/AdvantageItem';
import { PlanCardProps } from 'widgets/PlanSelector/planSelector.types';

import { PlanCodes } from 'utils/enums';
import { getPhraseForMembershipPlan } from 'utils/helpers';

const PlanCard: React.FC<PlanCardProps> = ({
  isActive,
  planInfo,
  onChoosePlan,
  pricePoint,
  flow,
  currentPlan
}) => {
  const subTitle = getPhraseForMembershipPlan(
    planInfo.planCode,
    pricePoint.subsequentAppointmentCost
  );
  const monthlyPriceText =
    planInfo.planCode === PlanCodes.FlexCare
      ? 'Pay per visit'
      : `$${Number(pricePoint.monthlyPrice)}/mo`;

  const appointmentPriceText =
    planInfo.planCode === PlanCodes.FlexCare ? (
      <span>${pricePoint.subsequentAppointmentCost}</span>
    ) : (
      <>
        <span className="mr-1 text-primary-300 line-through">$139</span>
        <span>
          {flow !== 'default' && planInfo.planCode === PlanCodes.TotalCareMembership
            ? '1 Free/mo'
            : '$0'}
        </span>
      </>
    );

  const helperText =
    planInfo.planCode === PlanCodes.FlexCare
      ? ''
      : `$${pricePoint.totalCost} for ${pricePoint.paymentInterval.qty} ${pricePoint.paymentInterval.units}`;

  return (
    <div
      className={classNames(
        'flex size-full flex-col gap-6 rounded-2xl border border-gray-300 p-6',
        isActive ? 'border-secondary-300 bg-secondary-50' : 'bg-white'
      )}
    >
      <div className="min-h-[77px]">
        <div className="flex items-center gap-2">
          <h2 className="font-bold text-primary-700">{planInfo.planName}</h2>
          {isActive && <Common.ColorTag className="grow" color="secondary" text="Most popular" />}
        </div>
        <div className="flex items-center gap-2">
          <span className="mb-1 text-m2xl font-bold text-primary-700 md:text-2xl">
            {monthlyPriceText}
          </span>
          {!!helperText && <span className="text-mSm text-gray md:text-sm">{helperText}</span>}
        </div>
        {subTitle && <span className="block text-left text-mSm text-primary">{subTitle}</span>}
      </div>
      <div
        className={classNames(
          '-mx-6 flex items-center justify-between px-6 py-2 font-bold text-primary-700',
          isActive ? 'bg-secondary-100' : 'bg-gray-100'
        )}
      >
        <span>{flow === 'default' ? 'Today’s appointment' : 'Appointments'}</span>
        <span>{appointmentPriceText}</span>
      </div>
      <div className="mb-10 flex flex-col gap-2">
        {planInfo.additionalInfo.map((el) => (
          <AdvantageItem advantage={el} className="!text-base" key={el.question} showCustomIcon />
        ))}
      </div>
      {(flow === 'short' && planInfo.planCode === PlanCodes.FlexCare) ||
      currentPlan === planInfo._id ? (
        <Common.Button className="sticky bottom-4 mt-auto w-full justify-center self-end" disabled>
          Current plan
        </Common.Button>
      ) : (
        <Common.Button
          className="sticky bottom-4 mt-auto w-full justify-center self-end"
          color="blue"
          onClick={() => onChoosePlan(planInfo._id, pricePoint)}
        >
          Choose {planInfo.planName}
        </Common.Button>
      )}
    </div>
  );
};

export default PlanCard;

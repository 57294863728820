import { ChangeEvent, PropsWithChildren, useRef } from 'react';

import { notifyError } from 'shared/Toast/Toast';

import { fileValidator } from './dropContainer.settings';
import { DropContainerProps } from './dropContainer.types';

const DropContainer: React.FC<PropsWithChildren<DropContainerProps>> = ({
  children,
  onChange,
  setDragOverlay,
  className,
  multiple = true
}) => {
  const dragCounter = useRef(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    handleDragOver(e);
    dragCounter.current++;
    if (e.dataTransfer.items.length > 0) {
      setDragOverlay?.(true);
    }
  };
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    handleDragOver(e);
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverlay?.(false);
    }
  };

  const onChangeFiles = (files: FileList) => {
    setDragOverlay?.(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files);
    if (!isValidFile) {
      if (errVal) {
        notifyError(errVal);
      }
      return false;
    }
    onChange(files);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (!e.dataTransfer) {
      return;
    }
    const files = e.dataTransfer.files;
    onChangeFiles(files);
    handleDragOver(e);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    onChangeFiles(e.target.files);
    e.target.files = null;
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <div
      className={className}
      data-testid="drop-container"
      onClick={handleClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        className="hidden"
        multiple={multiple}
        ref={inputRef}
        role="input"
        type="file"
        onChange={handleChangeInput}
      />
      {children}
    </div>
  );
};

export default DropContainer;

import React, { useState } from 'react';
import { Common } from '@thecvlb/design-system';

import { selectUser } from 'store';

import CreditCard from 'features/CreditCard';
import DiscountCardDetails from 'modals/DiscountCardDetails/DiscountCardDetails';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { useAppSelector } from 'hooks';
import useWidth from 'hooks/useWidth';

import CVSImg from 'assets/images/logos/cvs-pharmacy.svg?react';
import RiteImg from 'assets/images/logos/rite-aid.svg?react';
import WalGreensImg from 'assets/images/logos/wall-greens.svg?react';
import WalmartImg from 'assets/images/logos/walmart.svg?react';

const DiscountCardTab = () => {
  const [activeState, setActiveState] = useState<'preview' | 'details'>('preview');
  const { isMobile } = useWidth();
  const { firstName, lastName } = useAppSelector(selectUser);
  return isMobile ? (
    <FadeWrapper>
      <div
        className="discount-card-wrapper relative mx-4 my-6 flex min-h-[366px] flex-1 flex-col justify-end pb-6"
        data-testid="discount_card_mobile"
      >
        <div className="discount-card-wrapper absolute -inset-x-4 top-0 mt-4">
          <div className="mx-auto w-[343px] max-w-full">
            <CreditCard
              additionalClassName="mx-auto"
              ownerName={`${firstName} ${lastName}`}
              provider="ProCare RX"
            />
          </div>
        </div>
        <div className="mx-4">
          <p className="mb-4 text-center text-mSm font-semibold text-gray-200">
            Present this card at your pharmacy to save up to 92% on prescription medications.
          </p>
          <Common.Button
            color="white"
            preIcon="info"
            fullWidthOnMobile
            onClick={() => setActiveState('details')}
          >
            See details
          </Common.Button>
        </div>
      </div>
      <DiscountCardDetails
        isOpen={activeState === 'details'}
        onClose={() => setActiveState('preview')}
      />
    </FadeWrapper>
  ) : (
    <FadeWrapper
      className="discount-card-wrapper mt-4 flex flex-col flex-wrap items-center justify-center gap-2 p-4 md:p-12 xl:flex-row"
      data-testid="discount_card"
    >
      <div className="flex w-full max-w-[656px] flex-1 flex-col gap-4 text-white">
        <h2 className="text-mLg font-bold md:text-2xl">Medication Savings program</h2>
        <div className="text-mBase font-bold md:text-lg md:font-semibold">
          <h4 className="text-secondary">Member Services:</h4>
          <a href="tel:866-936-7381">866-936-7381</a>
        </div>

        <p className="mb-0.5 text-mSm font-medium md:text-base">
          Present this card to your pharmacist at the time of purchase. The program discount will be
          applied for all non-insurance claims. Discounts available only at participating
          pharmacies. By using the card, the holder agrees to the terms and conditions under which
          it was issued.
        </p>
        <div className="flex gap-4">
          <p className="mb-0.5 w-1/2 text-mSm font-medium md:text-base">
            <span className="font-bold">Participant: </span>For questions or comments regarding the
            use of your LifeMD pharmacy discount card, contact us toll-free at{' '}
            <span className="font-bold">1-866-936-7381</span>.
          </p>
          <p className="mb-0.5 w-1/2 text-mSm font-medium md:text-base">
            <span className="font-bold">Pharmacist: </span>The RxGRP and BIN must be submitted
            online to ProCare Rx to process claims for this program. For questions regarding online
            claims submission call toll-free at 1-888-299-5383.
          </p>
        </div>
        <div className="flex w-full flex-wrap items-center justify-between rounded-2xl bg-secondary-100 px-12 py-4 xl:px-5">
          <WalmartImg role="img" />
          <CVSImg role="img" />
          <RiteImg role="img" />
          <WalGreensImg role="img" />
        </div>
      </div>
      <div className="-ml-8 max-w-[343px] origin-right scale-[0.8]">
        <CreditCard ownerName={`${firstName} ${lastName}`} provider="ProCare RX" />
        <p className="mt-4 text-center text-mSm font-semibold text-gray-200 md:text-base">
          Present this card at your pharmacy to save up to 92% on prescription medications.
        </p>
      </div>
    </FadeWrapper>
  );
};

export default DiscountCardTab;

import React, { PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { CollapseProps } from './collapse.types';

const Collapse: React.FC<PropsWithChildren<CollapseProps>> = ({
  children,
  title,
  subTitle,
  onChange,
  dataTestId,
  alwaysOpenChildren,
  actions,
  colorTag
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  const handleClick = () => {
    toggleIsOpen();
    onChange?.();
  };

  return (
    <div className="w-full rounded-2xl border border-gray-200 bg-white p-4">
      <button className="flex w-full items-center" onClick={handleClick}>
        <div className="flex flex-1 flex-col items-start gap-2">
          <span className="text-mBase font-semibold text-gray md:text-lg">{title}</span>
          <span className="text-m2xl font-bold md:text-xl" data-testid={dataTestId}>
            {subTitle}
          </span>
        </div>
        {colorTag}
        <Common.Icon className="text-gray" name={isOpen ? 'arrow-down' : 'arrow-right'} />
      </button>
      {alwaysOpenChildren}
      {!isOpen && actions && actions(handleClick, isOpen)}
      <div>{isOpen && children}</div>
      {isOpen && actions && actions(handleClick, isOpen)}
    </div>
  );
};

export default Collapse;

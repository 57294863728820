import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

export const calculateWeightLose = (totalWL: string | number, period: string) => {
  const wl: { [key: string]: string } = {
    '1': '1.7%',
    '12': '20%',
    '3': '5%',
    '6': '10%'
  };

  return wl[period] ? `${((Number(totalWL) * parseFloat(wl[period])) / 100).toFixed(0)}` : 'N/A';
};

export const compoundMedicationAdvantages = (
  <>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm font-bold md:text-sm">Medication included</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Prescription & Doctor included</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Cancel anytime</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Full Medical history assessment</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Weight loss related labs (no addtl. cost)</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Medication shipped directly to your door</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">
        Clinical oversight + tailored treatment to your door
      </span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Ongoing consultations with LifeMD provider</span>
    </div>
  </>
);

export const semaglutideExtendedAdvantages = (
  <>
    {[
      'Medication included',
      'Prescription & Doctor included',
      'Shipped monthly. Cancel anytime',
      'Full Medical history assessment',
      'Weight loss related labs (no addtl. cost)',
      'Medication shipped directly to your door',
      'Clinical oversight + tailored treatment to your door',
      'Ongoing consultations with LifeMD provider'
    ].map((a, i) => (
      <div
        className={classNames('flex gap-2 text-start text-primary-700 max-md:items-center', {
          'font-bold': i === 0
        })}
        key={a}
      >
        <Common.Icon className="flex-none" name="check" />
        <span className="text-mSm md:text-sm">{a}</span>
      </div>
    ))}
  </>
);

export const brandedMedicationAdvantages = (
  <>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Prescription & Doctor included</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Medication through insurance</span>
    </div>
    <div className={'flex gap-2 text-start text-primary-700 max-md:items-center'}>
      <Common.Icon className="flex-none" name="check" />
      <span className="text-mSm md:text-sm">Billed quarterly</span>
    </div>
    <div className={'flex items-center gap-2 text-start text-primary-700'}>
      <Common.Icon className="flex-none" name="check" />
      <div className="flex flex-col text-mSm md:text-sm">
        <span className="font-bold">Medications available:</span>
        <span>Ozempic®, Zepbound®, Wegovy®</span>
      </div>
    </div>
  </>
);

export const DUMMY_REVIEWS_WM = [
  {
    author: 'Jamie R.',
    rating: 5,
    text: (
      <p>
        “Every morning I look forward to getting on the scale. <b>I’ve lost 17 pounds so far.”</b>
      </p>
    )
  },
  {
    author: 'Blake T.',
    rating: 5,
    text: (
      <p>
        “I’ve been on the medication for about six weeks and <b>I’ve lost 20 pounds!</b>”
      </p>
    )
  },
  {
    author: 'Tara O.',
    rating: 5,
    text: (
      <p>
        “<b>My clothes are fitting the way they should,</b> and I’m losing the extra fluff around my
        muscle. I just feel way more comfortable in myself”
      </p>
    )
  },
  {
    author: 'Justin G.',
    rating: 5,
    text: (
      <p>
        “Just want to say thank you! <b>I feel much better now.</b>”
      </p>
    )
  },
  {
    author: 'Jack D.',
    rating: 5,
    text: (
      <p>
        “Starting the program was a seamless, easy process.{' '}
        <b>For anybody who’s struggling with their weight, it’s a...</b>”
      </p>
    )
  }
];

import { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { Common } from '@thecvlb/design-system';

import { useAddReviewMutation } from 'services/providers/providers';
import { ReviewPlatform } from 'services/providers/providers.types';

import { selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import { useAppDispatch, useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useWidth from 'hooks/useWidth';
import { handleRequestCatch } from 'utils/helpers';

import { ProviderReviewProps } from './providerReview.types';

const COMMENT_LENGTH_LIMIT = 5000;

const ProviderReview: React.FC<ProviderReviewProps> = ({ isOpen, onClose, onSkip, provider }) => {
  const logEvent = useAnalytics();
  const dispatch = useAppDispatch();
  const { reviewedProviders } = useAppSelector(selectUser);
  const { isMobile } = useWidth();
  const [addReview, { isLoading }] = useAddReviewMutation();
  const [rating, setRating] = useState(0);
  const [reviewComment, setReviewComment] = useState('');

  const handleAddReviewThen = () => {
    dispatch(setUser({ lastSentRating: rating }));
    if (!reviewedProviders?.includes(provider.userId)) {
      dispatch(
        setUser({
          reviewedProviders: [...reviewedProviders, provider.userId]
        })
      );
    }
    onClose();
  };

  const handleClickSendReview = () => {
    logEvent('review_provider_send_btn_click');
    if (!provider?.userId) onSkip?.();
    const body = {
      doctorId: provider.userId,
      platform: isMobile ? ReviewPlatform.Mobile : ReviewPlatform.Desktop,
      rating,
      reviewComment
    };
    addReview(body).unwrap().then(handleAddReviewThen).catch(handleRequestCatch);
  };

  const handleClickSkip = () => {
    logEvent('review_provider_skip_btn_click');
    onSkip?.();
  };

  return (
    <Common.Modal close={onSkip} isOpen={isOpen} size="sm" zIndex={30}>
      <div
        className="mb-4 mt-5 flex flex-col items-center justify-center gap-[16px]"
        data-testid="review_provider"
      >
        <h2
          className="flex text-mXl font-bold text-gray-700 md:text-xl"
          data-testid="review_provider_heading"
        >
          Review your provider
        </h2>
        <Common.ProfileImage size="2xl" src={provider.profileImage} />
        <div className="flex max-w-[80%] flex-col items-center gap-[16px]">
          <span className="truncate text-mLg font-bold text-secondary md:text-lg">
            {provider.displayName}
          </span>
          <Rating
            emptyColor="transparent"
            fillColor="#FFA700"
            size={32}
            SVGclassName="inline-block"
            SVGstorkeWidth="1"
            SVGstrokeColor={rating === 0 ? '#C4CACC' : '#FFA700'}
            onClick={setRating}
          />
        </div>
      </div>
      <h4 className="mb-1 text-mBase font-semibold text-gray-700 md:text-base">Additional notes</h4>
      <Common.TextArea
        className="mx-0.5 mb-4"
        dataTestId="review_field"
        maxLength={COMMENT_LENGTH_LIMIT}
        name="review"
        placeholder="Tell us about your experience…"
        rows={3}
        value={reviewComment}
        onChange={(e) => setReviewComment(e.target.value)}
      />
      <p className="mb-6 text-base text-gray md:text-sm">
        Please do not include any private medical information. This review may be available
        publicly.
      </p>
      <div className="flex w-full items-center gap-4 max-md:flex-col-reverse max-md:pb-4 md:gap-2">
        <Common.Button
          className="md:basis-1/2"
          color="white-alt"
          fullWidthOnMobile
          onClick={handleClickSkip}
        >
          Skip
        </Common.Button>
        <Common.Button
          className="md:basis-1/2"
          color="blue"
          dataTestId="continue_without_app"
          disabled={rating < 1 || isLoading}
          fullWidthOnMobile
          onClick={handleClickSendReview}
        >
          Send review
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default ProviderReview;

import { AppointmentProps } from 'store/appointments/appointments.types';

import { StepName } from './createAppointment.types';

export const isButtonDisabled = (loading: boolean, stepName: StepName, data: AppointmentProps) => {
  if (loading) {
    return true;
  }
  if (stepName === 'CATEGORY') {
    return !data.appointmentTypeId;
  }
  if (stepName === 'DATE_TIME') {
    return !data.endTime;
  }
  return false;
};

export const buildBodyForApptSchedule = (data: AppointmentProps) => ({
  appointmentDescription: data.appointmentDescription.trim(),
  appointmentMethod: data.callType,
  appointmentTime: {
    endTime: data.endTime,
    startTime: data.startTime
  },
  appointmentTypeId: data.appointmentTypeId,
  doctorId: data.doctorId,
  isWeightManagementAppointment: data.isWeightManagementAppointment
});

import { Socket } from 'socket.io-client/build/esm/socket';

export interface SocketResProps {
  message: string;
  status: boolean;
}

export function socketInit(socket: Socket, accessToken: string) {
  if (socket.connected) {
    return;
  }
  socket.auth = { authorization: accessToken };
  socket.connect();
}
export function socketClose(socket: Socket) {
  socket.auth = { authorization: '' };
  socket.disconnect();
}

export async function socketReconnect(socket: Socket, refreshToken: string) {
  try {
    socketClose(socket);
    const res = await fetch(import.meta.env.VITE_BASE_URL + '/auth/token-renewal', {
      body: JSON.stringify({ refreshToken }),
      headers: {
        'API-KEY': import.meta.env.VITE_API_KEY ?? '',
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });
    const data = await res.json();
    socketInit(socket, data.data?.accessToken || '');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
}

export const errorCallBack = (event: string, res: SocketResProps | Error) => {
  if ('status' in res && res.status) {
    // eslint-disable-next-line no-console
    console.log(`socket ${event} => ${res.message}`);
  } else {
    // eslint-disable-next-line no-console
    console.error(`socket ${event} error => ${res.message}`);
  }
};

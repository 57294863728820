import { Common } from '@thecvlb/design-system';

import {
  LINK_TO_LOCAL_EMERGENCY_SERVICES,
  LINK_TO_LOCAL_URGENT_CARE
} from 'constants/externalLinks';

import CalendarSvg from 'assets/icons/ambulance.svg?react';

const MedicalCareNear = () => {
  const openLink = (link: string) => window.open(link);

  return (
    <div className="max-w-xl">
      <CalendarSvg className="mx-auto w-40 text-primary-100" />
      <h1 className="large-title mt-4 md:mt-1">
        Use the buttons below to find in-person medical care near you
      </h1>
      <p className="mt-4">
        The symptoms you’re experiencing could possibly represent signs of an urgent/emergent
        problem and require in-person care to determine proper diagnosis and course of treatment.
        This immediate hands-on evaluation (in the form of imaging, lab work, a physical
        examination, or other approach) is beyond the scope of what LifeMD can provide through
        telemedicine. Please access in-person care as soon as possible.
      </p>
      <div className="mb-11 mt-8 flex flex-col items-center justify-center gap-5 md:flex-row md:items-stretch">
        <Common.Button
          color="red-alt"
          postIcon="arrow-right"
          style="pill"
          onClick={() => openLink(LINK_TO_LOCAL_EMERGENCY_SERVICES)}
        >
          Emergency Room
        </Common.Button>
        <Common.Button
          color="blue"
          postIcon="arrow-right"
          style="pill"
          onClick={() => openLink(LINK_TO_LOCAL_URGENT_CARE)}
        >
          Urgent Care
        </Common.Button>
      </div>
    </div>
  );
};

export default MedicalCareNear;

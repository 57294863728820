import { useTitle } from 'react-use';

const MedicaidWaiver = () => {
  useTitle('LifeMD - Medicaid Waiver');

  return (
    <div className="text-primary-700">
      <p>
        LifeMD, Inc. (“LifeMD”) provides a secure platform for working with health providers of the
        following affiliated medical P.C.s and P.A.s:  LifeMD Southern Patient Medical Care, P.C.;
        LifeMD Atlantic Patient Medical Care, P.C.; LifeMD Kansas Patient Medical Care, P.A., LifeMD
        Midwest Patient Medical Care, P.C.; LifeMD New England Patient Medical Care, P.A.; LifeMD
        Puerto Rico Patient Medical Care, P.C.; LifeMD South Central Patient Medical Care, P.A.; New
        York Patient Medical Care, P.C.; and Puopolo M.D., P.C. d/b/a LifeMD Western Patient Medical
        Care P.C. (“LifeMD Affiliated PCs”). The purpose of this form is to obtain certain consents
        related to the use of your insurance coverage for telehealth care with one of LifeMD
        Affiliated PCs’ health providers via LifeMD’s Telehealth platform. By signing your name
        below, you certify that you are the patient identified and that you are 18 years of age or
        older or otherwise legally authorized to consent. You further certify that you have
        carefully read, understood, and agree to the below statements. You understand this informed
        consent will become a part of your medical record. I acknowledge that, as a courtesy, LifeMD
        and/or LifeMD Affiliated PCs may bill my insurance company for services provided to me. I
        agree to pay for services that are not covered or covered charges not paid in full
        including, but not limited to any co-payment, co-insurance and/or deductible, or charges not
        covered by insurance. Insurance Patient Financial Policy. I acknowledge and agree to the
        following insurance patient financial policy:
      </p>
      <p className="mt-10 text-mXs font-semibold">Agreed on 11/12/2024 by</p>
      <p className="my-2 text-gray">Evan Bates</p>
    </div>
  );
};
export default MedicaidWaiver;

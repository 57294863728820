import buzzfeed from 'assets/images/wm-research/buzzfeed.svg';
import cnn from 'assets/images/wm-research/cnn.svg';
import glamour from 'assets/images/wm-research/glamour.svg';
import insider from 'assets/images/wm-research/insider.svg';
import tnyt from 'assets/images/wm-research/the-new-york-times.svg';
import twsj from 'assets/images/wm-research/the-wall-street-journal.svg';
import variety from 'assets/images/wm-research/variety.svg';

const brands = [cnn, insider, variety, twsj, tnyt, buzzfeed, glamour];

const ListOfResearch = () => {
  return (
    <div className="flex items-center gap-8 max-md:flex-col md:justify-center md:gap-10">
      {brands.map((b) => (
        <img alt="" className="max-h-[18px]" height={18} key={b} src={b} width={150} />
      ))}
    </div>
  );
};

export default ListOfResearch;

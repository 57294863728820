import { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';

import { useChangeOrdersStatusMutation, useLazyGetOrderQuery } from 'services/orders/orders';
import { GetOrderResProps } from 'services/orders/orders.types';

import { selectOrders } from 'store';
import { OrdersItemsItemProps } from 'store/orders/orders.types';

import MemberPricing from 'modals/MemberPricing';
import Loader from 'shared/Loader';

import { LINK_TO_SCHEDULE_LABS } from 'constants/externalLinks';
import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { DateFormat, FeatureFlag } from 'utils/enums';

import { RequestedLabProps } from './requestedLab.types';

const RequestedLab: React.FC<RequestedLabProps> = ({
  stepsList,
  isOpen,
  onClose,
  pending,
  date
}) => {
  const logEvent = useAnalytics();
  const [openModal, toggleOpenModal] = useToggle(false);
  const { orders } = useAppSelector(selectOrders);
  const [getOrderQuery, { isLoading }] = useLazyGetOrderQuery();
  const [changeStatus] = useChangeOrdersStatusMutation();
  const isEnabledMemberPricingFeature = useFlag(FeatureFlag.MemberPricing);

  const handleGetOrderQueryThen = (data: GetOrderResProps) => {
    window.open(window.URL.createObjectURL(data));
  };

  const handleView = (labOrderId: string) => {
    getOrderQuery({ labOrderId }).unwrap().then(handleGetOrderQueryThen);
  };

  const handleClickOrder = (order: OrdersItemsItemProps) => {
    logEvent('labs_details_popup_order_item_click');
    handleView(order.elationOrderId);
  };

  const handleClickScheduleLabs = () => {
    logEvent('labs_details_popup_schedule_btn_click');
  };

  const handleClickCall = () => {
    logEvent('labs_details_popup_sup_call_btn_click');
  };

  const handleUpdatedOpenModal = () => {
    const items = orders.find((item) => item.date === date)?.items;
    if (isOpen && !pending && items) {
      changeStatus({
        orderIdsList: items.filter((item) => !item.isViewed).map((item) => item._id),
        orderStatus: 'viewed'
      });
    }
  };

  useEffect(handleUpdatedOpenModal, [isOpen]);

  return (
    <>
      <Common.Modal close={onClose} isOpen={isOpen} size="base" zIndex={30}>
        <Loader isVisible={isLoading} />
        <h1
          className="mt-6 text-center text-xl font-bold text-primary md:font-normal"
          data-testid="schedule_lab_header"
        >
          It’s time to schedule your labs.
        </h1>
        <ul className="my-7 flex flex-col gap-2.5">
          {stepsList.map((item, index) => (
            <li className="flex items-center gap-2 text-base font-medium md:text-sm" key={item}>
              <span className="flex size-4 flex-none items-center justify-center rounded-full border border-primary text-2xs font-bold text-primary">
                {index + 1}
              </span>
              {item}
            </li>
          ))}
        </ul>
        {orders
          .find((item) => item.date === date)
          ?.items.filter((item) => (pending ? item.isViewed : !item.isViewed))
          .map((item) => (
            <button
              className="mb-2 flex w-full items-center gap-3 rounded-lg bg-gray-100 px-4 py-2 text-left"
              data-testid="button_view"
              key={item._id}
              onClick={() => handleClickOrder(item)}
            >
              <Common.Icon className="text-primary" name="articles" />
              <div className="flex-1">
                <p className="font-bold text-primary md:text-base md:font-normal">{item.title}</p>
                <p className="mt-0.5 text-base text-gray md:text-sm">
                  {dayjs(item.signedDate).format(DateFormat.MMM_DD)}
                </p>
              </div>
              <span className="text-base font-medium text-gray md:text-sm">View PDF</span>
            </button>
          ))}
        {isEnabledMemberPricingFeature && stepsList.length > 3 && (
          <p className="text-center text-base font-bold text-gray md:text-sm md:font-normal">
            Estimated price: $15.25{' '}
            <button
              className="cursor-pointer text-base font-bold underline md:text-sm md:font-normal"
              onClick={toggleOpenModal}
            >
              View member pricing
            </button>
          </p>
        )}
        <div className="mt-7 flex flex-col flex-wrap items-center justify-center gap-3 md:flex-row">
          <a href={LINK_TO_SCHEDULE_LABS} target="_blank">
            <Common.Button
              color="blue"
              dataTestId="schedule_lab_online"
              style="pill"
              onClick={handleClickScheduleLabs}
            >
              Schedule labs online
            </Common.Button>
          </a>
          <span className="text-base font-medium text-primary md:hidden">OR</span>
          <a href="tel:18882778772">
            <Common.Button color="blue-alt" style="pill" onClick={handleClickCall}>
              Call 1-888-277-8772
            </Common.Button>
          </a>
        </div>
      </Common.Modal>
      <MemberPricing isOpen={openModal} onClose={toggleOpenModal} />
    </>
  );
};

export default RequestedLab;

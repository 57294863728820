import { Common } from '@thecvlb/design-system/lib/src';

import { FeaturedProductProps } from './featuredProduct.types';

import medications from 'assets/images/medications/med-l.webp';

const FeaturedProduct: React.FC<FeaturedProductProps> = ({ onUpgradePlan }) => {
  return (
    <div className="flex w-full max-md:flex-col" data-testid="featured_product">
      <img alt="medications" className="w-[360px] basis-1/2" src={medications} />
      <div className="flex basis-1/2 flex-col gap-4 bg-primary-50 p-6 backdrop-blur-sm md:justify-between">
        <h3 className="text-mBase font-semibold">GLP-1 Weight Management (Medication included)</h3>
        <Common.Button color="blue" dataTestId="upgrade_plan" size="sm" onClick={onUpgradePlan}>
          Upgrade plan
        </Common.Button>
      </div>
    </div>
  );
};

export default FeaturedProduct;

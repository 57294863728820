import { Common } from '@thecvlb/design-system';

import { RescheduleAppointmentProps } from './rescheduleAppointment.types';

const RescheduleAppointment: React.FC<RescheduleAppointmentProps> = ({
  onClose,
  reschedule,
  isOpen
}) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="sm">
      <div className="p-4" data-testid="confirm_popup">
        <h2 className="mb-2 text-xl font-bold" data-testid="header">
          Cancel this appointment?
        </h2>
        <p className="mb-6 text-base font-medium">
          Since your provider has already joined the call, you cannot reschedule this appointment,
          and will still be charged if you cancel.
        </p>
        <div className="flex flex-col gap-3 md:flex-row">
          <Common.Button
            color="red"
            dataTestId="reschedule_btn_on_popup"
            fullWidthOnMobile
            onClick={reschedule}
          >
            Reschedule / cancel
          </Common.Button>
          <Common.Button
            color="white-alt"
            dataTestId="cancel_btn"
            fullWidthOnMobile
            onClick={onClose}
          >
            Back
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default RescheduleAppointment;

import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { useGetFrontDeskTypesQuery } from 'services/lookup/lookup';

import { selectLookup, selectUser } from 'store';

import ChannelCard from 'pages/Messages/components/Channels/ChannelCard';
import ChannelTabs from 'pages/Messages/components/Channels/ChannelTabs';
import { TabProps } from 'pages/Messages/components/Channels/ChannelTabs/channelTabs.types';

import AskToUpgradeToTheWM from 'modals/AskToUpgradeToTheWM';

import { useAppSelector, useFrontDesk, useMessages, useQuery } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { useRouteMatch } from 'hooks/useRouteMatch';
import useWidth from 'hooks/useWidth';
import { MessageTab, PathName } from 'utils/enums';
import { sumUnreadMessages } from 'utils/helpers';

const Channels = () => {
  const logEvent = useAnalytics();

  const { isMobile } = useWidth();
  const query = useQuery();
  const chatId = query.get('chatId');
  const queryTabId = query.get('tab');
  const { channels: frontDeskChannels, getChannels: getFrontDeskChannels } = useFrontDesk();
  const { channels: careTeamChannels, getChannels: getCareTeamChannels } = useMessages();
  const { frontDeskTypes } = useAppSelector(selectLookup);
  const { activePlanCode } = useAppSelector(selectUser);
  useGetFrontDeskTypesQuery();
  const isCreateNewRequest = useRouteMatch(PathName.CreateNewRequest)?.isExact;

  const navigate = useNavigate();
  const tabs: TabProps[] = [
    {
      icon: 'info-person-filled',
      id: MessageTab.FRONT_DESK,
      label: 'Front desk',
      unreadMessageCount: sumUnreadMessages(frontDeskChannels.map((c) => c.unreadMessageCount))
    },
    {
      icon: 'doctor',
      id: MessageTab.CARE_TEAM,
      label: 'Care team',
      unreadMessageCount: sumUnreadMessages(careTeamChannels.map((c) => c.unreadMessageCount))
    }
  ];

  const [scrollTop, setScrollTop] = useState(0);
  const scrollBlock = useRef<HTMLDivElement | null>(null);

  const selectedChannel =
    careTeamChannels.find((el) => el.channelId === chatId) ||
    frontDeskChannels.find((el) => el.channelId === chatId);

  const isFrontDesk = queryTabId === MessageTab.FRONT_DESK;

  const handleClickCreateNewRequest = () => {
    logEvent('messages_new_request_btn_click');
    navigate(PathName.CreateNewRequest + `?tab=${MessageTab.FRONT_DESK}`);
  };

  const handleChangeTab = (tabId: MessageTab.FRONT_DESK | MessageTab.CARE_TEAM) => {
    const channelId =
      tabs[0].id === tabId ? frontDeskChannels[0]?.channelId : careTeamChannels[0]?.channelId;
    if (!channelId && tabId === MessageTab.FRONT_DESK) {
      navigate({
        pathname: PathName.CreateNewRequest,
        search: `tab=${MessageTab.FRONT_DESK}`
      });
      return;
    }
    navigate({
      pathname: PathName.Messages,
      search: `tab=${tabId}${isMobile ? '' : channelId ? `&chatId=${channelId}` : ''}`
    });
  };

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  const handleUpdatedScrollTop = () => {
    const offsetHeight = scrollBlock.current?.offsetHeight || 0;
    const scrollHeight = Math.round(Math.abs(scrollTop) + offsetHeight);
    if (scrollHeight === scrollBlock.current?.scrollHeight) {
      if (isFrontDesk) {
        getFrontDeskChannels();
      } else {
        getCareTeamChannels();
      }
    }
  };

  useEffect(handleUpdatedScrollTop, [scrollTop]);

  useEffect(() => {
    if (!queryTabId) {
      navigate({ search: `?tab=${tabs[0].id}` });
    }
  }, [queryTabId]);

  useEffect(() => {
    if (!chatId) {
      if (isCreateNewRequest) return;
      handleChangeTab((queryTabId || tabs[0].id) as MessageTab.FRONT_DESK | MessageTab.CARE_TEAM);
    }
  }, [queryTabId, chatId, frontDeskChannels, careTeamChannels]);

  const isRestrictedAccess = queryTabId === MessageTab.CARE_TEAM && !activePlanCode;

  return (
    <>
      <AskToUpgradeToTheWM
        alertContent={
          <>
            <b>Please upgrade your plan</b> to access messaging with the care team.
          </>
        }
        declineButtonText="Close"
        isOpen={isRestrictedAccess}
        hideAdditionalPopup
        onClose={() => handleChangeTab(tabs[0].id)}
        onReopen={() => {}}
      />
      {!(isMobile && (selectedChannel || isCreateNewRequest)) && (
        <div className="flex size-full flex-none flex-col md:max-w-[360px] md:border-r md:border-gray-200">
          <ChannelTabs
            selectedTabId={queryTabId}
            tabs={tabs}
            onChange={({ id }) => handleChangeTab(id)}
          />
          <div
            className="flex w-full flex-col overflow-auto"
            data-testid="channel_list"
            ref={scrollBlock}
            onScroll={handleScroll}
          >
            {(isFrontDesk ? frontDeskChannels : careTeamChannels).map((channel) => (
              <ChannelCard channel={channel} key={channel.channelId} />
            ))}
            {frontDeskTypes.filter(
              (frontDeskType) =>
                !frontDeskChannels.filter(
                  (channel) => channel.frontDeskRequestTypeId === frontDeskType._id
                ).length
            ).length > 0 &&
              isFrontDesk && (
                <button
                  className={classNames(
                    'relative flex items-center gap-2 p-4 pl-10 font-bold',
                    'text-blue after:absolute after:bottom-0',
                    'after:left-10 after:right-4 after:border-b after:border-gray-200',
                    { 'bg-gray-100': isCreateNewRequest }
                  )}
                  data-testid="create_new_request"
                  onClick={handleClickCreateNewRequest}
                >
                  <Common.Icon className="relative z-[1] size-4" name="plus" />
                  <span className="relative z-[1] flex-1 text-left">Create new request</span>
                  <Common.Icon className="relative size-4 text-gray md:hidden" name="arrow-right" />
                </button>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default Channels;

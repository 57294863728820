import classNames from 'classnames';

import { SwitcherProps } from './switchToApp.types';

const Switcher: React.FC<SwitcherProps> = ({ onUpdate, activeItemIndex = 0, tabs = [] }) => {
  const wrapperClassName = 'flex items-center rounded-full p-1 shadow';
  const getButtonClassName = (t: string | number) =>
    classNames(
      'px-4 py-2 transition-all text-sm font-semibold rounded-full',
      {
        'text-secondary-600 bg-secondary-100': t === activeItemIndex
      },
      { 'text-gray': t !== activeItemIndex }
    );

  const handleUpdate = (v: number) => () => onUpdate(v);

  return (
    <div className={wrapperClassName} data-testid="switcher">
      {tabs.map(({ label, value, dataTestId }) => (
        <button
          className={getButtonClassName(value)}
          data-testid={dataTestId}
          key={value}
          onClick={handleUpdate(value)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default Switcher;

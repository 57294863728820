import { useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';

import ExpandablePanel from 'shared/ExpandablePanel';

import { parseText } from 'utils/parseText';

import { AccordionInfoProps } from './accordionInfo.types';

const AccordionInfo: React.FC<AccordionInfoProps> = ({ items }) => {
  const [activeElement, setActiveElement] = useState('');

  return (
    <div
      className="divide-y divide-gray-200 border-b-2 border-gray-200"
      data-testid="product_info_accordion"
    >
      {items.map((el) => (
        <ExpandablePanel
          className="px-3 py-4"
          content={
            typeof el.text !== 'string' ? (
              <p className="text-gray-700">{el.text}</p>
            ) : (
              <div>{HTMLReactParser(parseText(el.text))}</div>
            )
          }
          dataTestId="accordion_item"
          header={
            <div className="flex cursor-pointer justify-between">
              <h3 className="text-mSm font-semibold text-gray-700 md:text-sm">{el.title}</h3>
              <Common.Icon
                className={classNames(
                  'ml-1.5 size-5 flex-none text-gray transition-all duration-300 md:h-4 md:w-4',
                  {
                    'rotate-90': el.title === activeElement
                  }
                )}
                name="arrow-right"
              />
            </div>
          }
          isShowContent={el.title === activeElement}
          key={el.title}
          toggleContent={() => setActiveElement(el.title === activeElement ? '' : el.title)}
        />
      ))}
    </div>
  );
};

export default AccordionInfo;

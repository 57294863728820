import { SpecialtyProps } from './specialty.types';

const Specialty: React.FC<SpecialtyProps> = ({ name, icon }) => {
  return (
    <div className="flex items-center gap-1 rounded-lg bg-primary-50 p-2">
      <img alt={name} className="size-5" src={icon} />
      <span className="text-sm text-gray-700" data-testid="areas">
        {name}
      </span>
    </div>
  );
};

export default Specialty;

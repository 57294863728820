import { useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import classNames from 'classnames';

import { ASAP_OPTION_DATE } from 'constants/defaults';

import { DayCardProps } from '../schedulingDaySlider.types';

import { getContent } from './dayCard.settings';

const DayCard: React.FC<DayCardProps> = ({
  onClick,
  selected,
  date,
  selectedDate,
  loading,
  disabled = false,
  queueWaitingTime,
  datesList
}) => {
  const isOutRangeDateSelected = !datesList.includes(selectedDate);
  const visibility = useContext(VisibilityContext);

  return (
    <button
      className={classNames(
        'h-20 w-24 rounded-xl disabled:opacity-30 md:w-[88px]',
        {
          'bg-white': !selected || date === 'calendar',
          'border-orange': date === ASAP_OPTION_DATE && !disabled
        },
        { 'bg-orange-100': date == ASAP_OPTION_DATE && selected },
        (date !== ASAP_OPTION_DATE && selected) ||
          (date === 'calendar' && !!selectedDate && isOutRangeDateSelected)
          ? 'border-2 border-primary-300 !bg-primary-100'
          : 'border border-gray-200'
      )}
      data-testid={`day_${
        selected || (date === 'calendar' && isOutRangeDateSelected) ? 'selected' : 'not_selected'
      }`}
      disabled={loading || disabled}
      tabIndex={0}
      onClick={() => onClick(visibility)}
    >
      <div>
        <span
          className={classNames('mb-1 block select-none font-bold', {
            'max-md:text-base': date === ASAP_OPTION_DATE
          })}
          data-testid="date"
        >
          {getContent(date, 'day', selectedDate, datesList, disabled, queueWaitingTime)}
        </span>
        <span className="block text-mSm md:text-sm">
          {getContent(date, 'dayName', selectedDate, datesList, disabled)}
        </span>
      </div>
    </button>
  );
};

export default DayCard;

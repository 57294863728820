import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FreemiumState } from './freemium.types';

export const initialState: FreemiumState = {
  dob: '',
  doctorLicenseState: '',
  firstAppointment: {
    _id: '',
    appointmentDescription: '',
    appointmentDisplayName: '',
    appointmentFiles: [],
    appointmentTime: {
      endTime: '',
      startTime: ''
    },
    appointmentTypeId: '',
    bookedSlotId: '',
    isQueue: false
  },
  firstName: '',
  lastName: '',
  mailingAddress: { address: '', city: '', state: '', zipCode: '' },
  phone: '',
  provider: {
    displayName: ''
  },
  selectedPlan: null,
  sexAtBirth: '',
  terms: false
};

const freemiumSlice = createSlice({
  initialState,
  name: 'freemium',
  reducers: {
    clearFreemium: (): FreemiumState => initialState,
    setFreemium: (state, action: PayloadAction<Partial<FreemiumState>>) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { setFreemium, clearFreemium } = freemiumSlice.actions;

export default freemiumSlice.reducer;

import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { nanoid } from 'nanoid';

import { useDeleteDocumentMutation } from 'services/documents/documents';

import { DocsInfo } from 'containers/VerifyProfile/verifyProfile.types';
import FileZone from 'features/FileZone';
import UpdateBirthday from 'modals/UpdateBirthday';
import { notifyError } from 'shared/Toast/Toast';

import useAnalytics from 'hooks/useAnalytics';
import { useUploadDocument } from 'hooks/useUploadDocument';

// import { ERRORS_FOR_NOTIFICATIONS_FROM_VOUCHED } from 'utils/constants';
import { GovernmentIDProps } from './governmentID.types';

export const GovernmentID: React.FC<GovernmentIDProps> = ({
  onFileStatusUpdate,
  initialValue,
  accessToken
}) => {
  const logEvent = useAnalytics();
  const [fileData, setFileData] = useState<DocsInfo['government']>({
    _id: undefined,
    fileName: '',
    filePath: '',
    fileStatus: 'initial',
    identity: null
  });
  const [deleteDocument] = useDeleteDocumentMutation();
  const { isLoading, uploadFile } = useUploadDocument();
  const [isOpenUpdateBirthday, toggleIsOpenUpdateBirthday] = useToggle(false);

  const handleProcessing = () => {
    setFileData((prev) => ({
      ...prev,
      fileStatus: 'processing'
    }));
  };

  const handleDelete = (id: string) => {
    deleteDocument({ documentId: id, accessToken }).unwrap().then(onFileStatusUpdate);
  };

  const handleUpload = async (files: File[]) => {
    handleProcessing();
    try {
      if (fileData._id) {
        await deleteDocument({ documentId: fileData._id, accessToken }).unwrap();
      }
      const { data, error } = await uploadFile(files, {
        category: 'user-identity',
        subCategory: 'government-issued-id'
      });
      if (error !== null) {
        setFileData((prev) => ({
          ...prev,
          fileStatus: 'initial'
        }));
        notifyError(error);
        onFileStatusUpdate();
      } else {
        setFileData((prev) => ({
          ...prev,
          ...data,
          fileStatus: 'success'
        }));
        onFileStatusUpdate();
      }
    } catch {
      setFileData((prev) => ({
        ...prev,
        fileStatus: 'initial'
      }));
    }
  };

  const handleClickUpdateBirthdate = () => {
    logEvent('verify_identity_upd_birthday_btn_click');
    toggleIsOpenUpdateBirthday();
  };
  const governmentErrors = fileData?.identity?.errors || [];

  useEffect(() => {
    setFileData({
      ...initialValue,
      ...(!!accessToken && { fileStatus: !!initialValue._id ? 'success' : 'initial' })
    });
  }, [initialValue]);

  return (
    <>
      <UpdateBirthday
        isOpen={isOpenUpdateBirthday}
        onClose={() => toggleIsOpenUpdateBirthday(false)}
      />
      <Common.Icon
        className="size-20 rounded-full text-primary-400 max-md:bg-primary-50 max-md:p-4 md:size-12"
        name="identification"
      />
      <div className="text-center">
        <h2 className="verify-profile-heading">1. Upload a government issued ID</h2>
        <div className="mt-4 text-mSm md:mt-2 md:text-sm">
          <p className="font-bold">Accepted forms of ID:</p>
          <span>Driver’s License, ID Card, or Passport</span>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1 text-gray">
          <Common.Icon className="size-4 flex-none" name="arrow-alt-right" />
          <p className="text-mSm">Photo must be clean with all edges visible.</p>
        </div>
        <div className="flex items-center gap-1 text-gray">
          <Common.Icon className="size-4 flex-none" name="arrow-alt-right" />
          <p className="text-mSm">Full name and date of birth must be legible.</p>
        </div>
        <div className="flex items-center gap-1 text-gray">
          <Common.Icon className="size-4 flex-none" name="arrow-alt-right" />
          <p className="text-mSm">Your name must match that on your ID.</p>
        </div>
      </div>
      <div className="w-full md:my-4 md:max-w-[280px]" data-testid="government_id">
        <FileZone
          accept="image/png, image/jpeg, image/jpg, .heic"
          loading={isLoading}
          status={fileData}
          type="Add photo"
          onDelete={handleDelete}
          onSelect={handleUpload}
        />
      </div>
      {governmentErrors.map((error) => (
        <Common.Alert className="w-full" key={nanoid()} type="error" colorableBackground>
          <b>Error:</b> {error.message}
          {error.type === 'BirthDateMatchError' && (
            <Common.Button className="mt-2" color="white-alt" onClick={handleClickUpdateBirthdate}>
              Update birthdate
            </Common.Button>
          )}
        </Common.Alert>
      ))}
    </>
  );
};

import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';

import { StatusProps } from './status.types';

const Status: React.FC<StatusProps> = ({ message, self, combined }) => {
  // const receiverDetails = message.receiverDetails.find((el) => el.messageStatus?.seen != null);
  const getFormattedTime = (time: Date | null) => dayjs(time).format(DateFormat.h_mm_a);

  if (combined) return null;

  return (
    <p className="-mt-2 mb-2 text-left text-xs text-gray" data-testid="message_datestamp">
      {self
        ? // receiverDetails
          //   ? `Seen • ${getFormattedTime(receiverDetails.messageStatus.seen)}`
          //   : `Sent • ${getFormattedTime(message.messageStatus.sent)}`
          `Sent • ${getFormattedTime(message.messageStatus.sent)}`
        : `${message.senderDetails?.name} • ${getFormattedTime(message.messageStatus.sent)}`}
    </p>
  );
};

export default Status;

import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { ListItemWrapperProps } from './listItemWrapper.types';

export const VARIANTS = {
  hidden: {
    opacity: 0,
    x: -16
  },
  visible: {
    opacity: 1,
    x: 0
  }
};

const ListItemWrapper: React.FC<PropsWithChildren<ListItemWrapperProps>> = ({
  children,
  className,
  layoutId,
  dataTestId
}) => {
  return (
    <motion.div
      animate="visible"
      className={className}
      custom={1}
      data-testid={dataTestId}
      exit="hidden"
      initial="hidden"
      layoutId={layoutId}
      variants={VARIANTS}
    >
      {children}
    </motion.div>
  );
};

export default ListItemWrapper;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';

import { OrdersItemProps, OrdersItemsItemProps, OrdersState } from './orders.types';

const initialState: OrdersState = {
  orders: [],
  totalItems: 0
};

const ordersSlice = createSlice({
  initialState,
  name: 'orders',
  reducers: {
    setOrders: (state, action: PayloadAction<OrdersItemsItemProps[]>) => {
      const result: OrdersItemProps[] = [];

      action.payload.forEach((item) => {
        const current = result.find(
          ({ date }) => date === dayjs(item.signedDate).format(DateFormat.MMM_DD)
        );

        if (current) {
          result[result.indexOf(current)].items.push(item);
        } else {
          result.push({
            date: dayjs(item.signedDate).format(DateFormat.MMM_DD),
            items: [item]
          });
        }
      });

      state.orders = result;
      state.totalItems = action.payload.length;
    }
  }
});

export const { setOrders } = ordersSlice.actions;

export default ordersSlice.reducer;

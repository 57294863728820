import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { SlideAnimateWrapperProps } from './slideAnimateWrapper.types';

export const VARIANTS = {
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3
    },
    translateX: -200
  },
  exitReverse: {
    opacity: 0,
    transition: {
      duration: 0.3
    },
    translateX: 200
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3
    },
    translateX: 200
  },
  hiddenReverse: {
    opacity: 0,
    transition: {
      duration: 0.3
    },
    translateX: -200
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3
    },
    translateX: 0
  }
};

export const VARIANTS_SLIDE_IN_FADE_OUT = {
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  },
  exitReverse: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3
    },
    translateX: 200
  },
  hiddenReverse: {
    opacity: 0,
    transition: {
      duration: 0.3
    },
    translateX: -200
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3
    },
    translateX: 0
  }
};

const SlideAnimateWrapper: React.FC<PropsWithChildren<SlideAnimateWrapperProps>> = ({
  children,
  isBackAnimation,
  className = 'max-w-full',
  src
}) => {
  return (
    <motion.div
      animate="visible"
      className={className}
      exit={isBackAnimation ? 'exitReverse' : 'exit'}
      initial={isBackAnimation ? 'hiddenReverse' : 'hidden'}
      variants={src === 'signup' ? VARIANTS_SLIDE_IN_FADE_OUT : VARIANTS}
    >
      {children}
    </motion.div>
  );
};

export default SlideAnimateWrapper;

import { Common } from '@thecvlb/design-system';

import { BillingHistoryItemProps } from 'services/myAccount/myAccount.types';

import { getStatusContent } from '../../billingHistory.settings';

const ModalTitle: React.FC<{ item: BillingHistoryItemProps }> = ({ item }) => {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-gray">Invoice #{item.number}</h3>
      {'status' in item && typeof item.status === 'string' && (
        <Common.ColorTag color={getStatusContent(item.status).color} text={item.status} />
      )}
      <h4 className="text-mLg font-bold md:text-xl">{item.invoice}</h4>
      <h4 className="md:font-bold">{item.date}</h4>
    </div>
  );
};

export default ModalTitle;

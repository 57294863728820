import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import Calendar from 'features/Calendar';

import { DateAndTimeCalendarProps } from './dateAndTimeCalendar.types';

const startMonth = new Date();

const DateAndTimeCalendar: React.FC<DateAndTimeCalendarProps> = ({
  isOpen,
  onClose,
  handleDayClick,
  selectedDay,
  onMonthChange,
  disabledDays,
  loading = false
}) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="sm" zIndex={50}>
      <div className="p-2">
        <Calendar
          disabled={disabledDays}
          loading={loading}
          selected={selectedDay ? dayjs(selectedDay).toDate() : undefined}
          startMonth={startMonth}
          onDayClick={handleDayClick}
          onMonthChange={onMonthChange}
        />
      </div>
    </Common.Modal>
  );
};

export default DateAndTimeCalendar;

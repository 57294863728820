import { useNavigate } from 'react-router-dom';

import { useQuery } from 'hooks';

export const useMifNavigate = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const isEdit = query.get('step-action') === 'edit';
  const onNavigate = (cb: () => void) => {
    if (isEdit) {
      return navigate(-1);
    } else {
      cb();
    }
  };
  return { isEdit, onNavigate };
};

import { createApi } from '@reduxjs/toolkit/query/react';

import { setPages } from 'store/wm/wmSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  CreateCheckInSurveyReqProps,
  CreateRenewalReqProps,
  CreateRenewalResProps,
  GetOrdersReqProps,
  GetOrdersResProps,
  GetPagesReqProps,
  GetPagesResProps,
  GetSurveyDetailsReqProps,
  GetSurveyDetailsResProps,
  GetSurveyReqProps,
  GetSurveyResProps,
  MarkPageViewedReqProps,
  MarkPageViewedResProps,
  NewDeviceMeasurementsReqProps,
  NewDeviceMeasurementsResProps,
  SubmitSurveyReqProps,
  SubmitSurveyResProps,
  SurveyResponsesReqProps,
  SurveyResponsesResProps,
  UpdateSurveyReqProps,
  UpdateSurveyResProps
} from './wm.types';

export const wmApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    createCheckInSurvey: build.mutation<unknown, CreateCheckInSurveyReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/wm/check-in-survey'
      })
    }),
    createRenewal: build.mutation<CreateRenewalResProps, CreateRenewalReqProps>({
      query: ({ orderId }) => ({
        method: 'POST',
        url: `/wm/orders/${orderId}/surveys/renewal`
      })
    }),
    getWMOrders: build.query<GetOrdersResProps, GetOrdersReqProps>({
      query: (params) => ({
        params,
        url: '/wm/orders'
      })
    }),
    getPages: build.query<GetPagesResProps, GetPagesReqProps>({
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setPages(data.data));
        }
      },
      query: () => 'wm-pages'
    }),
    getSurveyDetails: build.query<GetSurveyDetailsResProps, GetSurveyDetailsReqProps>({
      query: ({ type, orderId }) => ({ url: `/wm/survey-details/${type}/${orderId}` })
    }),
    markPageViewed: build.mutation<MarkPageViewedResProps, MarkPageViewedReqProps>({
      query: ({ wmPageId, ...body }) => ({
        body,
        method: 'PUT',
        url: `wm-pages/${wmPageId}`
      })
    }),
    surveyResponses: build.mutation<SurveyResponsesResProps, SurveyResponsesReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'wm/survey-responses'
      })
    }),
    getSurvey: build.query<GetSurveyResProps, GetSurveyReqProps>({
      query: ({ type, orderId }) => ({
        url: `/wm/surveys/${type}/${orderId}`
      })
    }),
    updateSurvey: build.mutation<UpdateSurveyResProps, UpdateSurveyReqProps>({
      query: ({ type, orderId, ...body }) => ({
        method: 'PATCH',
        body,
        url: `/wm/surveys/${type}/${orderId}`
      })
    }),
    submitSurvey: build.mutation<SubmitSurveyResProps, SubmitSurveyReqProps>({
      query: (body) => ({
        method: 'POST',
        body,
        url: `/wm/submit-survey`
      })
    }),
    newDeviceMeasurements: build.mutation<
      NewDeviceMeasurementsResProps,
      NewDeviceMeasurementsReqProps
    >({
      query: (body) => ({
        method: 'POST',
        body,
        url: `/wm/new-device-measurements`
      })
    })
  }),
  reducerPath: 'wmApi',
  tagTypes: ['WeightManagement']
});

export const {
  useCreateCheckInSurveyMutation,
  useCreateRenewalMutation,
  useGetPagesQuery,
  useGetWMOrdersQuery,
  useGetSurveyDetailsQuery,
  useMarkPageViewedMutation,
  useSurveyResponsesMutation,
  useGetSurveyQuery,
  useUpdateSurveyMutation,
  useSubmitSurveyMutation,
  useNewDeviceMeasurementsMutation
} = wmApi;

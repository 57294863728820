import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from '../modal.types';

const features = [
  'Care team messaging',
  'Appointment booking',
  'Simple prescription renewals',
  'LifeMD program discounts',
  'Access to health metrics to track your progress'
];

const LimitedAccessPlan: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="base">
      <Common.Icon
        className="mx-auto mt-6 size-[112px] rounded-full bg-red-100 p-8 text-red"
        name="error"
      />
      <p className="mt-4 font-bold text-red">Limited access plan</p>
      <p className="mt-2">
        This plan opts you out of the LifeMD platform. Please note that opting out will result in
        limited access to important features for your GLP-1. This includes creating appointments
        online, messaging with your care team, requesting prescription renewals, and more.
      </p>
      <p className="mt-4 font-semibold">NOT included with plan:</p>
      <ul className="mt-2 flex list-inside flex-col gap-2 text-gray">
        {features.map((feature) => (
          <li className="flex items-center gap-2" key={feature}>
            <Common.Icon className="flex-none text-red" name="close" /> {feature}
          </li>
        ))}
      </ul>
      <p className="mt-4">
        Call 1-800-979-9384 if you would like to opt out of the LifeMD platform.
      </p>
      <Common.Button className="mx-auto mt-6" color="blue" fullWidthOnMobile onClick={onClose}>
        Back to plans
      </Common.Button>
    </Common.Modal>
  );
};

export default LimitedAccessPlan;

import { Common } from '@thecvlb/design-system';

import { Medication } from 'models/medication.types';

interface CompoundCardProps {
  medication: Medication;
}

const CompoundCard: React.FC<CompoundCardProps> = ({ medication }) => {
  return (
    <div className="rounded-2xl bg-white shadow">
      <div className="flex items-center gap-2 rounded-t-2xl bg-primary-100 p-4">
        <Common.Logo className="size-5" name="cross" />
        <h4
          className="text-mBase font-bold text-primary md:text-lg"
          data-testid="select_plan_logo_header"
        >
          Compounded medication
        </h4>
      </div>
      <div className="flex flex-col gap-2.5 p-4">
        <div className="flex flex-col gap-1 rounded-lg bg-yellow-100 p-4 text-primary-700">
          <span className="text-mXs">Program + Medication cost</span>
          <span className="text-m2xl font-bold">${medication.copay}</span>
          <span className="text-mSm">Semaglutide GLP-1</span>
          <span className="text-mXs text-gray">Medication included</span>
        </div>
        <div className="flex flex-col gap-1 py-1.5 text-primary-700">
          <h2 className="font-bold md:text-lg">Medication options:</h2>
          <span>Semaglutide & Tirzepatide</span>
        </div>
        <h3 className="text-mBase font-bold text-primary-700">What’s included:</h3>
        {[
          'Medication: Semaglutide',
          'Full Medical history assessment',
          'Weight loss related labs (no addtl. cost)',
          'Medication shipped directly to your door',
          'Clinical oversight with tailored treatment plan',
          'Ongoing consultations to increase medication dosage if needed'
        ].map((a) => (
          <div className="flex gap-2 text-primary-700" key={a}>
            <Common.Icon className="flex-none" name="check" />
            <span className="text-mSm md:text-sm">{a}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompoundCard;

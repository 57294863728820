import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Option } from 'models/forms.types';
import { MembershipPlan } from 'models/plans.types';

import { FrontDeskTypeItemProps, LookupState } from './lookup.types';

const initialState: LookupState = {
  frontDeskTypes: [],
  membershipPlans: [],
  states: []
};

const lookupSlice = createSlice({
  initialState,
  name: 'lookup',
  reducers: {
    setFrontDeskTypes: (state, action: PayloadAction<FrontDeskTypeItemProps[]>) => {
      state.frontDeskTypes = action.payload;
    },
    setMembershipPlans: (state, action: PayloadAction<MembershipPlan[]>) => {
      state.membershipPlans = action.payload;
    },
    setStates: (state, action: PayloadAction<Option[]>) => {
      state.states = action.payload;
    }
  }
});

export const { setFrontDeskTypes, setStates, setMembershipPlans } = lookupSlice.actions;

export default lookupSlice.reducer;

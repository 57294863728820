import { NavLink } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { LINK_TO_ACCESSIBILITY } from 'constants/externalLinks';
import useAnalytics from 'hooks/useAnalytics';
import { PathName } from 'utils/enums';

import packageInfo from '../../../package.json';

const About = () => {
  useTitle('LifeMD - About');
  const logEvent = useAnalytics();

  const navItemClassName =
    'flex items-center gap-2 py-4 w-full text-left text-mBase cursor-pointer font-semibold md:font-bold';
  const navIconClassName = 'size-5 text-gray';
  const darkGrayNavIconClassName = 'size-5 text-gray-700';

  return (
    <div className="flex flex-col">
      <NavLink
        className={navItemClassName}
        to={PathName.Legal}
        onClick={() => logEvent('my_account_legal_mitem_click')}
      >
        <Common.Icon className={darkGrayNavIconClassName} name="lock" />
        <span className="flex-1">Legal</span>
        <Common.Icon className={navIconClassName} name="arrow-right" />
      </NavLink>
      <a className={navItemClassName} data-acsb-custom-trigger="true" href={LINK_TO_ACCESSIBILITY}>
        <Common.Icon className={darkGrayNavIconClassName} name="accessibility" />
        <span className="flex-1">Accessibility</span>
        <Common.Icon className={navIconClassName} name="arrow-right" />
      </a>
      <span className="mx-auto mt-8 text-gray">v{packageInfo.version}</span>
    </div>
  );
};
export default About;

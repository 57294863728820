import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useDeleteFromCartMutation, useGetCartQuery } from 'services/shop/shop';

import { selectShop } from 'store';

import CartItem from 'pages/shop/Cart/components/CartItem';

import Loader from 'shared/Loader';

import { useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';

import EmptyCart from 'assets/icons/empty-cart.svg?react';

const Cart = () => {
  useTitle('LifeMD: Cart');
  const navigate = useNavigate();
  const [deleteProduct, { isLoading }] = useDeleteFromCartMutation();
  const { refetch, isFetching } = useGetCartQuery();
  const { cart } = useAppSelector(selectShop);

  const handleDelete = (id: string) => {
    if (!!id) {
      deleteProduct({ productId: id }).unwrap().then(refetch);
    }
  };
  const cartProducts = cart.items ?? [];
  const isCartEmpty = cartProducts.length === 0;

  const handleContinueToCheckout = () => {
    navigate(isCartEmpty ? PathName.Shop : PathName.ShopCheckout);
  };

  return (
    <div className="flex h-full flex-col gap-8">
      <Loader isVisible={isFetching} />
      <div className="flex flex-col max-md:h-full">
        {isCartEmpty && !isFetching ? (
          <div className="mx-auto flex h-full max-w-[306px] flex-col place-content-center items-center gap-2.5 py-8 text-center max-md:grow">
            <EmptyCart className="size-[52px]" />
            <span className="text-gray" data-testid="empty_cart_tooltip">
              Your cart is empty. When you add products, they’ll appear here.
            </span>
          </div>
        ) : (
          <div
            className="flex h-full flex-col divide-y-8 divide-gray-100 overflow-auto max-md:-mx-4"
            data-testid="products_in_cart"
          >
            {cartProducts.map((product) => (
              <CartItem
                key={product.productId}
                product={product}
                onDelete={() => handleDelete(product.productId)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="max-md:mt-auto md:w-full md:max-w-[360px] md:self-end">
        <h3 className="flex justify-between text-mLg font-bold text-primary-700">
          <span>Sub total</span>
          <span data-testid="sub_total_price">${cart.subTotal}</span>
        </h3>
        <span className="text-mSm text-gray" data-testid="no_shipping_no_taxes_included">
          Does not include shipping or taxes
        </span>
        <Common.Button
          className="mt-4 !w-full"
          color="blue"
          dataTestId="continue_to_checkout_view_products_btn"
          disabled={isLoading || isFetching}
          size="lg"
          fullWidthOnMobile
          onClick={handleContinueToCheckout}
        >
          {isCartEmpty ? 'View Products' : 'Continue to checkout'}
        </Common.Button>
      </div>
    </div>
  );
};

export default Cart;

import { Common } from '@thecvlb/design-system';

import { UpgradeToUnlimitedProps } from './upgradeToUnlimited.types';

import SuccessIcon from 'assets/icons/check-icon.svg?react';

const UpgradeToUnlimited: React.FC<UpgradeToUnlimitedProps> = ({
  isOpen,
  onClose,
  confirmBtnText = 'Continue'
}) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="sm">
      <SuccessIcon className="mx-auto my-4" />
      <h3
        className="mb-4 text-center text-m2xl font-bold text-primary md:mb-6 md:text-2xl"
        data-testid="modal_heading"
      >
        Welcome to Unlimited!
      </h3>
      <p className="mb-4 text-center text-gray-900 md:mb-6">
        You’ve upgraded your plan, and now have access to unlimited appointments, after hours care,
        and more.
      </p>
      <Common.Button
        className="mx-auto"
        color="blue"
        dataTestId="done_btn"
        postIcon="arrow-right"
        fullWidthOnMobile
        onClick={onClose}
      >
        {confirmBtnText}
      </Common.Button>
    </Common.Modal>
  );
};

export default UpgradeToUnlimited;

import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { motion } from 'framer-motion';
import { nanoid } from 'nanoid';

import { selectMifInfo, selectSignUp } from 'store';
import { AllergyItemProps } from 'store/mif/mif.types';
import { setMifInformation, setMifPick } from 'store/mif/mifSlice';

import ButtonsGroup from 'pages/SignUp/regularFlows/mifSteps/parts/ButtonsGroup';
import StepHeaderInfo from 'pages/SignUp/regularFlows/mifSteps/parts/StepHeaderInfo';
import YesNoSelect from 'pages/SignUp/regularFlows/mifSteps/parts/YesNoSelect';

import { VARIANTS } from 'shared/animationWrappers/ListItemWrapper/ListItemWrapper';
import Allergies from 'widgets/mif/Allergies';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';
import useSubmitMifParts from 'hooks/useSubmitMifParts';
import { createGtmEvent } from 'utils/gtm';
import { handleRequestCatch } from 'utils/helpers';
import { AT_LEAST_ONE_LETTER_REGEXP } from 'utils/regExp';

import { MifStepProps } from '../mifSteps.types';

const AllergiesPage: React.FC<MifStepProps> = ({ moveToStep, mifStepsLength }) => {
  useTitle('LifeMD - Medical intake form');
  const { sendMifPart, isLoading } = useSubmitMifParts();
  const { isEdit, onNavigate } = useMifNavigate();

  const dispatch = useAppDispatch();

  const { user } = useAppSelector(selectSignUp);
  const {
    mifInfo: { allergyList },
    mifPicks
  } = useAppSelector(selectMifInfo);
  const [hasAllergies, setHasAllergies] = useState<boolean>(false);
  const [allergiesList, setAllergiesList] = useState<
    (AllergyItemProps & {
      id: string;
    })[]
  >([]);

  const handleSubmit = () => {
    const body = {
      accessToken: user?.accessToken,
      metrics: {
        allergyList: [...allergiesList.map(({ id, ...r }) => r)]
      }
    };

    sendMifPart(body)
      .catch(handleRequestCatch)
      .finally(() => onNavigate(() => moveToStep('next')));
  };

  const handleSelect = (v: boolean) => {
    if (v) {
      dispatch(setMifPick({ allergiesPick: v }));
      setHasAllergies(v);
      setAllergiesList([{ id: nanoid(), name: '' }]);
    } else {
      dispatch(
        setMifInformation({
          data: [],
          prop: 'allergyList'
        })
      );
      handleSubmit();
    }
  };

  const setSelectedValues = () => {
    dispatch(
      setMifInformation({
        data: allergiesList.map(({ id, ...rest }) => rest),
        prop: 'allergyList'
      })
    );
    createGtmEvent('allergies_info', user);
    handleSubmit();
  };

  const isDisabled =
    isLoading ||
    allergiesList.some(
      (allergy) => allergy.name.trim().length < 3 || !allergy.name.match(AT_LEAST_ONE_LETTER_REGEXP)
    );

  useEffect(() => {
    setAllergiesList(
      allergyList.map((v) => ({
        id: nanoid(),
        ...v
      }))
    );
    setHasAllergies(mifPicks?.allergiesPick || !!allergyList.length);
  }, []);

  useEffect(() => {
    if (!allergiesList.length && hasAllergies) {
      setHasAllergies(false);
    }
  }, [allergiesList]);

  return (
    <>
      <div className="intake-form-wrapper">
        <StepHeaderInfo
          currentStep={`Step 2 of ${mifStepsLength}`}
          dataTestId="allergies"
          stepSubtitle={
            !hasAllergies
              ? 'Do you have any allergies to medications?'
              : 'Please list all the allergies you have.'
          }
          stepTitle="Drug allergies"
        />
        {hasAllergies ? (
          <>
            <motion.div
              animate="visible"
              className="flex flex-col gap-4"
              exit="hidden"
              initial="hidden"
              variants={VARIANTS}
            >
              <Allergies allergyList={allergiesList} onUpdate={setAllergiesList} />
            </motion.div>
            <motion.div className="mt-6 hidden h-px w-full bg-gray-200 md:block" key="separator" />
            <motion.div className="mt-auto md:mt-0" key="btns">
              <ButtonsGroup
                isDisabledNextBtn={isDisabled}
                loading={isLoading}
                nextBtnText={isEdit ? 'Update' : 'Next'}
                hidePrev
                onClickNext={setSelectedValues}
              />
            </motion.div>
          </>
        ) : (
          <YesNoSelect loading={isLoading} selectedValue={hasAllergies} onSelect={handleSelect} />
        )}
      </div>
    </>
  );
};

export default AllergiesPage;

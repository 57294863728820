import React from 'react';
import { NavLink, To, useMatch, useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { selectShop } from 'store';

import { LINK_TO_SUPPORT } from 'constants/externalLinks';
import { useAppSelector } from 'hooks';
import { useRouteMatch } from 'hooks/useRouteMatch';
import { PageTitle, PathName } from 'utils/enums';

const BreadCrumbs = () => {
  const navigate = useNavigate();
  const { activeProductName, cart } = useAppSelector(selectShop);

  const routeMatch = (path: string) => !!useRouteMatch(path)?.isExact;

  const showBreadCrumbs = !routeMatch(PathName.ShopCheckout) && !routeMatch(PathName.ShopAddress);
  const isOrderHistoryPage = routeMatch(PathName.ShopOrders);
  const isShopHomePage = routeMatch(PathName.Shop);
  const isProductDetailsPage =
    useMatch(PathName.ShopProducts + '/:id')?.pattern.path === PathName.ShopProducts + '/:id';

  const getHeaderParams = (): { label: string; url?: To } => {
    const routes = [
      { match: routeMatch(PathName.Shop), label: PageTitle.Shop, url: PathName.WeightManagement },
      { match: isProductDetailsPage, label: activeProductName, url: PathName.Shop },
      { match: routeMatch(PathName.ShopCart), label: 'Cart', url: -1 as To },
      { match: routeMatch(PathName.ShopCheckout), label: 'Confirm and pay', url: -1 as To },
      { match: routeMatch(PathName.ShopAddress), label: 'Shipping address', url: -1 as To },
      { match: routeMatch(PathName.ShopOrders), label: 'Order History', url: -1 as To }
    ];

    const matchedRoute = routes.find((route) => route.match);
    return matchedRoute
      ? { label: matchedRoute.label, url: matchedRoute.url }
      : { label: '', url: '' };
  };

  const { label, url } = getHeaderParams();

  if (!showBreadCrumbs) return null;

  return (
    <div
      className={classNames('flex w-full justify-between max-md:hidden', { 'mb-8': !!url })}
      data-testid="arrow_back"
    >
      {!!url && (
        <NavLink className="flex items-center gap-3 text-mLg font-bold" to={url}>
          <Common.Icon className="size-5" name="arrow-left" />
          <span>{label}</span>
        </NavLink>
      )}
      {isShopHomePage && (
        <div className="flex items-center gap-2">
          <button
            className="flex-none p-2"
            data-testid="orders_history_btn"
            onClick={() => navigate(PathName.ShopOrders)}
          >
            <Common.Icon className="size-5 flex-none" name="clock" />
          </button>
          <button
            className="relative flex-none rounded-full border border-gray-200 p-2"
            data-testid="shopping_cart_btn"
            onClick={() => navigate(PathName.ShopCart)}
          >
            {cart.items.length > 0 && (
              <div
                className="absolute -right-1 -top-1 flex size-4 items-center justify-center rounded-full bg-red text-xs text-white"
                data-testid="unread_messages_counter"
              >
                {cart.items.length}
              </div>
            )}
            <Common.Icon className="size-5 flex-none" name="checkout" />
          </button>
        </div>
      )}
      {isOrderHistoryPage && (
        <Common.Button color="blue" size="sm" onClick={() => window.open(LINK_TO_SUPPORT)}>
          Contact support
        </Common.Button>
      )}
    </div>
  );
};

export default BreadCrumbs;

import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { PaginationProps } from './pagination.types';

const Pagination: React.FC<PaginationProps> = ({ params, onChange, labelDirection = 'row' }) => {
  const showingFrom =
    params.pageNo + 1 === 1 ? params.pageNo + 1 : params.pageNo * params.limit + 1;
  const showingTo =
    (params.pageNo + 1) * params.limit > params.totalCount
      ? params.totalCount
      : (params.pageNo + 1) * params.limit;
  const pageCount = Math.ceil(params.totalCount / params.limit);

  const arrowIconClasses = (direction: 'left' | 'right') => {
    return classNames(
      'relative inline-flex items-center w-9 h-9 px-2 py-2 border border-gray-300 bg-white text-base font-medium hover:bg-gray-50',
      {
        hidden:
          (direction === 'left' && !params.pageNo) ||
          (direction === 'right' && params.pageNo === pageCount - 1),
        'rounded-l-md': direction === 'left',
        'rounded-r-md': direction === 'right'
      }
    );
  };

  useEffect(() => {
    if (pageCount <= params.pageNo) {
      onChange({ selected: pageCount - 1 });
    }
  }, [params.pageNo, pageCount]);

  return (
    <div
      className={classNames('flex flex-wrap items-center justify-between p-4', {
        'flex-col-reverse gap-2 text-gray': labelDirection === 'column'
      })}
    >
      <p className="text-base" data-testid="pagination_info">
        Showing <b>{showingFrom}</b> to <b>{showingTo}</b> of <b>{params.totalCount}</b> results
      </p>
      <ReactPaginate
        activeLinkClassName="bg-secondary-100 border-secondary-400 text-secondary border-secondary"
        breakClassName=" border-gray-300 relative inline-flex items-center px-4 border text-base font-medium"
        breakLabel={'...'}
        className="relative flex rounded-md text-gray"
        forcePage={params.pageNo}
        marginPagesDisplayed={1}
        nextLabel={
          <Common.Icon
            className={arrowIconClasses('right')}
            data-testid="paginate_right"
            name="arrow-right"
          />
        }
        pageCount={pageCount}
        pageLinkClassName="border-gray-300 relative inline-flex items-center h-9 px-4 py-2 border text-sm font-medium"
        pageRangeDisplayed={3}
        previousLabel={
          <Common.Icon
            className={arrowIconClasses('left')}
            data-testid="paginate_left"
            name="arrow-left"
          />
        }
        onPageChange={onChange}
      />
    </div>
  );
};

export default Pagination;

import { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Tooltip } from 'react-tooltip';
import { useUpdate } from 'react-use';
import dayjs from 'dayjs';

import { EncounterProps } from 'services/patientChart/patientChart.types';

import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { TimeLineProps } from './timeLine.types';

const TimeLine: React.FC<TimeLineProps> = ({ medicalRecords }) => {
  const [timeLineData, setTimeLineData] = useState<EncounterProps[]>([]);
  const { isMobile } = useWidth();
  const ref = useRef<HTMLHRElement | null>(null);
  const nowYear = dayjs().year();
  const update = useUpdate();
  const firstYear = dayjs().subtract(9, 'year').year();
  const years = Array.from(Array(nowYear - firstYear), (_, i) => (++i + firstYear).toString());
  const countOfDaysFromNow = dayjs().diff(dayjs().year(nowYear - 9), 'day');

  const getLeft = (date: string) => {
    const diffDays = dayjs().diff(dayjs(date), 'day');
    return (ref.current ? (ref.current.offsetWidth / countOfDaysFromNow) * diffDays : 0) + 'px';
  };

  const getDesktopData = () => {
    return timeLineData.reduce((a: EncounterProps[], b) => {
      const index = a.findIndex((el) => dayjs(el.date).diff(b.date, 'day') === 0);
      if (index >= 0) {
        a[index] = { ...a[index], type: a[index].type + '\n' + b.type };
      } else {
        a.push(b);
      }
      return a;
    }, []);
  };

  const getMobileData = () => {
    const mobileData: { date: number; elements: EncounterProps[] }[] = [];
    getDesktopData().forEach((element) => {
      const current = mobileData.find((el) => el.date === dayjs(element.date).year());
      if (current) {
        mobileData[mobileData.indexOf(current)].elements.push(element);
      } else {
        mobileData.push({
          date: dayjs(element.date).year(),
          elements: [element]
        });
      }
    });
    return mobileData;
  };

  const handleUpdatedTab = () => {
    medicalRecords.Encounter &&
      setTimeLineData(
        medicalRecords.Encounter.filter(
          (el) => dayjs(el.date).year() >= dayjs().subtract(8, 'year').year()
        ).sort((a, b) => dayjs(a.date).diff(b.date))
      );
  };

  useEffect(handleUpdatedTab, [medicalRecords]);

  useEffect(update, [isMobile]);

  return (
    <>
      {!!getDesktopData().length &&
        (isMobile ? (
          <div className="-my-1 flex gap-2 overflow-auto py-5">
            {getMobileData().map((el) => (
              <div className="flex flex-col gap-2" key={el.date}>
                <div className="flex w-full items-center gap-2.5 text-mBase font-bold text-gray">
                  <hr className="h-0.5 w-full bg-gray-200" />
                  {el.date}
                </div>
                <div className="flex justify-end gap-2">
                  {el.elements.map((element, i) => {
                    const key = element.date + element.type + i;
                    return (
                      <div key={key}>
                        <Tooltip
                          className="!rounded-xl !bg-white !p-3 !text-gray-700 !opacity-100 !shadow-sm"
                          classNameArrow="w-4 h-4"
                          id={key}
                        />
                        <div
                          className="group relative flex flex-col items-center gap-0.5 rounded border border-gray-200 bg-white p-2 px-3"
                          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <>
                              <b className="text-sm">
                                {dayjs(element.date).format(DateFormat.MMM_D)}
                              </b>
                              <p className="whitespace-pre text-sm">{element.type}</p>
                            </>
                          )}
                          data-tooltip-id={key}
                        >
                          <span className="text-mBase font-bold text-gray-700">
                            {dayjs(element.date).format(DateFormat.D)}
                          </span>
                          <span className="text-mSm font-medium text-gray-700">
                            {dayjs(element.date).format(DateFormat.MMM)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col rounded-xl bg-gray-50 p-6">
            <h2 className="mb-4 text-lg font-bold text-gray-700">Timeline</h2>
            <div className="relative flex items-center">
              <hr className="my-4 h-0.5 w-full bg-gray-200" ref={ref} />
              {getDesktopData().map((el, i) => {
                const key = el.date + el.type + i;
                return (
                  <div key={key}>
                    <Tooltip
                      className="!rounded-xl !bg-white !p-3 !text-gray-700 !opacity-100 !shadow-sm"
                      classNameArrow="w-4 h-4"
                      data-tooltip-position-strategy="absolute"
                      id={key}
                    />
                    <div
                      className="group absolute inset-y-0 my-auto flex size-1 cursor-pointer flex-col items-center justify-center rounded-full bg-primary"
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <>
                          <b className="text-sm">{dayjs(el.date).format(DateFormat.MMM_D)}</b>
                          <p className="whitespace-pre text-sm">{el.type}</p>
                        </>
                      )}
                      data-tooltip-id={key}
                      style={{ right: getLeft(el.date) }}
                    ></div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-between gap-1">
              {years.map((year) => (
                <span className="text-sm font-bold text-gray" key={year}>
                  {year}
                </span>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};
export default TimeLine;

import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import {
  useDeselectPharmacyMutation,
  useSelectPharmacyMutation
} from 'services/pharmacies/pharmacies';

import { selectMifInfo, selectSignUp, selectUser } from 'store';
import { setMifInformation } from 'store/mif/mifSlice';
import { setUserState } from 'store/signup/signupSlice';

import { MoveStepFunction } from 'containers/SignUp/signUp.types';
import MyChartPharmacy from 'widgets/Pharmacy';

import { useAppDispatch, useAppSelector } from 'hooks';
import { createGtmEvent } from 'utils/gtm';
import { handleRequestCatch } from 'utils/helpers';

const Pharmacy: React.FC<{ moveToStep: MoveStepFunction }> = ({ moveToStep }) => {
  useTitle('LifeMD - Pharmacy');

  const [selectPharmacy, { isLoading }] = useSelectPharmacyMutation();
  const [deselectPharmacy, { isLoading: isDeselectPharmacy }] = useDeselectPharmacyMutation();

  const dispatch = useAppDispatch();
  const {
    mifInfo: { preferredPharmacy }
  } = useAppSelector(selectMifInfo);
  const {
    accessToken: loggedUserAccessToken,
    zipCode,
    city,
    address,
    state
  } = useAppSelector(selectUser);
  const { user } = useAppSelector(selectSignUp);
  const { accessToken, mailingAddress } = user;

  const token = loggedUserAccessToken || accessToken;
  const setSelectedValues = async (id?: number | string) => {
    dispatch(
      setMifInformation({
        data: id,
        prop: 'preferredPharmacy'
      })
    );

    await selectPharmacy({ accessToken: token, pharmacyId: Number(id) })
      .unwrap()
      .then(() => {
        createGtmEvent('pharmacy_info', user);
        createGtmEvent('completed_quiz', user);
        dispatch(setUserState({ preferredPharmacyID: Number(id) }));
        // moveToStep('next');
      })
      .catch((e) => handleRequestCatch(e, 'Error with setting pharmacy, please try again later'));
  };

  const handleDeselectedPharmacy = (pharmacyId?: number | string) => {
    if (!pharmacyId) {
      return;
    }
    deselectPharmacy({ accessToken, pharmacyId: Number(pharmacyId) })
      .unwrap()
      .then(() => dispatch(setUserState({ preferredPharmacyID: undefined })))
      .catch((e) => handleRequestCatch(e, 'Error with setting pharmacy, please try again later'));
  };

  return (
    <div
      className={classNames('flex h-full flex-col', {
        'rounded-xl md:px-4 md:shadow-xl': !!accessToken
      })}
    >
      <div className="flex items-center justify-between p-4 md:justify-center">
        <h2 className="accent-header md:text-center">Choose Pharmacy</h2>
        <Common.Button
          className="mt-1 !text-gray md:hidden"
          color="white"
          size="sm"
          style="text-only"
          onClick={() => moveToStep('next')}
        >
          Skip
        </Common.Button>
      </div>
      <div
        className={classNames(
          'hidden-scrollbar sticky -top-5 z-[5] grow max-md:-mx-4 max-md:-mb-4 max-md:min-h-[500px] md:mx-8 md:max-h-[500px]',
          { 'md:pb-8': preferredPharmacy },
          { 'md:-mb-5': !accessToken },
          { 'bg-gray-50': !loggedUserAccessToken },
          { 'md:min-h-[550px] md:pb-12': loggedUserAccessToken }
        )}
      >
        <MyChartPharmacy
          accessToken={token}
          isLoading={isLoading || isDeselectPharmacy}
          mailingAddress={
            !loggedUserAccessToken ? mailingAddress : { zipCode, city, address, state }
          }
          userPharmacyID={Number(preferredPharmacy)}
          isIntakeForm
          onContinue={() => moveToStep('next')}
          onDeselect={handleDeselectedPharmacy}
          onSelect={setSelectedValues}
        />
      </div>
      {!preferredPharmacy && (
        <div
          className={classNames(
            'fixed inset-x-1/4 -bottom-2 z-10 max-md:hidden md:relative md:inset-x-auto',
            { 'md:-bottom-20': !loggedUserAccessToken }
          )}
        >
          <Common.Button
            className="mx-auto mb-4"
            color="blue"
            disabled={isLoading}
            isLoading={isLoading}
            postIcon="arrow-right"
            onClick={() => moveToStep('next')}
          >
            Skip for now
          </Common.Button>
        </div>
      )}
    </div>
  );
};

export default Pharmacy;

import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { APPROX_WAIT_TIME, ASAP_OPTION_DATE } from 'constants/defaults';
import { DateFormat } from 'utils/enums';

export const getContent = (
  date: string,
  contentType: 'day' | 'dayName',
  selectedDate: string,
  datesList: string[],
  disabled = false,
  waitingTime = APPROX_WAIT_TIME
) => {
  const asapIconClassName = `mx-auto ${disabled ? '' : 'text-orange'}`;

  switch (contentType) {
    case 'day':
      return date === ASAP_OPTION_DATE ? (
        waitingTime
      ) : date === 'calendar' ? (
        <Common.Icon className="mx-auto" name="calendar-filled" />
      ) : (
        dayjs(date).format(DateFormat.MMM_D)
      );
    case 'dayName':
      return date === ASAP_OPTION_DATE ? (
        <>
          <Common.Icon className={asapIconClassName} name="lightning" />
          <span className="text-gray-700">ASAP</span>
        </>
      ) : dayjs(date).isToday() ? (
        'Today'
      ) : dayjs(date).isTomorrow() ? (
        'Tomorrow'
      ) : date === 'calendar' &&
        (!selectedDate || selectedDate === ASAP_OPTION_DATE || datesList.includes(selectedDate)) ? (
        'Calendar'
      ) : date === 'calendar' ? (
        dayjs(selectedDate).format(DateFormat.MMM_D)
      ) : (
        dayjs(date).format(DateFormat.ddd)
      );
    default:
      return '';
  }
};

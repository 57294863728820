import { createApi } from '@reduxjs/toolkit/query/react';

import { setHealthProfile } from 'store/healthProfile/healthProfileSlice';

import { baseQueryWithReauth } from 'utils/services';

import { GetHealthProfileReqProps, GetHealthProfileResProps } from './healthProfile.types';

export const healthProfileApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getHealthProfile: build.query<GetHealthProfileResProps, GetHealthProfileReqProps>({
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data.data) {
          dispatch(setHealthProfile(data.data));
        }
      },
      query: () => 'health-profile'
    })
  }),
  reducerPath: 'healthProfileApi',
  tagTypes: ['HealthProfile']
});

export const { useGetHealthProfileQuery } = healthProfileApi;

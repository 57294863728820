import { NavLink } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { PathName } from 'utils/enums';

import { CartItemProps } from './cartItem.types';

const CartItem: React.FC<CartItemProps> = ({ product, onDelete }) => {
  return (
    <NavLink
      className="flex items-center justify-between gap-3 p-4"
      to={PathName.ShopProducts + '/' + product.productId}
    >
      <img
        alt="product"
        className="size-[120px] flex-none rounded-lg object-cover"
        src={product.product.featuredAsset.url}
      />
      <div className="flex grow flex-col justify-between self-stretch">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="font-semibold text-gray-800">{product.product.name}</h3>
            <span className="text-mSm text-gray md:text-sm">{product.product.type}</span>
          </div>
          <span className="text-gray-800">${product.product.unitPrice}</span>
        </div>
        <button
          className="flex w-fit items-center rounded-full bg-gray-200 p-2 transition-all"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <Common.Icon className="flex-none" name="trash" />
          <span className="w-6 text-sm font-bold">1</span>
        </button>
      </div>
    </NavLink>
  );
};

export default CartItem;

import { PaymentMethod } from 'models/payments.types';

export const getCardTypeName = (data: PaymentMethod) => {
  const isPayPal = data.paymentMethod === 'paypal_account';
  if (isPayPal) {
    return 'PayPal';
  }
  if (data.cardType === 'master card') {
    return 'Mastercard';
  }
  return data.cardType;
};

import { Common } from '@thecvlb/design-system';

import { ConfirmDeleteInsuranceProps } from './confirmDeleteInsurance.types';

const ConfirmDeleteInsurance: React.FC<ConfirmDeleteInsuranceProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading
}) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="sm">
      <div className="flex flex-col gap-6">
        <div>
          <h2 className="mb-2 text-xl font-bold">Delete insurance?</h2>
          <h4 className="text-base">This action cannot be undone.</h4>
        </div>
        <div className="flex w-full flex-col gap-3 md:flex-row-reverse md:gap-2">
          <Common.Button
            className="w-full justify-center md:basis-1/2"
            color="red"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            Delete insurance
          </Common.Button>
          <Common.Button
            className="w-full justify-center md:basis-1/2"
            color="white-alt"
            disabled={isLoading}
            onClick={onClose}
          >
            Cancel
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default ConfirmDeleteInsurance;

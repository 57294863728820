import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { Props } from './yesNoSelect.types';

const YesNoSelect: React.FC<Props> = ({ onSelect, selectedValue, loading = false }) => {
  return (
    <div className="flex flex-col gap-2">
      <button
        className={classNames(
          'p-px',
          // removed progress-wrapper class for now as client asked for it
          !!loading && selectedValue === true ? '' : ''
        )}
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          onSelect(true);
        }}
      >
        <Common.RichRadioButton defaultChecked={selectedValue} label="Yes" />
      </button>
      <button
        className={classNames('p-px', loading && selectedValue === false ? 'progress-wrapper' : '')}
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          onSelect(false);
        }}
      >
        <Common.RichRadioButton label="No" />
      </button>
    </div>
  );
};

export default YesNoSelect;

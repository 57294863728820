import { useTitle } from 'react-use';

import { useGetMembershipPlansQuery } from 'services/lookup/lookup';

import { selectLookup } from 'store';

import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { useAppSelector } from 'hooks';
import { useMigrateToTT } from 'hooks/useMigrateToTT';

import Content from './Content';

const MigrateToTT = () => {
  useTitle('LifeMD - Migrate to Triple Therapy');
  const { membershipPlans } = useAppSelector(selectLookup);
  const { steps, currentStep, moveToStep, loading, isFetching, setSteps } = useMigrateToTT();
  useGetMembershipPlansQuery(undefined, { skip: membershipPlans.length > 0 });

  return loading ? (
    <Loader isVisible />
  ) : (
    <div className="relative flex grow flex-col p-4">
      <Header
        flowLength={steps.length}
        step={steps.indexOf(currentStep)}
        onClickPrev={() => moveToStep('prev')}
      />
      <div className="grow">
        <Content
          loading={isFetching}
          moveToStep={moveToStep}
          setSteps={setSteps}
          step={currentStep}
          steps={steps}
        />
      </div>
    </div>
  );
};

export default MigrateToTT;

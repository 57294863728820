import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { RootState } from 'store';
import { setFrontDeskTypes, setMembershipPlans, setStates } from 'store/lookup/lookupSlice';

import { STATES_WHERE_ONBOARDING_IS_NOT_ALLOWED } from 'constants/onboarding';

import packageInfo from '../../../package.json';

import {
  GetAppointmentCategoriesReqProps,
  GetAppointmentCategoriesResProps,
  GetAppointmentTypesReqProps,
  GetAppointmentTypesResProps,
  GetFrontDeskTypesReqProps,
  GetFrontDeskTypesResProps,
  GetMembershipPlansReqProps,
  GetMembershipPlansResProps,
  GetStatesReqProps,
  GetStatesResProps,
  GetSuggestedAddressesReqProps,
  GetSuggestedAddressesResProps
} from './lookup.types';

export const lookupApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE_URL,
    paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'bracket' }),
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.accessToken;
      headers.set('App-Version', packageInfo.version);
      headers.set('Platform', 'web-patient');
      headers.set('API-KEY', import.meta.env.VITE_API_KEY ?? '');

      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    getAppointmentCategories: build.query<
      GetAppointmentCategoriesResProps,
      GetAppointmentCategoriesReqProps
    >({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken || undefined
        },
        url: '/lookup/appointment-categories'
      })
    }),
    getAppointmentTypes: build.query<GetAppointmentTypesResProps, GetAppointmentTypesReqProps>({
      keepUnusedDataFor: 60 * 60 * 1000,
      query: ({ accessToken, ...params }) => ({
        headers: {
          Authorization: accessToken
        },
        params,
        url: '/lookup/appointment-types'
      })
    }),
    getFrontDeskTypes: build.query<GetFrontDeskTypesResProps, GetFrontDeskTypesReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setFrontDeskTypes(data.data));
        }
      },
      query: () => ({
        url: '/lookup/front-desk-request-types'
      })
    }),
    getMembershipPlans: build.query<GetMembershipPlansResProps, GetMembershipPlansReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data?.data) {
          dispatch(setMembershipPlans(data.data));
        }
      },
      query: (params) => ({
        url: '/lookup/membership-plans',
        ...(params && { params })
      })
    }),
    getStates: build.query<GetStatesResProps, GetStatesReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          const states = data.data
            .map((v) => ({
              label: v.name,
              value: v.stateCode
            }))
            .filter((v) => !STATES_WHERE_ONBOARDING_IS_NOT_ALLOWED.includes(v.label));
          dispatch(setStates(states));
        }
      },
      query: (params) =>
        `/lookup/state-list${
          params?.hideStatesWithoutLicense ? '?hideStatesWithoutLicense=true' : ''
        }`
    }),
    getSuggestedAddresses: build.query<
      GetSuggestedAddressesResProps,
      GetSuggestedAddressesReqProps
    >({
      query: (params) => ({
        params,
        url: '/lookup/verify-addresses'
      })
    })
  }),
  reducerPath: 'lookupApi',
  tagTypes: ['Lookup']
});

export const {
  useGetFrontDeskTypesQuery,
  useGetStatesQuery,
  useGetMembershipPlansQuery,
  useLazyGetMembershipPlansQuery,
  useGetAppointmentTypesQuery,
  useLazyGetAppointmentTypesQuery,
  useGetAppointmentCategoriesQuery,
  useLazyGetAppointmentCategoriesQuery,
  useLazyGetSuggestedAddressesQuery
} = lookupApi;

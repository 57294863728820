import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { BaseModalProps } from 'modals/modal.types';
import { notifyToast } from 'shared/Toast/Toast';

import { PathName } from 'utils/enums';

const RenewalPending: React.FC<BaseModalProps> = ({ onClose, isOpen }) => {
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    notifyToast('We’ve kept your existing renewal request and will notify you when it’s complete.');
    navigate(PathName.Dashboard);
  };

  return (
    <>
      <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="sm" persist>
        <div className="flex flex-col gap-6 px-4 md:p-6">
          <div>
            <h3 className="mb-2 text-xl font-bold">Renewal already pending</h3>
            <p className="text-base">
              You already have a pending prescription renewal. We’ll notified when it’s been
              completed and your prescription is on the way.
            </p>
          </div>
          <div className="flex gap-3 max-md:flex-col-reverse">
            <Common.Button
              className="!w-full"
              color="white-alt"
              fullWidthOnMobile
              onClick={onClose}
            >
              Back
            </Common.Button>
            <Common.Button
              className="!w-full"
              color="blue"
              fullWidthOnMobile
              onClick={handleNavigateToDashboard}
            >
              View dashboard
            </Common.Button>
          </div>
        </div>
      </Common.Modal>
    </>
  );
};

export default RenewalPending;

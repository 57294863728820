import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { GENDER_OPTIONS } from 'constants/formFields';

const GenderSelect = <TFormValues extends FieldValues>({ control, name }: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name: name
  });

  return (
    <Common.Select
      {...field}
      dataTestId="gender_dropdown"
      errors={fieldState.error}
      helper={fieldState.error?.message}
      label="Gender (optional)"
      options={GENDER_OPTIONS}
      placeholder="Gender"
      value={
        GENDER_OPTIONS.find(
          (state) => state.label === field.value || state.value === field.value
        ) ?? field.value
      }
      onChange={(e) => field.onChange(e.value)}
    />
  );
};

export default GenderSelect;

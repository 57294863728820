import React, { PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { AnimatePresence, motion } from 'framer-motion';

const VARIANTS = {
  hidden: {
    opacity: 0,
    translateY: -10
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3
    },
    translateY: 0
  }
};

const Collapse: React.FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div className="flex flex-col gap-4 border-b py-5">
      <AnimatePresence initial={false} mode="wait">
        <button className="flex w-full items-center justify-between" onClick={toggleIsOpen}>
          <span className="font-semibold">{title}</span>
          <Common.Icon name={isOpen ? 'arrow-down' : 'arrow-right'} />
        </button>
        {isOpen && (
          <motion.div animate="visible" className="mt-4" initial="hidden" variants={VARIANTS}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Collapse;

import { CreditCardAttributesProps } from 'services/myAccount/myAccount.types';

import { buildCreditCardAttributesFromForm } from 'widgets/PaymentFormNew/paymentFormNew.settings';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

type ReturnT = CreditCardAttributesProps & { method: 'POST' | 'PUT' };

export const buildBodyForPaymentUpdate = (
  formInfo: PaymentFormFields | string,
  method: 'POST' | 'PUT' = 'POST',
  paymentMethod: CreditCardAttributesProps['paymentMethod'] = 'credit_card'
): ReturnT => {
  const info = typeof formInfo === 'string' ? { token: formInfo } : formInfo;
  return {
    ...buildCreditCardAttributesFromForm(info),
    method,
    paymentMethod
  };
};

import { Common } from '@thecvlb/design-system/lib/src';
import HTMLReactParser from 'html-react-parser';

interface MoreAboutYouProps {
  description: string;
  onClickContinue: () => void;
}

const MoreAboutYou: React.FC<MoreAboutYouProps> = ({ onClickContinue, description }) => {
  return (
    <div className="flex size-full flex-col items-center gap-6">
      <div className="rounded-full bg-secondary-100 p-4 md:p-8">
        <Common.Icon className="size-[60px] text-secondary-600 md:size-[48px]" name="info-person" />
      </div>
      <div className="flex flex-col items-center gap-2 md:gap-4">
        <h3 className="wm-signup-title" data-testid="header">
          More about you
        </h3>
        <span
          className="text-center text-primary-700 md:max-w-[374px] md:text-lg md:font-semibold"
          data-testid="description"
        >
          {HTMLReactParser(description)}
        </span>
      </div>
      <Common.Button
        className="max-md:mt-auto"
        color="blue"
        dataTestId="continue_btn"
        fullWidthOnMobile
        onClick={onClickContinue}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default MoreAboutYou;

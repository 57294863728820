import { ColorTagProps, IconProps } from '@thecvlb/design-system/lib/src/common';

export const getStatusContent = (
  status: string
): {
  color: ColorTagProps['color'];
  icon: IconProps['name'];
} => {
  switch (status.toLowerCase()) {
    case 'paid':
      return {
        color: 'green',
        icon: 'check'
      };
    case 'full refund':
    case 'partial refund':
      return {
        color: 'yellow',
        icon: 'check'
      };
    case 'not paid':
      return {
        color: 'red',
        icon: 'close'
      };
    case 'credit':
      return {
        color: 'blue',
        icon: 'check'
      };
    default:
      return {
        color: 'green',
        icon: 'check'
      };
  }
};

export const desktopHeaderSettings = [
  {
    id: 'date',
    size: '20%'
  },
  {
    id: 'status',
    size: '20%'
  }
];

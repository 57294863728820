import { createApi } from '@reduxjs/toolkit/query/react';

import { setLatestHealthMetrics } from 'store/healthMetrics/healthMetricsSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  AddHealthMetricsResProps,
  DeleteHealthMetricReqProps,
  DeleteHealthMetricResProps,
  GetChartHealthMetricsReqProps,
  GetChartHealthMetricsResProps,
  GetHealthMetricsReqProps,
  GetHealthMetricsResProps,
  GetLatestHealthMetricsReqProps,
  GetLatestHealthMetricsResProps,
  SetHealthMetricsReqProps,
  UpdateHealthMetricsResProps
} from './healthMetrics.types';

export const healthMetricsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addHealthMetrics: build.mutation<AddHealthMetricsResProps, SetHealthMetricsReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        method: 'POST',
        url: '/health-metrics'
      })
    }),
    deleteHealthMetric: build.mutation<DeleteHealthMetricResProps, DeleteHealthMetricReqProps>({
      query: (params) => ({
        method: 'DELETE',
        url: `/health-metrics/${params.collectedDate}/${params.metricType}`
      })
    }),
    getChartHealthMetrics: build.query<
      GetChartHealthMetricsResProps,
      GetChartHealthMetricsReqProps
    >({
      query: (params) => ({
        params,
        url: '/chart-health-metrics'
      })
    }),
    getHealthMetrics: build.query<GetHealthMetricsResProps, GetHealthMetricsReqProps>({
      query: (params) => ({
        params: { ...params },
        url: '/health-metrics'
      })
    }),
    getLatestHealthMetrics: build.query<
      GetLatestHealthMetricsResProps,
      GetLatestHealthMetricsReqProps
    >({
      keepUnusedDataFor: 0,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setLatestHealthMetrics(data.data));
        }
      },
      query: () => '/latest-health-metrics'
    }),
    updateHealthMetrics: build.mutation<UpdateHealthMetricsResProps, SetHealthMetricsReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        method: 'PATCH',
        url: '/health-metrics'
      })
    })
  }),
  reducerPath: 'healthMetricsApi',
  tagTypes: ['HealthMetrics']
});

export const {
  useGetHealthMetricsQuery,
  useLazyGetHealthMetricsQuery,
  useGetLatestHealthMetricsQuery,
  useLazyGetLatestHealthMetricsQuery,
  useGetChartHealthMetricsQuery,
  useLazyGetChartHealthMetricsQuery,
  useAddHealthMetricsMutation,
  useUpdateHealthMetricsMutation,
  useDeleteHealthMetricMutation
} = healthMetricsApi;

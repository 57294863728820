import { FieldValues } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

const PaymentFormNew = <
  TFormValues extends FieldValues & { showEligible?: boolean; showExtendedFields?: boolean }
>({
  control,
  getValues,
  showEligible = true
}: TFormValues) => {
  return (
    <div>
      <Common.CreditCardForm control={control} getValues={getValues} />
      {showEligible && (
        <div className="mx-auto mt-2 flex justify-center gap-1 text-mSm text-gray">
          <Common.Icon name="check" />
          <span>HSA & FSA eligible</span>
        </div>
      )}
    </div>
  );
};

export default PaymentFormNew;

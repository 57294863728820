import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { RequestedLabProps } from './requestedLab.types';

const RequestedLab: React.FC<RequestedLabProps> = ({ unread, pending, date, onClick }) => {
  return (
    <button
      className={classNames(
        'mb-2 flex w-full items-center gap-3.5 rounded-xl px-4 py-2 md:rounded-lg md:p-4',
        pending ? 'bg-gray-100' : 'bg-yellow-100'
      )}
      data-testid="requested_lab"
      onClick={onClick}
    >
      {pending && <Common.Icon className="text-primary" name="clock" />}
      {!!unread && (
        <div className="m-0.5 flex size-4 items-center justify-center rounded-full bg-red text-xs text-white">
          {unread}
        </div>
      )}
      <p className="flex flex-1 flex-col text-left font-bold md:flex-row md:items-center md:gap-2 md:font-medium">
        {pending ? 'Pending labs' : 'Schedule your labs'}
        <span className="text-mSm font-normal text-gray md:text-sm">Requested on {date}</span>
      </p>
      <Common.Icon className="text-gray" name="arrow-right" />
    </button>
  );
};

export default RequestedLab;

import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { LabItem } from 'services/auth/auth.types';

import { cutURLSlugs } from 'utils/helpers';

import LabCorp from 'assets/images/logos/labcorp.svg?react';
import Quest from 'assets/images/logos/quest.png';

interface LabSelectedProps {
  lab: LabItem;
  onContinue: () => void;
}

const LabSelected: React.FC<LabSelectedProps> = ({ onContinue, lab }) => {
  const [isExpanded, toggle] = useToggle(false);

  return (
    <div className="onboarding-page-wrapper items-center text-center">
      <Common.Illustration name="success" />
      <h2 className="wm-signup-title" data-testid="header">
        Lab location selected
      </h2>
      <p data-testid="sub_header">
        Once your lab order is ready, simply visit your selected lab during open hours; you do not
        need to make an appointment.
      </p>
      <div
        className="flex w-full flex-col gap-4 rounded-2xl border border-gray-200 bg-white p-4"
        data-testid="selected_lab_block"
      >
        <div className="flex items-center gap-2">
          <span className="block size-8">
            {lab.labType === 'quest' ? (
              <img className="size-full" src={Quest} />
            ) : (
              <LabCorp className="size-full" />
            )}
          </span>
          <span className="font-bold" data-testid="lab_name">
            {lab.displayName}
          </span>
          {/* <span className="ml-auto">0.4 ml</span> */}
        </div>
        <span className="max-w-[200px] text-start font-semibold" data-testid="lab_address">
          {lab.address}
        </span>
        <span data-testid="please_note">
          Please note that your lab order will be valid at <b>all</b>{' '}
          <span className="first-letter:uppercase">{lab.labType}</span> locations.
        </span>
        <div className="flex flex-col gap-3">
          <button
            className="flex items-center gap-1.5 text-gray"
            data-testid="see_details_btn"
            onClick={toggle}
          >
            <span>See details</span>
            <Common.Icon name={isExpanded ? 'arrow-alt-up' : 'arrow-alt-down'} />
          </button>
          {isExpanded && (
            <>
              <div className="flex flex-col text-sm" data-testid="working_hours">
                {lab.openHours?.map((hour, index) => (
                  <span className="text-start" key={index}>
                    {hour}
                  </span>
                ))}
              </div>
              <div className="flex flex-col gap-2">
                {!!lab.phone && (
                  <div className="flex items-center gap-1 text-mXs">
                    <Common.Icon className="size-4" name="phone" />
                    <span data-testid="lab_phone_number">{lab.phone}</span>
                  </div>
                )}
                {!!lab.website && (
                  <div className="flex items-center gap-1 text-mXs">
                    <Common.Icon className="size-4" name="bandage" />
                    <a
                      className="max-w-full break-words"
                      data-testid="lab_website"
                      href={lab.website}
                      target="_blank"
                    >
                      {cutURLSlugs(lab.website)}
                    </a>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {isExpanded && (
          <Common.Button
            color="white-alt"
            dataTestId="see_in_maps_btn"
            fullWidthOnMobile
            onClick={() => window.open(lab.googleMapsUri, '_blank')}
          >
            View in maps
          </Common.Button>
        )}
      </div>
      <Common.Button color="blue" fullWidthOnMobile onClick={onContinue}>
        Continue
      </Common.Button>
    </div>
  );
};

export default LabSelected;

import { Rating } from 'react-simple-star-rating';
import classNames from 'classnames';

import { ReviewItemProps } from './reviewItem.types';

const ReviewItem: React.FC<ReviewItemProps> = ({ text, rating, author }) => {
  return (
    <div
      className={classNames(
        'flex h-[260px] w-[220px] max-w-full flex-none flex-col gap-2 rounded-lg border-secondary bg-white p-8 md:h-[168px] md:w-[398px] md:p-6'
      )}
    >
      <Rating
        emptyColor="transparent"
        fillColor="#FFA700"
        initialValue={rating}
        size={16}
        SVGclassName="inline-block"
        SVGstorkeWidth="1"
        SVGstrokeColor="#FFA700"
        allowFraction
        readonly
      />
      <div
        className={classNames(
          'line-clamp-3 text-start text-mSm md:text-base',
          !!author ? 'line-clamp-2' : 'line-clamp-3'
        )}
      >
        {text}
      </div>
      {!!author && (
        <p className="mt-auto flex flex-col gap-1 text-mSm font-semibold text-gray-700">{author}</p>
      )}
      <span className="mt-auto block text-mXs text-secondary-600">Verified Patient</span>
    </div>
  );
};

export default ReviewItem;

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import { AddSubscriptionsReqProps, AddSubscriptionsResProps } from './subscriptions.types';

export const subscriptionsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addSubscriptions: build.mutation<AddSubscriptionsResProps, AddSubscriptionsReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'subscriptions'
      })
    }),
    resumeSubscription: build.mutation<AddSubscriptionsResProps, void>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'subscriptions/resume'
      })
    })
  }),
  reducerPath: 'subscriptionsApi',
  tagTypes: ['Subscriptions']
});

export const { useAddSubscriptionsMutation, useResumeSubscriptionMutation } = subscriptionsApi;

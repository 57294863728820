import { createApi } from '@reduxjs/toolkit/query/react';
import { nanoid } from 'nanoid';

import { baseQueryWithReauth } from 'utils/services';

import {
  AllSymptomsReqProps,
  AllSymptomsResProps,
  DiagnosisReqProps,
  DiagnosisResProps,
  SearchReqProps,
  SearchResProps,
  SpecialistResProps,
  SuggestedResProps,
  TriageResProps
} from './symptomChecker.types';

const interviewId = nanoid();
const sessionId = sessionStorage.getItem('session-id');
const headers = {
  ...(sessionId && { sessionId }),
  'interview-Id': interviewId
};

const infermedicaUrl = 'infermedica';

export const symptomCheckerApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getAllSymptoms: build.mutation<AllSymptomsResProps, AllSymptomsReqProps>({
      query: (age) => ({
        headers,
        method: 'GET',
        url: `${infermedicaUrl}/symptoms?age.value=${age}`
      })
    }),
    getSearch: build.query<SearchResProps, SearchReqProps>({
      query: (params) => ({
        headers,
        params,
        url: `${infermedicaUrl}/search`
      })
    }),
    getSuggests: build.mutation<SuggestedResProps, DiagnosisReqProps>({
      query: (body) => ({
        body,
        headers,
        method: 'POST',
        url: `${infermedicaUrl}/suggest`
      })
    }),
    sendAnswer: build.mutation<DiagnosisResProps, DiagnosisReqProps>({
      query: (body) => ({
        body,
        headers,
        method: 'POST',
        url: `${infermedicaUrl}/diagnosis`
      })
    }),
    sendSpecialist: build.mutation<SpecialistResProps, DiagnosisReqProps>({
      query: (body) => ({
        body,
        headers,
        method: 'POST',
        url: `${infermedicaUrl}/recommend_specialist`
      })
    }),
    sendTriage: build.mutation<TriageResProps, DiagnosisReqProps>({
      query: (body) => ({
        body,
        headers,
        method: 'POST',
        url: `${infermedicaUrl}/triage`
      })
    })
  }),
  reducerPath: 'symptomCheckerApi',
  tagTypes: ['SymptomChecker']
});

export const {
  useGetSuggestsMutation,
  useSendAnswerMutation,
  useLazyGetSearchQuery,
  useSendTriageMutation,
  useSendSpecialistMutation,
  useGetAllSymptomsMutation
} = symptomCheckerApi;

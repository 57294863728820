import { AppointmentProps } from 'store/appointments/appointments.types';

import { PossibleStepsType, StepName } from './createAppointment.types';

export const buildBodyForApptSchedule = (
  data: Omit<AppointmentProps, '_id' | 'files' | 'category'>
) => ({
  appointmentDescription: data.appointmentDescription.trim(),
  appointmentMethod: data.callType,
  appointmentTime: {
    endTime: data.endTime,
    startTime: data.startTime
  },
  appointmentTypeId: data.appointmentTypeId,
  doctorId: data.doctorId,
  isWeightManagementAppointment: data.isWeightManagementAppointment
});

export const defineFlowSteps = (
  steps: PossibleStepsType,
  params: { isInsurancePatient: boolean; isUnlimitedPlan: boolean }
): StepName[] => {
  let copySteps: StepName[] = [...steps];
  const stepsToFilter: StepName[] = [];
  // TODO: ADD CONDITIONS TO FILTER STEPS
  // Check different conditions to filter steps by adding steps to filter array in the end

  // 1. If the user has an unlimited plan, we remove the membership and checkout steps
  if (params.isUnlimitedPlan) {
    const membershipRelatedSteps = params.isInsurancePatient
      ? (['membership'] as const)
      : (['membership', 'checkout'] as const);
    stepsToFilter.push(...membershipRelatedSteps);
  }

  // 2. Since SC is removed we remove step of medical care near
  stepsToFilter.push('medical-care-near');

  copySteps = copySteps.filter((step) => !stepsToFilter.includes(step));
  return copySteps;
};

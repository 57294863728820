import React from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import useAnalytics from 'hooks/useAnalytics';
import { MessageTab } from 'utils/enums';

import { ChannelTabsProps, TabProps } from './channelTabs.types';

const ChannelTabs: React.FC<ChannelTabsProps> = ({ tabs, onChange, selectedTabId }) => {
  const logEvent = useAnalytics();

  const handleClickTab = (tab: TabProps) => {
    logEvent(
      tab.id === MessageTab.FRONT_DESK
        ? 'messages_front_desk_tab_click'
        : 'messages_care_team_tab_click'
    );
    onChange(tab);
  };
  return (
    <div
      className={classNames(
        'relative flex w-full border-b border-gray-200' +
          'before:duration-400 before:absolute before:bottom-0 before:h-0.5 before:w-6/12 before:bg-secondary before:transition-all',
        selectedTabId === tabs[0].id ? 'before:left-0' : 'before:left-[50%]'
      )}
      data-testid="tabs_filter_menu"
    >
      {tabs.map((tab) => (
        <button
          className={classNames(
            'group relative flex grow items-center gap-2 px-6 py-4 text-mBase font-semibold hover:text-secondary md:px-5',
            tab.id === selectedTabId ? 'text-secondary' : 'text-gray'
          )}
          data-testid="channel_tab"
          key={tab.label}
          type="button"
          onClick={() => handleClickTab(tab)}
        >
          <div
            className={classNames(
              'flex size-12 items-center justify-center rounded-full',
              tab.id === selectedTabId ? 'bg-secondary-100' : 'bg-gray-100'
            )}
          >
            <Common.Icon
              className={classNames(
                'size-8 group-hover:text-secondary',
                tab.id === selectedTabId ? 'text-secondary' : 'text-gray'
              )}
              name={tab.icon}
            />
          </div>
          {tab.unreadMessageCount > 0 && (
            <div
              className="absolute left-14 top-4 flex size-4 items-center justify-center rounded-full bg-red text-xs text-white"
              data-testid="unread_messages_counter"
            >
              {tab.unreadMessageCount > 9 ? '9+' : tab.unreadMessageCount}
            </div>
          )}
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default ChannelTabs;

import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import { EvidenceItem } from 'services/symptomChecker/symptomChecker.types';

import { selectSymptomChecker } from 'store';
import { SymptomCheckerState } from 'store/symptomChecker/symptomChecker.types';
import {
  setCommonRiskFactors,
  setEvidence,
  setQuestions,
  setSuggestedEvidence
} from 'store/symptomChecker/symptomCheckerSlice';

import { SymptomCheckerSource } from 'containers/SymptomChecker/symptomChecker.types';
import ActionButtons from 'features/symptomChecker/ActionButtons';
import FadeSlideWrapper from 'shared/animationWrappers/FadeSlideWrapper';
import GroupMultiple from 'widgets/symptomChecker/questionTypes/GroupMultiple';
import { GroupMultipleProps } from 'widgets/symptomChecker/questionTypes/GroupMultiple/groupMultiple.types';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getRisksFactors } from 'utils/helpers';

import { StepProps } from '../steps.types';

const PatientRiskFactors: React.FC<StepProps & { source: SymptomCheckerSource }> = ({
  moveToStep,
  source
}) => {
  const [answers, setAnswers] = useState<SymptomCheckerState['commonRiskFactors']>([]);
  const [questions, setRiskQuestions] = useState<
    GroupMultipleProps<EvidenceItem['choice_id']>['items']
  >([]);
  const { commonRiskFactors, userInfo } = useAppSelector(selectSymptomChecker);
  const dispatch = useAppDispatch();

  const handleChange = (id: string, value: EvidenceItem['choice_id']) => {
    const hasAnswerAlready = answers.some((i) => i.id === id);
    if (!hasAnswerAlready) {
      setAnswers([...answers, { id, value }]);
    } else {
      setAnswers([...answers.map((answer) => (answer.id !== id ? answer : { id, value }))]);
    }
  };

  const handlePrev = () => {
    moveToStep('prev');
    if (source === 'guest' || userInfo.patientSelect === 'someone else') {
      moveToStep('prev');
    } else {
      moveToStep(1);
    }
  };

  const handleNext = () => {
    dispatch(setCommonRiskFactors([...answers]));
    if (!isEqual(answers, commonRiskFactors)) {
      dispatch(setSuggestedEvidence([]));
      dispatch(setEvidence([]));
      dispatch(setQuestions([]));
    }
    moveToStep('next');
  };

  const onInit = () => {
    const actualQuestionsList = getRisksFactors(
      userInfo.age,
      userInfo.gender,
      userInfo.patientSelect
    );
    setRiskQuestions(actualQuestionsList);
    const IDsCurrent = actualQuestionsList.map((q) => q.id);
    const IDsStored = commonRiskFactors.map((q) => q.id);
    if (IDsCurrent.length === IDsStored.length && IDsStored.every((q) => IDsCurrent.includes(q))) {
      setAnswers(commonRiskFactors);
    } else {
      dispatch(setCommonRiskFactors([]));
    }
  };
  const isDisabled = answers.length < questions.length;

  const pronoun =
    userInfo.patientSelect === 'myself' ? 'you' : userInfo.gender === 'female' ? 'her' : 'him';

  useEffect(onInit, []);

  return (
    <>
      <FadeSlideWrapper>
        <div className="symptom-content-wrapper">
          <h2 className="mb-2 text-mLg font-semibold text-primary md:text-xl">
            {`Please check all the statements below that apply to ${pronoun}!`}
          </h2>
          <GroupMultiple answers={answers} items={questions} onSelect={handleChange} />
        </div>
      </FadeSlideWrapper>
      <ActionButtons
        isNextBtnDisabled={isDisabled}
        onClickBack={handlePrev}
        onClickNext={handleNext}
      />
    </>
  );
};

export default PatientRiskFactors;

import { Dispatch, ReactElement, SetStateAction } from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import BipolarDisorder from 'pages/SignUp/tripleTherapy/BipolarDisorder';
import Bupropion from 'pages/SignUp/tripleTherapy/Bupropion';
import Glaucoma from 'pages/SignUp/tripleTherapy/Glaucoma';
import KetogenicDiet from 'pages/SignUp/tripleTherapy/KetogenicDiet';
import KidneyStones from 'pages/SignUp/tripleTherapy/KidneyStones';
import LabWork from 'pages/SignUp/tripleTherapy/LabWork';
import LacticAcidosis from 'pages/SignUp/tripleTherapy/LacticAcidosis';
import LiverOrKidneyImpairment from 'pages/SignUp/tripleTherapy/LiverOrKidneyImpairment';
import MAOInhibitors from 'pages/SignUp/tripleTherapy/MAOInhibitors';
import PriorSeizures from 'pages/SignUp/tripleTherapy/PriorSeizures';
import SideEffectsWithMetforminBupropion from 'pages/SignUp/tripleTherapy/SideEffectsWithMetforminBupropion/SideEffectsWithMetforminBupropion';
import TTCompleteOnboarding from 'pages/SignUp/tripleTherapy/TTCompleteOnboarding';
import TTPlanSelect from 'pages/SignUp/tripleTherapy/TTPlanSelect';
import TTResults from 'pages/SignUp/tripleTherapy/TTResults';
import WhatNext from 'pages/SignUp/tripleTherapy/WhatNext';
import WhyTT from 'pages/SignUp/tripleTherapy/WhyTT';
import Congrats from 'pages/SignUp/WM/Congrats';
import Diabetes from 'pages/SignUp/WM/Diabetes';
import HeightAndWeight from 'pages/SignUp/WM/HeightAndWeight';
import MigrationCheckout from 'pages/SignUp/WM/MigrationCheckout';
import ScheduleAnAppointment from 'pages/SignUp/WM/ScheduleAnAppointment';
import SelectLab from 'pages/SignUp/WM/SelectLab';
import YouAreInGoodHands from 'pages/SignUp/WM/YouAreInGoodHands';

import { MoveStepFunction } from 'containers/SignUp/signUp.types';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';

import { FlowTypes } from 'utils/enums';

import { MoveToStepFunction, PossibleStepsType, StepName } from '../migrateToTT.types';

type ContentProps = {
  loading: boolean;
  moveToStep: MoveToStepFunction;
  setSteps: Dispatch<SetStateAction<StepName[]>>;
  step: StepName;
  steps: StepName[];
};

const renderWithAnimation = (
  component: JSX.Element,
  key: string,
  params?: {
    className?: string;
    isBackAnimation?: boolean;
  }
) => {
  return (
    <SlideAnimateWrapper
      className={classNames(
        'mx-auto w-full max-md:h-full',
        params?.className ?? 'md:max-w-[500px]'
      )}
      key={key}
    >
      {component}
    </SlideAnimateWrapper>
  );
};

const Content: React.FC<ContentProps> = ({
  step,
  steps,
  moveToStep: originalMoveToStepFunc,
  // loading,
  setSteps
}) => {
  const moveToStep: MoveStepFunction = (type: 'next' | 'prev' | number, options) => {
    if (type === 'next' || type === 'prev') {
      originalMoveToStepFunc(type, options?.searchParams);
    } else {
      originalMoveToStepFunc(steps[type], options?.searchParams);
    }
  };

  const handleAdditionalConditions = (type: string, value: unknown) => {
    if (type === 'select-labs') {
      if (!!value) {
        setSteps(steps.filter((step) => step !== 'select-labs'));
      } else if (!steps.includes('select-labs')) {
        const newSteps = [...steps];
        newSteps.splice(steps.indexOf('lab-work') + 1, 0, 'select-labs');
        setSteps(newSteps);
      }
    }
  };

  const content: { [key in PossibleStepsType[number]]: ReactElement | null } = {
    'bipolar-disorder': renderWithAnimation(
      <BipolarDisorder selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'bipolar-disorder'
    ),
    'complete-migration': renderWithAnimation(
      <TTCompleteOnboarding flow="authorized" />,
      'wm-complete-onboarding'
    ),
    congrats: renderWithAnimation(
      <Congrats moveToStep={moveToStep} selectedFlow={FlowTypes.TripleTherapy} />,
      'wm-congrats'
    ),
    'height-and-weight': renderWithAnimation(
      <HeightAndWeight moveToStep={moveToStep} selectedFlow="authorized" isMigrationToTT />,
      'height-and-weight'
    ),
    'schedule-appointment': renderWithAnimation(
      <ScheduleAnAppointment moveToStep={moveToStep} selectedFlow={FlowTypes.TripleTherapy} />,
      'wm-schedule-appointment'
    ),
    'why-tt': renderWithAnimation(<WhyTT onContinue={() => moveToStep('next')} />, 'why-tt'),
    'you-are-in-good-hands': renderWithAnimation(
      <YouAreInGoodHands selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'you-are-in-good-hands'
    ),
    'what-next': renderWithAnimation(
      <WhatNext onContinue={() => moveToStep('next')} />,
      'what-next'
    ),
    'wm-diabetes-question': renderWithAnimation(
      <Diabetes migrationFlow="tt" moveToStep={moveToStep} selectedFlow={'authorized'} />,
      'diabetes'
    ),
    'kidney-stones': renderWithAnimation(
      <KidneyStones selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'kidney-stones'
    ),

    'prior-seizure': renderWithAnimation(
      <PriorSeizures selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'prior-seizure'
    ),
    glaucoma: renderWithAnimation(
      <Glaucoma selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'glaucoma'
    ),
    'mao-inhibitors': renderWithAnimation(
      <MAOInhibitors selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'mao-inhibitors'
    ),
    'liver-or-kidney-impairment': renderWithAnimation(
      <LiverOrKidneyImpairment selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'liver-or-kidney-impairment'
    ),
    'lactic-acidosis': renderWithAnimation(
      <LacticAcidosis selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'lactic-acidosis'
    ),
    'bupropion-diet': renderWithAnimation(
      <Bupropion selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'bupropion-diet'
    ),
    'ketogenic-diet': renderWithAnimation(
      <KetogenicDiet selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'ketogenic-diet'
    ),
    'side-effects-with-metformin-bupropion': renderWithAnimation(
      <SideEffectsWithMetforminBupropion
        selectedFlow="authorized"
        onContinue={() => moveToStep('next')}
      />,
      'side-effects-with-metformin-bupropion'
    ),
    'questioner-results': renderWithAnimation(
      <TTResults onContinue={() => moveToStep('next')} />,
      'questioner-results',
      { className: 'md:mx-auto md:max-w-[360px]' }
    ),
    'lab-work': renderWithAnimation(
      <LabWork
        selectedFlow="authorized"
        onContinue={() => {
          moveToStep('next');
        }}
        onSelectLabsType={(v) => handleAdditionalConditions('select-labs', v)}
      />,
      'lab-work'
    ),
    'select-labs': renderWithAnimation(
      <SelectLab
        onContinue={() =>
          moveToStep('next', {
            searchParams: '?shouldEmulateBack=true'
          })
        }
      />,
      'select-labs'
    ),
    'plan-details': renderWithAnimation(
      <TTPlanSelect selectedFlow="authorized" onContinue={() => moveToStep('next')} />,
      'plan-details'
    ),
    checkout: renderWithAnimation(
      <MigrationCheckout
        selectedFlow={FlowTypes.TripleTherapy}
        onContinue={() => moveToStep('next')}
      />,
      'wm-checkout',
      {
        className: 'md:max-w-[800px]'
      }
    )
  };

  const contentClassName = classNames('flex flex-col grow w-full mx-auto max-md:h-full px-0.5');

  return (
    <div className={contentClassName}>
      <AnimatePresence mode="wait">{content[step]}</AnimatePresence>
    </div>
  );
};

export default Content;

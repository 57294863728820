import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { SUPPORT_PHONE_NUMBER } from 'utils/constants';
import { FlowTypes } from 'utils/enums';

import { Props } from './headerNew.types';

import fop from 'assets/images/signUp/fop.webp';
import fopTwo from 'assets/images/signUp/fop-2.webp';

const HeaderNew: React.FC<Props> = ({
  clickOnPrev,
  isShowBackButton = true,
  flow,
  isShowCenterLogos = true
}) => {
  const logoClassName = 'size-7 md:h-8 md:w-8';

  return (
    <header className="flex items-center justify-between max-md:py-6 md:px-6">
      <button
        className={classNames('flex items-center p-2.5', {
          invisible: !isShowBackButton
        })}
        data-testid="arrow_back"
        onClick={clickOnPrev}
      >
        <Common.Icon className="size-5 text-gray-700 md:text-gray" name="arrow-left" />
      </button>

      {isShowCenterLogos && (
        <div className="flex items-center gap-4">
          <Common.Logo className={logoClassName} name="cross" />
          {flow === FlowTypes.WeightManagementBalladHealth && (
            <>
              <Common.Icon name="close" />
              <Common.Logo className={logoClassName} name="ballad-health" />
            </>
          )}
          {flow === FlowTypes.BlueLineFlow && (
            <>
              <Common.Icon name="close" />
              <div className="flex items-center gap-2">
                <img className={logoClassName} src={fop} />
                <img className={logoClassName} src={fopTwo} />
              </div>
            </>
          )}
          {flow === FlowTypes.WeightManagementFlowOptavia && (
            <>
              <Common.Icon name="close" />
              <Common.Logo className={logoClassName} name="optavia" />
            </>
          )}
        </div>
      )}
      <a className="p-2" href={SUPPORT_PHONE_NUMBER.link}>
        <Common.Icon className="size-7 text-primary-700 md:size-6" name="phone" />
      </a>
    </header>
  );
};

export default HeaderNew;

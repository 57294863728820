import { ChangeEvent, useRef } from 'react';
import { useDropArea, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import Image from 'modals/Image';

import { FileZoneProps } from './fileZone.types';

const FileZone: React.FC<FileZoneProps> = ({
  onSelect,
  type,
  status: { fileName, filePath, fileStatus, _id },
  onDelete,
  loading,
  accept = 'image/*',
  placeholder,
  defaultIcon = 'camera'
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpenImage, toggleIsOpenImage] = useToggle(false);
  const [bond, { over }] = useDropArea({
    onFiles: (files) =>
      (fileStatus === 'initial' || fileStatus === 'error') && onSelect(files, type)
  });
  const isProcessing = fileStatus === 'processing';

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    onSelect(Array.from(e.target.files), type);
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Image filePath={filePath} isOpen={isOpenImage} onClose={toggleIsOpenImage} />
      <label
        {...bond}
        className={classNames(
          'relative flex h-[140px] w-full items-center justify-center rounded-2xl border px-4 py-6 text-gray transition-all duration-300',
          {
            'border-green': fileStatus === 'success',
            'pointer-events-none border-gray': fileStatus === 'error',
            'target-animate-div cursor-pointer active:border-gray-600 active:text-gray-600 md:hover:border-gray-600 md:hover:text-gray-600':
              fileStatus === 'initial'
          },
          isProcessing
            ? 'bg-gray-200 text-gray-700'
            : fileStatus === 'success'
              ? 'bg-green text-white'
              : fileStatus === 'error'
                ? 'bg-gray text-white'
                : over
                  ? 'border-2 border-solid border-primary bg-primary-400'
                  : 'border-dashed'
        )}
        data-testid={`file_zone_${fileStatus}`}
        htmlFor={`file_zone_${type}`}
      >
        {!!_id && fileStatus === 'success' && (
          <button
            className="absolute right-4 top-4 text-black/40"
            disabled={loading}
            role="delete-button"
            type="button"
            onClick={() => onDelete(_id)}
          >
            <Common.Icon name="trash" />
          </button>
        )}
        <input
          accept={accept}
          className="hidden"
          data-testid="file_zone"
          disabled={(fileStatus !== 'initial' && fileStatus !== 'error') || loading}
          id={`file_zone_${type}`}
          ref={inputRef}
          role="input"
          type="file"
          onChange={handleChangeInput}
        />
        <div
          className={classNames('max-w-full', {
            'text-black': fileStatus === 'success',
            'text-white': over
          })}
        >
          <Common.Icon
            className={classNames('m-auto', {
              'animate-spin text-gray': isProcessing,
              'text-green-700': fileStatus === 'success',
              'text-white': fileStatus === 'error'
            })}
            name={
              isProcessing
                ? 'loader'
                : fileStatus === 'success'
                  ? 'check-circle'
                  : fileStatus === 'error'
                    ? 'reset'
                    : over
                      ? 'arrow-alt-down'
                      : defaultIcon
            }
          />
          <span
            className={classNames(
              'mx-auto block max-h-[50px] max-w-full truncate text-pretty break-all text-center text-sm first-letter:uppercase max-md:max-w-[100px]',
              { 'opacity-40': fileStatus === 'success' }
            )}
          >
            {isProcessing
              ? 'uploading...'
              : fileStatus === 'success' || fileStatus === 'error'
                ? fileName.length > 100
                  ? fileName.slice(0, 100) + '...'
                  : fileName
                : over
                  ? 'drop photo to upload'
                  : placeholder
                    ? placeholder
                    : type}
          </span>
          {(fileStatus === 'success' || fileStatus === 'error') && (
            <Common.Button
              className="pointer-events-auto mx-auto mt-2 text-white hover:text-white/80"
              color="white-alt"
              size="sm"
              type="button"
              onClick={
                fileStatus === 'success' ? toggleIsOpenImage : () => inputRef.current?.click()
              }
            >
              {fileStatus === 'success' ? 'View' : 'Replace'}
            </Common.Button>
          )}
        </div>
      </label>
    </>
  );
};

export default FileZone;

import { useNavigate } from 'react-router-dom';

import MigrateFromTirzepatide from 'widgets/MigrateFromTirzepatide';

import { PathName } from 'utils/enums';

const MigrateFromTirzepatidePage = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full px-4">
      <MigrateFromTirzepatide
        onComplete={() => navigate(PathName.Dashboard)}
        onLeaveFlow={() => navigate(-1)}
      />
    </div>
  );
};

export default MigrateFromTirzepatidePage;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useToggle } from 'react-use';
import AddToCalendar from '@culturehq/add-to-calendar';
import { Common } from '@thecvlb/design-system';

import { LINK_TO_ACCESSING_APPOINTMENTS } from 'constants/externalLinks';

import { ButtonAddToCalendarProps } from './buttonAddToCalendar.types';

const ButtonAddToCalendar: React.FC<ButtonAddToCalendarProps> = ({
  dataTestId,
  id,
  name,
  startsAt,
  endsAt,
  onClick
}) => {
  const [open, toggle] = useToggle(false);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggle();
    onClick?.();
  };
  const details = `
    <b>Your LifeMD appointment has been confirmed. To access the appointment please use the following link:</b>
    \n(Please be sure to enter the digital waiting room at least 5 minutes before your scheduled appointment)
    \n<a href="${window.location.origin}/call?callId=${id}">${window.location.origin}/call?callId=${id}</a>
    \n-----------------
    \n<u>How to access your appointment from the web platform or App:</u>
    \nFor more information about accessing appointments in the LifeMD platform please <a href="${LINK_TO_ACCESSING_APPOINTMENTS}">click here</a>.
    `.trim();

  return (
    <div className="w-full md:w-auto" onClick={handleClick}>
      {
        // @ts-ignore
        <AddToCalendar
          dataTestId={dataTestId}
          event={{ details, endsAt, location: '', name, startsAt }}
          filename={name}
          open={open}
        >
          <Common.Icon className="mr-1 size-4 text-gray-700" name="calendar" />
          <p className="font-bold">Add to calendar</p>
        </AddToCalendar>
      }
    </div>
  );
};

export default ButtonAddToCalendar;

import { useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { BodyModelProps } from '../symptomsSelector.types';

import { getPanelPosition } from './bodyModel.settings';

import FemaleSvg from 'assets/images/symptomChercker/female.svg?react';
import FemaleBackSvg from 'assets/images/symptomChercker/female-back.svg?react';
import MaleSvg from 'assets/images/symptomChercker/male.svg?react';
import MaleBackSvg from 'assets/images/symptomChercker/male-back.svg?react';

const BodyModel: React.FC<BodyModelProps> = ({ gender, onBodyPartClick, isVisible, source }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isRotate, toggleIsRotate] = useToggle(false);
  const [currentRegion, setCurrentRegion] = useState('');

  useEffect(() => {
    const handleClick = (el: Event) => {
      const targetElement = el.target as HTMLElement;
      const fixedRegionString = targetElement.id.replace('body-model-', '');
      const regionClicked = {
        coords: getPanelPosition(targetElement, source === 'authed' ? 630 : 354),
        id: fixedRegionString
      };
      if (currentRegion !== fixedRegionString) {
        setCurrentRegion(fixedRegionString);
        onBodyPartClick(regionClicked);
      }
    };
    const bodyElement = ref.current;
    bodyElement?.addEventListener('click', handleClick);
    return () => {
      bodyElement?.removeEventListener('click', handleClick);
    };
  }, []);

  const imageClassName = 'h-[450px] duration-150';

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="relative flex flex-col items-center" data-testid="body_model" ref={ref}>
          {isVisible && (
            <>
              {gender === 'male' ? (
                <>
                  <MaleBackSvg className={classNames(imageClassName, { hidden: !isRotate })} />
                  <MaleSvg className={classNames(imageClassName, { hidden: isRotate })} />
                </>
              ) : (
                <>
                  <FemaleBackSvg className={classNames(imageClassName, { hidden: !isRotate })} />
                  <FemaleSvg className={classNames(imageClassName, { hidden: isRotate })} />
                </>
              )}
              <button
                className="mt-3 flex items-center gap-2.5 text-sm font-semibold text-primary"
                onClick={toggleIsRotate}
              >
                <Common.Icon className="size-[27px]" name="rotate-view" />
                <span className="font-bold"> Rotate model</span>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BodyModel;

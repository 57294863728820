import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';

import { selectSignUp } from 'store';

import { WmFlowType } from 'containers/SignUp/Content/content.types';
import Loader from 'shared/Loader';
import Heading from 'widgets/wmOnboarding/parts/Heading';

import { useAppSelector, useQuery } from 'hooks';
import useContinueInWeb from 'hooks/useContinueInWeb';
import { PathName } from 'utils/enums';

import { NextSteps } from '../TTPlanSelect/parts';

const TTCompleteOnboarding: React.FC<{ flow?: WmFlowType }> = ({ flow = 'onboarding' }) => {
  const [getMyAccount, { isFetching }] = useLazyGetMyAccountQuery();
  const query = useQuery();
  const navigate = useNavigate();

  const { user } = useAppSelector(selectSignUp);

  const continueInWeb = useContinueInWeb(user);

  const handleComplete = () => {
    if (flow === 'authorized') {
      getMyAccount()
        .unwrap()
        .finally(() => navigate(PathName.Dashboard));
    } else {
      const isErrorState = query.get('verification-failed');
      continueInWeb(
        '',
        isErrorState ? PathName.VerifyProfile : PathName.IntakeForm,
        `?redirectTo=${PathName.IntakeForm}${isErrorState ? '&verification-failed=1' : ''}`
      );
    }
  };

  return (
    <>
      <Loader isVisible={isFetching} />
      <div className="flex flex-col gap-6 pb-6 pt-4 md:pb-16 md:pt-8">
        <Heading
          category="Sign up"
          subtitle={
            <p className="text-mSm text-primary-700">
              Please check your email for details on how to access your LifeMD patient portal. (Be
              sure to check your spam folder as well.)
            </p>
          }
          title="Welcome to LifeMD!"
        />
        <NextSteps activeStep="labs" />
        <Common.Button
          className="self-center"
          color="blue"
          fullWidthOnMobile
          onClick={handleComplete}
        >
          Done
        </Common.Button>
      </div>
    </>
  );
};

export default TTCompleteOnboarding;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { useGetMembershipPlansQuery } from 'services/lookup/lookup';

import { selectLookup, selectUser } from 'store';

import DowngradeAccount from 'features/DowngradeAccount';
import Loader from 'shared/Loader';
import { notifyError } from 'shared/Toast/Toast';
import PlanSelector from 'widgets/PlanSelector';

import { useAppSelector } from 'hooks';
import { PathName, PlanCodes } from 'utils/enums';
import { getNewMembershipPlans } from 'utils/helpers';

const ChangePlan = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { membershipPlans } = useAppSelector(selectLookup);
  const { activePlanId, activePlanCode, activePlanName } = useAppSelector(selectUser);
  const { isLoading, isFetching, isError } = useGetMembershipPlansQuery(
    { isComponentPlan: false },
    { skip: membershipPlans.length < 0 }
  );

  const filteredPlans = getNewMembershipPlans(membershipPlans);

  const handleGetPlans = () => {
    isError && notifyError('Error while getting plans, please try again later');
  };

  const handleUpdate = (planID: string) => {
    const newPlan = membershipPlans.find((plan) => plan._id === planID);
    if (!newPlan) {
      notifyError('Issues with plan, please try again later');
      navigate(PathName.Dashboard);
    }
    navigate({
      pathname: PathName.Checkout,
      search: search + `&newPlanID=${planID}`
    });
  };

  useEffect(handleGetPlans, [isError]);

  return (
    <>
      <Loader isVisible={isFetching} />
      {!isLoading && (
        <>
          {activePlanCode === PlanCodes.WeightManagementMembership && (
            <div className="mx-auto mb-4 max-w-[500px] md:mb-8">
              <Common.Alert type="error" colorableBackground>
                <span className="text-base">
                  You’re currently on a Weight Management program, which requires your current
                  {` ${activePlanName}`} plan. If you change your plan, you will no longer have
                  access to this program.
                </span>
              </Common.Alert>
            </div>
          )}
          <h1 className="text-center text-mLg font-bold text-primary-700 max-md:mb-8 md:text-2xl">
            Change your plan
          </h1>
          <PlanSelector
            currentPlan={activePlanId}
            plans={filteredPlans}
            showSwitcher={false}
            onUpdate={handleUpdate}
          />
          <div className="mt-8">
            <DowngradeAccount />
          </div>
        </>
      )}
    </>
  );
};

export default ChangePlan;

import { Common } from '@thecvlb/design-system';

import { useGetTokenForCancelMutation } from 'services/general/general';
import { GetTokenResProps } from 'services/general/general.types';

import { handleRequestCatch } from 'utils/helpers';

const DowngradeAccount: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const [getToken, { isLoading }] = useGetTokenForCancelMutation();

  const handleGetTokenThen = ({ data }: GetTokenResProps) => {
    setTimeout(() => {
      window.open(data.link, '_top');
      onClick?.();
    });
  };

  const handleClickCancelMembership = () => {
    getToken().unwrap().then(handleGetTokenThen).catch(handleRequestCatch);
  };

  return (
    <Common.Button
      className="mx-auto text-gray-500"
      isLoading={isLoading}
      style="text-only"
      onClick={handleClickCancelMembership}
    >
      Cancel/pause membership
    </Common.Button>
  );
};

export default DowngradeAccount;

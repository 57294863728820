import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import {
  DeselectPharmacyReqProps,
  DeselectPharmacyResProps,
  GetPharmaciesReqProps,
  GetPharmaciesResProps,
  SelectPharmacyReqProps,
  SelectPharmacyResProps
} from './pharmacies.types';

export const pharmaciesApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    deselectPharmacy: build.mutation<DeselectPharmacyResProps, DeselectPharmacyReqProps>({
      query: ({ pharmacyId, accessToken }) => ({
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        method: 'PATCH',
        url: `/pharmacies/${pharmacyId}/deselect`
      })
    }),
    getPharmacies: build.query<GetPharmaciesResProps, GetPharmaciesReqProps>({
      query: ({ accessToken, ...params }) => ({
        headers: {
          Authorization: accessToken
        },
        method: 'GET',
        params,
        url: '/pharmacies/search'
      })
    }),
    selectPharmacy: build.mutation<SelectPharmacyResProps, SelectPharmacyReqProps>({
      query: ({ pharmacyId, accessToken }) => ({
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: `/pharmacies/${pharmacyId}/select`
      })
    })
  }),
  reducerPath: 'pharmaciesApi',
  tagTypes: ['Pharmacies']
});

export const { useLazyGetPharmaciesQuery, useSelectPharmacyMutation, useDeselectPharmacyMutation } =
  pharmaciesApi;

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetSet } from 'react-use';
import { AnimatePresence } from 'framer-motion';
import uniqBy from 'lodash/uniqBy';

import { useSendMigrationDecisionMutation } from 'services/general/general';
import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import { useLazyGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import {
  AppointmentMifQuestion,
  MifStructure,
  QuestionWithAnswerConfig
} from 'services/mifs/mifs.types';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { selectMifInfo, selectUser } from 'store';
import { selectLookup } from 'store';
import { clearTirzepatideMif, setTirzepatideMif } from 'store/mif/mifSlice';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { SEMAGLUTIDE_PRICE_POINT, TIRZEPATIDE_PRICE_POINT } from 'constants/onboarding';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PlanCodes } from 'utils/enums';
import { findMaintenancePP, handleRequestCatch } from 'utils/helpers';

import { UserCurrentWMPPType } from 'models/user.types';

import {
  AllSet,
  Appointment,
  Checkout,
  Intro,
  MifQuestionOne,
  MifQuestionThree,
  MifQuestionTwo,
  Results,
  SelectMedication
} from './components';
import { UserDecision } from './migrateFormTirzepatide.types';

const validSteps = [
  'intro',
  'mif-1',
  'mif-2',
  'mif-3',
  'results',
  'select-medication',
  'appointment-tbd',
  'checkout'
];

const ID_FOR_MIF_REQUESTS = 'tirzepatide-migration';

type PatchedQuestion = Omit<MifStructure['configs'][number], 'config'> & {
  config: QuestionWithAnswerConfig;
};

const MigrateFromTirzepatide = ({
  onComplete,
  onLeaveFlow
}: {
  onComplete: () => void;
  onLeaveFlow: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();
  const step = params.get('s') ?? '';
  const [isBackAnimation, setIsBackAnimation] = useGetSet(false);
  const [mifSteps, setMifSteps] = useState<PatchedQuestion[]>([]);
  const [isFlowPassed, setIsFlowPassed] = useState(false);

  const { tirzepatideMif } = useAppSelector(selectMifInfo);
  const { membershipPlans } = useAppSelector(selectLookup);
  const { activePricePoint, tirzepatideMigration } = useAppSelector(selectUser);

  const mifCheckResults = tirzepatideMigration?.eligibilityType;

  const { isFetching } = useGetMembershipPlansQuery();
  const [getMifStructure, { isLoading: isMifLoading }] = useLazyGetMifStructureQuery();
  const [submitMif, { isLoading }] = useSendMifResponseMutation();
  const [getMyAccount, { isFetching: isGettingMyAccount }] = useLazyGetMyAccountQuery();
  const [updateMyAccount, { isLoading: isUpdatingUser }] = useUpdateMyAccountMutation();

  const [sendDecision, { isLoading: isSendingMigrationDecision }] =
    useSendMigrationDecisionMutation();

  const WMPLan = membershipPlans.find((p) => p.planCode === PlanCodes.WeightManagementMembership);
  const pricePoints = WMPLan?.pricePoints;

  const userCurrentPPType: UserCurrentWMPPType =
    activePricePoint?.includes('low-dose') || activePricePoint?.includes('maintenance')
      ? 'maintenance'
      : activePricePoint?.includes('bundle')
        ? 'bundle'
        : 'care-only';

  const userCurrentPP = pricePoints?.find((pp) => pp.planPricePointId === activePricePoint);

  const tirzepatideMaintenancePP = findMaintenancePP(pricePoints, {
    keyword: 'tirzepatide'
  });
  const semaglutideMaintenancePP = findMaintenancePP(pricePoints, {
    keyword: 'semaglutide'
  });

  const ppToUpgrade = pricePoints?.find(
    (pp) =>
      pp.planPricePointId ===
      (userCurrentPPType === 'bundle'
        ? SEMAGLUTIDE_PRICE_POINT
        : semaglutideMaintenancePP?.planPricePointId)
  );

  const tirzepatidePP = pricePoints?.find((pp) =>
    userCurrentPPType === 'bundle'
      ? pp.planPricePointId === TIRZEPATIDE_PRICE_POINT
      : tirzepatideMaintenancePP
  );

  const handleCompleteFlow = (manualAction?: UserDecision | 'skip') => {
    // HIDE unless BE will say that we need to send this action for eligible users
    // const getEligibleActionByCurrentPP = (): UserDecision => {
    //   if (userCurrentPPType === 'care-only') {
    //     return 'accepted-tirzepatide-l-arginine';
    //   } else if (userCurrentPPType === 'bundle') {
    //     return 'accepted-tirzepatide-l-arginine-bundled';
    //   } else if (userCurrentPPType === 'maintenance') {
    //     return 'accepted-tirzepatide-l-arginine-maintenance';
    //   }
    //   return 'accepted-tirzepatide-l-arginine';
    // };
    const getAction = (): UserDecision | null => {
      if (manualAction === 'skip') {
        return null;
      }
      if (manualAction) {
        return manualAction;
      }
      switch (mifCheckResults) {
        case 'eligible':
          return null;
        case 'not-eligible-care-only':
          return 'accepted-semaglutide';
        case 'not-eligible-bundled':
          return 'accepted-tirzepatide-l-arginine';
        case 'not-eligible-maintenance':
          return 'accepted-tirzepatide-l-arginine-maintenance';
        default:
          return null;
      }
    };
    const action = getAction();
    if (!action) {
      dispatch(clearTirzepatideMif());
      return onComplete();
    }
    sendDecision({
      action
    })
      .unwrap()
      .then(() => {
        dispatch(clearTirzepatideMif());
        onComplete();
      })
      .catch(handleRequestCatch);
  };

  const handleSaveMif = ({ question, answer }: { answer: string[]; question: string }) => {
    dispatch(
      setTirzepatideMif([
        {
          question,
          answer
        }
      ])
    );
    const lastQuestionConfig = mifSteps[mifSteps.length - 1].config;
    if (!isInfoScreen(lastQuestionConfig) && question === lastQuestionConfig.question.value) {
      const body = uniqBy([...tirzepatideMif, { question, answer }], 'question');
      submitMif({ body, id: ID_FOR_MIF_REQUESTS })
        .unwrap()
        .then(() => {
          getMyAccount()
            .unwrap()
            .then(() => handleNavigate('next'));
        })
        .catch(handleRequestCatch);
    } else {
      handleNavigate('next');
    }
  };

  const handleNavigate = (type: 'prev' | 'next') => {
    window.scrollTo({ left: 0, top: 0 });
    if (type === 'prev') {
      if (validSteps.indexOf(step) === 0) {
        return onLeaveFlow();
      }
      navigate(-1);
      setIsBackAnimation(true);
    } else {
      setIsBackAnimation(false);
      switch (step) {
        case 'intro':
          return setParams((p) => {
            p.set('s', 'mif-1');
            return p;
          });
        case 'mif-1':
          return setParams((p) => {
            p.set('s', 'mif-2');
            return p;
          });
        case 'mif-2':
          return setParams((p) => {
            p.set('s', 'mif-3');
            return p;
          });
        case 'mif-3':
          return setParams((p) => {
            p.set('s', 'results');
            return p;
          });
        case 'results':
          switch (mifCheckResults) {
            case 'eligible':
            case 'not-eligible-care-only':
              return handleCompleteFlow();
            case 'not-eligible-bundled':
            case 'not-eligible-maintenance':
              return setParams((p) => {
                p.set('s', 'select-medication');
                return p;
              });
            default:
              return handleCompleteFlow();
          }
        case 'appointment-tbd':
          return handleCompleteFlow('requested-appointment');
        case 'checkout':
          return handleCompleteFlow('skip');
        default:
          return navigate({ search: 's=intro' });
      }
    }
  };

  const handleSelectOption = (v: string) => {
    if (v === 'appointment') {
      setParams((p) => {
        p.set('s', 'appointment-tbd');
        return p;
      });
    } else {
      setParams((p) => {
        p.set('s', 'checkout');
        return p;
      });
    }
  };

  const handleCheckout = async () => {
    try {
      await updateMyAccount({
        planPricePointId: ppToUpgrade?.planPricePointId,
        planId: WMPLan?._id ?? ''
      }).unwrap();
      await getMyAccount().unwrap();
      handleNavigate('next');
    } catch (error) {
      handleRequestCatch(
        error as MessageEvent,
        'Error during plan upgrade, please try again later'
      );
    }
  };

  const onInit = () => {
    if (
      !!tirzepatideMigration?.patientActionSubmittedAt ||
      !!tirzepatideMigration?.patientAction
      // ||
      // (tirzepatideMigration?.eligibilityType === 'eligible' && !!tirzepatideMigration?.mifSubmittedAt)
    ) {
      setIsFlowPassed(true);
      return;
    }
    getMifStructure(ID_FOR_MIF_REQUESTS)
      .unwrap()
      .then(({ data }) => {
        const filteredQuestions: PatchedQuestion[] = data.configs.filter(
          (c): c is PatchedQuestion => c.config.type !== 'info'
        );
        setMifSteps(filteredQuestions.toSorted((a, b) => a.order - b.order));
      });
    if (!step || !validSteps.includes(step)) {
      setParams(
        (p) => {
          p.set('s', 'intro');
          return p;
        },
        {
          replace: true
        }
      );
    }
  };

  const getSelectedItems = (question: string) => {
    return tirzepatideMif.find((item) => item.question === question)?.answer ?? [];
  };

  useEffect(onInit, []);

  const isInfoScreen = (config: AppointmentMifQuestion['config']) => {
    return config.type === 'info';
  };

  if (isFlowPassed) {
    return (
      <div className="flex h-full flex-col gap-4">
        <Header flowLength={1} step={1} onClickPrev={() => handleNavigate('prev')} />
        <SlideAnimateWrapper className="grow md:mx-auto md:max-w-[500px]" key="all-set">
          <AllSet />
        </SlideAnimateWrapper>
      </div>
    );
  }
  return (
    <div className="flex h-full flex-col gap-4">
      <Header
        flowLength={validSteps.length}
        step={validSteps.indexOf(step)}
        onClickPrev={() => handleNavigate('prev')}
      />
      <AnimatePresence mode="wait">
        {isFetching || isMifLoading ? (
          <Loader isVisible />
        ) : (
          <div className="flex w-full grow flex-col px-px pb-4 max-md:h-full md:mx-auto md:max-w-[960px]">
            <Loader isVisible={isSendingMigrationDecision || isUpdatingUser} />
            {step === 'intro' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="intro"
              >
                <Intro onContinue={() => handleNavigate('next')} />
              </SlideAnimateWrapper>
            )}
            {step === 'mif-1' && !!mifSteps[0] && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="mif-1"
              >
                <MifQuestionOne
                  config={mifSteps[0].config}
                  selected={getSelectedItems(mifSteps[0].config.question.value)}
                  onContinue={(v) =>
                    handleSaveMif({
                      answer: v,
                      question: mifSteps[0].config.question.value
                    })
                  }
                />
              </SlideAnimateWrapper>
            )}
            {step === 'mif-2' && !!mifSteps[1] && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="mif-2"
              >
                <MifQuestionTwo
                  config={mifSteps[1].config}
                  selected={getSelectedItems(mifSteps[1].config.question.value)}
                  onContinue={(v) =>
                    handleSaveMif({
                      answer: v,
                      question: mifSteps[1].config.question.value
                    })
                  }
                />
              </SlideAnimateWrapper>
            )}
            {step === 'mif-3' && !!mifSteps[2] && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="mif-3"
              >
                <MifQuestionThree
                  config={mifSteps[2].config}
                  loading={isLoading || isGettingMyAccount}
                  selected={getSelectedItems(mifSteps[2].config.question.value)}
                  onContinue={(v) =>
                    handleSaveMif({
                      answer: v,
                      question: mifSteps[2].config.question.value
                    })
                  }
                />
              </SlideAnimateWrapper>
            )}
            {step === 'results' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="results"
              >
                <Results
                  status={mifCheckResults}
                  onClickDiscuss={() => handleSelectOption('appointment')}
                  onContinue={() => handleNavigate('next')}
                />
              </SlideAnimateWrapper>
            )}
            {step === 'appointment-tbd' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="appointment-tbd"
              >
                <Appointment onContinue={() => handleNavigate('next')} />
              </SlideAnimateWrapper>
            )}
            {step === 'checkout' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="checkout"
              >
                {!!ppToUpgrade && (
                  <Checkout
                    currentPP={userCurrentPP}
                    newPricePoint={ppToUpgrade}
                    userCurrentPPType={userCurrentPPType}
                    onContinue={handleCheckout}
                  />
                )}
              </SlideAnimateWrapper>
            )}
            {step === 'select-medication' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="select-medication"
              >
                <SelectMedication
                  pp={ppToUpgrade}
                  ppType={userCurrentPPType}
                  tirzepatidePP={tirzepatidePP}
                  onContinue={handleSelectOption}
                />
              </SlideAnimateWrapper>
            )}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MigrateFromTirzepatide;

import { Common } from '@thecvlb/design-system';

import SliderPanel from 'features/SlidePanel';
import { PopupInfoProps } from 'features/SlidePanel/slidePanel.types';

import useWidth from 'hooks/useWidth';

const PopupInfo: React.FC<{
  popupInfo: PopupInfoProps & { isShow: boolean };
  togglePopup: () => void;
}> = ({ popupInfo, togglePopup }) => {
  const { isMobile } = useWidth();

  if (isMobile) {
    return (
      <Common.Modal close={togglePopup} isOpen={popupInfo.isShow} size="sm">
        {popupInfo.title}
        {popupInfo.content}
      </Common.Modal>
    );
  }

  return (
    <SliderPanel isShowModal={popupInfo.isShow} title={popupInfo.title} toggleModal={togglePopup}>
      {popupInfo.content}
    </SliderPanel>
  );
};

export default PopupInfo;

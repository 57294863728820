import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common/Icon';

import { useLazyGetMpMagicLinkQuery } from 'services/patientChart/patientChart';

import { selectUser } from 'store';

import Grid from 'widgets/myChart/Grid';

import { LINK_TO_SUPPORT } from 'constants/externalLinks';
import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { useLogout } from 'hooks/useLogout';
import useWidth from 'hooks/useWidth';
import { PathName } from 'utils/enums';

const Account = () => {
  useTitle('LifeMD - Account');
  const logEvent = useAnalytics();
  const user = useAppSelector(selectUser);
  const { isMobile } = useWidth();
  const navigate = useNavigate();
  const handleLogout = useLogout();
  const [getMpMagicLink, { data }] = useLazyGetMpMagicLinkQuery();
  const isOptavia = user.companyPartners?.onboardingPartner?.partnerName === 'Optavia';

  const MY_ACCOUNT_LINKS = [
    {
      icon: 'patient-filled',
      name: 'Account Settings',
      path: PathName.AccountInformation
    },
    ...(data?.data.mpLink
      ? [{ icon: 'prescription', name: 'Member portal', path: data?.data.mpLink }]
      : []),
    {
      icon: 'info-person-filled',
      name: 'Support',
      path: isOptavia ? PathName.Support : LINK_TO_SUPPORT
    },
    {
      icon: 'info',
      name: 'About',
      path: ''
    }
  ];

  const handleClickLink = (name: string) => {
    switch (name) {
      case 'Account Settings':
        logEvent('my_account_settings_mitem_click');
        return navigate(PathName.AccountSettings);
      case 'Member portal':
        logEvent('my_account_member_portal_mitem_click');
        return window.open(data?.data.mpLink, '_blank');
      case 'Support':
        logEvent('my_account_support_mitem_click');
        return isOptavia ? navigate(PathName.Support) : window.open(LINK_TO_SUPPORT, '_blank');
      case 'About':
        logEvent('my_account_about_mitem_click');
        return navigate(PathName.About);
    }
  };

  const handleUpdatedUserId = () => {
    user.userId && getMpMagicLink(user.userId);
  };

  const handleClickSignOut = () => {
    logEvent('my_account_sign_out_btn_click');
    handleLogout();
  };

  useEffect(handleUpdatedUserId, [user.userId]);

  useEffect(() => {
    !isMobile && navigate(PathName.AccountInformation);
  }, [isMobile]);

  return (
    <div>
      <div className="flex items-center gap-5 pb-4 text-mLg font-bold">
        <Common.ProfileImage size="2xl" src={user.profileImage} />
        <div className="flex-1">
          <p className="text-mLg font-bold text-gray-700">
            {user.firstName} {user.lastName}
          </p>
          <NavLink
            className="mt-1 text-mSm font-medium text-gray"
            to={PathName.AccountInformation}
            onClick={() => logEvent('my_account_edit_profile_text_click')}
          >
            Edit profile
          </NavLink>
        </div>
        <NavLink to={PathName.Dashboard}>
          <Common.Icon className="size-5 text-gray" name="close" />
        </NavLink>
      </div>
      <h3 className="py-3 font-bold">My chart</h3>
      <Grid />
      <div className="mb-4 mt-8 h-px w-full bg-gray-200" />
      <h3 className="py-3 font-bold">My account</h3>
      {MY_ACCOUNT_LINKS.map((el) => (
        <button
          className="flex w-full cursor-pointer items-center gap-2 py-4 text-left text-mBase font-semibold md:font-bold"
          key={el.path}
          onClick={() => handleClickLink(el.name)}
        >
          <div className="mr-2 flex size-9 items-center justify-center rounded-full bg-gray-100 md:mr-0">
            <Common.Icon className="size-5 text-gray-700" name={el.icon as IconProps['name']} />
          </div>
          <span className="flex-1">{el.name}</span>
          <Common.Icon className="size-5 text-gray" name="arrow-right" />
        </button>
      ))}
      <Common.Button
        className="mb-8 mt-12"
        color="blue"
        preIcon="log-out"
        fullWidthOnMobile
        onClick={handleClickSignOut}
      >
        Sign out
      </Common.Button>
    </div>
  );
};
export default Account;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdminPrescriptionItem, ElationPrescriptionItem } from 'models/prescriptions.types';

import { MedicalRecordsProps, PatientChartState } from './patientChart.types';

const initialState: PatientChartState = {
  adminPrescriptions: [],
  medicalRecords: {},
  prescriptions: []
};

const patientChartSlice = createSlice({
  initialState,
  name: 'patientChart',
  reducers: {
    setAdminPrescriptions: (state, action: PayloadAction<AdminPrescriptionItem[]>) => {
      state.adminPrescriptions = action.payload;
    },
    setMedicalRecords: (state, action: PayloadAction<MedicalRecordsProps>) => {
      state.medicalRecords = action.payload;
    },
    setPrescriptions: (state, action: PayloadAction<ElationPrescriptionItem[]>) => {
      state.prescriptions = action.payload;
    }
  }
});

export const { setPrescriptions, setAdminPrescriptions, setMedicalRecords } =
  patientChartSlice.actions;

export default patientChartSlice.reducer;

import { EvidenceItem } from 'services/symptomChecker/symptomChecker.types';

import { SidebarCategoryProps } from 'features/symptomChecker/Sidebar/sidebar.types';
import { RiskGroupProps } from 'widgets/symptomChecker/questionTypes/GroupMultiple/groupMultiple.types';

import { SymptomCheckerSteps } from 'utils/enums';

type InfermedicaLookup = {
  [K: string]: { infermedicaID: string };
};

export const INFERMEDICA_TO_ONBOARDING: InfermedicaLookup = {
  Cancer: { infermedicaID: 'p_178' },
  Cigarettes: { infermedicaID: 's_195' },
  Diabetes: { infermedicaID: 'p_8' },
  'High blood pressure': { infermedicaID: 'p_9' },
  'High cholesterol': { infermedicaID: 'p_10' },
  'Overweight / obese': { infermedicaID: 'p_7' },
  Postmenopausal: { infermedicaID: 'p_11' },
  Pregnant: { infermedicaID: 'p_42' },
  'Recently suffered an injury': { infermedicaID: 'p_264' },
  Vape: { infermedicaID: 's_195' }
};

export const COMMON_RISKS_QUESTIONS: RiskGroupProps<EvidenceItem['choice_id']>['items'] = [
  {
    id: 'p_8',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: 'She has diabetes',
      male: 'He has diabetes',
      myself: 'I have diabetes'
    }
  },
  {
    id: 'p_7',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: 'She is overweight / obese',
      male: 'He is overweight / obese',
      myself: "I'm overweight / obese"
    }
  },
  {
    id: 'p_9',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: 'She has hypertension',
      male: 'He has hypertension',
      myself: 'I have hypertension'
    }
  },
  {
    id: 'p_28',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: 'She smokes cigarettes',
      male: 'He smokes cigarettes',
      myself: 'I smoke cigarettes'
    }
  },
  {
    id: 'p_264',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: 'She recently suffered an injury',
      male: 'He recently suffered an injury',
      myself: 'I recently suffered an injury'
    }
  },
  {
    id: 'p_10',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: 'She has high cholesterol',
      male: 'He has high cholesterol',
      myself: 'I have high cholesterol'
    }
  },
  {
    id: 'p_11',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: "She's postmenopausal",
      male: "He's postmenopausal",
      myself: "I'm postmenopausal"
    }
  },
  {
    id: 'p_42',
    options: [
      { label: 'yes', value: 'present' },
      { label: 'no', value: 'absent' },
      { label: 'unsure', value: 'unknown' }
    ],
    title: {
      female: "She's pregnant",
      male: "He's pregnant",
      myself: "I'm pregnant"
    }
  }
];

export const REGIONS_LIST_OPTIONS = [
  { label: 'United States or Canada', value: 'p_13' },
  { label: 'Central or South America', value: 'p_14' },
  { label: 'Europe', value: 'p_15' },
  { label: 'Northern Africa', value: 'p_16' },
  { label: 'Central Africa', value: 'p_17' },
  { label: 'Southern Africa', value: 'p_18' },
  { label: 'Australia and Oceania', value: 'p_19' },
  { label: 'Russia, Kazakhstan or Mongolia', value: 'p_20' },
  { label: 'Middle East', value: 'p_21' },
  { label: 'Asia excluding Middle East, Russia, Mongolia and Kazakhstan', value: 'p_236' }
];

export const SYMPTOM_CHECKER_STEP_CATEGORIES: SidebarCategoryProps[] = [
  { steps: [SymptomCheckerSteps.Intro1, SymptomCheckerSteps.Intro2], title: 'introduction' },
  {
    steps: [
      SymptomCheckerSteps.Patient1,
      SymptomCheckerSteps.Patient2,
      SymptomCheckerSteps.Patient3
    ],
    title: 'patient'
  },
  {
    steps: [SymptomCheckerSteps.Symptoms1, SymptomCheckerSteps.Symptoms2],
    title: 'symptoms'
  },
  { steps: [SymptomCheckerSteps.Regions], title: 'regions' },
  {
    steps: [SymptomCheckerSteps.Interview],
    title: 'interview'
  },
  { steps: [SymptomCheckerSteps.Results], title: 'results' }
];

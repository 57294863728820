import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useClickAway, useDebounce, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import isEqual from 'lodash/isEqual';

import {
  useGetAllSymptomsMutation,
  useLazyGetSearchQuery
} from 'services/symptomChecker/symptomChecker';
import { SymptomOption, SymptomResult } from 'services/symptomChecker/symptomChecker.types';

import { selectSymptomChecker } from 'store';
import { InitialSymptom } from 'store/symptomChecker/symptomChecker.types';
import {
  setEvidence,
  setInitialSymptoms,
  setQuestions,
  setSuggestedEvidence,
  setSymptomList
} from 'store/symptomChecker/symptomCheckerSlice';

import ActionButtons from 'features/symptomChecker/ActionButtons';
import FadeSlideWrapper from 'shared/animationWrappers/FadeSlideWrapper';
import Loader from 'shared/Loader';

import { useAppDispatch, useAppSelector } from 'hooks';
import useWidth from 'hooks/useWidth';
import { handleRequestCatch } from 'utils/helpers';

import { Option } from 'models/forms.types';

import ActionPanelSelect from './ActionPanel';
import BodyModel from './BodyModel';
import RemovableTags from './RemovableTags';
import {
  AgeKeys,
  AllAgeSymptoms,
  BodyRegionKeys,
  ClickedRegion,
  Props
} from './symptomsSelector.types';

// TO DO: Update when Infermedica has a dedicated endpoint for body region symptoms rather than hardcoded json
const getAgeKey = () => {
  return Object.keys(AllAgeSymptoms).find((element, index, array) => {
    const ageRange = array[index];
    const [min, max] = ageRange.split('-');
    return element >= min && element <= max;
  });
};

const SymptomsSelector: React.FC<Props> = ({ moveToStep, source }) => {
  const { isMobile } = useWidth();
  const dispatch = useAppDispatch();
  const [getSearchQuery, { isLoading: SearchLoading }] = useLazyGetSearchQuery();
  const [getAllSymptoms, { isLoading: SymptomsLoading }] = useGetAllSymptomsMutation();

  const {
    userInfo: { age, gender, patientSelect },
    initialSymptoms,
    allSymptoms
  } = useAppSelector(selectSymptomChecker);

  const pronoun = patientSelect === 'myself' ? 'my' : gender === 'female' ? 'her' : 'his';

  const searchPopupRef = useRef<HTMLDivElement | null>(null);
  const bodyPopupRef = useRef<HTMLDivElement | null>(null);
  const [showModal, toggleShowModal] = useToggle(false);
  const [showSearchPopup, toggleShowSearchPopup] = useToggle(false);
  const [showBodyPopup, toggleShowBodyPopup] = useToggle(false);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [selectedSymptoms, setSelectedSymptoms] = useState<SymptomOption[]>(initialSymptoms);
  const [searchResults, setSearchResults] = useState<SymptomOption[]>([]);
  const [allPossibleSymptoms, setAllPossibleSymptoms] = useState<SymptomResult[]>(allSymptoms);
  const [clickedRegion, setClickedRegion] = useState<ClickedRegion>({});
  const [clickedRegionSymptoms, setClickedRegionSymptoms] = useState<Option[]>([]);
  const [selectedRegionSymptoms, setSelectedRegionSymptoms] = useState<Option[]>([]);
  const [panelLocation, setPanelLocation] = useState<{
    left?: string;
    top?: string;
  }>({});
  const [displayAlert, toggleDisplayAlert] = useToggle(false);
  const regionPrefix = 'body-model-';
  let regionKey: BodyRegionKeys;
  const ageKey = getAgeKey() as AgeKeys;
  const allAgeSymptoms = AllAgeSymptoms[ageKey];
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filterSymptomGenders = () => {
    const regionsArray = Object.entries(allAgeSymptoms);
    return regionsArray.reduce((regions: { [key: string]: string[] }[], [regionName, genders]) => {
      const f = [...(genders.both || []), ...(genders[gender] || [])];
      if (f.length) {
        regions.push({ [regionName]: f });
      }
      return regions;
    }, []);
  };

  const filteredAgeGenderSymptoms: { [key: string]: string[] }[] = filterSymptomGenders();

  const handleGetAllSymptoms = () => {
    getAllSymptoms(age)
      .unwrap()
      .then((res) => {
        dispatch(setSymptomList(res));
        setAllPossibleSymptoms(res);
      })
      .catch(handleRequestCatch);
  };

  const getRegionSymptomIDs = () => {
    regionKey = Object.keys(allAgeSymptoms).find((element) => {
      return clickedRegion.id ? element.includes(clickedRegion.id) : false;
    }) as BodyRegionKeys;

    if (regionKey) {
      const userAgeSymptoms: { [key: string]: string[] } = allAgeSymptoms[regionKey];
      const regionKeys = Object.keys(userAgeSymptoms);
      let iDsResult;
      if (regionKeys.length > 0 && regionKeys.includes('both')) {
        const genderSymptoms = userAgeSymptoms[gender];
        const deDupedSymptoms = genderSymptoms
          ? new Set([...userAgeSymptoms.both, ...genderSymptoms])
          : new Set([...userAgeSymptoms.both]);
        iDsResult = Array.from(deDupedSymptoms);
      } else {
        iDsResult = Object.values(userAgeSymptoms)[0];
      }
      if (selectedSymptoms.length > 0) {
        iDsResult = iDsResult.filter((symptomID) => {
          const matchedID = selectedSymptoms.find((symptom) => symptom.id === symptomID);
          return !matchedID;
        });
      }
      return iDsResult;
    }
  };
  const getRegionSymptoms = (regionSymptomIDs: string[]) => {
    const regionSymptoms: Option[] = allPossibleSymptoms
      .filter(({ id }) => {
        return regionSymptomIDs.includes(id);
      })
      .map(({ id, common_name }) => {
        return { label: common_name, value: id };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    return regionSymptoms;
  };

  const tabs = [{ label: 'Search' }]; // { label: 'Point on the body' }
  const wrapperClassName =
    'symptom-content-wrapper md:gap-8 flex flex-col md:flex-row justify-between';
  const headingClassName = 'font-bold text-mLg md:text-xl text-primary mb-2';
  const secondaryTextClassName = 'font-medium text-gray';
  const subtitleClassName = classNames(secondaryTextClassName, 'text-sm md:text-base mb-6 md:mb-8');
  const searchInputClassName = 'max-w-[500px]';
  const selectClassName = 'h-fit max-h-[300px] bg-white overflow-auto rounded-md shadow-lg';
  const symptomsSelectClassName = classNames(
    selectClassName,
    'flex flex-col w-full top-full mt-1 py-1.5'
  );
  const optionClassName = 'px-3 py-2 font-semibold w-full text-left first-letter:capitalize';
  const bodyWrapperClassName = ' grow';
  const tooltipWrapperClassName = 'absolute z-10 left-[-100px]';
  const selectMoreSymptomsTextClassName = classNames(
    'text-mXs mb-6 pt-2',
    selectedSymptoms.length === 1 ? 'text-primary' : 'text-gray'
  );

  const addRegionStyle = (symptomLocations: string[]) => {
    symptomLocations.forEach((symptomLocation) => {
      const regionId = regionPrefix + symptomLocation;
      document?.querySelectorAll(`#${regionId}`).forEach((sideRegion) => {
        sideRegion.classList.add(`sc-body-model-svg__path--selected`);
        !showBodyPopup && sideRegion.classList.remove(`sc-body-model-svg__path--active`);
      });
    });
  };

  let keySequence: string[] = [];
  let secretString = '';
  const konamiCode = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a'
  ];

  const clownNose = () => {
    const regionId = regionPrefix + 'nose';
    const noseClasses = document?.getElementById(regionId)?.classList;
    displayAlert
      ? noseClasses?.add('sc-body-model-svg__path--clown')
      : noseClasses?.remove('sc-body-model-svg__path--clown');
  };

  const konamiListener = (e: KeyboardEvent) => {
    keySequence.push(e.key);
    keySequence.splice(-konamiCode.length - 1, keySequence.length - konamiCode.length);
    secretString = konamiCode.join('');

    if (keySequence.join('').includes(secretString)) {
      toggleDisplayAlert();
      keySequence = [];
    }
  };

  const variants = {
    closed: {
      height: '0',
      opacity: 0,
      scale: 0.5,
      transition: {
        duration: 0.6,
        opacity: {
          duration: 0.2
        }
      },
      y: '-40%'
    },
    open: {
      height: '90px',
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5
      },
      y: 0
    }
  };

  const removeRegionStyle = (symptomLocations: string[]) => {
    symptomLocations.forEach((location) => {
      const regionId = regionPrefix + location;
      document?.querySelectorAll(`#${regionId}`).forEach((sideRegion) => {
        sideRegion.classList.remove(
          'sc-body-model-svg__path--selected',
          'sc-body-model-svg__path--hover',
          'sc-body-model-svg__path--active'
        );
      });
    });
  };
  const removeActiveStyle = (location: string) => {
    const regionId = regionPrefix + location;
    document
      .querySelectorAll(`#${regionId}`)
      .forEach((element) => element?.classList.remove('sc-body-model-svg__path--active'));
  };

  const handleTagClick = (tag: SymptomOption) => {
    const filteredSymptoms = selectedSymptoms.filter((d) => d.id !== tag.id);
    if (tag.region) {
      if (selectedSymptoms.length > 1) {
        const notMatchedRegions = tag.region.filter((symptomLocation) => {
          return filteredSymptoms.find((fs) => !fs?.region?.includes(symptomLocation));
        });
        if (notMatchedRegions?.length > 0) {
          removeRegionStyle(notMatchedRegions);
        }
      } else {
        removeRegionStyle(tag.region);
      }
    }
    setSelectedSymptoms(filteredSymptoms);
  };
  const locateSymptom = (optionId: string) => {
    if (filteredAgeGenderSymptoms.length < 1) {
      filterSymptomGenders();
    }
    let matchingLocations:
      | string[]
      | {
          [key: string]: string[];
        }[] = filteredAgeGenderSymptoms.filter((regionOption) => {
      return Object.values(regionOption)[0].find((symptomID) => symptomID === optionId);
    });
    matchingLocations = matchingLocations.map((match) => {
      return Object.keys(match)[0];
    });
    return matchingLocations;
  };
  const handleMultiSelect = (e: unknown) => {
    const selectedOption: Option[] = e as Option[];
    const goodDucks: Option[] = [];
    const oddDuck = selectedRegionSymptoms.filter((symptom) => {
      const matchedOption = selectedOption.find((option) => option.value === symptom.value);
      if (matchedOption) {
        goodDucks.push(matchedOption);
      }
      return !matchedOption;
    });
    if (oddDuck?.length > 0) {
      const oddDuckId = oddDuck[0].value;
      const filteredForDuck = selectedSymptoms.filter((symptom) => symptom.id !== oddDuckId);
      const duckLocations = locateSymptom(oddDuckId);
      if (duckLocations?.length > 0) {
        const notMatchedRegions = duckLocations.filter((symptomLocation) => {
          return filteredForDuck.find((fs) => !fs?.region?.includes(symptomLocation));
        });
        if (notMatchedRegions?.length > 0) {
          removeRegionStyle(notMatchedRegions);
        }
      }
      setSelectedSymptoms(filteredForDuck);
      setSelectedRegionSymptoms(goodDucks as Option[]);
    } else {
      setSelectedRegionSymptoms(selectedOption as Option[]);
    }
  };
  const renderMySymptoms = () => {
    return (
      <>
        <p className="mb-4 mt-8 text-mBase font-bold first-letter:uppercase md:my-4 md:text-lg">{`${pronoun} symptoms`}</p>
        {selectedSymptoms.length === 0 ? (
          <p
            className={classNames('text-mSm md:text-base', secondaryTextClassName)}
            data-testid="no_symptoms_listed"
          >
            No symptoms listed
          </p>
        ) : (
          <RemovableTags tags={selectedSymptoms} onTagClick={handleTagClick} />
        )}
      </>
    );
  };
  const duplicateSymptom = (optionId: string) => {
    return selectedSymptoms.find((symptom: SymptomOption) => symptom.id === optionId);
  };

  const handleOptionClick = (option: SymptomOption) => {
    const isDuplicate = duplicateSymptom(option.id);
    setSearchPhrase('');
    if (!isDuplicate) {
      const symptomLocations = locateSymptom(option.id);
      if (symptomLocations.length > 1) {
        const newOption = {
          id: option.id,
          label: option.label,
          region: symptomLocations
        };
        addRegionStyle(symptomLocations);
        setSelectedSymptoms([newOption, ...selectedSymptoms]);
      } else {
        setSelectedSymptoms([option, ...selectedSymptoms]);
      }
    }
  };

  const renderSymptomsSelect = () =>
    searchResults
      .filter((r) => {
        const IDs = selectedSymptoms.map((i) => i.id);
        return !IDs.includes(r.id);
      })
      .map((item) => (
        <button
          className={optionClassName}
          data-testid="option_in_dropdown"
          key={item.id}
          onClick={() => handleOptionClick(item)}
        >
          {item.label}
        </button>
      ));

  const handleGetSearchQuery = (phrase: string) => {
    getSearchQuery({
      'age.value': age,
      include_pro: false,
      max_results: 20,
      phrase,
      sex: gender,
      types: 'symptom'
    })
      .unwrap()
      .then((res) => {
        const notSelectedSearchResults = res
          .filter((item) => !selectedSymptoms.map((selected) => selected.id).includes(item.id))
          .sort((a, b) => a.label.localeCompare(b.label));
        setSearchResults(notSelectedSearchResults);
        if (isMobile && searchInputRef.current) {
          searchInputRef.current.blur();
        }
      })
      .catch(handleRequestCatch);
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value.toLowerCase());
  };

  const handleSearchQuery = () => {
    if (searchPhrase.length > 1) {
      handleGetSearchQuery(searchPhrase);
      toggleShowSearchPopup(true);
    }
  };
  const handleBack = () => {
    dispatch(setInitialSymptoms([]));
    moveToStep('prev');
  };
  const handleNext = () => {
    const newSelectedSymptoms: InitialSymptom[] = selectedSymptoms.map((s) => ({
      value: 'present',
      ...s
    }));
    dispatch(setInitialSymptoms(newSelectedSymptoms));
    if (!isEqual(newSelectedSymptoms, initialSymptoms)) {
      dispatch(setSuggestedEvidence([]));
      dispatch(setEvidence([]));
      dispatch(setQuestions([]));
    }
    moveToStep('next');
  };

  const handleClickOutside = () => {
    if (showBodyPopup) {
      toggleShowBodyPopup(false);
      clickedRegion.id && removeActiveStyle(clickedRegion.id);
      setSelectedRegionSymptoms([]);
    } else {
      toggleShowSearchPopup(false);
    }
  };
  useClickAway(searchPopupRef, handleClickOutside);
  useClickAway(bodyPopupRef, handleClickOutside);

  useDebounce(handleSearchQuery, 1000, [searchPhrase]);

  useEffect(() => {
    document.addEventListener('keydown', konamiListener);
    return () => {
      document.removeEventListener('keydown', konamiListener);
    };
  }, []);

  useEffect(() => {
    clownNose();
  }, [displayAlert]);

  useEffect(() => {
    if (clickedRegion?.id?.length) {
      const regionId = regionPrefix + clickedRegion.id;
      const updatedStyle = clickedRegion.coords
        ? {
            left: clickedRegion.coords?.panelLeft,
            top: clickedRegion.coords?.panelTop
          }
        : {};
      setPanelLocation(updatedStyle);
      toggleShowBodyPopup(true);
      document?.querySelectorAll(`#${regionId}`).forEach((sideRegion) => {
        sideRegion.classList.add('sc-body-model-svg__path--active');
      });
      const regionSymptomIDs = getRegionSymptomIDs();
      if (regionSymptomIDs) {
        const regionSymptoms: Option[] = getRegionSymptoms(regionSymptomIDs);
        setClickedRegionSymptoms(regionSymptoms);
      }
    } else {
      if (allSymptoms?.length < 1) {
        handleGetAllSymptoms();
      }
    }
  }, [clickedRegion]);

  useEffect(() => {
    let symptomLocations: string[] = [];
    if (selectedRegionSymptoms.length > 0) {
      const selectedId = selectedRegionSymptoms[selectedRegionSymptoms.length - 1].value;
      const symptomDuplicate = selectedSymptoms.find((symptom) => symptom.id === selectedId);
      if (!symptomDuplicate) {
        const updatedOption: SymptomOption = {
          id: selectedId,
          label: selectedRegionSymptoms[selectedRegionSymptoms.length - 1].label,
          region: locateSymptom(selectedId)
        };
        setSelectedSymptoms([updatedOption, ...selectedSymptoms]);
        addRegionStyle(symptomLocations);
      }
    } else if (selectedSymptoms.length > 0) {
      selectedSymptoms.forEach((symptom) => {
        symptomLocations = symptomLocations.concat(symptom.region as string[]);
      });
      symptomLocations.length > 0 && addRegionStyle(symptomLocations);
    }
  }, [selectedRegionSymptoms]);

  return (
    <>
      <Loader isVisible={SearchLoading || SymptomsLoading} />
      <FadeSlideWrapper>
        <div className={wrapperClassName}>
          {isMobile ? (
            <div className="w-fit md:w-full md:max-w-[500px]">
              <h2 className={headingClassName}>{`Add ${pronoun} symptoms`}</h2>
              <>
                <p className={subtitleClassName}>Please try to add more than one symptom.</p>
                <Common.Button
                  className="mt-6 rounded-2xl border md:rounded-xl"
                  color="blue"
                  preIcon="plus"
                  style="pill"
                  onClick={() => toggleShowModal(true)}
                >
                  Add symptom
                </Common.Button>
                {renderMySymptoms()}
              </>
            </div>
          ) : (
            <>
              <div className="w-fit md:w-full md:max-w-[500px]">
                <h2 className={headingClassName}>{`Add ${pronoun} symptoms`}</h2>
                <>
                  <p className={subtitleClassName}>
                    Click the model to the right, or search below to add symptoms.
                    <br /> Add as many symptoms as you can for best results.
                  </p>
                  <Common.SearchInput
                    className={searchInputClassName}
                    dataTestId="search_input"
                    placeholder="Search, e.g. headache"
                    postIcon={searchPhrase ? 'close' : undefined}
                    value={searchPhrase}
                    onChange={handleSearchInputChange}
                    onClickPostIcon={() => setSearchPhrase('')}
                    onFocus={() => toggleShowSearchPopup(true)}
                  />
                  {showSearchPopup && searchPhrase.length > 0 && searchResults.length > 0 && (
                    <div className={symptomsSelectClassName} ref={searchPopupRef}>
                      {renderSymptomsSelect()}
                    </div>
                  )}
                  {renderMySymptoms()}
                </>
              </div>
              <div className={bodyWrapperClassName}>
                {showBodyPopup && clickedRegion.id && clickedRegionSymptoms.length > 0 && (
                  <div
                    className={tooltipWrapperClassName}
                    id="body-tooltip"
                    ref={bodyPopupRef}
                    style={panelLocation}
                  >
                    <ActionPanelSelect
                      header={
                        clickedRegion.id ? clickedRegion.id.replaceAll('_', ' ') : 'Clicked region:'
                      }
                    >
                      <Common.MultiSelect
                        options={clickedRegionSymptoms}
                        placeholder="Symptom"
                        value={selectedRegionSymptoms}
                        onChange={handleMultiSelect}
                      />
                    </ActionPanelSelect>
                  </div>
                )}
                <AnimatePresence initial={false}>
                  <motion.div animate={displayAlert ? 'open' : 'closed'} variants={variants}>
                    <Common.Alert type="success">
                      Congrats, you found our easter egg!
                      <br />
                      Sorry about your nose..
                    </Common.Alert>
                  </motion.div>
                </AnimatePresence>
                <BodyModel
                  dataTestId="body_model"
                  gender={gender}
                  isVisible={!SymptomsLoading}
                  source={source}
                  onBodyPartClick={setClickedRegion}
                />
              </div>
            </>
          )}
        </div>
      </FadeSlideWrapper>
      <ActionButtons
        isNextBtnDisabled={selectedSymptoms.length < 1}
        onClickBack={handleBack}
        onClickNext={handleNext}
      />
      {isMobile && (
        <Common.Modal close={() => toggleShowModal(false)} isOpen={showModal} size="sm">
          <div className="px-1">
            <Common.Tabs className="mb-6" data={tabs} type="line" />
            <Common.SearchInput
              dataTestId="mobile_view_search_input"
              placeholder="Search, e.g. headache"
              postIcon={searchPhrase ? 'close' : undefined}
              ref={searchInputRef}
              value={searchPhrase}
              onChange={handleSearchInputChange}
              onClickPostIcon={() => setSearchPhrase('')}
              onFocus={() => toggleShowSearchPopup(true)}
            />
            {showSearchPopup && searchPhrase.length > 0 && searchResults.length > 0 && (
              <div className={symptomsSelectClassName}>{renderSymptomsSelect()}</div>
            )}
            {selectedSymptoms.length > 0 && (
              <p className={selectMoreSymptomsTextClassName}>
                Please try to add more than one symptom.
              </p>
            )}
            {renderMySymptoms()}
          </div>
        </Common.Modal>
      )}
    </>
  );
};

export default SymptomsSelector;

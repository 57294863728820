import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';

import Loader from 'shared/Loader';
import PaymentFormNew from 'widgets/PaymentFormNew';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';
import ChargifyForm from 'widgets/signUp/ChargifyForm';

import { FeatureFlag } from 'utils/enums';

import { PaymentMethodPurpose } from 'models/payments.types';

import { PaymentMethodFormProps } from './paymentMethodForm.types';

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  isShowOptions,
  isPayPalConnected,
  isLoading,
  action,
  onDelete,
  onCancel,
  onConfirm,
  preselectedType
}) => {
  const [options, setOptions] = useState<PaymentMethodPurpose[]>([]);
  const [chargifyLoading, setLoading] = useState(!isPayPalConnected);

  const isEnabledPaypalOption = useFlag(FeatureFlag.Paypal);

  const { handleSubmit, formState, control, getValues } = useForm<PaymentFormFields>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldFocusError: false
  });

  const handleChange = (option: PaymentMethodPurpose) => () => {
    if (options.includes(option)) {
      setOptions(options.filter((o) => o !== option));
    } else {
      setOptions([...options, option]);
    }
  };

  useEffect(() => {
    if (preselectedType) {
      setOptions([preselectedType]);
    } else {
      setOptions(['membership']);
    }
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <div>
      {chargifyLoading && (
        <div className="mx-auto flex h-[90px]">
          <Loader isVisible relative />
        </div>
      )}
      {!isPayPalConnected && isEnabledPaypalOption && (
        <div className={classNames({ 'invisible hidden': chargifyLoading })}>
          <ChargifyForm onHandlePaypalToken={(v) => onConfirm(v, options)} />
          <div className="flex items-center gap-4 py-4">
            <div className="h-px grow bg-gray-200"></div>
            <span className="uppercase text-gray">or</span>
            <div className="h-px grow bg-gray-200"></div>
          </div>
        </div>
      )}

      <form
        className="flex flex-col gap-6 p-0.5"
        data-testid="chargify_form"
        onSubmit={handleSubmit((v) => onConfirm(v, options))}
      >
        <PaymentFormNew control={control} getValues={getValues} showEligible={false} />
        {!!isShowOptions && action === 'add' && (
          <div className="flex flex-col gap-4">
            <Common.Checkbox
              checked={options.includes('appointments')}
              color="blue"
              onChange={handleChange('appointments')}
            >
              Use for appointments
            </Common.Checkbox>
            <Common.Checkbox
              checked={options.includes('membership')}
              color="blue"
              onChange={handleChange('membership')}
            >
              Use for monthly plan
            </Common.Checkbox>
          </div>
        )}
        <div className="flex justify-between gap-2 max-md:flex-col-reverse max-md:pt-4">
          {action === 'edit' && !!onDelete && (
            <Common.Button
              color="red-alt"
              disabled={isLoading}
              type="button"
              fullWidthOnMobile
              onClick={onDelete}
            >
              Remove card
            </Common.Button>
          )}
          <div />
          <div className="flex gap-2 max-md:flex-col-reverse">
            <Common.Button
              color="white-alt"
              disabled={isLoading}
              type="button"
              fullWidthOnMobile
              onClick={onCancel}
            >
              Cancel
            </Common.Button>
            <Common.Button
              color="blue"
              dataTestId="submit_payment_btn"
              disabled={
                !!Object.keys(formState.errors).length ||
                isLoading ||
                (isShowOptions && !options.length)
              }
              isLoading={isLoading}
              type="submit"
              fullWidthOnMobile
            >
              {action === 'add' ? 'Add payment method' : 'Update card'}
            </Common.Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentMethodForm;

import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import CalendarInput from 'shared/form/CalendarInput';

import { PATIENTS_AGE_LIMITS } from 'utils/constants';
import { DateFormat } from 'utils/enums';
import { MM_DD_YYYY_REGEXP } from 'utils/regExp';

const MeasurementDateInput = <TFormValues extends FieldValues>({
  control,
  showTodayCheckbox = false,
  isTodayChecked = showTodayCheckbox,
  name = 'date',
  label = 'Date',
  hint,
  customErrorMessage,
  labelDirection,
  className,
  dataTestId
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: dayjs().format(DateFormat.MM_DD_YYYY),
    name,
    rules: {
      pattern: {
        message: 'Date must be in the format MM/DD/YYYY',
        value: MM_DD_YYYY_REGEXP
      },
      required: {
        message: `Date is required`,
        value: true
      },
      validate: (value: string) => {
        const date = dayjs(value, DateFormat.MM_DD_YYYY);
        const today = dayjs();
        if (date.isAfter(today)) {
          return 'Date cannot be after today';
        }
        return true;
      }
    }
  });
  const [isToday, toggleToday] = useToggle(isTodayChecked);
  return (
    <div
      className={classNames('flex flex-col gap-4', {
        'md:self-end md:pr-4': isToday
      })}
    >
      {showTodayCheckbox && isToday && (
        <Common.Checkbox checked={isToday} color="blue" onChange={toggleToday}>
          <span className={classNames('text-mBase md:text-base', { 'font-bold': isToday })}>
            Use today’s date for this measurement
          </span>
        </Common.Checkbox>
      )}
      {(!showTodayCheckbox || !isToday) && (
        <CalendarInput
          {...field}
          className={className}
          dataTestId={dataTestId}
          disabled={[
            {
              after: new Date(),
              before: dayjs().subtract(PATIENTS_AGE_LIMITS.MAX, 'years').toDate()
            }
          ]}
          endMonth={new Date()}
          errors={fieldState.error}
          helper={customErrorMessage || fieldState.error?.message}
          hint={hint}
          label={label}
          labelDirection={labelDirection}
          selected={dayjs(field.value).toDate()}
          startMonth={dayjs().subtract(PATIENTS_AGE_LIMITS.MAX, 'years').toDate()}
          onDateChange={field.onChange}
        />
      )}
    </div>
  );
};

export default MeasurementDateInput;

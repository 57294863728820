import { OptionTypeProps } from './actionPanel.types';

// TODO: add close button
const ActionPanelSelect: React.FC<OptionTypeProps> = ({ header, children }) => {
  const titleCaseHeader = header.charAt(0).toUpperCase() + header.slice(1);

  return (
    <div className="arrow right z-10 w-[350px] border bg-white p-4 shadow-lg md:rounded-lg">
      <h3
        className="pb-2 text-lg font-medium normal-case leading-6 text-gray-900"
        data-testid="selected_part"
      >
        {titleCaseHeader}
      </h3>
      <form>
        <div className="w-full">{children}</div>
      </form>
    </div>
  );
};

export default ActionPanelSelect;

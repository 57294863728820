import { memo, useState } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useToggle } from 'react-use';
import classNames from 'classnames';

import { ResendEmailButtonProps } from './buttonResendEmail.types';

const DISABLED_MS = 59000; // 59 seconds

const ButtonResendEmail: React.FC<ResendEmailButtonProps> = ({
  children,
  className,
  dataTestId = 'resend_email_btn',
  disabled,
  initDisabled = false,
  onClick,
  ...restProps
}) => {
  const [isEnabled, toggleIsEnabled] = useToggle(!initDisabled);
  const [date, setDate] = useState(DISABLED_MS);

  return (
    <button
      {...restProps}
      className={classNames('flex gap-1', className, {
        'text-gray-400 !no-underline': !isEnabled
      })}
      data-testid={dataTestId}
      disabled={disabled || !isEnabled}
      type="button"
      onClick={(e) => {
        setDate(new Date().getTime() + DISABLED_MS);
        onClick?.(e);
        toggleIsEnabled();
      }}
    >
      {!isEnabled && (
        <Countdown
          date={date}
          renderer={({ seconds }: CountdownRenderProps) => (
            <span data-testid="timer">{seconds}s</span>
          )}
          onComplete={toggleIsEnabled}
        />
      )}
      {children}
    </button>
  );
};

export default memo(ButtonResendEmail);

import { Common } from '@thecvlb/design-system';

import { ChronicDiseasesItemProps } from 'store/mif/mif.types';

import ListItemWrapper from 'shared/animationWrappers/ListItemWrapper';

import { CHRONIC_DISEASES_FORM_FIELDS } from 'constants/formFields';

import { ChronicDiseasesProps } from './chronicDiseases.types';

const ChronicDiseases: React.FC<ChronicDiseasesProps> = ({
  loading = false,
  onUpdate,
  chronicDiseases
}) => {
  const optionNamesList = chronicDiseases.map((v) => v.name);
  const NONE_ELEMENT = CHRONIC_DISEASES_FORM_FIELDS.find((e) => e.fieldID === '0');

  const handleChange = (name: string) => {
    let history: ChronicDiseasesItemProps[];
    if (chronicDiseases.map((v) => v.name).includes(name)) {
      history = chronicDiseases.filter((field) => field.name !== name);
    } else {
      if (name === NONE_ELEMENT?.fieldID) {
        history = [{ name, notes: '' }];
      } else {
        history = [...chronicDiseases, { name, notes: '' }].filter(
          (e) => e.name !== NONE_ELEMENT?.fieldID
        );
      }
    }
    onUpdate(history);
  };

  const handleChangeNotes = (id: string, notes: string) => {
    onUpdate(chronicDiseases.map((v) => (v.name === id ? { ...v, notes } : v)));
  };

  return (
    <div className="flex flex-col gap-2">
      {CHRONIC_DISEASES_FORM_FIELDS.map((el) => (
        <ListItemWrapper dataTestId="chronic_diseases" key={el.fieldID}>
          <Common.RichCheckbox
            checked={optionNamesList.includes(el.fieldID)}
            dataTestId="checkbox"
            disabled={loading}
            key={el.fieldID}
            label={el.label}
            value={el.fieldID}
            onChange={() => handleChange(el.fieldID)}
          >
            {optionNamesList.includes(el.fieldID) && el.fieldID !== NONE_ELEMENT?.fieldID && (
              <Common.TextArea
                dataTestId="text_area"
                disabled={loading}
                label={`Notes ${loading ? '- saving...' : ''}`}
                name="drug"
                placeholder={`Please add any relevant notes here...\n- Are you being treated for this? How? \n- When did it start?`}
                rows={5}
                value={chronicDiseases.find((value) => value.name === el.fieldID)?.notes}
                onChange={(e) => handleChangeNotes(el.fieldID, e.target.value)}
              />
            )}
          </Common.RichCheckbox>
        </ListItemWrapper>
      ))}
    </div>
  );
};

export default ChronicDiseases;

import { Common } from '@thecvlb/design-system';

import useWidth from 'hooks/useWidth';

const ChangePasswordSuccess: React.FC<{
  onClickAgain: () => void;
  onClickDone: () => void;
}> = ({ onClickAgain, onClickDone }) => {
  const { isMobile } = useWidth();

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4 md:p-16">
        <Common.Illustration name="success" />
        <h2 className="text-mXl font-bold text-primary-700 md:text-xl">Password changed!</h2>
        <p className="text-center text-primary-700">
          Awesome. You’ve successfully updated your password.
        </p>
      </div>
      <div className="fixed inset-x-4 bottom-4 flex flex-col justify-center gap-4 border-gray-200 md:static md:w-full md:flex-row-reverse md:border-t md:px-8 md:py-6">
        <Common.Button color="blue" dataTestId="done_btn" fullWidthOnMobile onClick={onClickDone}>
          Done
        </Common.Button>
        <Common.Button
          color="white-alt"
          dataTestId="change_again_btn"
          type="button"
          fullWidthOnMobile
          onClick={onClickAgain}
        >
          {isMobile ? 'Change password again' : 'Change again'}
        </Common.Button>
      </div>
    </div>
  );
};

export default ChangePasswordSuccess;

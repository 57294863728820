import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import BirthInput from 'shared/form/BirthInput';
import { notifySuccess } from 'shared/Toast/Toast';

import { useAppSelector } from 'hooks';
import { DateFormat } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { UpdateBirthdayFormInput, UpdateBirthdayProps } from './updateBirthday.types';

const UpdateBirthday: React.FC<UpdateBirthdayProps> = ({ isOpen, onClose }) => {
  const { dob } = useAppSelector(selectUser);
  const [updateMyAccount, { isLoading }] = useUpdateMyAccountMutation();
  const [getMyAccount] = useLazyGetMyAccountQuery();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset
  } = useForm<UpdateBirthdayFormInput>();

  const handleUpdateMyAccountThen = () => {
    getMyAccount();
    onClose(true);
    notifySuccess('Your profile was successfully updated');
  };

  const onSubmit = (e: UpdateBirthdayFormInput) => {
    updateMyAccount({ personalInfo: { dob: dayjs(e.dob).format(DateFormat.YYYY_MM_DD) } })
      .unwrap()
      .then(handleUpdateMyAccountThen)
      .catch(handleRequestCatch);
  };

  useEffect(() => {
    if (dob) {
      reset({
        dob: dayjs.utc(dob).format(DateFormat.MM_DD_YYYY)
      });
    }
  }, [dob]);

  return (
    <Common.Modal
      isOpen={isOpen}
      size="sm"
      title="Please enter your birthdate"
      zIndex={100}
      persist
    >
      <form className="mt-4 px-0.5" onSubmit={handleSubmit(onSubmit)}>
        <BirthInput
          control={control}
          label="Date of birth"
          name="dob"
          placeholder="MM/DD/YYYY"
          maxAgeValidation
        />
        <div className="mt-6 flex gap-3 max-md:flex-col-reverse md:gap-2">
          <Common.Button
            className="!w-full"
            color="white-alt"
            type="button"
            fullWidthOnMobile
            onClick={() => onClose(false)}
          >
            Close
          </Common.Button>
          <Common.Button
            className="!w-full"
            color="blue"
            disabled={!isValid || isLoading}
            isLoading={isLoading}
            fullWidthOnMobile
          >
            Update
          </Common.Button>
        </div>
      </form>
    </Common.Modal>
  );
};

export default UpdateBirthday;

import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import useWidth from 'hooks/useWidth';

import { CancellationReasonProps } from './cancellationReason.types';

const OPTIONS = [
  {
    label: 'No longer needed',
    value: 'NoLongerNeeded'
  },
  {
    label: 'No longer available',
    value: 'NoLongerAvailable'
  },
  {
    label: 'Needs in person care',
    value: 'NeedsInPersonCare'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

const CancellationReason: React.FC<CancellationReasonProps> = ({ isOpen, close, onConfirm }) => {
  const { isMobile } = useWidth();
  const buttonsSize = isMobile ? 'lg' : 'sm';

  const { control, handleSubmit, formState, reset } = useForm<{
    cancelReason: string;
    cancelReasonMessage?: string;
  }>();

  const { field } = useController({
    control,
    name: 'cancelReason',
    rules: {
      required: true
    }
  });

  const { field: MessageField } = useController({
    control,
    defaultValue: '',
    name: 'cancelReasonMessage',
    rules: {
      maxLength: 255,
      required: field.value === 'Other'
    }
  });

  const onFormSubmit = ({
    cancelReason,
    cancelReasonMessage
  }: {
    cancelReason: string;
    cancelReasonMessage?: string;
  }) => {
    onConfirm(cancelReason, cancelReason === 'Other' ? cancelReasonMessage : undefined);
    close();
  };

  useEffect(() => {
    reset({
      cancelReason: '',
      cancelReasonMessage: ''
    });
  }, [isOpen]);

  return (
    <Common.Modal close={close} isOpen={isOpen} size="sm" persist>
      <form
        className="flex flex-col gap-6"
        data-testid="cancellation_reason_modal"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <h2 className="text-xl font-bold text-gray-700" data-testid="header">
          Cancellation reason
        </h2>
        <div className="flex flex-col gap-3">
          {OPTIONS.map((option) => (
            <Common.RadioButton
              {...field}
              checked={option.value === field.value}
              color="blue"
              dataTestId="reason_option"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </Common.RadioButton>
          ))}
          {field.value === 'Other' && (
            <Common.TextArea
              {...MessageField}
              className="px-0.5"
              placeholder="Please enter a reason"
              rows={3}
            />
          )}
        </div>
        <div className="flex flex-col-reverse gap-2 md:flex-row">
          <div className="md:w-1/2">
            <Common.Button
              className="md:!w-full"
              color="white-alt"
              dataTestId="keep_appo_btn"
              size={buttonsSize}
              type="button"
              fullWidthOnMobile
              onClick={close}
            >
              Keep appointment
            </Common.Button>
          </div>
          <div className="md:w-1/2">
            <Common.Button
              className="md:!w-full"
              color="red"
              dataTestId="cancel_btn"
              disabled={!formState.isValid}
              size={buttonsSize}
              fullWidthOnMobile
            >
              Cancel appointment
            </Common.Button>
          </div>
        </div>
      </form>
    </Common.Modal>
  );
};

export default CancellationReason;

import { useState } from 'react';
import { Common } from '@thecvlb/design-system';
import { nanoid } from 'nanoid';

import { useAddFeedbackReviewMutation } from 'services/review/review';

import { selectUser } from 'store';

import { TRUST_PILOT_URL } from 'constants/externalLinks';
import { useAppSelector } from 'hooks';
import useWidth from 'hooks/useWidth';
import { handleRequestCatch } from 'utils/helpers';

import { FeedbackModalProps, IMPROVEMENT_OPTIONS, ImprovementOption } from './feedbackReview.types';

const COMMENT_LENGTH_LIMIT = 5000;

const FeedbackReview: React.FC<FeedbackModalProps> = ({ isOpen, onClose, origin }) => {
  const { isMobile } = useWidth();
  const { lastSentRating: rating } = useAppSelector(selectUser);
  const [addReview, { isLoading }] = useAddFeedbackReviewMutation();
  const [reviewComment, setReviewComment] = useState('');
  const [selectedOption, setSelectedOption] = useState<ImprovementOption['id']>();
  const isSubmitDisabled =
    isLoading ||
    rating === 0 ||
    (rating === 4 && reviewComment === '') ||
    (rating < 4 && !selectedOption);

  const navigateToTrustPilot = () => window.open(TRUST_PILOT_URL, '_blank', 'noreferrer');

  const handleSubmitClick = () => {
    if (rating === 5) {
      navigateToTrustPilot();
      onClose();
    } else {
      addReview({
        origin,
        problemType: selectedOption,
        reviewComment
      })
        .unwrap()
        .then(onClose)
        .catch(handleRequestCatch);
    }
  };

  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={!isMobile} size="sm">
      <div className="flex w-full flex-col items-center gap-4 px-4 py-6 md:px-1 md:py-0">
        {rating === 5 && (
          <>
            <span aria-label="tada" className="text-[50px] leading-[115%]" role="img">
              🎉
            </span>
            <h3 className="text-mXl font-bold text-gray-700 md:text-xl">
              We’re happy to hear that!
            </h3>
            <p className="text-center">
              Would you do us a favor and rate us on the <b>Trust Pilot</b>? This will help us quite
              a bit.
            </p>
          </>
        )}
        {rating === 4 && (
          <>
            <h3 className="text-mLg font-bold text-gray-700 md:text-lg">
              Please let us know what we can do to improve.
            </h3>
            <Common.TextArea
              className="w-full"
              dataTestId="review_field"
              name="review"
              placeholder="By making your voice heard, you helps us improve LifeMD..."
              rows={3}
              value={reviewComment}
              onChange={(e) => setReviewComment(e.target.value)}
            />
          </>
        )}
        {rating < 4 && (
          <>
            <h3 className="text-mLg font-bold text-gray-700 md:text-lg">
              Please let us know what problems you may be experiencing
            </h3>
            {IMPROVEMENT_OPTIONS.map((option) => (
              <Common.RichRadioButton
                checked={selectedOption === option.id}
                className="!font-medium"
                key={nanoid()}
                label={option.label}
                onChange={() => setSelectedOption(option.id)}
              />
            ))}
            <div className="w-full">
              <h4 className="mb-2 text-mBase font-semibold text-gray-700 md:text-base">
                Additional notes
              </h4>
              <Common.TextArea
                className="font-normal"
                dataTestId="review_field"
                maxLength={COMMENT_LENGTH_LIMIT}
                name="review"
                placeholder="Please let us know what we can do to improve..."
                rows={3}
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
              />
            </div>
          </>
        )}
        <div className="mb-10 mt-1.5 flex w-full items-center gap-3 max-md:flex-col-reverse md:mb-2 md:gap-2">
          <Common.Button
            className="md:basis-1/2 md:text-sm"
            color="white-alt"
            fullWidthOnMobile
            onClick={onClose}
          >
            {rating === 5 ? 'No thanks' : 'Skip'}
          </Common.Button>
          <Common.Button
            className="md:basis-1/2 md:text-sm"
            color="blue"
            dataTestId="continue_without_app"
            disabled={isSubmitDisabled}
            fullWidthOnMobile
            onClick={handleSubmitClick}
          >
            {rating === 5 ? 'Sure' : 'Submit feedback'}
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default FeedbackReview;

import { PossibleStepsType, StepName } from './migrateToTT.types';

export const defineFlowSteps = (steps: PossibleStepsType): StepName[] => {
  let copySteps: StepName[] = [...steps];
  const stepsToFilter: StepName[] = [];

  // Check different conditions to filter steps by adding steps to filter array in the end

  // 2. Remove pregnancy step for non female patients
  // if (params.sexAtBirth !== SexAtBirth.Female) {
  //   stepsToFilter.push('pregnancy');
  // }

  copySteps = copySteps.filter((step) => !stepsToFilter.includes(step));

  return copySteps;
};

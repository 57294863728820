const Terms = () => {
  return (
    <>
      <p data-testid="terms_of_use_body">
        Thank you for visiting the LifeMD™ website located at lifemd.com (the “<u>Site</u>”). The
        Site is an Internet property of LifeMD, Inc. (collectifvely, “<u>LifeMD™</u>,” “<u>we</u>,”
        “<u>our</u>” or “<u>us</u>”). The following LifeMD™ Terms and Conditions (“
        <u>Terms and Conditions</u>”) are inclusive of the <u>LifeMD™</u>{' '}
        <a href="/privacy">
          <u>Privacy Policy</u>
        </a>{' '}
        (“<u>Privacy Policy</u>”) and any and all other applicable operating rules, policies, price
        schedules and other supplemental terms and conditions or documents that may be published
        from time to time, which are expressly incorporated herein by reference (collectively, the “
        <u>Agreement</u>”).&nbsp;{' '}
      </p>
      <p>
        <br />
        Each end-user visitor to the Site (“<u>user</u>,” “<u>you</u>” or “<u>your</u>”) agrees to
        the terms of the Agreement, in their entirety, when she/he: (a) accesses or uses the Site;
        (b) accesses and/or downloads any of the (i) blog posts, text, audio, video, photographs,
        graphics, artwork, testimonials and/or other content featured on the Site(collectively, “
        <u>Informational Content</u>”) and/or (ii) links to third party websites, products and/or
        services (“
        <u>Third-Party Links</u>,” and together with the Informational Content, the “<u>Content</u>
        ”); (c) accesses links to LifeMD’s™ social media pages, accounts, or advertisements
        (collectively, “<u>Social Media Pages</u>”) on third-party social media websites, such as
        Facebook®, Instagram®, Twitter® and YouTube®; (d) accesses certain product review
        sections, message boards, comments sections, customer ratings and other interactive features
        of the Site (collectively, “<u>Interactive Services</u>”); (e) registers to receive the
        LifeMD™ e-mail newsletter; (f) purchases any of the non-prescription items or devices made
        available by and through the Site (collectively, the “<u>LifeMD</u>
        <u>™ </u>
        <u>Products</u>”) as provided by LifeMD™ and its affiliates; (g) purchases prescription
        medications via the Site (“<u>Prescription Medications</u>”), which are prescribed by
        certain third-party licensed providers who provide Telemedicine Services in connection with
        the Site; (h) registers, for a fee, to receive ongoing medical services that are provided by
        third-party providers and other medical professionals (“Healthcare Providers”) who provide
        Telemedicine Services in connection with the Site (“
        <u>Medical Services</u>”);&nbsp;&nbsp; and/or (j) utilizes the various contact forms and/or
        contact information made available on the Site as a means to contact directly, or request to
        be contacted by, LifeMD™ (collectively, together with the Site, Content, Social Media
        Pages, LifeMD™ Products, Newsletter, Medical Services, Prescription Medications and
        Telemedicine Services, the “<u>Site Offerings</u>
        ”).&nbsp; The Content, Interactive Services, and LifeMD Products collectively shall be
        referred to as the “Products and Services.”
        <u />
      </p>
      <p>
        <strong>
          PLEASE REVIEW THE TERMS OF THE AGREEMENT CAREFULLY. IF A USER DOES NOT AGREE WITH THE
          TERMS OF THIS AGREEMENT IN THEIR ENTIRETY, THAT USER IS NOT AUTHORIZED TO USE THE SITE
          OFFERINGS IN ANY MANNER OR FORM.
        </strong>
      </p>
      <p>
        <strong>
          THE AGREEMENT CONTAINS DISCLOSURES, DISCLAIMERS OF WARRANTIES, LIMITATIONS OF LIABILITY,
          RELEASES, A CLASS-ACTION WAIVER, AND THE REQUIREMENT TO ARBITRATE ANY AND ALL CLAIMS THAT
          MAY ARISE HEREUNDER AGAINST{' '}
        </strong>
        <strong>LIFEMD</strong>
        <strong>
          ™, AS WELL AS ITS PARENT, SUBSIDIARIES, AND MARKETING PARTNERS (COLLECTIVELY, “
          <u>COVERED PARTIES</u>”), WHO ARE EXPRESS THIRD-PARTY BENEFICIARIES OF THE MANDATORY
          ARBITRATION PROVISION. THE AFOREMENTIONED PROVISIONS ARE AN ESSENTIAL BASIS OF THE
          AGREEMENT.
        </strong>
      </p>
      <p>
        <strong>
          NEW JERSEY STATE RESIDENTS ARE ENCOURAGED TO REVIEW THEIR RIGHTS UNDER THE AGREEMENT, AS
          PROVIDED UNDER THE NEW JERSEY TRUTH-IN-CONSUMER CONTRACT WARRANTY AND NOTICE ACT (“
          <u>TCCWNA</u>”).
        </strong>
      </p>
      <p />
      Instagram® and Facebook® are registered trademarks of Facebook, Inc. (“<u>Facebook</u>
      ”).&nbsp; Twitter® is a registered trademark of Twitter, Inc. (“<u>Twitter</u>”).&nbsp;
      YouTube® is a registered trademark of Google, Inc.&nbsp; (“Google”).&nbsp; Please be advised
      that LifeMD™ is not in any way affiliated with Facebook, Google, or Twitter, nor are the Site
      Offerings endorsed, administered or sponsored by any of the aforementioned entities.
      <p />
      <p>
        <strong>Disclosures</strong>
      </p>
      <p>
        <strong>
          You acknowledge and understand that the Products and Services are not intended for use by
          persons under eighteen (18) years of age.&nbsp; You further acknowledge and understand
          that the Site Content and LifeMD™ Products and Services have not been evaluated by the US
          Food &amp; Drug Administration (“<u>FDA</u>”).&nbsp; You understand and agree that some of
          the Content associated with the Products and Services, and/or the efficacy of the Products
          and Services, is obtained from independent third-party sources, such as news agencies,
          scientific reports and/or scientific research entities (collectively, “
          <u>Third-Party Sources</u>”). &nbsp;LifeMD™ does not warrant or represent that such
          Content is error-free, and LifeMD™ does not represent or endorse any Third-Party Sources
          or the methods that they use to arrive at their conclusions. &nbsp;All Products and
          Services specifications, performance data and other related information made available via
          the Site Offerings is for informational and illustrative purposes only, and do not
          constitute a guarantee or representation that the Products and Services will conform to
          such specifications or performance data. &nbsp;LifeMD™ does not warrant or represent that
          the Products and Services will provide you with any particular benefits, or that your
          results will match those of others who have used the Products and Services, including
          users appearing on the Site. &nbsp;Individual results will vary from person to person, and
          are dependent on factors including pre-existing medical conditions, age, weight, body
          chemistry, and lifestyle.&nbsp;{' '}
        </strong>
      </p>
      <p>
        <strong>
          Consultation with your provider or other healthcare professional is always recommended
          before using any LifeMD™ Products or Prescription Medications, whether featured by and
          through the Site Offerings or otherwise, especially if you suffer from any medical
          condition including, but not limited to, skin diseases or ailments, strokes, high blood
          pressure, heart, liver, kidney or thyroid disease, diabetes, anemia, depression, anxiety,
          other psychiatric conditions or have a family history of these or other medical
          conditions.&nbsp; Your provider should allow for proper follow-up visits and individualize
          your treatment plan as appropriate.&nbsp; If you have or suspect that you have a medical
          problem as a result of using any Products and Services and/or Prescription Medications,
          promptly contact your health care provider.&nbsp; Never disregard professional medical
          advice and/or delay in seeking professional advice because of something that you have read
          on the Site or in connection with any other Site Offerings.
        </strong>
      </p>
      <p>
        <strong>
          Without limiting the foregoing, those who are taking medication or are under treatment for
          any disease, or are pregnant or lactating, should consult with their health care
          professional before using any LifeMD™ Products and/or Prescription Medications. In
          addition, the LifeMD™ Products and Prescription Medications may include ingredients to
          which you may be allergic.&nbsp; You should always check the ingredients in any LifeMD™
          Products and Prescription Medications to avoid potential allergic reactions. If you have,
          or suspect that you are experiencing, an allergic reaction or other adverse health event,
          promptly contact your health care provider.
        </strong>
      </p>
      <p>
        <strong>
          The Medical Services provided by third-party Healthcare Providers are not meant to be
          complete substitutes for all primary or specialized care.&nbsp; Users of Medical Services
          should continue to follow the recommendations of their primary and specialized care
          providers, particularly if suffering from medical conditions such as high blood pressure,
          heart, liver, kidney or thyroid disease, skin diseases, recent stroke, diabetes, anemia,
          depression, anxiety or other psychiatric ailments, or have a family history of these or
          other medical conditions.&nbsp; Consult your provider or other medical professionals if an
          emergency occurs that requires immediate, in-person care.
        </strong>
      </p>
      <p>
        <br />
        <strong>1. Scope; Modification of Agreement.</strong>&nbsp; The Agreement constitutes the
        entire and only agreement between users and LifeMD™ with respect to users’ use of the Site
        Offerings, and supersedes all prior or contemporaneous agreements, representations,
        warranties and/or understandings with respect to same.&nbsp; We may amend the Agreement from
        time to time in our sole discretion, without specific notice to you; provided, however,
        that: (a) any amendment or modification to the arbitration provisions, prohibition on class
        action provisions or any other provisions applicable to dispute resolution shall not apply
        to any disputes incurred prior to the applicable amendment or modification; and (b) any
        amendment or modification to pricing and/or billing provisions shall not apply to any
        charges incurred prior to the applicable amendment or modification.&nbsp; The current
        Agreement will be posted on the Site, and you should review the Agreement prior to using the
        Site Offerings.&nbsp; By your continued use of the Site Offerings, you hereby agree to
        comply with, and be bound by, all terms and conditions contained within the Agreement
        effective at that time. &nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p>
        <strong>
          2. Requirements; Termination of Access to the Site Offerings; Necessary Equipment
        </strong>
        .&nbsp; The Site Offerings are available only to individuals who: (a) are over eighteen (18)
        years of age (or the applicable age of majority, if greater than eighteen (18) years of age
        in their respective jurisdictions of residence); and (b) can enter into legally binding
        contracts under applicable law.&nbsp; The Site Offerings are not intended for individuals
        who are under eighteen (18) years of age (or the applicable age of majority, if greater than
        eighteen (18) years of age in their respective jurisdictions of residence) and/or
        individuals who cannot enter into legally binding contracts under applicable law.
        Individuals in these two categories do not have permission to access or use the Site
        Offerings.{' '}
      </p>
      <p>
        To the extent permitted by applicable law, LifeMD™ may terminate your right to access the
        Site Offerings at any time where you: (i) are in any way in breach of the Agreement; (ii)
        are engaged in any improper conduct in connection with the Site Offerings; and/or (iii) are,
        at any time, conducting any unauthorized commercial activity by or through your use of the
        Site Offerings.{' '}
      </p>
      <p>
        You are responsible, at all times, for ensuring that you have an Internet connection,
        computer/mobile device, up-to-date Internet browser versions, a functioning e-mail account,
        applicable software, applicable hardware and/or other equipment necessary to access the Site
        Offerings.&nbsp; LifeMD™ does not guarantee the quality, speed or availability of the
        Internet connection associated with your mobile device and/or computer.&nbsp; LifeMD™ does
        not guarantee that the Site Offerings can be accessed: (i) on all mobile devices; (ii)
        through all wireless service plans; (iii) in connection with all Internet browsers; or (iv)
        in all geographical areas.&nbsp; Standard messaging, data and wireless access fees may apply
        to your use of the Site Offerings through your wireless device.&nbsp; You are fully
        responsible for all such charges, and LifeMD™ has no liability or responsibility to you,
        whatsoever, for any such charges billed by your wireless carrier.{' '}
      </p>
      <p>
        <strong>3. Registration Forms.</strong> In order to purchase Products and Services and/or
        utilize certain Site Offerings, including the Contact Services, you may be required to
        submit one or more registration forms (each, a “<u>Form</u>”).&nbsp; The information that
        you must supply on a Form may include, without limitation: (a) your full name; (b) your
        mailing/billing address; (c) your e-mail address; (d) your telephone number; (e) your
        gender; (f) your credit card information (where purchasing Products and Services); (g)
        answers to questions regarding your health and well-being, as asked via medical intake
        Forms; and/or (h) any other information requested by us on the applicable registration Form
        (collectively, “<u>Registration Data</u>”).&nbsp; You agree to provide true, accurate,
        current and complete responses on the Forms.{' '}
      </p>
      <p>
        LifeMD’s™ use of Registration Data shall be governed by the Privacy Policy.&nbsp; For a
        copy of the Privacy Policy, please <a href="/privacy">Click Here.</a>
      </p>
      <p>
        <strong>
          4. Purchasing Products and Services, Prescription Drugs, and Medical Services.&nbsp;{' '}
        </strong>
        For a fee, you can: (a) purchase LifeMD™ Products by completing the applicable Form and
        providing the requisite Registration Data; (b) purchase Prescription Medication by
        completing the applicable Form, providing the requisite Registration Data, completing the
        Telemedicine Services process and receiving a prescription from a Healthcare Provider; and
        (c) purchase and register for Medical Services by completing the applicable Forms, providing
        the requisite Registration Data, and completing the Telemedicine Services process, including
        patient training.&nbsp;{' '}
      </p>
      <p>
        <u>One-Time Purchase</u>: Where you purchase LifeMD™ Products and/or Prescription
        Medications in a one-time transaction, and/or the Medical Services plan for which you
        register includes a per visit charge, the credit card or debit card account (collectively, “
        <u>Payment Method</u>”) that you provide on the Form (or update at a later date) will be
        charged the amount listed on the Site for the subject LifeMD™ Products, Prescription
        Medications, or per visit Medical Services, plus any applicable sales tax and shipping and
        handling charges in the case of products and medications. &nbsp;
      </p>
      <p>
        <u>Automatic Renewal Programs</u>:&nbsp; Where you purchase LifeMD™ Products and/or
        Prescription Medications in connection with an automatically renewing subscription model (“
        <u>Automatic Renewal Product Program</u>”), your Payment Method will be charged the
        applicable amount for the subject LifeMD™ Products and/or Prescription Medications on a
        recurring basis for as long as that Automatic Renewal Product Program subscription remains
        active (the “<u>Recurring Product Fees</u>”).&nbsp; Where you purchase Medical Services
        provided by a third-party Healthcare Provider in connection with an automatically renewing
        subscription model (“
        <u>Automatic Renewal Medical Services Program</u>,” and together with the Automatic Renewal
        Product Program, the “<u>Automatic Renewal Program</u>”), your Payment Method will be
        charged the applicable amount for the ongoing Medical Services on a recurring basis for as
        long as that Automatic Renewal Medical Services Program subscription remains active (the “
        <u>Recurring Medical Services Fees</u>,” and together with the Recurring Product Fees, the “
        <u>Recurring Fees</u>”).&nbsp; Such Recurring Fees will be charged in advance, and you
        acknowledge and agree that LifeMD™ will not obtain any additional authorization from you
        for the applicable Automatic Renewal Program’s Recurring Fees.&nbsp; Every time that you
        accept delivery of the subject LifeMD™ Products and/or Prescription Medications, or have a
        Medical Services visit with a Healthcare Provider, as applicable, you re-affirm that
        LifeMD™ is authorized to charge your Payment Method and to have the Recurring Fees applied
        to same.&nbsp; If you wish to cancel an Automatic Renewal Program, you may do so at any time
        by: (i) signing in to your member portal; (ii) calling us at:&nbsp; (800) 852-1575; or (iii)
        e-mailing us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>
        .&nbsp; Please be advised, all Automatic Renewal Programs must be cancelled within (8) hours
        of the renewal subscription date to avoid incurring charges for same.{' '}
      </p>
      <p>
        <strong>Purchasing Medical Services.&nbsp; </strong>Where you purchase Medical Services
        provided by a third-party Healthcare Provider in connection with an automatically renewing
        subscription model (“
        <u>Automatic Renewal Medical Services Program</u>”) your Payment Method will be charged the
        applicable amount for the ongoing Medical Services on a recurring basis for as long as that
        Automatic Renewal Medical Services Program subscription remains active.&nbsp; Where the
        Medical Services plan for which you register includes a per visit charge, the{' '}
        <u>Payment Method</u> that you provide on the Form (or update at a later date) will be
        charged the amount listed on the Site for per visit Medical Services,{' '}
      </p>
      <p>
        <u>General Billing Terms</u>:&nbsp; You must promptly notify us if your Payment Method is
        cancelled or is no longer valid (e.g., due to loss or theft) or your access to Medical
        Services may be denied and/or the LifeMD™ Products and/or Prescription Medications shipment
        may be delayed or cancelled. Changes to such information can be made by contacting a
        customer care professional at: (800) 852-1575 or patientcare@lifemd.com. If you participate
        in a Recurring Fee program using a credit card and your credit card fails to process, you
        agree that LifeMD™ may contact you on any phone number (including a cell phone number) or
        e-mail address provided by you for alternate payment information. If you fail to pay for any
        product or service received, your account may be sent to collection.&nbsp;{' '}
        <strong>UNLESS OTHERWISE INDICATED,</strong>{' '}
        <strong>ALL SALES ARE FINAL AND NON-REFUNDABLE.&nbsp; </strong>The fees associated with your
        purchases will appear on your Payment Method statement through the identifiers “LifeMD” or
        “LifeMD Inc.”&nbsp; All prices displayed on the Site are quoted in U.S. Dollars, are payable
        in U.S. Dollars and are valid and effective only in the United States.&nbsp; Failure to use
        the Medical Services, LifeMD™ Products and/or Prescription Medications, as applicable, does
        not constitute a basis for refusing to pay any of the associated charges.&nbsp; Subject to
        the conditions set forth herein, you agree to be bound by the Billing Provisions of LifeMD™
        in effect at any given time.&nbsp; Upon reasonable prior notice to you (with Site-updates
        and/or e-mail sufficing), LifeMD™ reserves the right to change its Billing Provisions
        whenever necessary, in its sole discretion.&nbsp; Continued use of the Site and/or purchase
        of Products and Services, Medical Services, and/or Prescription Drugs after receipt of such
        notice shall constitute consent to any and all such changes; <em>provided, however, </em>
        that any amendment or modification to the Billing Provisions shall not apply to any charges
        incurred prior to the applicable amendment or modification.&nbsp;{' '}
      </p>
      <p>
        <u>Electronic Signatures</u>:&nbsp; LifeMD’s™ authorization to provide and bill for the
        Products and Services, Medical Services, and Prescription Drugs is obtained by way of your
        electronic signature or, where applicable, via physical signature and/or voice affirmation.
        Once an electronic signature is submitted, this electronic order constitutes an electronic
        letter of agency.&nbsp; LifeMD’s™ reliance upon your electronic signature was specifically
        sanctioned and written into law when the Uniform Electronic Transactions Act and the
        Electronic Signatures in Global and National Transactions Act were enacted in 1999 and 2000,
        respectively (collectively, the “<u>E-Sign Act</u>”).&nbsp; Both laws specifically preempt
        all state laws that recognize only paper and handwritten signatures. Pursuant to any and all
        applicable statutes, regulations, rules, ordinances or other laws including, without
        limitation, the E-Sign Act and other similar state and federal statutes, YOU HEREBY AGREE TO
        THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER RECORDS AND TO ELECTRONIC
        DELIVERY OF NOTICES, POLICIES AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED THROUGH THE
        SITE OFFERINGS.&nbsp; Further, you hereby waive any rights and/or requirements under any
        statutes, regulations, rules, ordinances or other law in any jurisdiction which requires an
        original signature or delivery or retention of non-electronic records, or to payments or the
        granting of credits by other than electronic means.&nbsp; You acknowledge and agree that you
        have the ability to print information delivered to you electronically or otherwise know how
        to store that information in a way that ensures that it remains accessible to you in
        unchanged form.{' '}
      </p>
      <p>
        <u>Restrictions</u>: You agree that any Products and Services or Prescription Drugs that you
        purchase from LifeMD™ will be used for your personal, non-commercial use. &nbsp;You agree
        that you will not re-sell, re-distribute, or export any Products and Services or
        Prescription Drugs that you order from LifeMD™.&nbsp; LifeMD™ does not accept orders from
        dealers, wholesalers, or customers who are resellers or who intend to resell Products and
        Services or Prescription Drugs offered on the Site. &nbsp;If LifeMD™ discovers that you are
        placing orders with the intent to resell Products and Services or Prescription Drugs offered
        on the Site, we will immediately cancel your order, suspend or terminate your account and,
        at our option, pursue any and all available legal remedies under applicable law. &nbsp;To
        the extent that your conduct may be fraudulent, such as attempting to purchase or purchasing
        Products and Services, Medical Services, or Prescription Drugs through the use of fake or
        stolen credit/debit cards or payment instruments, LifeMD™ will report you to federal, state
        and/or local enforcement authorities and take any additional legal action deemed appropriate
        by LifeMD™.
      </p>
      <p>
        <strong>5. LifeMD™ Products and Prescription Medications</strong>.&nbsp; The LifeMD™
        Products and Prescription Medications remain, at all times, subject to the disclosures and
        disclaimers contained herein and on the Site. In the event that LifeMD™ Products and/or
        Prescription Medications are listed at incorrect price points due to a typographical error
        or an error in pricing information received from our suppliers, LifeMD™ shall have the
        right to refuse or cancel any orders placed for the subject LifeMD™ Products and/or
        Prescription Medications listed at the incorrect prices.&nbsp; LifeMD™ shall have the right
        to refuse or cancel any order whether or not the order has been confirmed and whether or not
        the applicable customer paid for the subject LifeMD™ Products and/or Prescription
        Medications, as applicable.&nbsp; If a customer has already paid for LifeMD™ Products
        and/or Prescription Medications, as applicable, and that order is cancelled, LifeMD™ shall
        immediately issue a credit to that customer’s Payment Method in the amount of the subject
        charge. <br />
        <br />
        The Site contains LifeMD™ Products and Prescription Medications inventory
        information.&nbsp; This information can be used to estimate the likelihood that the
        applicable LifeMD™ Products and/or Prescription Medications will be shipped immediately
        after you place your order.&nbsp; Unfortunately, we cannot guarantee that LifeMD™ Products
        and/or Prescription Medications listed as “in stock” will actually ship right away, as
        inventory can change significantly from day-to-day, and hour-to-hour.&nbsp; In rare cases,
        the applicable LifeMD™ Products and/or Prescription Medications may be in stock when you
        place your order and sold out by the time that your order is processed.&nbsp; Should this
        happen, we will notify you via email.&nbsp; If, for any reason, we determine that
        backordered LifeMD™ Products and/or Prescription Medications are no longer available, we
        will cancel your order, notify you immediately via email and provide you with a refund.
        <br />
        Shipments of products on occasion may be delayed due to carrier-related problems.&nbsp;
        LifeMD has little control over such delays.&nbsp; In the cases of such delays, LifeMD will
        act in a commercially reasonable manner to ensure product delivery as promptly as possible.
      </p>
      <p>
        <strong>6.Return Policy.</strong>&nbsp; If you wish to return LifeMD™ Products, you may
        contact a customer care professional at: (800) 852-1575.&nbsp; Please be advised that
        Prescription Medications are not eligible for return or refund.{' '}
      </p>
      <p>
        <strong>7.&nbsp; Medical Services</strong>.&nbsp; The Medical Services remain, at all times,
        subject to the disclosures and disclaimers contained herein and on the Site. When you obtain
        Medical Services, you will have access to general practitioner medical services from a
        participating third-party Healthcare Provider.&nbsp; Such Medical Services may include an
        annual physical (including some of the associated diagnostic tests), access to discounted
        prescription drugs, laboratory testing, and imaging services, assistance with appropriate
        consultation with a specialist and/or referral to a specialist or other healthcare provider,
        and messaging to a dedicated healthcare team.&nbsp; Premium Medical Service options may
        include, among other things, unlimited visits with Healthcare Providers.
      </p>
      <p>
        <br />
        <strong>Please be advised that </strong>
        <strong>LifeMD™ </strong>
        <strong>does not itself offer </strong>
        <strong>the </strong>
        <strong>Medical Services made available by third-party Healthcare Providers via</strong>
        <strong> the Site Offerings</strong>
        <strong>.</strong>
        <strong> &nbsp;The ultimate terms and conditions of any </strong>
        <strong>Medical Services </strong>
        <strong>made available </strong>
        <strong>by and through third-party Healthcare Providers </strong>
        <strong>will be determined by the applicable </strong>
        <strong>Healthcare Provider(s)</strong>
        <strong>.&nbsp; You understand and agree that </strong>
        <strong>LifeMD™ </strong>
        <strong>
          shall not be liable to you or any third-party for any services, and/or medications offered
          by or through any{' '}
        </strong>
        <strong>third-party Healthcare Provider(s) as part of the Medical Services</strong>
        <strong>.</strong>
      </p>
      <p>
        <br />
        <strong>8.</strong>&nbsp;{' '}
        <strong>Prescription Drug-Related Telemedicine Services.&nbsp; </strong>Where you request
        Prescription Medication, you must complete the medical intake Form. Upon completion of same,
        LifeMD™ will submit same to a participating Healthcare Provider.&nbsp; If the Healthcare
        Provider determines, after performing the requisite Telemedicine Services, that Prescription
        Medication is suitable for you, your Prescription Medication order will be processed.&nbsp;
      </p>
      <p>
        <strong>
          Please be advised that LifeMD™ does not itself offer the Telemedicine Services made
          available by third-party Healthcare Providers via the Site Offerings. &nbsp;The ultimate
          terms and conditions of any prescription(s) made available by third-party Healthcare
          Providers via the Telemedicine Services will be determined by the applicable Healthcare
          Provider(s).&nbsp; You understand and agree that LifeMD™ shall not be liable to you or
          any third party for any treatment decisions or medications offered by any third-party
          Healthcare Provider(s) by and/or through the Telemedicine Services.
        </strong>
      </p>
      <p>
        <br />
        <strong>9. Content.&nbsp; </strong>The Site contains Content which includes, but is not
        limited to, product reviews, text, audio, video, photographs, graphics, artwork,
        testimonials and other information about LifeMD™, the Products and Services and, on
        occasion, Prescription Drugs.&nbsp; The Content is compiled, distributed and displayed by
        LifeMD™, as well as third-party content providers, such as Third-Party Sources and other
        Site users (collectively, “<u>Third-Party Providers</u>”).&nbsp; LifeMD™ does not control
        the Content provided by Third-Party Providers that is made available by and through the Site
        Offerings.&nbsp; Such Third-Party Providers are solely responsible for the accuracy,
        completeness, appropriateness and/or usefulness of such Content. &nbsp;
        <strong>
          Reliance on any Content or other information made available to you by and through the Site
          Offerings is solely at your own risk.{' '}
        </strong>
        &nbsp;LifeMD™ does not represent or warrant that the Content and other information posted
        by and through the Site Offerings is accurate, complete, up-to-date or appropriate.&nbsp;
        You understand and agree that LifeMD™ will not be responsible for, and LifeMD™ undertakes
        no responsibility to monitor or otherwise police, Content provided by Third-Party
        Providers.&nbsp; You agree that LifeMD™ shall have no obligation and incur no liability to
        you in connection with any Content.&nbsp; You may find certain Content to be outdated,
        harmful, inaccurate and/or deceptive.&nbsp; Please use caution, common sense and safety when
        using the Content.&nbsp;{' '}
        <strong>
          The Content is offered for informational purposes only and is at all times subject to the
          disclosures and disclaimers contained herein and on the Site.
        </strong>
      </p>
      <p>
        <br />
        <strong>10.&nbsp; </strong>
        <strong>Interactive Services.</strong>
        <br />
        (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>User Content</u>.&nbsp; In connection with
        the Interactive Services, users may be able to upload and/or post certain product reviews,
        ratings, comments, content, material, communications, feedback and/or other information
        (collectively, the “<u>User Content</u>”).&nbsp; By making the User Content available by and
        through the Interactive Services or otherwise through the Site Offerings, each user provides
        to LifeMD™ a perpetual, irrevocable, worldwide license to make same available by and
        through the Site Offerings.&nbsp; Without limiting the foregoing, user acknowledges and
        agrees that LifeMD™ shall be free to utilize certain features and aspects of the User
        Content in connection with marketing and promoting the Site Offerings to third parties.
        &nbsp;Each user represents and warrants that she/he owns and/or has any and all rights to
        publish, display, perform and permit the use of, and grant the license associated with, the
        User Content as contemplated by the Agreement.&nbsp; Without limiting the foregoing,
        LifeMD™ may reject and/or remove any User Content at any time and for any reason, in
        LifeMD’s™ sole discretion.&nbsp; <strong>Notwithstanding the foregoing, </strong>
        <strong>
          LifeMD™ undertakes no responsibility to monitor or otherwise police the User Content made
          available by and through the Site Offerings.&nbsp; Each user and third-party agrees that
          LifeMD™ shall: (i) have no obligations and incur no liabilities to such party in
          connection with any such User Content; and (ii) not be liable to any party for any claim
          in connection with the User Content.
        </strong>
      </p>
      <p>
        {' '}
        (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <u>User Content Restrictions</u>. In
        connection with the User Content, each user agrees not to: (i) display any telephone
        numbers, street addresses, last names, URLs, e-mail addresses or any confidential
        information of any third-party; (ii) display any audio files, text, photographs, videos or
        other images that may be deemed indecent or obscene in the applicable user’s community, as
        defined under applicable law; (iii) impersonate any person or entity; (iv) “stalk” or
        otherwise harass any person; (v) engage in advertising to, or commercial solicitation of,
        end-users or other third parties; (vi) transmit any chain letters, spam or junk e-mail to
        any end-users or other third parties; (vii) express or imply that any statements she/he
        makes are endorsed by LifeMD™; (viii) harvest or collect personal information of end-users
        or other third parties whether or not for commercial purposes, without their express
        consent; (ix) use any robot, spider, site search/retrieval application, or other manual or
        automatic device or process to retrieve, index, “data mine,” or in any way reproduce or
        circumvent the navigational structure or presentation of the Site Offerings or related
        content; (x) post, distribute or reproduce in any way any copyrighted material, trademarks
        or other proprietary information without obtaining the prior consent of the owner of such
        proprietary rights; (xi) remove any copyright, trademark or other proprietary rights notices
        contained within the Site Offerings; (xii) interfere with or disrupt any of the Site
        Offerings and/or the servers or networks connected to same; (xiii) post, offer for download,
        e-mail or otherwise transmit any material that contains software viruses or any other
        computer code, files or programs designed to interrupt, destroy or limit the functionality
        of any computer software or hardware or telecommunications equipment; (xiv) post, offer for
        download, transmit, promote or otherwise make available any software, product or service
        that is illegal or that violates the rights of a third-party including, but not limited to,
        spyware, adware, programs designed to send unsolicited advertisements (i.e. “spamware”),
        services that send unsolicited advertisements, programs designed to initiate “denial of
        service” attacks, mail bomb programs and programs designed to gain unauthorized access to
        networks on the Internet; (xv) “frame” or “mirror” any part of the Site; (xvi) use metatags
        or code or other devices containing any reference to the Site Offerings in order to direct
        any person to any other website for any purpose; and/or (xvii) modify, adapt, sublicense,
        translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion
        of the Site Offerings or any software used on or in connection with same.&nbsp; Engaging in
        any of the aforementioned prohibited practices shall be deemed a breach of the Agreement and
        may result in the immediate termination of such user’s account and/or access to some or all
        of the Site Offerings without notice, in the sole discretion of LifeMD™.&nbsp; LifeMD™
        reserves the right to pursue any and all legal remedies against users that engage in the
        aforementioned prohibited conduct.
      </p>
      <p>
        <br />
        <strong>11.&nbsp;Social Media Pages.</strong> The Site contains links to the various
        LifeMD™ Social Media Pages.&nbsp; The Social Media Pages are hosted and made available on
        third-party Social Media Websites by third-party entities.&nbsp; Your use of Social Media
        Pages and Social Media Websites shall be governed by those Social Media Websites’ applicable
        agreements, terms and conditions.&nbsp;{' '}
        <strong>
          You understand and agree that LifeMD™ shall not be liable to you, any other user or any
          third-party for any claim in connection with your use of, or inability to use, the Social
          Media Pages and/or Social Media Websites.
        </strong>
      </p>
      <p>
        <strong>12. Representations and Warranties. </strong>Each user hereby represents and
        warrants to LifeMD™ as follows: (a){' '}
        <strong>where user purchases or attempts to purchase </strong>
        <strong>
          Products and Services or Prescription Drugs, that user is doing so for her/his own
          personal use, and with no intent to resell such Products and Services
        </strong>
        ; (b) the Agreement constitutes the legal, valid and binding obligation of user, which is
        fully enforceable against such user in accordance with its terms; (c) user understands and
        agrees that user has independently evaluated the desirability of utilizing the Site
        Offerings and that user has not relied on any representation and/or warranty other than
        those set forth in the Agreement; and (d) the execution, delivery and performance of the
        Agreement by user will not conflict with or violate: (i) any applicable law; (ii) any order,
        judgment or decree applicable to user; and/or (iii) any agreement or other instrument
        applicable to user.
      </p>
      <p>
        <strong>13.&nbsp; Indemnification.&nbsp; </strong>Each user agrees to indemnify, defend and
        hold LifeMD™, its officers, directors, employees, agents and attorneys, as well as the
        Covered Parties, harmless from and against any and all liabilities, claims, actions, suits,
        proceedings, judgments, fines, damages, costs, losses and/or expenses (including reasonable
        attorneys’ fees, court costs and/or settlement costs) arising from and/or related to: (a)
        any dispute between that user and any third-party Healthcare Provider(s) and/or other third
        party(ies); (b) that user’s breach of the Agreement and/or any representation or warranty
        contained herein; and/or (c) that user’s unauthorized and/or improper use of the Site
        Offerings.&nbsp; The provisions of this Section 13 are for the benefit of LifeMD™, its
        parent, subsidiaries and/or affiliates, and each of their respective officers, directors,
        members, employees, agents, shareholders, licensors, suppliers and/or attorneys.&nbsp; Each
        of these individuals and entities shall have the right to assert and enforce these
        provisions directly against users on its own behalf.{' '}
      </p>
      <p>
        <strong>14.&nbsp; License Grant.</strong>&nbsp; Each user is granted a non-exclusive,
        non-transferable, revocable and limited license to access and use the Site Offerings.&nbsp;
        LifeMD™ may terminate this license at any time for any reason.&nbsp; Unless otherwise
        expressly authorized by LifeMD™, users may only use the Site Offerings for their own
        personal, non-commercial use.&nbsp; No part of the Site Offerings may be reproduced in any
        form or incorporated into any information retrieval system, electronic or mechanical.&nbsp;
        No user or other third party may use any automated means or form of scraping or data
        extraction to access, query or otherwise collect material from the Site Offerings except as
        expressly permitted by LifeMD™.&nbsp; No user or other third party may use, copy, emulate,
        clone, rent, lease, sell, modify, decompile, disassemble, reverse engineer or transfer the
        Site Offerings, or any portion thereof.&nbsp; No user or other third party may create any
        “derivative works” by altering any aspect of the Site Offerings.&nbsp; No user or other
        third party may use the Site Offerings in conjunction with any other third-party
        content.&nbsp; No user or other third party may exploit any aspect of the Site Offerings for
        any commercial purposes not expressly permitted by LifeMD™.&nbsp; Each user further agrees
        to indemnify and hold LifeMD™ harmless for that user’s failure to comply with this Section
        14.&nbsp; LifeMD™ reserves any rights not explicitly granted in the Agreement.&nbsp;{' '}
      </p>
      <p>
        <strong>15.&nbsp; Proprietary Rights.&nbsp; </strong>The Site Offerings, as well as the
        organization, graphics, design, compilation, magnetic translation, digital conversion,
        software, services and other matters related to same, are protected under applicable
        copyrights, trademarks and other proprietary (including, but not limited to, intellectual
        property) rights.&nbsp; The copying, redistribution or publication by any user or other
        third party of any part of the Site Offerings is strictly prohibited.&nbsp; No user or other
        third party acquires ownership rights in or to any content, document, software, services or
        other materials viewed by or through the Site Offerings.&nbsp; The posting of information or
        material by and through the Site Offerings does not constitute a waiver of any right in or
        to such information and/or materials.&nbsp; The “LifeMD” name and logo, and all associated
        graphics, icons and service names, are registered trademarks of LifeMD, Inc.&nbsp; The use
        of any trademark without the applicable trademark owner&apos;s express written consent is
        strictly prohibited.
      </p>
      <p>
        <strong>16.&nbsp; Legal Warning.</strong>&nbsp; Any attempt by any individual to damage,
        destroy, tamper with, vandalize and/or otherwise interfere with the operation of the Site
        and Site Offerings is a violation of criminal and civil law and LifeMD™ will diligently
        pursue any and all remedies against any offending individual or entity to the fullest extent
        permissible by law and in equity.{' '}
      </p>
      <p>
        <strong>17. Disclaimer of Warranties.</strong>&nbsp; THE SITE OFFERINGS AND THE OTHER
        PRODUCTS AND SERVICES OFFERED BY AND THROUGH SAME ARE PROVIDED TO USERS ON AN “AS IS” AND
        “AS AVAILABLE” BASIS AND ALL WARRANTIES, EXPRESS AND IMPLIED, ARE DISCLAIMED TO THE FULLEST
        EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW (INCLUDING, BUT NOT LIMITED TO, THE DISCLAIMER
        OF ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY AND FITNESS
        FOR A PARTICULAR PURPOSE).&nbsp; IN PARTICULAR, BUT NOT AS A LIMITATION THEREOF, LIFEMD™
        MAKES NO WARRANTY THAT THE SITE OFFERINGS AND THE OTHER PRODUCTS AND SERVICES OFFERED BY AND
        THROUGH SAME: (A) WILL, AS APPLICABLE, MEET ANY USER’S REQUIREMENTS; (B) WILL BE
        UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE OR THAT DEFECTS WILL BE CORRECTED; (C) WILL BE
        FREE OF HARMFUL COMPONENTS; (D) WILL RESULT IN ANY SPECIFIC MEDICAL BENEFIT OR OTHER
        HEALTH-RELATED OUTCOME; OR (E) WILL BE ACCURATE OR RELIABLE.&nbsp; THE SITE OFFERINGS AND
        THE OTHER PRODUCTS AND SERVICES OFFERED BY AND THROUGH SAME MAY CONTAIN BUGS, ERRORS,
        PROBLEMS OR OTHER LIMITATIONS.&nbsp; LIFEMD™ WILL NOT BE LIABLE FOR THE AVAILABILITY OF THE
        UNDERLYING INTERNET CONNECTION ASSOCIATED WITH THE SITE OFFERINGS.&nbsp; NO ADVICE OR
        INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY ANY USER FROM LIFEMD™ OR OTHERWISE
        THROUGH OR FROM THE SITE OFFERINGS SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
        AGREEMENT.{' '}
      </p>
      <p>
        <strong>18. Limitation of Liability.</strong>&nbsp; EACH USER EXPRESSLY UNDERSTANDS AND
        AGREES THAT LIFEMD™ SHALL NOT BE LIABLE TO THAT USER OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL AND/OR EXEMPLARY DAMAGES INCLUDING, BUT NOT
        LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
        (EVEN IF LIFEMD™ HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), TO THE FULLEST
        EXTENT PERMISSIBLE BY LAW FOR: (A) THE USE OR INABILITY TO USE THE SITE OFFERINGS AND THE
        OTHER PRODUCTS AND SERVICES OFFERED BY AND THROUGH SAME; (B) THE COST OF PROCUREMENT OF
        SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, CONTENT AND THE
        OTHER PRODUCTS OR SERVICES PURCHASED OR OBTAINED FROM OR THROUGH THE SITE OFFERINGS; (C) ANY
        DISPUTE BETWEEN ANY USERS AND THIRD PARTIES; (D) THE UNAUTHORIZED ACCESS TO, OR ALTERATION
        OF, ANY REGISTRATION DATA; (E) THE FAILURE TO REALIZE ANY SPECIFIC MEDICAL BENEFIT OR OTHER
        HEALTH-RELATED OUTCOME; AND/OR (F) ANY OTHER MATTER RELATING TO THE SITE OFFERINGS AND THE
        OTHER PRODUCTS AND SERVICES OFFERED BY AND THROUGH SAME.&nbsp; THIS LIMITATION APPLIES TO
        ALL CAUSES OF ACTION, IN THE AGGREGATE INCLUDING, BUT NOT LIMITED TO, BREACH OF CONTRACT,
        BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATION AND ANY AND ALL OTHER
        TORTS.&nbsp; EACH USER HEREBY RELEASES LIFEMD™ FROM ANY AND ALL OBLIGATIONS, LIABILITIES
        AND CLAIMS IN EXCESS OF THE LIMITATIONS STATED HEREIN.&nbsp; IF APPLICABLE LAW DOES NOT
        PERMIT SUCH LIMITATIONS, THE MAXIMUM LIABILITY OF LIFEMD™ TO ANY USER UNDER ANY AND ALL
        CIRCUMSTANCES WILL BE FIVE HUNDRED DOLLARS ($500.00).&nbsp;{' '}
        <strong>NO ACTION, REGARDLESS OF FORM, ARISING OUT OF </strong>
        <strong>
          THE SITE OFFERINGS AND THE OTHER PRODUCTS AND SERVICES OFFERED BY AND THROUGH SAME, MAY BE
          BROUGHT BY ANY USER OR LIFEMD™ MORE THAN ONE (1) YEAR FOLLOWING THE EVENT WHICH GAVE RISE
          TO THE CAUSE OF ACTION.
        </strong>
        &nbsp; THE NEGATION OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE
        BARGAIN BETWEEN EACH USER AND LIFEMD™.&nbsp; ACCESS TO THE SITE OFFERINGS WOULD NOT BE
        PROVIDED TO USERS WITHOUT SUCH LIMITATIONS.&nbsp; SOME JURISDICTIONS DO NOT ALLOW CERTAIN
        LIMITATIONS ON LIABILITY AND IN SUCH JURISDICTIONS THE LIABILITY OF LIFEMD™ SHALL BE
        LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
      </p>
      <p>
        <strong>19. Third-Party Websites.</strong>&nbsp; The Site Offerings contain links to other
        websites on the Internet that are owned and operated by third parties including, without
        limitation, the Social Media Websites and/or Third-Party Links.&nbsp; LifeMD™ does not
        control the information, products or services made available on, by or through these
        third-party websites.&nbsp; The inclusion of any link does not imply endorsement by LifeMD™
        of the applicable website or any association with the website’s operators.&nbsp; Because
        LifeMD™ has no control over such websites and/or resources, each user agrees that LifeMD™
        is not responsible or liable for the availability or the operation of such external
        websites, for any material located on or available from or through any such websites or for
        the protection of any user’s data privacy by third parties.&nbsp; Each user further agrees
        that LifeMD™ shall not be responsible or liable, directly or indirectly, for any loss or
        damage caused by the use of or reliance on any such material available on, by or through any
        such site.{' '}
      </p>
      <p>
        <br />
        <strong>20.&nbsp; </strong>
        <strong>Copyright Policy/DMCA Compliance.&nbsp; </strong>LifeMD™ reserves the right to
        terminate the account of any user who infringes upon third-party copyrights.&nbsp; If any
        user or other third-party believes that a copyrighted work has been copied and/or posted via
        the Site Offerings in a way that constitutes copyright infringement, that party should
        provide LifeMD™ with the following information: (a) an electronic or physical signature of
        the person authorized to act on behalf of the owner of the copyrighted work; (b) an
        identification and location of the copyrighted work that such party claims has been
        infringed upon; (c) a written statement by such party that it has a good faith belief that
        the disputed use is not authorized by the owner, its agent or the law; (d) such party’s name
        and contact information, such as telephone number or e-mail address; and (e) a statement by
        such party that the above information in such party’s notice is accurate and, under penalty
        of perjury, that such party is the copyright owner or authorized to act on the copyright
        owner’s behalf.&nbsp; Contact information for LifeMD’s™ Copyright Agent for notice of
        claims of copyright infringement is as follows: &nbsp;
      </p>
      <p>
        Klein Moynihan Turco LLP
        <br />
        Attn: Copyright Attorney <br />
        450 Seventh Avenue, 40th Floor <br />
        New York, NY 10123
        <br />
        info@kleinmoynihan.com <br />
        Fax: (212) 216-9559
      </p>
      <p>
        <br />
        <strong>21.&nbsp; Editing, Deleting and Modification.</strong>&nbsp; LifeMD™ reserves the
        right, in its sole discretion, to edit and/or delete any documents, information or Content
        appearing on the Site.
      </p>
      <p>
        <strong>22.&nbsp; Use of Registration Data.</strong>&nbsp; All material submitted by users
        through or in association with the Site Offerings including, without limitation, the
        Registration Data, shall be subject to the Privacy Policy.&nbsp; For a copy of the Privacy
        Policy, please <a href="/src/modals/TermsAndRules/Terms/Terms">Click Here.</a>
      </p>
      <p>
        <br />
        <strong>23. Dispute Resolution Provisions.</strong> The Agreement shall be treated as though
        it were executed and performed in New York, NY and shall be governed by and construed in
        accordance with the laws of the State of New York (without regard to conflict of law
        principles).&nbsp;{' '}
        <strong>
          The parties hereby agree to arbitrate all claims that may arise under the Agreement.&nbsp;
          Without limiting the foregoing, should a dispute arise between the parties (including the
          Covered Parties) including, without limitation, any matter concerning the Site Offerings,
        </strong>{' '}
        <strong>
          the terms and conditions of the Agreement or the breach of same by any party hereto, (a)
          the parties agree to submit their dispute for resolution by arbitration before the
          American Arbitration Association (“<u>AAA</u>”) in New York, NY, in accordance with the
          then current Commercial Arbitration rules of the AAA, and (b) you agree to first commence
          a formal dispute proceeding by completing and submitting an Initial Dispute Notice which
          can be found <a href="/src/modals/TermsAndRules/Terms/Terms">here.</a>
          &nbsp; We may choose to provide you with a final written settlement offer after receiving
          your Initial Dispute Notice (“
          <u>Final Settlement Offer</u>”).&nbsp; If we provide you with a Final Settlement Offer and
          you do not accept it, or we cannot otherwise satisfactorily resolve your dispute and you
          wish to proceed, you must submit your dispute for resolution by arbitration before the
          AAA, in your county of residence, by filing a separate Demand for Arbitration, which is
          available{' '}
        </strong>
        <a href="https://www.adr.org/sites/default/files/Consumer_Demand_for_Arbitration_Form_1.pdf">
          here
        </a>
        <strong>.</strong>
        <strong>
          &nbsp; For claims of Ten Thousand Dollars ($10,000.00) or less, you can choose whether the
          arbitration proceeds in person, by telephone or based only on submissions. If the
          arbitrator awards you relief that is greater than our Final Settlement Offer, then we will
          pay all filing, administration and arbitrator fees associated with the arbitration and, if
          you retained an attorney to represent you in connection with the arbitration, we will
          reimburse any reasonable attorneys&apos; fees that your attorney accrued for
          investigating, preparing and pursuing the claim in arbitration. Any award rendered shall
          be final and conclusive to the parties and a judgment thereon may be entered in any court
          of competent jurisdiction. Although we may have a right to an award of attorneys&apos;
          fees and expenses if we prevail in arbitration, we will not seek such an award from you
          unless the arbitrator determines that your claim was frivolous.{' '}
        </strong>
        <br />
        <strong>
          To the extent permitted by law, you agree that you will not bring, join or participate in
          any class action lawsuit as to any claim, dispute or controversy that you may have against
          the Covered Parties and/or their respective employees, officers, directors, members,
          representatives and/or assigns. You agree to the entry of injunctive relief to stop such a
          lawsuit or to remove you as a participant in the suit. You agree to pay the
          attorney&apos;s fees and court costs that{' '}
        </strong>
        <strong>
          any Covered Party incurs in seeking such relief. This provision preventing you from
          bringing, joining or participating in class action lawsuits: (i) does not constitute a
          waiver of any of your rights or remedies to pursue a claim individually and not as a class
          action in binding arbitration as provided above; and (ii) is an independent agreement. You
          may opt-out of these dispute resolution provisions by providing written notice of your
          decision within thirty (30) days of the date that you first access the Site.
        </strong>{' '}
        &nbsp;&nbsp;&nbsp;
      </p>
      <p>
        <br />
        <strong>24.</strong>&nbsp; <strong>California User Consumer Rights</strong>.&nbsp; In
        accordance with Cal. Civ. Code Sec. 1789.3, California State resident Users may file
        grievances and complaints with the California Department of Consumer Affairs, 400 R Street,
        Ste. 1080, Sacramento, CA 95814; or by phone at 916-445-1254 or 800-952-5210; or by email to
        dca@dca.ca.gov.&nbsp;
      </p>
      <p>
        <strong>25.&nbsp; California Proposition 65 Warnings. &nbsp;</strong>
        <a href="https://www.p65warnings.ca.gov/">Proposition 65</a>, officially the Safe Drinking
        Water and Toxic Enforcement Act of 1986, is a law that requires warnings to be provided to
        California consumers when they might be exposed to chemicals identified by California state
        government as causing cancer or reproductive toxicity. The warnings are intended to help
        California State consumers make informed decisions about their exposures to these chemicals
        from the products they use. The California Office of Environmental Health Hazard Assessment
        (“<u>OEHHA</u>”) administers the Proposition 65 program and publishes the listed chemicals,
        which includes more than 850 chemicals. In August 2016, OEHHA adopted new regulations,
        effective on August 30, 2018, which change the information required in Proposition 65
        warnings. We are providing the following warning for products linked to this page:{' '}
      </p>
      <p>
        <strong>WARNING:</strong> <br />
        <u>Bisphenol A (BPA)</u>: LifeMD™ Product and Prescription Medication containers may have
        linings containing bisphenol A (BPA), a chemical known to the State of California to cause
        harm to the female reproductive system. Jar lids and bottle caps may also contain BPA.
        &nbsp;You can be exposed to BPA when you ingest LifeMD™ Products and/or Prescription
        Medications packaged in these containers.&nbsp; For more information go to:{' '}
        <a href="https://www.p65warnings.ca.gov/BPA">www.P65Warnings.ca.gov/BPA</a>.
      </p>
      <br />
      <h2>Additional Information about Proposition 65</h2>
      <br />
      <p>
        For background on the new Proposition 65 warnings, see{' '}
        <a href="https://www.p65warnings.ca.gov/new-proposition-65-warnings">
          https://www.p65warnings.ca.gov/new-proposition-65-warnings
        </a>
        .
      </p>
      <p>
        Proposition 65 and its regulations are posted at{' '}
        <a href="https://oehha.ca.gov/proposition-65/law/proposition-65-law-and-regulations">
          https://oehha.ca.gov/proposition-65/law/proposition-65-law-and-regulations
        </a>
        .
      </p>
      <p>
        <strong>26. Miscellaneous.</strong> To the extent that anything in or associated with the
        Site Offerings is in conflict or inconsistent with the Agreement, the Agreement shall take
        precedence.&nbsp; LifeMD’s™ failure to enforce any provision of the Agreement shall not be
        deemed a waiver of such provision nor of the right to enforce such provision.&nbsp; The
        parties do not intend that any agency or partnership relationship be created through
        operation of the Agreement.&nbsp; Should any part of the Agreement be held invalid or
        unenforceable, that portion shall be construed consistent with applicable law and the
        remaining portions shall remain in full force and effect.&nbsp; LifeMD™ may assign its
        rights and obligations under the Agreement, in whole or in part, to any party at any time
        without notice to you. The Agreement may not, however, be assigned by you, and you may not
        delegate your duties under it.&nbsp; Headings are for reference purposes only and in no way
        define, limit, construe or describe the scope or extent of such section. &nbsp;{' '}
      </p>
      <p>
        <strong>27.&nbsp; Contact Us.</strong>&nbsp; If you have any questions about the Agreement,
        Site Offerings or the practices of LifeMD™, you may utilize the contact method applicable
        to the subject matter of your inquiry, as set forth on the “Contact Us” page of the Site, or
        you can e-mail us at: patientcare@lifemd.com; call us at: (800) 852-1575; or send us U.S.
        mail to: LifeMD, Inc., 236 Fifth Avenue, Suite 400, New York, NY 10001.&nbsp;&nbsp;{' '}
      </p>
    </>
  );
};

export default Terms;

const DOC_MIMETYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];
const IMAGE_MIMETYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/heic'];
const VIDEO_MIMETYPES = ['video/quicktime', 'video/mp4', 'video/x-m4v', 'video/webm'];
export const ACCEPT_CHAT_MIMETYPES = [...DOC_MIMETYPES, ...IMAGE_MIMETYPES, ...VIDEO_MIMETYPES];

export const fileValidator = (files: FileList) => {
  const { allowedFileFormats, fileSizeMBLimit } = {
    allowedFileFormats: ACCEPT_CHAT_MIMETYPES,
    fileSizeMBLimit: 20
  };
  const { length } = files;
  const { size, type } = files[0];
  const result = {
    errVal: '',
    isValidFile: false
  };
  if (length === 0) {
    return result;
  } else if (!allowedFileFormats.includes(type)) {
    result.errVal = 'File format is invalid';
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    result.errVal = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  return result;
};

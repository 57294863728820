import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { useLottie } from 'lottie-react';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { PathName } from 'utils/enums';

import { NotFoundProps } from './notFound.types';

import notFound from 'assets/animations/404.json';

const NotFound: React.FC<NotFoundProps> = ({
  fullSize = false,
  button = {
    navLink: PathName.Dashboard,
    text: 'Dashboard'
  }
}) => {
  useTitle('LifeMD - Not found');
  const navigate = useNavigate();

  const options = {
    animationData: notFound,
    loop: true
  };

  const { View } = useLottie(options);

  return (
    <FadeWrapper
      className={classNames(
        'flex h-[calc(100vh_-_96px)] w-full flex-col items-center justify-center p-8 text-center',
        { 'max-h-[50vh] md:m-auto md:w-[421px]': !fullSize }
      )}
    >
      <div className="w-full">{View}</div>
      <h2 className="mb-8 mt-2 text-mLg md:text-lg" data-testid="404_description">
        We can’t find the page you are looking for.
      </h2>
      <Common.Button
        color="blue"
        dataTestId="404_dashboard_btn"
        postIcon="arrow-right"
        onClick={() => navigate(button.navLink)}
      >
        {button.text}
      </Common.Button>
    </FadeWrapper>
  );
};
export default NotFound;

import { LabItem as LabItemType } from 'services/auth/auth.types';

import { LabItem } from '../index';

interface LabsListProps {
  isZipCodeValid: boolean;
  items: LabItemType[];
  loading: boolean;
  onSelect: (lab: LabItemType) => void;
  selectedLabAddress?: string;
}

const LabsList: React.FC<LabsListProps> = ({
  items,
  loading,
  onSelect,
  isZipCodeValid,
  selectedLabAddress
}) => {
  return (
    <div className="flex flex-col gap-2" data-testid="labs">
      {items.map((item) => (
        <LabItem
          item={item}
          key={item.address}
          loading={loading}
          selectedLabAddress={selectedLabAddress}
          onSelect={() => onSelect(item)}
        />
      ))}
      {!items.length && !loading && (
        <span className="text-gray">
          {isZipCodeValid
            ? 'No labs found, please try another ZIP Code'
            : 'Please enter full ZIP Code'}
        </span>
      )}
    </div>
  );
};

export default LabsList;

import { useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { ChangePlanWarningProps } from './changePlanWarning.types';

const ChangePlanWarning: React.FC<ChangePlanWarningProps> = ({
  isOpen,
  onClose,
  onSelect,
  activePlanName = ''
}) => {
  const [userPick, setUserPick] = useState<null | 'yes' | 'no'>(null);

  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="sm">
      <div className="grid gap-6 p-4 md:p-6">
        <h2 className="text-2xl font-bold text-primary-700">Change plan</h2>
        <Common.Alert type="error" colorableBackground>
          <span className="text-base">
            You’re currently on a Weight Management program, which requires your current{' '}
            {` ${activePlanName} `}
            plan. If you change your plan, you will no longer have access to this program.
          </span>
        </Common.Alert>
        <div className="grid gap-4">
          <Common.RichRadioButton
            checked={userPick === 'yes'}
            color="blue"
            label={
              <div>
                <h2
                  className={classNames(
                    'text-base',
                    userPick === 'yes' ? 'font-bold' : 'font-semibold'
                  )}
                >
                  Keep Weight Management
                </h2>
                <span className="text-sm text-gray">You’ll stay on the {activePlanName} plan.</span>
              </div>
            }
            size="sm"
            value="yes"
            onChange={() => setUserPick('yes')}
          />
          <Common.RichRadioButton
            checked={userPick === 'no'}
            color="blue"
            label={
              <div>
                <h2
                  className={classNames(
                    'text-base',
                    userPick === 'no' ? 'font-bold' : 'font-semibold'
                  )}
                >
                  Cancel Weight Management
                </h2>
                <span className="text-sm text-gray">Choose a new plan on the next page.</span>
              </div>
            }
            size="sm"
            value="no"
            onChange={() => setUserPick('no')}
          />
        </div>
        <div className="grid gap-3 md:grid-cols-2 md:gap-2">
          <Common.Button
            className="md:w-full"
            color="white-alt"
            fullWidthOnMobile
            onClick={onClose}
          >
            Close window
          </Common.Button>
          <Common.Button
            className="md:w-full"
            color="blue"
            disabled={userPick !== 'no'}
            fullWidthOnMobile
            onClick={onSelect}
          >
            Next
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default ChangePlanWarning;

import { useNavigate } from 'react-router-dom';

import { SignUpProps } from 'store/signup/signup.types';
import { clearFirstAppointment, clearUserState } from 'store/signup/signupSlice';
import { setUser } from 'store/user/userSlice';

import { PathName } from 'utils/enums';

import { useAppDispatch } from './index';

const useContinueInWeb = (user: SignUpProps['user']) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (firstAppointmentId?: string, specificNavPath?: string, searchParams = '') => {
    dispatch(setUser({ ...user, userId: user._id }));
    dispatch(clearUserState());
    sessionStorage.removeItem('session-id');

    const pathname =
      specificNavPath || (firstAppointmentId ? PathName.ZoomCall : PathName.Dashboard);
    const search = specificNavPath
      ? searchParams
      : firstAppointmentId
        ? `callId=${firstAppointmentId}`
        : searchParams;

    setTimeout(() => navigate({ pathname, search }));

    dispatch(clearFirstAppointment());
  };
};

export default useContinueInWeb;

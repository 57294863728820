import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { FadeWrapperProps } from './fadeWrapper.types';

const VARIANTS = {
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5
    }
  },
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
};
const FadeWrapper: React.FC<PropsWithChildren<FadeWrapperProps>> = ({
  children,
  className,
  style,
  dataTestId
}) => {
  return (
    <motion.div
      animate="visible"
      className={className}
      data-testid={dataTestId}
      initial="hidden"
      style={style}
      variants={VARIANTS}
    >
      {children}
    </motion.div>
  );
};

export default FadeWrapper;

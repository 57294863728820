import { createApi } from '@reduxjs/toolkit/query/react';

import { setProvider } from 'store/provider/providerSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  AddReviewReqProps,
  AddReviewResProps,
  GetAvailableDatesReqProps,
  GetAvailableDatesResProps,
  GetAvailableTimesReqProps,
  GetAvailableTimesResProps,
  GetCombinedAvailabilityReqProps,
  GetCombinedAvailabilityResProps,
  GetProviderReqProps,
  GetProviderResProps,
  GetReviewReqProps,
  GetReviewResProps
} from './providers.types';

export const providersApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addReview: build.mutation<AddReviewResProps, AddReviewReqProps>({
      query: ({ doctorId, ...body }) => ({
        body,
        method: 'PUT',
        url: `doctors/${doctorId}/review`
      })
    }),
    getAvailableDates: build.query<GetAvailableDatesResProps, GetAvailableDatesReqProps>({
      query: ({ doctorId, accessToken, ...params }) => ({
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          ...(accessToken && { Authorization: accessToken })
        },
        params,
        url: `doctors/${doctorId}/available-dates`
      })
    }),
    getAvailableTimes: build.query<GetAvailableTimesResProps, GetAvailableTimesReqProps>({
      query: ({ doctorId, accessToken, ...params }) => ({
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          Authorization: accessToken
        },
        params,
        url: `doctors/${doctorId}/availability`
      })
    }),
    getCombinedAvailability: build.query<
      GetCombinedAvailabilityResProps,
      GetCombinedAvailabilityReqProps
    >({
      query: (params) => ({
        params,
        url: 'doctors/combined-availability'
      })
    }),
    getProvider: build.query<GetProviderResProps, GetProviderReqProps>({
      async onQueryStarted({ shouldUpdateState = true }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data && shouldUpdateState) {
          dispatch(setProvider(data.data));
        }
      },
      query: ({ providerId }) => `doctors/${providerId}?reviewLimit=2`
    }),
    getProviderReviews: build.query<GetReviewResProps, GetReviewReqProps>({
      keepUnusedDataFor: 0,
      query: ({ providerId, ...params }) => ({
        params,
        url: `doctors/${providerId}/reviews`
      })
    })
  }),
  reducerPath: 'providersApi',
  tagTypes: ['Providers']
});

export const {
  useGetProviderQuery,
  useLazyGetProviderQuery,
  useGetProviderReviewsQuery,
  useLazyGetProviderReviewsQuery,
  useAddReviewMutation,
  useLazyGetAvailableTimesQuery,
  useLazyGetAvailableDatesQuery,
  useLazyGetCombinedAvailabilityQuery
} = providersApi;

/* eslint-disable no-console */
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import { Socket } from 'socket.io-client';

import { selectRefreshToken } from 'store';

import {
  DateMessagesProps,
  GetOldMessageParams,
  MarkSeenParams,
  MessageProps,
  NewMessageToServerParams,
  UnreadMessageUpdatedProps
} from 'contexts/messagesContext/messagesContext.types';
import { useAppSelector } from 'hooks';
import { errorCallBack, socketReconnect, SocketResProps } from 'socket';

import {
  ChannelDetailsProps,
  ChannelProps,
  CreateChannelParams,
  FrontDeskContextProps,
  MessageToTheClientProps
} from './frontDeskContext.types';

export const FrontDeskContext = createContext<FrontDeskContextProps>({
  channelDetails: null,
  channels: [],
  closeChannel: () => {},
  createChannel: () => {},
  dateMessages: null,
  frontDeskChannelCreated: null,
  getChannels: () => {},
  getOldMessage: () => {},
  isLoading: false,
  joinRoom: () => {},
  markSeen: () => {},
  sendMessage: () => {}
});

export const FrontDeskProvider: React.FC<PropsWithChildren<{ socket: Socket }>> = ({
  children,
  socket
}) => {
  const refreshToken = useAppSelector(selectRefreshToken);
  const [frontDeskChannelCreated, setFrontDeskChannelCreated] = useState<ChannelProps | null>(null);
  const [messageToClients, setMessageToClients] = useState<MessageToTheClientProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listOfMessages, setListOfMessages] = useState<MessageProps[] | null>(null);
  const [dateMessages, setDateMessages] = useState<DateMessagesProps[] | null>(null);
  const [channels, setChannels] = useState<ChannelProps[]>([]);
  const [channelDetails, setChannelDetails] = useState<ChannelDetailsProps | null>(null);
  const [unreadMessageUpdated, setUnreadMessageUpdated] =
    useState<UnreadMessageUpdatedProps | null>(null);
  const [oldMessages, setOldMessages] = useState<MessageProps[]>([]);
  const [historyCatchUp, setHistoryCatchUp] = useState<MessageProps[] | null>(null);
  const [skip, setSkip] = useState(1);

  const closeChannel = () => {
    setOldMessages([]);
    setDateMessages(null);
    setChannelDetails(null);
  };

  const joinRoom = (channelId: string) => {
    socket.emit('joinRoom', channelId, (error: SocketResProps) => errorCallBack('joinRoom', error));
  };

  const markSeen = (params: MarkSeenParams) => {
    socket.emit('markSeen', params, (error: SocketResProps) => errorCallBack('markSeen', error));
  };

  const handleSetOldMessages = (data: MessageProps[]) => {
    setIsLoading(false);
    setOldMessages(data);
  };

  const handleUpdatedOldMessages = () => {
    if (oldMessages.length) {
      setHistoryCatchUp([...oldMessages, ...(listOfMessages || [])]);
    }
  };

  const updateChannel = (data: {
    channelId: string;
    latestMessage?: string;
    latestMessageDate?: Date;
    unreadMessageCount?: number;
  }) => {
    setChannels(
      channels.map((channel) => {
        if (channel.channelId === data.channelId) {
          return {
            ...channel,
            ...data
          };
        }

        return channel;
      })
    );
  };

  const handleUpdatedHistoryCatchUp = () => {
    if (!channelDetails || !historyCatchUp) return;

    const blurredHistory = historyCatchUp.map((el) => ({ ...el, isBlur: true }));
    const filteredData = blurredHistory.filter(
      (message) => message.channelId === channelDetails._id
    );

    if (filteredData.length || !historyCatchUp.length) {
      setListOfMessages(filteredData);
    }
  };

  const handleUpdatedUnreadMessageUpdated = () => {
    if (unreadMessageUpdated) {
      updateChannel(unreadMessageUpdated);
    }
  };

  const handleUpdatedMessageToClients = () => {
    if (messageToClients) {
      setListOfMessages([...(listOfMessages || []), messageToClients]);
      updateChannel({
        channelId: messageToClients.channelId,
        latestMessage: messageToClients.message,
        latestMessageDate: messageToClients.messageStatus.sent
      });
    }
  };

  const handleUpdatedListOfMessages = () => {
    if (!listOfMessages) return;

    const formattedData: DateMessagesProps[] = listOfMessages.reduce((acc, message) => {
      const sentDate = new Date(message.messageStatus.sent).toDateString();
      const current = acc.find(({ date }) => date === sentDate);

      if (current) {
        current.messages.push(message);
      } else {
        acc.push({ date: sentDate, messages: [message] });
      }

      return acc;
    }, [] as DateMessagesProps[]);

    const sorted = formattedData
      .map(({ date, messages }) => ({
        date,
        messages: messages.sort((a, b) => dayjs(a.messageStatus.sent).diff(b.messageStatus.sent))
      }))
      .sort((a, b) => dayjs(a.date).diff(b.date));

    setDateMessages(sorted);
    setIsLoading(false);
  };

  const handleSetChannels = (items: ChannelProps[]) => {
    setChannels((prev) => uniqBy([...prev, ...items], 'channelId'));
  };

  const getOldMessage = (params: GetOldMessageParams, cb: () => void) => {
    if (isLoading) return;

    setIsLoading(true);
    socket.emit('getOldMessage', params, (error: SocketResProps) => {
      setIsLoading(false);
      errorCallBack('getOldMessage', error);
    });
    cb();
  };

  const getChannels = () => {
    socket.emit(
      'getNextChannels',
      {
        limit: 10,
        skip
      },
      (error: SocketResProps) => errorCallBack('getNextChannels', error)
    );
    setSkip(skip + 1);
  };

  const sendMessage = (params: NewMessageToServerParams) => {
    socket.emit('sendMessage', params, (error: SocketResProps) =>
      errorCallBack('sendMessage', error)
    );
  };

  const createChannel = (params: CreateChannelParams) => {
    socket.emit('createChannel', params, (error: SocketResProps) =>
      errorCallBack('createChannel', error)
    );
  };

  const handleUpdatedFrontDeskChannelCreated = () => {
    if (channels && frontDeskChannelCreated) {
      setChannels([...[frontDeskChannelCreated], ...channels]);
      setFrontDeskChannelCreated(null);
    }
  };

  useEffect(handleUpdatedFrontDeskChannelCreated, [frontDeskChannelCreated]);

  useEffect(handleUpdatedOldMessages, [oldMessages]);

  useEffect(handleUpdatedMessageToClients, [messageToClients]);

  useEffect(handleUpdatedUnreadMessageUpdated, [unreadMessageUpdated]);

  useEffect(handleUpdatedHistoryCatchUp, [historyCatchUp, channelDetails]);

  useEffect(handleUpdatedListOfMessages, [listOfMessages]);

  useEffect(() => {
    socket.on('channels', (data) => handleSetChannels(data.data));
    socket.on('oldChannels', (data) => handleSetChannels(data.data));
    socket.on('channelDetails', setChannelDetails);
    socket.on('frontDeskChannelCreated', setFrontDeskChannelCreated);
    socket.on('messageHistory', (data) => handleSetOldMessages(data.data));
    socket.on('historyCatchUp', (data) => setHistoryCatchUp(data.data));
    socket.on('messageToClients', setMessageToClients);
    socket.on('unreadMessageUpdated', setUnreadMessageUpdated);
    socket.on('connect', () => console.info('front desk connect'));
    socket.on('connect_error', (err) => {
      if (err.message === 'AUTH_EXPIRED') {
        socketReconnect(socket, refreshToken);
      }
    });
    socket.on('disconnect', () => console.info('front desk disconnect'));

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('channels');
      socket.off('oldChannels');
      socket.off('channelDetails');
      socket.off('frontDeskChannelCreated');
      socket.off('messageHistory');
      socket.off('historyCatchUp');
      socket.off('messageToClients');
      socket.off('unreadMessageUpdated');
    };
  }, []);

  return (
    <FrontDeskContext.Provider
      value={{
        channelDetails,
        channels,
        closeChannel,
        createChannel,
        dateMessages,
        frontDeskChannelCreated,
        getChannels,
        getOldMessage,
        isLoading,
        joinRoom,
        markSeen,
        sendMessage
      }}
    >
      {children}
    </FrontDeskContext.Provider>
  );
};

import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';

import { selectMedicalRecordsStatus, selectPatientChart, selectUser } from 'store';

import TimeLine from 'features/TimeLine';
import Grid from 'widgets/myChart/Grid';

import { useAppSelector } from 'hooks';
import useWidth from 'hooks/useWidth';
import { FeatureFlag } from 'utils/enums';

const Dashboard = () => {
  useTitle('LifeMD - My Chart');
  const isEnabledMedicalRecordsPageFeature = useFlag(FeatureFlag.MedicalRecords);
  const { isMobile } = useWidth();
  const user = useAppSelector(selectUser);
  const isDisabledMedicalRecords = useAppSelector(selectMedicalRecordsStatus);
  const { medicalRecords } = useAppSelector(selectPatientChart);

  return (
    <div className="flex flex-col gap-8 md:p-8" data-testid="my_chart_dashboard">
      <div className="flex items-center gap-4 md:gap-3">
        <Common.ProfileImage size={isMobile ? '2xl' : 'lg'} src={user.profileImage} />
        <div className="flex flex-col gap-1">
          <span className="text-mLg font-bold md:text-xl">
            {user.nickName || user.firstName} {user.lastName}
          </span>
          {user.dob && (
            <span className="text-mBase text-gray md:text-sm">
              {dayjs().diff(user.dob, 'year')} years old
            </span>
          )}
        </div>
      </div>
      {isEnabledMedicalRecordsPageFeature && !isDisabledMedicalRecords && (
        <TimeLine medicalRecords={medicalRecords} />
      )}
      <Grid />
    </div>
  );
};
export default Dashboard;

import { useEffect, useRef, useState } from 'react';
import { Common } from '@thecvlb/design-system';

import Consent from './Consent';
import Privacy from './Privacy';
import Terms from './Terms';
import { TermsAndRulesProps } from './termsAndRules.types';

const TermsAndRulesPopup: React.FC<TermsAndRulesProps> = ({
  tab,
  onClose,
  isOpen,
  withTeleHealthTab = false
}) => {
  const scrollBlock = useRef<HTMLDivElement | null>(null);
  const [activeTab, setActiveTab] = useState('terms');
  const setActive = (value: string) => {
    setActiveTab(value);
    scrollBlock.current?.scrollTo &&
      scrollBlock.current.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  };

  let tabs = [
    { label: 'Terms of Use', value: 'terms' },
    { label: 'Privacy Policy', value: 'privacy' }
  ];

  if (!withTeleHealthTab) {
    tabs = [...tabs, { label: 'Telehealth Consent Policy', value: 'consent' }];
  }

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="xl" zIndex={100}>
      <div className="flex max-h-[500px] flex-col md:max-h-[600px]">
        <div className="mb-4 py-4">
          <Common.Tabs
            data={tabs}
            defaultSelected={[tabs.find((el) => el.value === activeTab)?.label || '']}
            type="pill"
            onChange={(val) => setActive(val.value as string)}
          />
        </div>
        <div className="flex-1 overflow-y-auto rounded border border-gray p-4" ref={scrollBlock}>
          {activeTab === 'terms' && <Terms />}
          {activeTab === 'privacy' && <Privacy />}
          {activeTab === 'consent' && <Consent />}
        </div>
      </div>
    </Common.Modal>
  );
};

export default TermsAndRulesPopup;

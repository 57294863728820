import { Common } from '@thecvlb/design-system';

import { getStatusContent } from '../billingHistory.settings';

const TableColumnItem: React.FC<{ item: { content: string | number; name: string } }> = ({
  item
}) => {
  switch (item.name) {
    case 'amount':
      return <>${item.content}</>;
    case 'invoice':
      return <span className="underline">{item.content}</span>;
    case 'status':
      return (
        <Common.ColorTag
          color={getStatusContent(String(item.content)).color}
          icon={getStatusContent(String(item.content)).icon}
          text={item.content.toString()}
        />
      );
    default:
      return null;
  }
};

export default TableColumnItem;

const ITEMS = [
  {
    name: 'Metformin',
    dosage: '500mg',
    description:
      'An anti-diabetic medication primarily used to control blood sugar levels. It also helps reduce appetite, making it easier to manage your diet.'
  },
  {
    name: 'Bupropion',
    dosage: '100mg',
    description:
      'An atypical antidepressant and smoking cessation aid that can boost metabolism and enhance mood, contributing to weight loss efforts.'
  },
  {
    name: 'Topiramate',
    dosage: '50mg',
    description:
      'An anticonvulsant medication that also helps decrease hunger and promotes a feeling of fullness, aiding in Weight Management.'
  }
];

const MedicationsListInfo = () => {
  return (
    <div className="flex w-full flex-col gap-3">
      {ITEMS.map((m) => (
        <div className="flex flex-col gap-3 rounded-2xl bg-gray-100 p-4" key={m.name}>
          <div className="flex flex-col gap-1">
            <h4 className="text-mLg font-bold">{m.name}</h4>
            <span className="text-primary">{m.dosage}</span>
          </div>
          <p className="text-mSm">{m.description}</p>
        </div>
      ))}
    </div>
  );
};

export default MedicationsListInfo;

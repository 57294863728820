import { Common } from '@thecvlb/design-system';

import { Medication } from 'models/medication.types';

import CompoundCard from './CompoundCard';
import MedicationCard from './MedicationCard';

interface MedicationAtSelectPlanCardProps {
  loading: boolean;
  medication: Medication | { drug_name: 'TBD' };
  onClickContinue?: () => void;
  onClickEdit?: () => void;
}

const MedicationAtSelectPlanCard: React.FC<MedicationAtSelectPlanCardProps> = ({
  medication,
  onClickContinue,
  loading,
  onClickEdit
}) => {
  const isCompoundMedication = medication.drug_name === 'Semaglutide';
  return (
    <>
      {isCompoundMedication && (
        <>
          <Common.Button
            color="blue"
            disabled={loading}
            fullWidthOnMobile
            onClick={onClickContinue}
          >
            Continue to checkout
          </Common.Button>
          <div className="flex items-center gap-1 text-mSm text-primary-700 md:text-sm">
            <span>Backed by the</span>
            <span className="text-mSm font-bold md:text-sm">LifeMD Weight Loss Guarantee</span>
            <Common.Icon className="size-6 stroke-primary-700 text-white" name="check-circle" />
          </div>
          <div className="my-2 h-px w-full bg-gray-300 max-md:hidden"></div>
          <span className="font-bold text-primary-700">
            See what’s included with your treatment.
          </span>
        </>
      )}
      <div className="w-full max-w-[480px]">
        {isCompoundMedication ? (
          <CompoundCard medication={medication} />
        ) : (
          <MedicationCard medication={medication} onClickEdit={onClickEdit} />
        )}
      </div>
      {!isCompoundMedication && (
        <>
          <Common.Button
            color="blue"
            disabled={loading}
            fullWidthOnMobile
            onClick={onClickContinue}
          >
            Continue to checkout
          </Common.Button>
          <div className="flex items-center gap-1 text-mSm text-primary-700 md:text-sm">
            <span>Backed by the</span>
            <span className="text-mSm font-bold md:text-sm">LifeMD Weight Loss Guarantee</span>
            <Common.Icon className="size-6 stroke-primary-700 text-white" name="check-circle" />
          </div>
        </>
      )}
    </>
  );
};

export default MedicationAtSelectPlanCard;

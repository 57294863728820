import { Common } from '@thecvlb/design-system';

import { ReducedSignupStepProps } from 'containers/SignUp/Content/content.types';

import iPhone from 'assets/images/signUp/card_weight-loss.svg';

const MarketingLowerPrice: React.FC<ReducedSignupStepProps> = ({ moveToStep }) => {
  return (
    <div className="flex flex-col items-center gap-6 max-md:h-full">
      <div className="flex flex-col gap-2 text-center md:gap-6">
        <img
          alt="Weight loss without uncertainty."
          className="mx-auto w-[188px] md:w-[256px]"
          src={iPhone}
        />
        <div className="flex max-w-[500px] flex-col gap-2 md:gap-4">
          <h2 className="text-mXl text-primary-700 md:text-2xl" data-testid="header">
            We’re committed to getting you the lowest price possible on your GLP-1 and ensuring{' '}
            <b>you get the medication needed.</b>
          </h2>
          <div className="flex flex-col gap-2 px-5 pb-3 md:px-20">
            <div className="flex items-center gap-4 text-primary-700 md:text-lg">
              <Common.Icon className="size-9" name="phone-with-message" />
              <span className="text-left">Communicate with your insurance</span>
            </div>
            <div className="flex items-center gap-4 text-primary-700 md:text-lg">
              <Common.Icon className="size-9" name="stethoscope" />
              <span>Explore all medication options</span>
            </div>
            <div className="flex items-center gap-4 text-primary-700 md:text-lg">
              <Common.Icon className="size-9" name="dollar-with-arrows" />
              <span>Get the best price for your GLP-1</span>
            </div>
          </div>
        </div>
      </div>
      <Common.Button
        className="order-4 max-md:mt-auto md:order-3"
        color="blue"
        fullWidthOnMobile
        onClick={() => moveToStep('next')}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default MarketingLowerPrice;

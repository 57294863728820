import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { getMifContent } from './mifSummary.settings';
import { MifInfoItemProps } from './mifSummary.types';

const NAV_LABEL_INDEX = 0;
const FIELD_KEY_INDEX = 1;
const DATA_INDEX = 2;

const MifInfoItem: React.FC<MifInfoItemProps> = ({ item, onEdit }) => {
  const [itemsToShow, setItemsToShow] = useState<string | string[]>('');

  const editBtnClassName = 'ml-auto mr-3 block text-sm font-bold text-gray';
  const wrapperClassName = 'border-b pb-6 last:border-b-0 last:pb-0';
  const contentClassName = 'mb-2 flex w-full';
  const itemTextClassName = 'break-words text-mSm text-gray md:text-sm';
  const itemTextContainerClassName = classNames(
    itemTextClassName,
    'ml-6 mr-8 flex flex-col gap-2 max-md:font-semibold'
  );

  const itemContent = () => {
    if (Array.isArray(itemsToShow)) {
      return (
        <div className={itemTextContainerClassName}>
          {!!itemsToShow.length ? (
            itemsToShow.map((el) => <span key={el}>{el}</span>)
          ) : (
            <span className={classNames(itemTextClassName, 'max-md:font-semibold')}>
              {['medications', 'allergyList'].includes(item[FIELD_KEY_INDEX])
                ? 'No'
                : 'None of the above'}
            </span>
          )}
        </div>
      );
    }
    return (
      <span className={classNames(itemTextClassName, 'ml-6')}>
        {itemsToShow || 'None of the above'}
      </span>
    );
  };

  useEffect(() => {
    !!item[DATA_INDEX] && setItemsToShow(getMifContent(item[FIELD_KEY_INDEX], item[DATA_INDEX]));
  }, [item[DATA_INDEX]]);

  return (
    <div className={wrapperClassName}>
      <div className={contentClassName}>
        <Common.Icon className="mr-1 text-secondary" name="check"></Common.Icon>
        <span className="font-bold text-gray-700">{item[NAV_LABEL_INDEX]}</span>
        <button className={editBtnClassName} data-testid="edit_btn" onClick={onEdit}>
          Edit
        </button>
      </div>
      {itemContent()}
    </div>
  );
};

export default MifInfoItem;

import { ResponseProps } from 'services/services.types';

export enum DynamicLinkActions {
  BILLING = 'billing',
  DASHBOARD = 'dashboard',
  WAITING_ROOM = 'waiting-room'
}

export interface GenerateDynamicLinkQueueReqProps {
  accessToken: string;
  action: DynamicLinkActions;
  appointmentId: string;
  token: string;
}

export interface DynamicLinkRes {
  dynamicLink: string;
}
export type GenerateDynamicLinkResProps = ResponseProps<DynamicLinkRes>;

export interface GenerateDynamicLinkReqProps {
  accessToken?: string;
  action: DynamicLinkActions;
  appointmentId?: string;
  doctorId?: string;
  expiredToken?: string;
  src: 'signUp' | 'appMobile';
}

export type GetTokenResProps = ResponseProps<{ link: string }>;

export interface VerifyIdentityReqProps {
  accessToken?: string;
  governmentIssuedId: string;
  selfPortrait?: string;
}

export type VerifyIdentityCrosscheckResProps = ResponseProps<{
  errors: string[];
  isVerified: boolean;
  warnings: string[];
}>;

export type GetSignableDocumentsReqProps = {
  accessToken?: string;
} | void;

type SignableDocuments = 'insurance-terms' | 'medicaid-waiver-terms';
export type GetSignableDocumentsResProps = ResponseProps<
  [{ documentId: string; html: string; type: SignableDocuments }]
>;

export interface SignDocumentReqProps {
  accessToken?: string;
  documentId: string;
}

export type SignDocumentResProps = ResponseProps<{ success: boolean }>;

export type GetGlobalMessagesResProps = {
  frontDescMessage: string;
};

import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import ShopOrderDetails from 'modals/ShopOrderDetails';

import { TrackerProps } from './tracker.types';

const Tracker: React.FC<TrackerProps> = ({ order }) => {
  const [isOpenShopOrderDetails, toggleIsOpenShopOrderDetails] = useToggle(false);
  return (
    <>
      <ShopOrderDetails
        data={order}
        isOpen={isOpenShopOrderDetails}
        onClose={toggleIsOpenShopOrderDetails}
      />
      <div className="flex flex-col gap-4 rounded-2xl bg-secondary-100 p-4">
        <div className="text-primary-700">
          <h2 className="font-bold">Your devices are on their way!</h2>
          <span className="mt-1 text-mSm">{order.shippingStatus}</span>
        </div>
        <button
          className="flex items-center gap-4 rounded-2xl border border-black/10 bg-white p-2 shadow-sm max-md:px-4"
          onClick={toggleIsOpenShopOrderDetails}
        >
          <div className="text-start">
            <h4 className="font-bold text-gray-950">Order #: {order.orderNumber}</h4>
            {order.trackingNumber && <span>Tracking: {order.trackingNumber}</span>}
          </div>
          <Common.Icon className="ml-auto size-5 flex-none text-gray" name="arrow-right" />
        </button>
      </div>
    </>
  );
};

export default Tracker;

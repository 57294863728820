import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import {
  CheckMedicationCoverageResProps,
  GetInfermedicaMagicLinkReqProps,
  GetInfermedicaMagicLinkResProps,
  SaveSymptomCheckerResultsReqProps,
  SaveSymptomCheckerResultsResProps,
  SkipSCReqProps,
  SkipSCResProps
} from './patients.types';

export const patientsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    checkMedicationCoverage: build.query<CheckMedicationCoverageResProps, string>({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken
        },
        url: '/patients/benefits'
      })
    }),
    getInfermedicaMagicLink: build.query<
      GetInfermedicaMagicLinkResProps,
      GetInfermedicaMagicLinkReqProps
    >({
      query: ({ accessToken, patientId }) => ({
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          Authorization: accessToken
        },
        method: 'GET',
        url: `/patients/${patientId}/infermedica-magic-link`
      })
    }),
    saveSymptomCheckerResults: build.mutation<
      SaveSymptomCheckerResultsResProps,
      SaveSymptomCheckerResultsReqProps
    >({
      query: ({ accessToken, body }) => ({
        body,
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          Authorization: accessToken
        },
        method: 'POST',
        url: `/patients/symptom-checker-results`
      })
    }),
    skipSC: build.mutation<SkipSCResProps, SkipSCReqProps>({
      query: (accessToken) => ({
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          Authorization: accessToken
        },
        method: 'PATCH',
        url: '/patients/symptom-checker-skip'
      })
    })
  }),
  reducerPath: 'patientsApi',
  tagTypes: ['Patients']
});

export const {
  useLazyCheckMedicationCoverageQuery,
  useGetInfermedicaMagicLinkQuery,
  useSaveSymptomCheckerResultsMutation,
  useSkipSCMutation
} = patientsApi;

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { OptionProps } from './option.types';

const Option: React.FC<OptionProps> = ({
  warning,
  option,
  showAllOptions,
  answersArray,
  isAdditionalOption
}) => {
  const isChosen = (!showAllOptions && !isAdditionalOption) || answersArray.includes(option);
  const isChosenOrAdditional = isChosen || isAdditionalOption;

  const optionClassNames = classNames(
    'flex items-center gap-1',
    isChosenOrAdditional ? 'font-bold' : 'font-medium',
    isChosenOrAdditional ? (warning ? 'text-red-500' : 'text-green-500') : 'text-black/30',
    { 'pl-5': !isChosen || isAdditionalOption }
  );
  return (
    <span className={optionClassNames}>
      {isChosen && (
        <span data-testid="arrow-alt-right">
          <Common.Icon className="size-4" name="arrow-alt-right" />
        </span>
      )}
      {option}
    </span>
  );
};

export default Option;

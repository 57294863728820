import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import MemberPricing from 'modals/MemberPricing';

const MemberPricingBanner = () => {
  const [openModal, toggleOpenModal] = useToggle(false);

  return (
    <div
      className="flex h-fit flex-col items-center rounded-2xl bg-primary-50 px-8 py-12 max-md:mt-4 md:max-w-[300px] md:py-8"
      data-testid="member_pricing_banner"
    >
      <MemberPricing isOpen={openModal} onClose={toggleOpenModal} />
      <Common.Illustration className="flex-none md:max-h-[67px]" name="savings" />
      <div className="text-center text-primary-700 md:text-left">
        <p className="mt-4 text-xl font-bold md:mt-8" data-testid="banner_heading">
          Save up to 95% on your labs with LifeMD member pricing.
        </p>
        <p className="mt-4">
          We’ve secured special pricing from our trusted lab partners, and we’re passing these
          savings directly onto you. Check out our pricing comparison chart to see how much you can
          save.
        </p>
        <div className="mt-4 flex flex-col items-center gap-3 md:mt-8">
          <Common.Button color="blue" dataTestId="view_pricing_btn" onClick={toggleOpenModal}>
            View our pricing
          </Common.Button>
          {/*<Common.Button style="text-only" color="blue" postIcon="arrow-right">*/}
          {/*  Learn more*/}
          {/*</Common.Button>*/}
        </div>
      </div>
    </div>
  );
};

export default MemberPricingBanner;

import { ChatDateProps } from './chatDate.types';

const ChatDate: React.FC<ChatDateProps> = ({ date }) => {
  return (
    <div
      className="sticky top-2 z-[1] mx-auto mb-4 flex w-32 items-center justify-center rounded-full border border-gray bg-gray-50 px-10 py-1 text-center text-sm font-bold text-gray-700 transition-all md:top-3"
      data-testid="messages_date_title"
    >
      {date === new Date().toDateString() ? 'Today' : new Date(date).toLocaleDateString()}
    </div>
  );
};

export default ChatDate;

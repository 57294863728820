import classNames from 'classnames';
import { nanoid } from 'nanoid';

import Option from 'widgets/myChart/HealthProfile/MedicalIntakeForm/Option';

import { QuestionProps } from './question.types';

const Question: React.FC<QuestionProps> = ({ question, showAllOptions }) => {
  const answersArray = Array.isArray(question.answer)
    ? question.answer
    : question.answer
      ? [question.answer]
      : [];
  const options = question.options || answersArray;

  return (
    <div
      className={classNames('flex gap-4 border-b border-black/5 p-3', {
        'bg-red-100': question.warning
      })}
    >
      <span
        className={classNames(
          'size-6 flex-none rounded-3xl border py-0.5 text-center text-sm font-medium',
          question.warning ? 'border-red-500 text-red-500' : 'border-gray-500 text-gray-500'
        )}
      >
        {question.displayNumber}
      </span>
      <div className="flex flex-col gap-1 text-base">
        <span className="font-semibold">{question.question}</span>
        <span className="flex flex-col gap-1 font-medium text-gray-500">
          {(showAllOptions ? options : answersArray).map((option) => (
            <Option
              answersArray={answersArray}
              key={nanoid()}
              option={option}
              showAllOptions={showAllOptions}
              warning={question.warning}
            />
          ))}
        </span>
        {question.additionalDetails?.map((additionalOption) => (
          <Option
            answersArray={answersArray}
            key={nanoid()}
            option={additionalOption}
            showAllOptions={showAllOptions}
            warning={question.warning}
            isAdditionalOption
          />
        ))}
      </div>
    </div>
  );
};

export default Question;

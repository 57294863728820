import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import CreditCard from 'features/CreditCard';

import useAnalytics from 'hooks/useAnalytics';

import { DiscountCardInfoProps } from './discountCardInfo.types';

const DiscountCardInfo: React.FC<DiscountCardInfoProps> = ({ firstName, lastName }) => {
  const logEvent = useAnalytics();
  const navigate = useNavigate();

  const handleClickViewCard = () => {
    logEvent('prescriptions_view_card_btn_click');
    navigate({
      search: '?tab=Discount card'
    });
  };

  return (
    <div className="prescription-discount mt-4 flex flex-col items-center justify-center p-4 md:flex-row md:gap-5 md:p-6">
      <div className="w-[343px] md:basis-1/2">
        <div className="origin-top scale-[0.53] md:ml-auto md:origin-right">
          <CreditCard
            additionalClassName="ml-auto"
            ownerName={`${firstName} ${lastName}`}
            provider="Procare RX"
          />
        </div>
      </div>
      <div className="-mt-16 md:mt-0 md:basis-1/2">
        <h3 className="mb-5 max-w-[310px] text-center text-xl font-bold text-white md:mb-4 md:text-left">
          Save up to 92% on prescriptions with your discount card.
        </h3>
        <Common.Button
          color="white"
          postIcon="arrow-right"
          fullWidthOnMobile
          onClick={handleClickViewCard}
        >
          View card
        </Common.Button>
      </div>
    </div>
  );
};

export default DiscountCardInfo;

import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { TransitionWithDelayWrapperProps } from './transitionWithDelayWrapper.types';

const VARIANTS = {
  hidden: { opacity: 0 },
  visible: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.3
    }
  })
};

const TransitionWithDelayWrapper: React.FC<PropsWithChildren<TransitionWithDelayWrapperProps>> = ({
  children,
  className,
  custom
}) => {
  return (
    <motion.p animate="visible" className={className} custom={custom} variants={VARIANTS}>
      {children}
    </motion.p>
  );
};

export default TransitionWithDelayWrapper;

import { useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Common } from '@thecvlb/design-system';

const activeArrowClassName = 'hidden md:block text-primary p-3.5 mx-1 border rounded-full';
const inactiveArrowClassName = 'hidden md:block text-gray-200 p-3.5 mx-1 border rounded-full';

export function LeftArrow() {
  const { items, isItemVisible, scrollPrev } = useContext(VisibilityContext);
  const listOfItems = items.toItems();
  const isFirstItemVisible = isItemVisible(listOfItems[0]);

  return !isFirstItemVisible ? (
    <button
      className={!isFirstItemVisible ? activeArrowClassName : inactiveArrowClassName}
      data-testid="left_arrow"
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <Common.Icon name="arrow-left" />
    </button>
  ) : null;
}

export function RightArrow() {
  const { items, isItemVisible, scrollNext } = useContext(VisibilityContext);
  const listOfItems = items.toItems();
  const isLastItemVisible = isItemVisible(listOfItems[listOfItems.length - 1]);

  return !isLastItemVisible ? (
    <button
      className={!isLastItemVisible ? activeArrowClassName : inactiveArrowClassName}
      data-testid="right_arrow"
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <Common.Icon name="arrow-right" />
    </button>
  ) : null;
}

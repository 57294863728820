import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import {
  AddFeedbackReviewReqProps,
  AddFeedbackReviewResProps,
  AddPlatformReviewReqProps,
  AddPlatformReviewResProps
} from './review.types';

export const reviewApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addFeedbackReview: build.mutation<AddFeedbackReviewResProps, AddFeedbackReviewReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: `/review/feedback`
      })
    }),
    addPlatformReview: build.mutation<AddPlatformReviewResProps, AddPlatformReviewReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: `/review/platform`
      })
    })
  }),
  reducerPath: 'reviewApi',
  tagTypes: ['Review']
});

export const { useAddPlatformReviewMutation, useAddFeedbackReviewMutation } = reviewApi;

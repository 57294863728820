import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';

import { ElationPrescriptionItem } from 'models/prescriptions.types';

export const handleDatePhrase = (prescriptionData: ElationPrescriptionItem, format: DateFormat) => {
  const orderedDate =
    prescriptionData?.orderedDate ||
    prescriptionData?.createdDate ||
    prescriptionData?.created_date ||
    false;
  if (orderedDate) {
    return `Ordered ${dayjs(orderedDate).format(format)}`;
  }
  return 'Ordered';
};

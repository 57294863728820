import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTitle } from 'react-use';

import { useGetCartQuery } from 'services/shop/shop';

import BreadCrumbs from 'pages/shop/Shop/components/BreadCrumbs';

const Shop = () => {
  useTitle('LifeMD: Shop');
  useGetCartQuery();

  return (
    <div className="h-full md:rounded-2xl md:bg-white md:p-8 md:shadow">
      <BreadCrumbs />
      <Outlet />
    </div>
  );
};

export default Shop;

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { notifyError } from 'shared/Toast/Toast';

import useWidth from 'hooks/useWidth';

import { ACCEPT_CHAT_MIMETYPES, fileValidator } from './chatForm.settings';
import { ChatFormProps } from './chatForm.types';

const ChatForm: React.FC<ChatFormProps> = ({ onSubmit, wrapperClasses, disabledSubmit }) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [previews, setPreviews] = useState<{ image: string; name: string; type: string }[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [message, setMessage] = useState<string>('');
  const { width } = useWindowSize();
  const { isMobile } = useWidth();

  const limitAvailableFiles = 10;

  const onClosePreview = () => {
    setPreviews([]);
    setFiles([]);
  };

  const reset = () => {
    onClosePreview();
    setMessage('');
  };

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles) return;
    const { isValidFile, errVal } = fileValidator(selectedFiles);
    if (!isValidFile) {
      if (errVal) notifyError(errVal);
      return false;
    }

    [...files, ...selectedFiles].map(async (file) => {
      let convertedFile = file;

      if (file.type === 'image/heic') {
        const heic2any = (await import('heic2any')).default;
        const imageBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg'
        });

        // Create a new File object with the converted image
        convertedFile = new File([imageBlob as Blob], file.name.replace('.HEIC', '.jpeg'), {
          type: 'image/jpeg'
        });
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews((prev) =>
          [
            ...prev,
            { image: reader.result as string, name: convertedFile.name, type: convertedFile.type }
          ]
            .filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i)
            .slice(0, limitAvailableFiles)
        );
        setFiles((prev) =>
          [...prev, convertedFile]
            .filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i)
            .slice(0, limitAvailableFiles)
        );
        e.target.value = '';
      };

      reader.readAsDataURL(convertedFile);
    });
  };

  const onChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const removePreview = (index: number) => {
    setPreviews((prev) => prev.filter((el, i) => i !== index));
    setFiles((prev) => prev.filter((el, i) => i !== index));
  };

  const handleOnSubmit = () => {
    if (disabledSubmit) return;
    onSubmit(message, files, reset);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleOnSubmit();
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${ref.current.scrollHeight || 28 + 1}px`;
    }
  }, [width, message]);

  return (
    <div className={wrapperClasses}>
      <div
        className={classNames(
          'border-gray-200 bg-white p-2 md:p-1',
          !isMobile ? 'rounded-xl border shadow-sm' : 'border-t'
        )}
        data-testid="chat_form"
      >
        {previews.length > 0 && (
          <div className="mb-2 flex gap-3 overflow-auto text-gray-700 md:mx-2 md:mb-0">
            {previews.map((el, i) => (
              <div
                className={classNames(
                  'relative mb-1 mt-1.5 flex-none rounded-xl md:mb-2.5 md:mt-2',
                  {
                    'mb-2.5': !isMobile,
                    'flex max-w-[150px] items-center gap-1 truncate bg-green-100 p-3 text-mBase font-medium md:text-base':
                      !el.type.includes('image')
                  }
                )}
                key={nanoid()}
              >
                <button
                  className={classNames(
                    'rounded-full border border-white bg-gray-700 p-0.5 text-white',
                    {
                      'absolute -right-1 -top-1.5': el.type.includes('image')
                    }
                  )}
                  type="button"
                  onClick={() => removePreview(i)}
                >
                  <Common.Icon className="size-3" name="close" />
                </button>
                {el.type.includes('image') ? (
                  <img
                    alt="preview"
                    className="size-[52px] truncate rounded-xl object-cover shadow-sm"
                    src={el.image}
                  />
                ) : (
                  <>
                    <Common.Icon className="size-6 flex-none" name="articles" />
                    <span className="truncate rounded-xl object-cover">{el.name}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="flex items-center">
          <div className="flex flex-1 items-center">
            <label className="cursor-pointer border-r p-2 text-gray md:p-1.5 md:pl-2 md:pr-3">
              <Common.Icon name="camera" />
              <input
                accept={ACCEPT_CHAT_MIMETYPES.join(',')}
                className="hidden"
                data-testid="file_upload_clip"
                type="file"
                multiple
                onChange={onChangeFile}
              />
            </label>
            <textarea
              autoFocus={!isMobile}
              className="max-h-[300px] flex-1 resize-none border-none py-0.5 text-gray-700 outline-none placeholder:text-gray focus:ring-0 md:text-base"
              data-testid="message_field"
              placeholder="Message..."
              ref={ref}
              rows={1}
              value={message}
              onChange={onChangeTextArea}
              onKeyPress={handleKeyPress}
            />
          </div>
          <button
            className="ml-2.5 rounded-lg bg-secondary p-1.5 px-3.5 text-white hover:bg-secondary-600 disabled:bg-gray-200 disabled:text-gray md:rounded-lg"
            data-testid="send_msg"
            disabled={!(message.trim().length || files.length) || disabledSubmit}
            onClick={() => handleOnSubmit()}
          >
            <Common.Icon className="size-6 md:size-5" name="send" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatForm;

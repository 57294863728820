// import { useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { Props } from './preInsurance.types';

const PreInsurance: React.FC<Props> = ({
  onSelect,
  loading,
  className = 'md:bg-white md:shadow',
  showSubheader = false,
  ppType = 'glp-1'
}) => {
  const isRegularWMUser = ppType === 'glp-1';
  // const [selectedAnswer, setSelectedAnswer] = useState<boolean | null>(null);

  const handleClick = (type: boolean) => async () => {
    // setSelectedAnswer(type);
    await onSelect(type);
    // setSelectedAnswer(null);
  };

  const buttonClassName =
    'text-start w-full rounded-lg border border-gray-300 p-6 md:max-w-xs hover:bg-gray-50 transition-all bg-white';

  return (
    <div className="flex w-full flex-col gap-4 md:gap-8">
      <div className={classNames('flex flex-col gap-4 max-md:h-full md:gap-8 md:p-8', className)}>
        <div className="flex flex-col gap-2">
          {showSubheader && (
            <p className="text-primary-700 md:mx-auto md:max-w-xs">
              Your plan will cover appointments with your provider and access to the weight loss
              program. The cost of medication is not included in the LifeMD weight loss program.
            </p>
          )}
          <h4 className="font-bold text-primary-700 md:mx-auto md:max-w-xs">
            Would you like to check if your medical insurance covers{' '}
            {isRegularWMUser ? 'GLP-1' : 'Triple Therapy'} prescriptions?
          </h4>
        </div>
        <div className="flex w-full flex-col items-center gap-4 max-md:py-4">
          <div
            className={classNames('w-full md:max-w-xs', {
              // 'progress-wrapper': loading && selectedAnswer === true
            })}
          >
            <button className={buttonClassName} disabled={loading} onClick={handleClick(true)}>
              Yes
            </button>
          </div>
          <div
            className={classNames('w-full md:max-w-xs', {
              // 'progress-wrapper': loading && selectedAnswer === false
            })}
          >
            <button className={buttonClassName} disabled={loading} onClick={handleClick(false)}>
              No
            </button>
          </div>
        </div>
        <Common.Alert type="info" colorableBackground>
          Your insurance will not be billed by LifeMD
        </Common.Alert>
      </div>
    </div>
  );
};

export default PreInsurance;

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import PaymentIcon from 'shared/PaymentIcon';

import { getCardTypeName } from './paymentMethodCard.settings';
import { PaymentMethodCardProps } from './paymentMethodCard.types';

import PaypalSvg from 'assets/icons/paypal.svg?react';

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  data,
  onEdit,
  onPickAsFavorite,
  isLoading,
  setIsExtended
}) => {
  const isCardType = data.paymentMethod === 'credit_card';
  const isSelected = data.isDefault;
  const handlePaymentType = () => {
    return data.paymentMethod === 'credit_card' ? 'card' : 'PayPal';
  };

  const handleClickSelected = () => {
    !isSelected && onPickAsFavorite?.(data.paymentProfileId);
  };

  const handleCLickEdit = () => {
    onEdit?.(data.paymentProfileId);
  };

  let isCardExpired = false;
  if (data.paymentMethod !== 'paypal_account') {
    const cardExpirationDate = data?.expirationMonth + '/' + data?.expirationYear;
    const days = dayjs(cardExpirationDate, 'M/YYYY').endOf('month').diff(dayjs(), 'days');
    isCardExpired = days < 30;
  }
  return (
    <>
      <FadeWrapper
        className={classNames('flex items-center gap-4 rounded-2xl border border-gray-200 p-4', {
          'bg-blue-50': isSelected && !setIsExtended,
          '!border-red !bg-red-50': isCardExpired && isSelected && !setIsExtended
        })}
      >
        <div
          className={classNames(
            'flex h-11 w-[60px] flex-none items-center rounded-lg px-4 py-2',
            isCardType ? 'bg-primary-100' : 'bg-[#FEC53B]'
          )}
        >
          {isCardType ? (
            <PaymentIcon cardType={data.cardType} />
          ) : (
            <PaypalSvg className="h-auto max-w-full" />
          )}
        </div>
        <div className="flex grow flex-wrap gap-2 max-md:flex-col md:items-center md:justify-between">
          <div className="flex flex-col gap-1">
            {isCardType ? (
              <>
                <span className="first-letter:uppercase">
                  {`${getCardTypeName(data)} ending in `}
                  <span className="font-bold">
                    {data.cardNumber.substring(data.cardNumber.length - 4)}
                  </span>
                </span>
                <span
                  className={classNames('flex gap-1', isCardExpired ? 'text-red' : 'text-gray')}
                >
                  {isCardExpired && <Common.Icon name="error-outline" />}
                  Expires&nbsp;
                  {`${data.expirationMonth}/${String(data.expirationYear).substring(
                    String(data.expirationYear).length - 2
                  )}`}
                </span>
              </>
            ) : (
              <>
                <span>Paypal</span>
                <span className="max-w-[150px] truncate text-gray">{data.email}</span>
              </>
            )}
          </div>
          <div className="flex flex-none gap-2 max-md:flex-col md:gap-4">
            {setIsExtended ? (
              <Common.Button
                color="white-alt"
                dataTestId="update_payment_btn"
                disabled={isLoading}
                size="md"
                type="button"
                fullWidthOnMobile
                onClick={setIsExtended}
              >
                Update payment
              </Common.Button>
            ) : (
              <>
                <Common.Button
                  className={classNames(
                    isSelected ? 'cursor-default !bg-blue-100 !text-blue-500' : ''
                  )}
                  color="white-alt"
                  dataTestId={`${isSelected ? 'selected' : 'use_card'}_btn`}
                  disabled={isLoading}
                  isLoading={isLoading && !isSelected}
                  preIcon={isSelected ? 'check' : undefined}
                  size="md"
                  type="button"
                  fullWidthOnMobile
                  onClick={handleClickSelected}
                >
                  {isSelected ? 'Selected' : `Use ${handlePaymentType()}`}
                </Common.Button>
                {!!onEdit && (
                  <Common.Button
                    color="white"
                    dataTestId="edit_btn"
                    disabled={isLoading}
                    preIcon="pencil"
                    size="md"
                    type="button"
                    fullWidthOnMobile
                    onClick={handleCLickEdit}
                  >
                    Edit
                  </Common.Button>
                )}
              </>
            )}
          </div>
        </div>
      </FadeWrapper>
    </>
  );
};

export default PaymentMethodCard;

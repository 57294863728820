import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { CreditCardProps } from './creditCard.types';

const CreditCard: React.FC<CreditCardProps> = ({
  ownerName,
  provider,
  additionalClassName = ''
}) => {
  return (
    <div
      className={classNames('credit-card-alt-wrapper', additionalClassName)}
      data-testid="credit-card-wrapper"
    >
      <div className="mb-2 flex items-center justify-between px-5">
        <Common.Logo className="h-6 w-[83px]" name="logo-inverse" />
        <h3 className="text-mXs text-white/60">Powered by {provider}</h3>
      </div>
      <div className="bg-black/30 px-4 py-2">
        <h3 className="text-center text-mBase font-semibold text-white">
          Medication Savings Program
        </h3>
      </div>
      <h3 className="mt-2 text-center text-mLg font-bold text-white">{ownerName}</h3>
      <h4 className="my-1 text-center text-mXs leading-none text-white/60">Member</h4>
      <div className="px-[10.6px]">
        <div className="mt-2 flex items-center justify-between gap-2 rounded border border-secondary px-[17px] py-2 text-secondary">
          <span className="text-mSm font-semibold uppercase">
            id: <span className="text-white">L500381277</span>
          </span>
          <span className="text-mSm font-semibold uppercase">
            grp: <span className="text-white">LFMD85003</span>
          </span>
          <span className="text-mSm font-semibold uppercase">
            bin: <span className="text-white">900020</span>
          </span>
        </div>
      </div>
      <h4 className="mt-2 text-center text-m2xs font-medium text-white/60">
        This card is not insurance and does not expire.
      </h4>
    </div>
  );
};

export default CreditCard;

import { SummaryCardProps } from './summaryCard.types';

const SummaryCard: React.FC<SummaryCardProps> = ({ items, total }) => {
  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-secondary-50 p-6">
      {items.map((item, index) => (
        <div className="flex flex-col gap-1" key={index}>
          <div
            className="flex grow items-center justify-between text-mBase font-bold text-primary-700"
            data-testid="product_in_summary"
          >
            <span data-testid="product_name_in_summary">{item.product.name}</span>
            <span data-testid="product_price_in_summary">${item.product.unitPrice}</span>
          </div>
          {item.product.type && (
            <span className="block text-mSm text-primary-700 md:text-sm">{item.product.type}</span>
          )}
        </div>
      ))}
      <hr className="w-full border-secondary" />
      <div className="flex items-center justify-between text-mLg font-bold text-primary-700">
        <span data-testid="total_header_in_summary">Total</span>
        <span data-testid="total_price_in_summary">${total}</span>
      </div>
    </div>
  );
};

export default SummaryCard;

import { useEffect, useState } from 'react';

import { selectOrchestrate } from 'store';

import { useAppSelector } from 'hooks';

export const useGetSelectedItem = (question: string) => {
  const { mif_qa: mifQA } = useAppSelector(selectOrchestrate);
  const [value, setValue] = useState<string | number | boolean | string[]>();

  useEffect(() => {
    const item = mifQA.find((q) => q.question === question)?.answer ?? '';

    if (item !== undefined) {
      setValue(item);
    }
  }, []);

  return { value, setValue };
};

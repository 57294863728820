import { Common } from '@thecvlb/design-system';

import { SkipUploadBodyPhotoProps } from './skipUploadBodyPhoto.types';

const SkipUploadBodyPhoto: React.FC<SkipUploadBodyPhotoProps> = ({ isOpen, onClose, isAsync }) => {
  return (
    <Common.Modal
      description={
        isAsync
          ? 'Your provider will not be able to review your case until you’ve uploaded your body photo.'
          : 'Your appointment may be cancelled if your body photo is not uploaded prior to your appointment.'
      }
      isOpen={isOpen}
      size="base"
      title={
        isAsync
          ? 'Important: Body photo is required to prescribe your medication'
          : 'Important: Body photo is required prior to your appointment.'
      }
    >
      <div className="flex justify-end gap-2 max-md:flex-col-reverse">
        <Common.Button color="white-alt" fullWidthOnMobile onClick={() => onClose(false)}>
          Upload photo
        </Common.Button>
        <Common.Button color="blue" fullWidthOnMobile onClick={() => onClose(true)}>
          Continue without uploading
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default SkipUploadBodyPhoto;

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { RemovableTagsProps } from '../symptomsSelector.types';

const RemovableTags: React.FC<RemovableTagsProps> = ({ tags, onTagClick, className }) => {
  return (
    <div
      className={classNames('mt-4 flex flex-wrap gap-2', className)}
      data-testid="selected_items_list"
    >
      {tags.map((tag) => (
        <button data-testid="selected_item" key={tag.id} onClick={() => onTagClick(tag)}>
          <Common.ColorTag
            className="h-[25px] text-sm font-semibold first-letter:uppercase"
            color="primary"
            icon="close"
            text={tag.label}
          />
        </button>
      ))}
    </div>
  );
};

export default RemovableTags;

import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import classNames from 'classnames';

import ReviewItem from 'features/ReviewItem';
import { ReviewItemProps } from 'features/ReviewItem/reviewItem.types';

import useWidth from 'hooks/useWidth';

interface StaticReviewsListProps {
  limit?: number;
  reviews: ReviewItemProps[];
}

const StaticReviewsList: React.FC<StaticReviewsListProps> = ({ reviews, limit = 3 }) => {
  const { isMobile } = useWidth();
  const [activeIndex, setActiveIndex] = useState(1);

  const getCardClassName = (i: number) =>
    classNames('mx-1.5 h-full w-auto md:max-w-[312px]', {
      'ml-4': i === 0,
      'mr-4': i === 2
    });

  const reviewsToShow = reviews.filter((_, index) => index < limit);

  return (
    <>
      {isMobile ? (
        <Carousel
          centerSlidePercentage={66}
          className="alt-scroll flex max-md:-mx-2"
          selectedItem={activeIndex}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          swipeScrollTolerance={20}
          centerMode
          preventMovementUntilSwipeScrollTolerance
          swipeable
          onChange={setActiveIndex}
        >
          {reviewsToShow.map((review, i) => (
            <div className={getCardClassName(i)} key={i + review.rating}>
              <ReviewItem {...review} />
            </div>
          ))}
        </Carousel>
      ) : (
        <div className="hidden-scrollbar flex w-full max-w-screen-lg gap-4 overflow-x-auto md:pb-4">
          {reviewsToShow.map((review, i) => (
            <div className="max-w-[330px] shadow-lg" key={i + review.rating}>
              <ReviewItem {...review} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default StaticReviewsList;

import { useEffect, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';

import { FeatureFlag } from 'utils/enums';

export const useDarkTheme = () => {
  const isEnabledDarkMode = useFlag(FeatureFlag.DarkMode);
  const initialTheme =
    isEnabledDarkMode &&
    (localStorage.theme === 'dark' || window.matchMedia('(prefers-color-scheme: dark)').matches)
      ? 'dark'
      : 'light';

  const [theme, setTheme] = useState<'dark' | 'light'>(initialTheme);

  const toggleTheme = () => {
    if (!isEnabledDarkMode) return;
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.documentElement.classList.toggle('dark', theme === 'dark');
  }, [theme]);

  return { theme, toggleTheme };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LabTestsState, MemberPricingItemProps } from './labTests.types';

const initialState: LabTestsState = {
  memberPricing: []
};

const labTestsSlice = createSlice({
  initialState,
  name: 'labTests',
  reducers: {
    setMemberPricing: (state, action: PayloadAction<MemberPricingItemProps[]>) => {
      state.memberPricing = action.payload;
    }
  }
});

export const { setMemberPricing } = labTestsSlice.actions;

export default labTestsSlice.reducer;

import TagManager from 'react-gtm-module';

import { MailingAddress } from 'models/user.types';

export function createGtmEvent(
  event: string,
  user: Record<string, unknown>,
  order: Record<string, unknown> | null = {}
) {
  const mailingAddress = user.mailingAddress as MailingAddress;
  function getGender(genderCode: string | unknown) {
    if (genderCode == '0') {
      return 'Male';
    } else if (genderCode == '1') {
      return 'Female';
    } else if (genderCode == '2') {
      return 'Unknown';
    }

    return genderCode;
  }
  try {
    TagManager.dataLayer({
      dataLayer: {
        current_event_ts: new Date().toISOString(),
        customer_birth_date: user.dob,
        customer_email: user.email,
        customer_first_name: user.firstName,
        customer_gender: getGender(user.sexAtBirth),
        customer_last_name: user.lastName,
        customer_phone: user.phoneNumber,
        customer_shipping_address1: mailingAddress?.address,
        customer_shipping_address2: '',
        customer_shipping_city: mailingAddress?.city,
        customer_shipping_state: mailingAddress?.state,
        customer_shipping_zip: mailingAddress?.zipCode,
        // event details
        event: event,
        // order and product details
        order_coupon_code: order?.couponCode || '',
        order_currency: 'USD',
        order_id: order?.orderId || '',
        order_total: order?.orderTotal || '',
        order_ts: order?.orderTs || '',
        // patient/customer details
        patient_id: user._id,
        product_brand: 'LifeMD',
        product_category: 'Membership',
        product_id: order?.productId || '',
        product_name: order?.productName || '',
        product_quantity: 1
      }
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

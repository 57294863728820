import { useEffect, useRef, useState } from 'react';
import { Modifiers } from 'react-day-picker';
import { useClickAway, useSessionStorage, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  useLazyGetAvailableDatesQuery as useLazyGetGlobalAvailableDatesQuery,
  useLazyGetGuestCombinedAvailabilityQuery
} from 'services/auth/auth';
import {
  useGetProviderQuery,
  useLazyGetAvailableDatesQuery,
  useLazyGetAvailableTimesQuery,
  useLazyGetCombinedAvailabilityQuery
} from 'services/providers/providers';
import {
  CombinedTimeSlots,
  GetAvailableDatesResProps,
  GetAvailableTimesResProps,
  GetAvailableTimesSlotsItemProps,
  GetCombinedAvailabilityResProps
} from 'services/providers/providers.types';

import { selectAppointments, selectProvider, selectUser } from 'store';

import Calendar from 'features/Calendar';
import Picker from 'features/Picker';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';
import SchedulingDaySlider from 'widgets/SchedulingDaySlider';
import { getDaysList } from 'widgets/SchedulingDaySlider/schedulingDaySlider.settings';

import { useAppSelector } from 'hooks';
import { useGetInitialDatesWithSlots } from 'hooks/useGetInitialDatesWithSlots';
import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';
import {
  checkCompletedOnboardingAppt,
  checkWmNotCompletedOnboarding,
  findDisabledDates,
  getUpcomingAppointments,
  handleRequestCatch
} from 'utils/helpers';

import { ProviderType, SearchDatesParams } from 'models/appointment.types';

import { DateAndTimeProps } from './dateAndTime.types';

dayjs.extend(relativeTime);

const startMonth = new Date();

const DateAndTime: React.FC<DateAndTimeProps> = ({
  appointmentTypeId,
  isReschedule = false,
  updateStartDate,
  onSelect,
  accessToken,
  doctorId,
  loading = false,
  isSignUp = false,
  initialDate,
  appointmentId,
  providerType,
  onboardingUserData,
  bufferPeriodDays,
  planPricePointId
}) => {
  const timezone = dayjs.tz?.guess();

  // combined slots per day
  const [getCombinedSlots, { isFetching: isCombinedLoading }] =
    useLazyGetCombinedAvailabilityQuery();
  const [getAvailableTimes, { isFetching: isTimesLoading }] = useLazyGetAvailableTimesQuery();
  const [getCombinedAvailability, { isFetching }] = useLazyGetGuestCombinedAvailabilityQuery();

  // free dates
  const [getAvailableDatesForProvider, { isFetching: isFetchingAvailableDates }] =
    useLazyGetAvailableDatesQuery();
  const [getGlobalDates, { isFetching: isFetchingGlobalDates }] =
    useLazyGetGlobalAvailableDatesQuery();

  const { profileImage, displayName, userId } = useAppSelector(selectProvider);
  const { activePlanCode, isUnlimitedPlan, state, activePlanId } = useAppSelector(selectUser);
  const { appointmentsList } = useAppSelector(selectAppointments);
  const [sessionId] = useSessionStorage<string>('session-id', '');
  useGetProviderQuery({ providerId: doctorId });

  const ref = useRef(null);
  const { isMobile } = useWidth();

  const [isShowCalendar, toggleShowCalendar] = useToggle(false);
  const [provider, setProvider] = useState<ProviderType>(
    providerType || ((!!doctorId && doctorId === userId) || isSignUp ? 'my' : 'any')
  );
  const latestInitialDate = (
    dayjs(initialDate).isBefore(dayjs()) ? dayjs() : dayjs(initialDate)
  ).format(DateFormat.YYYY_MM_DD);

  const DEFAULT_DATE =
    latestInitialDate && dayjs(latestInitialDate).isValid()
      ? latestInitialDate
      : bufferPeriodDays
        ? dayjs().add(bufferPeriodDays, 'days').format(DateFormat.YYYY_MM_DD)
        : '';

  const [disabledDates, setDisabledDates] = useState({ any: [], my: [] });
  const [selectedDay, setSelectedDay] = useState<string>(DEFAULT_DATE);
  const [timeSlots, setTimeSlots] =
    useState<(GetAvailableTimesSlotsItemProps | CombinedTimeSlots)[]>();
  const [slotsData, setSlotsData] = useState<
    { displayName: string; doctorId: string; label: string; value: string; valueEnd: string }[]
  >([]);

  const completedOnboarding = checkCompletedOnboardingAppt(appointmentsList);
  const wmNotCompletedOnboarding = checkWmNotCompletedOnboarding(
    activePlanCode,
    completedOnboarding
  );

  const {
    myProviderFreeDates,
    anyProvidersFreeDates,
    loading: isGettingInitialDates,
    myProviderDisabledDates,
    anyProvidersDisabledDates
  } = useGetInitialDatesWithSlots({
    initialDate: isReschedule && wmNotCompletedOnboarding ? latestInitialDate : undefined,
    accessToken,
    appointmentTypeId,
    doctorID: userId || doctorId,
    isReschedule,
    planId: activePlanId || onboardingUserData?.planID || '',
    state: state || onboardingUserData?.state || '',
    isAuthorized: true,
    ...(!!planPricePointId && { planPricePointId })
  });

  const upcomingAppointmentsList = getUpcomingAppointments(appointmentsList);

  const currentAppointment = upcomingAppointmentsList.find((item) => item._id === appointmentId);

  const onTimeSelect = (
    value: string,
    valueEnd: string,
    combinedDoctorId?: string,
    combinedDisplayName?: string
  ) => {
    updateStartDate('time', value, valueEnd, combinedDoctorId, combinedDisplayName);
  };

  const handleDayClick = (day: Date, modifiers: Modifiers) => {
    if (modifiers.disabled || loading || isCombinedLoading || !day) {
      return;
    }
    const newDate = dayjs(day).format(DateFormat.YYYY_MM_DD);
    setSelectedDay(newDate);
    toggleShowCalendar(false);
  };

  const handleGetAvailableDatesThen = (
    res: GetAvailableDatesResProps,
    periodStart: string,
    periodEnd: string
  ) => {
    const daysWithNoSlots = findDisabledDates(
      res.data.map((d) => d.date),
      periodStart,
      periodEnd
    );
    setDisabledDates((prev) => ({ ...prev, [provider]: daysWithNoSlots }));
  };

  const handleSelect = (v: string) => {
    if (v === 'calendar') {
      toggleShowCalendar(!isShowCalendar);
    } else {
      toggleShowCalendar(false);
      setSelectedDay(v);
    }
  };

  const handleUpdatedTimeSlots = () => {
    setSlotsData(
      Array.isArray(timeSlots)
        ? timeSlots
            .map(({ startTime, endTime, ...rest }) => ({
              displayName: (rest as CombinedTimeSlots)?.displayName,
              doctorId: (rest as CombinedTimeSlots)?.doctorId,
              label: `${dayjs(startTime).format(DateFormat.hh_mma_z)}`,
              value: startTime,
              valueEnd: endTime
            }))
            .filter((e) => (isSignUp ? dayjs(e.value).diff(dayjs(), 'hours') >= 2 : true))
        : []
    );
  };

  const getDaysWithSlots = (date: Date) => {
    const formattedDate = dayjs(date).isBefore(dayjs()) ? dayjs() : dayjs(date);
    const previousDisabledDates = disabledDates;
    setDisabledDates((prev) => ({
      ...prev,
      [provider]: {
        from: formattedDate.toDate(),
        to: dayjs(formattedDate).endOf('month').toDate()
      }
    }));
    const startDate = formattedDate.format(DateFormat.YYYY_MM_DD);
    const endDate = dayjs(formattedDate).endOf('month').format(DateFormat.YYYY_MM_DD);
    if (provider === 'my') {
      getAvailableDatesForProvider({
        appointmentTypeId,
        doctorId: userId || doctorId,
        endDate,
        startDate,
        timezone,
        ...(accessToken && { accessToken })
      })
        .unwrap()
        .then((r) => handleGetAvailableDatesThen(r, startDate, endDate))
        .catch(() => {
          setDisabledDates((prev) => ({ ...prev, [provider]: previousDisabledDates[provider] }));
        });
    } else {
      getGlobalDates({
        appointmentTypeId,
        endDate,
        isReschedule,
        planId: activePlanId || onboardingUserData?.planID || '',
        startDate,
        state: state || onboardingUserData?.state || '',
        timezone,
        ...(accessToken && { accessToken })
      })
        .unwrap()
        .then((r) => handleGetAvailableDatesThen(r, startDate, endDate))
        .catch(() => {
          setDisabledDates((prev) => ({ ...prev, [provider]: previousDisabledDates[provider] }));
        });
    }
  };

  useClickAway(ref, () => {
    setTimeout(() => {
      if (isShowCalendar) {
        toggleShowCalendar(false);
      }
    }, 100);
  });

  const handleGetSlotsThen = ({
    data
  }: GetAvailableTimesResProps | GetCombinedAvailabilityResProps) => setTimeSlots(data.slots);

  const handleGetAvailableTimes = () => {
    const id = isSignUp ? doctorId : userId;
    !!id &&
      getAvailableTimes({
        appointmentTypeId,
        date: selectedDay,
        doctorId: id,
        timezone,
        ...(isReschedule && { isReschedule }),
        ...(isSignUp && { isSignUp }),
        ...(accessToken && { accessToken })
      })
        .unwrap()
        .then(handleGetSlotsThen)
        .catch(handleRequestCatch);
  };

  const handleGetCombinedData = () => {
    const date = dayjs(selectedDay.toString()).format(DateFormat.YYYY_MM_DD);
    if (isSignUp) {
      getCombinedAvailability({
        appointmentDate: date,
        appointmentTypeId,
        doctorLicenseState: onboardingUserData?.state ?? '',
        patientTimezone: timezone,
        planId: onboardingUserData?.planID ?? '',
        sessionId,
        ...(planPricePointId && { planPricePointId })
      })
        .unwrap()
        .then(handleGetSlotsThen)
        .catch(handleRequestCatch);
    } else {
      getCombinedSlots({
        appointmentTypeId,
        date,
        ...(isReschedule && { isReschedule }),
        timezone
      })
        .unwrap()
        .then(handleGetSlotsThen)
        .catch(handleRequestCatch);
    }
  };

  const handleGetTimes = () => {
    if (selectedDay && appointmentTypeId) {
      provider === 'my' ? handleGetAvailableTimes() : handleGetCombinedData();
    }
  };

  const handleSwitchProviderType = () => {
    // little comments on how this function works:
    // 1. clear slots
    setTimeSlots([]);
    toggleShowCalendar(false);
    const intersectionDates = myProviderFreeDates.filter((date) =>
      anyProvidersFreeDates.includes(date)
    );
    // if there are no intersection dates, this means that we need to change date and then slots will be refe
    if (!intersectionDates.includes(selectedDay)) {
      const slotsToUpdate = provider === 'my' ? myProviderFreeDates : anyProvidersFreeDates;
      const slot =
        initialDate && slotsToUpdate.includes(initialDate) ? initialDate : slotsToUpdate[0];
      setSelectedDay(slot);
      // 3. if date is in the intersection of both providers, this means that useEffect handleGetTimes will not trigger refetch so we have to do it here
    } else {
      handleGetTimes();
    }
  };

  useEffect(handleGetTimes, [selectedDay, appointmentTypeId]);
  useEffect(handleUpdatedTimeSlots, [timeSlots]);
  useEffect(handleSwitchProviderType, [provider]);
  useEffect(() => {
    if (myProviderFreeDates.length && provider === 'my') {
      setSelectedDay(
        initialDate && myProviderFreeDates.includes(initialDate)
          ? initialDate
          : myProviderFreeDates[0]
      );
    } else if (anyProvidersFreeDates.length && provider === 'any') {
      setSelectedDay(
        initialDate && anyProvidersFreeDates.includes(initialDate)
          ? initialDate
          : anyProvidersFreeDates[0]
      );
    }
  }, [myProviderFreeDates, anyProvidersFreeDates]);
  useEffect(() => {
    setDisabledDates({
      any: anyProvidersDisabledDates as unknown as never[],
      my: myProviderDisabledDates as unknown as never[]
    });
  }, [myProviderDisabledDates, anyProvidersDisabledDates]);

  const providerNameClassName = (value: ProviderType) =>
    classNames(value === 'my' ? 'font-semibold' : 'font-bold');
  const providerRoleClassName = 'text-mSm font-medium text-gray md:text-sm';
  const getProviderCardClassName = (value: ProviderType) =>
    classNames(
      'flex-grow cursor-pointer basis-1/2 py-3.5 px-6 border rounded-2xl disabled:opacity-30 disabled:cursor-default',
      value === provider && 'border-primary-300 bg-primary-100 border-2'
    );

  const getDates = () => {
    let arrOfDates = provider === 'my' ? myProviderFreeDates : anyProvidersFreeDates;
    if (bufferPeriodDays) {
      arrOfDates = arrOfDates.filter((e) =>
        dayjs(e).isAfter(dayjs().add(bufferPeriodDays, 'days'))
      );
    }
    if (wmNotCompletedOnboarding && currentAppointment && isReschedule) {
      arrOfDates = arrOfDates.filter((e) =>
        dayjs(e).add(1, 'day').isAfter(currentAppointment.appointmentTime?.startTime)
      );
    }

    return arrOfDates.slice(0, 5) ?? [];
  };

  const mixedLoading =
    isTimesLoading || loading || isCombinedLoading || isFetching || isGettingInitialDates;

  const datesList = getDaysList(getDates());

  const getDisabledDates = () => {
    return [
      {
        before: bufferPeriodDays ? dayjs().add(bufferPeriodDays, 'days').toDate() : dayjs().toDate()
      },
      provider === 'my'
        ? myProviderDisabledDates.map((d) => dayjs(d).toDate())
        : anyProvidersDisabledDates.map((d) => dayjs(d).toDate()),
      Array.isArray(disabledDates[provider])
        ? disabledDates[provider].map((d) => dayjs(d).toDate())
        : disabledDates[provider]
    ].flat();
  };

  const params: SearchDatesParams =
    provider === 'my'
      ? {
          appointmentTypeId,
          doctorId,
          provider: 'my'
        }
      : {
          appointmentTypeId,
          isReschedule,
          planId: activePlanId,
          provider: 'any',
          state
        };

  return (
    <SlideAnimateWrapper>
      <Loader isVisible={mixedLoading} />
      <div
        className={classNames('mx-auto max-w-[624px] md:rounded-xl md:bg-white md:px-8', {
          'md:py-8 md:shadow': isReschedule
        })}
        data-testid="select_date_time_block"
      >
        {!isSignUp && (
          <>
            {isReschedule && !isMobile && (
              <div className="mb-4 flex flex-col items-center gap-2 md:mb-8 md:text-primary-700">
                <h1 className="text-2xl font-bold">Reschedule appointment</h1>
                <p>Select a date and time below to reschedule</p>
              </div>
            )}
            {!!userId && (
              <div className="mb-4 flex flex-col items-stretch justify-between gap-3 self-stretch md:mb-8 md:flex-row md:gap-4">
                <button
                  className={getProviderCardClassName('my')}
                  disabled={mixedLoading}
                  onClick={() => setProvider('my')}
                >
                  <div className="flex items-center gap-2" data-testid="my_doc">
                    <Common.ProfileImage src={profileImage} />
                    <div>
                      <h4 className={providerNameClassName('my')}>{displayName}</h4>
                      <span className={providerRoleClassName}>My provider</span>
                    </div>
                  </div>
                </button>
                <button
                  className={getProviderCardClassName('any')}
                  disabled={mixedLoading}
                  onClick={() => setProvider('any')}
                >
                  <div className="flex items-center gap-2" data-testid="any_available_doc">
                    <Common.Icon className="size-9 text-primary-600" name="doctor" />
                    <div>
                      <h4 className={providerNameClassName('any')}>Any available provider</h4>
                      <span className={providerRoleClassName}>Typically available in a day</span>
                    </div>
                  </div>
                </button>
              </div>
            )}
            {isReschedule && isMobile && (
              <div className="mb-4 flex flex-col items-center gap-2 md:mb-8 md:text-primary-700">
                <p>Select a date and time below to reschedule</p>
              </div>
            )}
          </>
        )}
        {!isGettingInitialDates && datesList.length ? (
          <div
            className="relative flex max-h-min flex-col justify-center gap-8 md:gap-6"
            data-testid="day_slide_picker"
          >
            <div>
              {!isReschedule && (
                <h3 className="mb-2 text-mBase font-semibold text-gray-700 md:hidden">
                  Choose time & date
                </h3>
              )}
              <SchedulingDaySlider
                additionalDisabledDates={getDisabledDates()}
                dates={datesList}
                isAsapAvailable={false}
                isCalendarSelected={isShowCalendar}
                loading={mixedLoading}
                params={params}
                selected={selectedDay}
                onSelect={handleSelect}
              />
            </div>
            {isShowCalendar && !isMobile && (
              <div
                className="absolute right-0 top-[88px] z-10 hidden rounded-xl bg-white shadow-lg md:block"
                ref={ref}
              >
                <Calendar
                  disabled={getDisabledDates()}
                  loading={isFetchingAvailableDates || isFetchingGlobalDates}
                  selected={dayjs(selectedDay).toDate()}
                  startMonth={startMonth}
                  onDayClick={handleDayClick}
                  onMonthChange={getDaysWithSlots}
                />
              </div>
            )}
            <Picker
              data={slotsData}
              date={selectedDay}
              isLoading={mixedLoading}
              isReschedule={isReschedule}
              isUnlimitedPlan={isUnlimitedPlan}
              provider={provider}
              setProvider={isSignUp ? undefined : setProvider}
              onConfirm={onSelect}
              onSelect={onTimeSelect}
            />
          </div>
        ) : (
          !isGettingInitialDates && (
            <span className="text-gray">
              No available dates for the next 60 days, please reach out to our support team
            </span>
          )
        )}
      </div>
    </SlideAnimateWrapper>
  );
};

export default DateAndTime;

import { useEffect } from 'react';
import { Common } from '@thecvlb/design-system';

import { BasicConfirmationProps } from './basicConfirmation.types';

const BasicConfirmation: React.FC<BasicConfirmationProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  confirmButtonText = 'Delete',
  headerText = 'Are you sure?',
  subHeaderText = 'This action cannot be undone.',
  cancelButtonText = 'Cancel'
}) => {
  useEffect(() => {
    const onClickEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isOpen) {
        onConfirm();
      }
    };
    document.addEventListener('keyup', onClickEnter);
    return () => {
      document.removeEventListener('keyup', onClickEnter);
    };
  }, []);

  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} persist={isLoading} size="sm">
      <div className="p-4">
        <h3 className="mb-2 text-xl font-bold" data-testid="confirmation_header">
          {headerText}
        </h3>
        <p className="mb-6 text-base font-medium">{subHeaderText}</p>
        <div className="flex flex-col-reverse gap-3 md:flex-row">
          <Common.Button
            className="grow"
            color="white-alt"
            dataTestId="cancel_btn"
            disabled={isLoading}
            fullWidthOnMobile
            onClick={onClose}
          >
            {cancelButtonText}
          </Common.Button>
          <Common.Button
            className="grow"
            color="red"
            dataTestId="confirm_on_popup_btn"
            disabled={isLoading}
            isLoading={isLoading}
            fullWidthOnMobile
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default BasicConfirmation;

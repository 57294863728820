import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { PlanCodes } from 'utils/enums';

import { PlanCardProps } from './planCard.types';

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  activePlanId,
  selectedPlan,
  planName,
  price,
  freeAppointmentStatus
}) => {
  const [isShowDetails, toggleShowDetails] = useToggle(false);

  const toggle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleShowDetails();
  };

  const planStatus = !!plan
    ? `${
        plan._id === activePlanId
          ? 'current'
          : plan.planCode === PlanCodes.LifeMDMembership
            ? 'downgrade'
            : 'upgrade your'
      } plan`
    : '';

  const statusClassName = (planCode: PlanCodes) =>
    classNames('rounded w-fit py-1 px-2 uppercase text-sm font-semibold', {
      'bg-primary-100 text-primary': [
        PlanCodes.LifeMDMembership,
        PlanCodes.InitialAppointment,
        PlanCodes.TotalCareMembership,
        PlanCodes.FlexCare
      ].includes(planCode),
      'bg-secondary text-white': planCode === PlanCodes.UnlimitedMembership
    });

  return (
    <>
      <div
        className="mb-4 flex h-8 w-full items-center justify-between"
        data-testid="plan_card_heading"
      >
        <div className={statusClassName(plan.planCode)}>{planStatus}</div>
        {selectedPlan === plan._id && (
          <Common.Icon className="size-8 text-primary" name="check-circle" />
        )}
      </div>
      <h3 className="mb-1 text-mXl font-semibold md:text-lg">
        ${price}
        {plan.planCode === PlanCodes.UnlimitedMembership && (
          <span className="text-mSm md:text-sm">/mo</span>
        )}
      </h3>
      <h4 className="mb-4 text-mLg font-bold text-primary md:text-lg">
        {planName || plan.planName}
      </h4>
      <span className="mb-4 block">
        {[PlanCodes.LifeMDMembership, PlanCodes.InitialAppointment].includes(plan.planCode)
          ? 'Each appointment with your provider is limited to a single issue.'
          : plan.planCode === PlanCodes.TotalCareMembership
            ? `Your current plan includes one free visit per month. ${
                freeAppointmentStatus === 'used'
                  ? 'You have already used this free visit, and will need to pay to schedule another appointment.'
                  : freeAppointmentStatus === 'booked'
                    ? 'You have already scheduled this free visit, and will need to pay to schedule another appointment.'
                    : ''
              }`
            : 'Upgrade your plan to get unlimited appointments and much more.'}
      </span>
      {plan.isUnlimitedPlan && (
        <>
          {isShowDetails && (
            <div className="my-4">
              {plan.additionalInfo.map((item) => (
                <div className="mb-2 flex items-center gap-2" key={item.question}>
                  <Common.Icon
                    className={classNames('flex-none', item.value ? 'text-secondary' : 'text-gray')}
                    name={item.value ? 'check' : 'close'}
                  />
                  <span
                    className={classNames(
                      'text-mSm font-medium md:text-sm',
                      item.value ? 'text-primary' : 'text-gray'
                    )}
                  >
                    {item.question}
                  </span>
                </div>
              ))}
              <div className="flex flex-col gap-2 text-mXs text-gray md:text-xs">
                <span>* With the option to choose a new primary care provider at any time.</span>
                <span>** Outside of controlled substances.</span>
              </div>
            </div>
          )}
          <a className="flex items-center gap-1 font-bold text-primary" onClick={toggle}>
            <Common.Icon name={isShowDetails ? 'arrow-alt-up' : 'arrow-alt-down'} />
            <span>{isShowDetails ? 'Hide details' : 'See details'}</span>
          </a>
        </>
      )}
    </>
  );
};

export default PlanCard;

import { useRef } from 'react';
import { Modifiers } from 'react-day-picker';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useClickAway, useToggle } from 'react-use';
import classNames from 'classnames';
import dayjs from 'dayjs';

import DateAndTimeCalendar from 'modals/DateAndTimeCalendar';
import DayCard from 'widgets/SchedulingDaySlider/DayCard';

import { ASAP_OPTION_DATE } from 'constants/defaults';
import useGetDisabledDates from 'hooks/useGetDisabledDates';
import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { LeftArrow, RightArrow } from './Arrows/Arrows';
import { SchedulingDaySliderProps } from './schedulingDaySlider.types';

const SchedulingDaySlider: React.FC<SchedulingDaySliderProps> = ({
  dates,
  onSelect,
  selected,
  isCalendarSelected,
  loading,
  isAsapAvailable,
  queueWaitingTime,
  params,
  additionalDisabledDates = []
}) => {
  const { isMobile } = useWidth();
  const ref = useRef(null);
  const [isExtendedLength, setIsExtendedLength] = useToggle(true);
  const [isShowCalendar, toggleShowCalendar] = useToggle(false);

  const { disabledDatesList, getDisabledDates, loading: isFetching } = useGetDisabledDates();

  const handleDayClick = (day: Date, modifiers: Modifiers) => {
    if (modifiers.disabled || loading || !day) {
      return;
    }
    const newDate = dayjs(day).format(DateFormat.YYYY_MM_DD);
    onSelect(newDate);
    toggleShowCalendar(false);
  };

  const getDaysWithSlots = (date: Date) => {
    getDisabledDates(date, params);
  };

  const handleClick = (day: string) => () => {
    if (day === 'calendar') {
      toggleShowCalendar(true);
      !isMobile && onSelect(day);
    } else {
      onSelect(day);
    }
  };

  useClickAway(ref, () => {
    setTimeout(() => {
      if (isShowCalendar) {
        toggleShowCalendar(false);
      }
    }, 100);
  });

  return dates?.length ? (
    <>
      <ScrollMenu
        itemClassName="shadow rounded-xl mx-1.5"
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        wrapperClassName={classNames('overflow-visible md:max-w-full transition-all', {
          '-mr-4 md:w-auto': isExtendedLength
        })}
        onUpdate={(e) => {
          const listOfItems = e.items.toItems();
          setIsExtendedLength(!e.isItemVisible(listOfItems[listOfItems.length - 1]));
        }}
      >
        {dates.map((date) => (
          <DayCard
            date={date}
            datesList={dates}
            disabled={!isAsapAvailable && date === ASAP_OPTION_DATE}
            itemId={date}
            key={date}
            loading={loading}
            queueWaitingTime={queueWaitingTime}
            selected={selected === date || (date === 'calendar' && isCalendarSelected)}
            selectedDate={selected}
            onClick={handleClick(date)}
          />
        ))}
      </ScrollMenu>
      <DateAndTimeCalendar
        disabledDays={[...additionalDisabledDates, ...disabledDatesList]}
        handleDayClick={handleDayClick}
        isOpen={isShowCalendar && isMobile}
        loading={isFetching}
        selectedDay={selected && selected !== ASAP_OPTION_DATE ? selected : ''}
        onClose={() => toggleShowCalendar(false)}
        onMonthChange={getDaysWithSlots}
      />
    </>
  ) : (
    <span className="block text-center text-gray">
      No dates available, please reach out to our support team
    </span>
  );
};

export default SchedulingDaySlider;

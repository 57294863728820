import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { MessageEvent } from 'utils/enums';

import { SystemMessageProps } from './systemMessage.types';

const SystemMessage: React.FC<SystemMessageProps> = ({ self, combined, message }) => {
  const isComplete = message.event === MessageEvent.APPOINTMENT_COMPLETE;
  return (
    <div
      className={classNames(
        'flex items-center gap-3 rounded-xl bg-gradient-to-b from-[#002632] to-[#00384A] px-4 py-3',
        !combined && (self ? 'rounded-br-none' : 'rounded-bl-none'),
        { 'opacity-30': !isComplete }
      )}
    >
      <Common.Icon
        className={classNames(
          'size-8 rounded-lg bg-secondary-400 p-1',
          isComplete ? 'text-white' : 'text-primary-400'
        )}
        name={isComplete ? 'phone-filled' : 'phone-end-filled'}
      />
      <div>
        <p className="text-white">{`Consultation ${isComplete ? 'completed' : 'missed'}`}</p>
        {isComplete && (
          <span className="text-mSm font-semibold text-white/50 md:text-sm">
            {message.callType === 'video' ? 'Video call' : 'Call'} ended
          </span>
        )}
      </div>
    </div>
  );
};

export default SystemMessage;

export const getOffset = (v: number) => {
  switch (true) {
    case v < 27:
      return 'left-[5%]';
    case v < 27.25:
      return 'left-[13%]';
    case v < 27.5:
      return 'left-[15%]';
    case v < 28:
      return 'left-[18%]';
    case v < 28.5:
      return 'left-[22%]';
    case v < 29:
      return 'left-[24%]';
    case v < 29.5:
      return 'left-[27%]';
    case v < 30:
      return 'left-[29%]';
    case v < 31:
      return 'left-[33%]';
    case v < 32:
      return 'left-[36%]';
    case v < 33:
      return 'left-[40%]';
    case v < 34:
      return 'left-[44%]';
    case v < 35:
      return 'left-[47%]';
    case v < 36:
      return 'left-[53%]';
    case v < 37:
      return 'left-[56%]';
    case v < 38:
      return 'left-[60%]';
    case v < 39:
      return 'left-[65%]';
    case v < 40:
      return 'left-[68%]';
    case v >= 40 && v < 45:
      return 'left-[72%] md:left-[75%]';
    case v >= 45:
      return 'left-[72%] md:left-[80%]';
  }
};

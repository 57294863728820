import { Common } from '@thecvlb/design-system/lib/src';

import { DUMMY_PRICE_OF_AT_HOME_LABS } from 'constants/defaults';

import LabKit from 'assets/images/signUp/lab-kit.webp';

const AshSelected = () => {
  return (
    <div className="flex h-full flex-col items-center gap-6">
      <Common.Illustration name="success" />
      <div className="flex flex-col gap-2 text-center">
        <h4 className="wm-signup-title" data-testid="header">
          Labs added to cart
        </h4>
        <span className="text-primary-700" data-testid="sub_header">
          All set! We added your at-home lab kit to your order.{' '}
        </span>
      </div>
      <Common.RichRadioButton
        label={
          <div
            className="flex w-full items-center justify-between gap-2"
            data-testid="lab_kit_in_cart"
          >
            <div className="flex flex-col gap-1">
              <span className="text-mSm font-bold text-primary-400">
                Only ${DUMMY_PRICE_OF_AT_HOME_LABS}
              </span>
              <span className="font-semibold">At-home lab kit</span>
              <span className="text-mSm text-gray">Shipping included</span>
            </div>
            <img alt="labs-at-home" className="size-16" src={LabKit} />
          </div>
        }
        name={'ash'}
        checked
        onChange={() => {}}
      ></Common.RichRadioButton>
    </div>
  );
};

export default AshSelected;

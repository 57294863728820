import { useTitle } from 'react-use';
import classNames from 'classnames';

import PaymentMethodActions from 'modals/PaymentMethodActions';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Stepper from 'shared/ProgressBar';

import { useCreateAppointment } from 'hooks/useCreateAppointment';
import { useExpiredCard } from 'hooks/useExpiredCard';

import Content from './Content';

const CreateAppointment = () => {
  useTitle('LifeMD - Schedule new appointment');
  const { isOpenEditPayment, cardInfo, toggleIsOpenEditPayment } = useExpiredCard();
  const {
    currentStep,
    exitFlow,
    isFetching,
    loading,
    moveToStep,
    steps,
    isBackAnimation,
    currentPlan,
    selectedPlan,
    fillAppointmentData,
    handleSelectTime,
    handleUpgradePlan
  } = useCreateAppointment();

  const showStepper = !['intro', 'confirmation'].includes(currentStep);

  return (
    <FadeWrapper
      className={classNames('flex min-h-full flex-1 flex-col md:flex-none', {
        'blur-sm': isOpenEditPayment
      })}
    >
      <PaymentMethodActions
        isDefault={cardInfo?.isDefault}
        isOpen={isOpenEditPayment}
        isPayPalConnected={cardInfo?.paymentMethod === 'paypal_account'}
        ppId={cardInfo?.chargifyPaymentProfileId}
        onClose={(res) => {
          toggleIsOpenEditPayment();
          !res && exitFlow();
        }}
      />
      <div className="flex h-full flex-1 flex-col rounded-2xl md:bg-white md:shadow">
        <Loader isVisible={isFetching} />
        <div className="mx-auto w-full py-4 md:p-8 md:pb-4">
          {showStepper && (
            <Stepper
              color="primary"
              step={steps.indexOf(currentStep)}
              totalSteps={steps.length - 1}
            />
          )}
        </div>
        <div className="flex grow md:p-8">
          <div className="mx-auto w-full max-w-screen-sm">
            <Content
              currentPlan={currentPlan}
              fillAppointmentData={fillAppointmentData}
              isBackAnimation={isBackAnimation}
              loading={loading}
              moveToStep={moveToStep}
              selectedPlan={selectedPlan}
              step={currentStep}
              onSelectDateAndTime={handleSelectTime}
              onUpgradePlan={handleUpgradePlan}
            />
          </div>
        </div>
      </div>
    </FadeWrapper>
  );
};

export default CreateAppointment;

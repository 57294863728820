import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProviderReviewItem } from 'services/providers/providers.types';

import { Gender } from 'utils/enums';

import { ProviderState } from './provider.types';

const initialState: ProviderState = {
  aboutDoctor: '',
  availableReview: false,
  displayName: '',
  dob: '',
  email: '',
  experience: [],
  firstName: '',
  gender: Gender.Man,
  lastName: '',
  npi: '',
  profileImage: '',
  rating: 0,
  reviews: [],
  shortBio: '',
  specialtyAreas: [],
  userId: ''
};

const providerSlice = createSlice({
  initialState,
  name: 'provider',
  reducers: {
    setProvider: (_state, action: PayloadAction<ProviderState>) => {
      return action.payload;
    },
    setReviews: (state, action: PayloadAction<ProviderReviewItem[]>) => {
      state.reviews = action.payload;
    }
  }
});

export const { setProvider, setReviews } = providerSlice.actions;

export default providerSlice.reducer;

import React, { useEffect } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { selectUser } from 'store';

import Chat from 'widgets/Chat';

import { useAppSelector, useMessages, useZoomCall } from 'hooks';

const ChatSidebar = () => {
  const { channels, channelDetails, markSeen } = useMessages();
  const { isOpenChat, isMinimize, toggleIsOpenChat } = useZoomCall();
  const { userId } = useAppSelector(selectUser);

  const handleMarkSeen = () => {
    if (!isOpenChat || !channelDetails) return;
    const channel = channels.find((item) => item.channelId === channelDetails._id);
    if (!channel?.unreadMessageCount) return;
    markSeen({ channelId: channel.channelId, userId });
  };

  useEffect(handleMarkSeen, [isOpenChat, channels, channelDetails]);

  return (
    <div
      className={classNames(
        'z-20 h-full overflow-hidden bg-white md:rounded-l-3xl md:shadow',
        isOpenChat ? 'w-full flex-none max-md:absolute md:w-[500px]' : 'w-0',
        { hidden: isMinimize }
      )}
    >
      <div className="flex items-center justify-between p-8 max-md:hidden">
        <h2 className="text-xl font-bold">Messages</h2>
        <button onClick={toggleIsOpenChat}>
          <Common.Icon className="text-gray" name="close" />
        </button>
      </div>
      <Chat isZoomCall />
    </div>
  );
};

export default ChatSidebar;

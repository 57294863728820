const Consent = () => {
  return (
    <>
      <p>
        LifeMD, Inc. (“LifeMD”) provides a secure platform for working with health providers of the
        following affiliated medical P.C.s and P.A.s:&nbsp; LifeMD Southern Patient Medical Care,
        P.C.; LifeMD Atlantic Patient Medical Care, P.C.; LifeMD Kansas Patient Medical Care, P.A.,
        LifeMD Midwest Patient Medical Care, P.C.; LifeMD New England Patient Medical Care, P.A.;
        LifeMD Puerto Rico Patient Medical Care, P.C.; LifeMD South Central Patient Medical Care,
        P.A.; New York Patient Medical Care, P.C.; and Puopolo M.D., P.C. d/b/a LifeMD Western
        Patient Medical Care P.C. (“LifeMD Affiliated PCs”) in the delivery of healthcare services
        using electronic communications, information technology, and/or remote means of
        communication (“Telehealth”). The purpose of this form is to obtain your consent to
        participate in a Telehealth visit with one of LifeMD Affiliated PCs’ health providers via
        LifeMD’s Telehealth platform.
      </p>
      <p>
        By affirmatively clicking the box saying that “I agree to the . . . consent to Telehealth,”
        you understand and agree that:{' '}
      </p>
      <ol>
        <li>
          <strong>Nature of the Telehealth Visit. </strong>During the Telehealth visit:
          <strong />
          <ol>
            <li>
              Details of your health information, including but not limited to any and all reports
              and/or records concerning your medical history, condition, diagnosis, testing,
              prognosis, treatment, billing information and identity of your health providers (“User
              Data”), will be discussed with your health provider through the use of interactive
              video, audio, and telecommunications technology.<strong> </strong>
            </li>
            <li>
              Video, audio, and/or digital photographs may be recorded during the Telehealth visit.
              <strong> </strong>
            </li>
            <li>
              Your User Data will continue to be shared via this connection until either you or your
              health provider terminates this connection.<strong> </strong>
            </li>
            <li>
              Non-medical technical personnel may be present during the Telehealth visit to aid with
              the video transmission. You will be informed of their presence and you may request
              that non-medical personnel leave the Telehealth visit at any time.<strong> </strong>
            </li>
            <li>
              You understand that: (a) the delivery of healthcare services via Telehealth is an
              evolving field; (b) the Telehealth visit may include technology (and uses thereof)
              that are not specifically described in this informed consent; and (c) by agreeing, you
              consent to the uses of those additional technologies. <strong> </strong>
            </li>
          </ol>
        </li>
        <li>
          <strong>Limits of Telehealth. </strong>A health provider will use Telehealth to deliver
          healthcare services to you. Telehealth may not be as complete as face-to-face treatment
          and is not intended to replace a full medical face-to-face evaluation by a health
          provider. You may benefit from Telehealth services; however, results cannot be guaranteed
          or assured.
        </li>

        <li>
          <strong>Telehealth as Appropriate for the Circumstances. </strong>It is the health
          provider’s role to determine whether or not the use of Telehealth and its enabling
          technologies, capabilities, and limitations is appropriate for the healthcare services you
          are seeking.
          <strong />
        </li>

        <li>
          <strong>Routine Electronic Messages.</strong> Health providers will typically respond to
          routine electronic messages within 24 hours.&nbsp; Patient’s electronic communications
          through LifeMD’s encrypted platform are securely directed only to the health provider.
          <strong />
        </li>

        <li>
          <strong>Medical Information and Records. </strong>All existing laws regarding the
          confidentiality of your User Data apply to this Telehealth visit. Dissemination of any
          patient-identifiable images or information from this Telehealth interaction to other
          entities shall not occur without your consent, unless authorized by law.
        </li>

        <li>
          <strong>Right to Care.</strong> You are under no obligation to use Telehealth and may
          withdraw at any time. You have the right to access your User Data and copies of your User
          Data in accordance with federal and state law.
        </li>

        <li>
          <strong>Risks. </strong>There are potential risks to using Telehealth technology,
          including interruptions, unauthorized access, confidentiality, and technical difficulties.
          You or your health provider can discontinue the Telehealth visit if it is felt that the
          videoconferencing connections are not adequate for the situation. Health providers will
          attempt to trouble shoot and re-establish Telehealth connection. If unable to re-establish
          Telehealth connection, or in an emergency, your health provider will attempt to reach you
          by calling the phone number you have provided. Risks include, but are not limited to, the
          following:
          <ol>
            <li>
              Information transmitted to your health provider via Telehealth may not in every case
              be sufficient to allow for appropriate medical decision-making by the health provider.
            </li>
            <li>
              The inability of your health provider to conduct certain tests or assess vital signs
              in-person may, in some cases, prevent the health provider from making accurate
              diagnoses, providing appropriate treatments, or identifying the need for emergency
              medical care or treatment.
            </li>
            <li>
              Your health provider may not be able to provide all of the necessary medical treatment
              for your particular condition via the Telehealth visit, in which case you should rely
              on alternative and/or specialized medical care.
            </li>
            <li>
              Delays in medical evaluation/treatment may occur due to unexpected failures in
              technology associated with providing Telehealth.
            </li>
            <li>
              Although security protocols and safeguards associated with the Telehealth visit are
              carefully designed to protect data privacy consistent with legal requirements, such
              protocols and safeguards may fail, causing a breach in data privacy.
            </li>
            <li>
              Your health provider’s treatment options may be limited by regulatory requirements in
              certain states.
            </li>
          </ol>
        </li>

        <li>
          <strong>Sharing Information. </strong>LifeMD Affiliated PCs may use and disclose your User
          Data for purposes of diagnosis, treatment, referral, follow-up, payment, and other
          administrative purposes as consistent with applicable law. All electronic communication
          between you and your health provider will be securely stored in one of LifeMD’s encrypted
          electronic systems. You will hold LifeMD and LifeMD Affiliated PCs harmless for any User
          Data lost due to technical failures. Further, LifeMD Affiliated PCs retain the right to
          share User Data pursuant to any legally required reporting.&nbsp; Please note that because
          the LifeMD Affiliated PCs do not submit claims to health insurance, we are not subject to
          the federal law governing the use and disclosure of health information, known as the HIPAA
          rules.&nbsp; You can find further information about our privacy and security practices in
          our <a href="/privacy">Privacy Policy.</a>
        </li>

        <li>
          <strong>Revocation</strong>.&nbsp; You make revoke this authorization at any time by
          informing your health provider during your Telehealth session, contacting LifeMD or LifeMD
          Affiliated PCs at (866) 351-5097, or contacting your dedicated medical team through your
          member portal with such instruction; provided, however, that where you revoke this
          authorization, you will no longer be able to access or use the Telemedicine services
          provided by LifeMD or Professional Practice. Absent such a revocation, this authorization
          will be considered renewed upon each new Telehealth visit with a participating health
          provider.
        </li>
      </ol>
    </>
  );
};

export default Consent;

import { useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { selectHealthProfile } from 'store';

import Question from 'widgets/myChart/HealthProfile/MedicalIntakeForm/Question';

import { useAppSelector } from 'hooks';
import { MIFTabs } from 'utils/enums';

const MedicalIntakeForm = () => {
  const { mifDetails } = useAppSelector(selectHealthProfile);

  const [activeTab, setActiveTab] = useState<string>(MIFTabs.WarningsOnly);
  const [showAllOptions, toggleShowAllOptions] = useToggle(false);
  const isOnlyWarnings = useMemo(() => activeTab === MIFTabs.WarningsOnly, [activeTab]);

  const tabs: Common.DataItemProps[] = [
    {
      badge: true,
      color: 'text-black',
      count: mifDetails?.warningCount || 0,
      label: MIFTabs.WarningsOnly
    },
    { label: MIFTabs.AllAnswers }
  ];

  const filteredQAData = useMemo(
    () =>
      isOnlyWarnings
        ? mifDetails?.qaData?.filter((question) => question.warning)
        : mifDetails?.qaData,
    [isOnlyWarnings, mifDetails?.qaData]
  );

  return (
    <div className="flex flex-col gap-4">
      <h2 className="hidden text-lg font-bold md:block">Medical intake form</h2>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <Common.Tabs data={tabs} type="bar" onChange={(e) => setActiveTab(e.label)} />
        <label
          className={classNames(
            'flex h-7 cursor-pointer items-center gap-2 text-sm font-medium',
            showAllOptions ? 'text-gray-700' : 'text-gray-500'
          )}
        >
          Show all options
          <Common.Toggle
            checked={showAllOptions}
            color="blue"
            size="sm"
            onClick={toggleShowAllOptions}
          />
        </label>
      </div>
      {isOnlyWarnings && !mifDetails?.warningCount ? (
        <Common.Alert
          children={'No concerning answers found in medical intake form.'}
          type="success"
        />
      ) : (
        <div>
          {filteredQAData?.map((question) => (
            <Question
              key={question.displayNumber}
              question={question}
              showAllOptions={showAllOptions}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MedicalIntakeForm;

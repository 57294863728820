import { useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import ExpandablePanel from 'shared/ExpandablePanel';

const FinalStepsAccordion: React.FC<{
  questions: {
    content: JSX.Element;
    id: string;
    title: string;
  }[];
}> = ({ questions }) => {
  const [activeElement, setActiveElement] = useState('0');

  return (
    <div className="flex flex-col gap-2">
      {questions.map((page, i) => {
        return (
          <ExpandablePanel
            className="rounded-2xl bg-black/10 p-5"
            content={page.content}
            header={
              <div className="flex cursor-pointer items-center justify-between gap-2">
                <span className="mx-1 text-mXl text-primary-300 md:text-2xl">{i + 1}</span>
                <h3 className="font-bold md:text-xl md:font-semibold">{page.title}</h3>
                <Common.Icon
                  className={classNames(
                    'ml-auto mt-1 size-5 flex-none transition-all duration-300 md:h-4 md:w-4',
                    { 'rotate-90': page.id === activeElement }
                  )}
                  name="arrow-right"
                />
              </div>
            }
            isShowContent={page.id === activeElement}
            key={page.id}
            toggleContent={() => setActiveElement(page.id === activeElement ? '0' : page.id)}
          />
        );
      })}
    </div>
  );
};

export default FinalStepsAccordion;

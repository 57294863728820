import { Fragment } from 'react';
import classNames from 'classnames';

import { ShippingProgressProps } from './shippingProgress.types';

const ShippingProgress: React.FC<ShippingProgressProps> = ({ phases }) => {
  const activeStepItem = [...phases].reverse().find((i) => i.completed);
  const activeStep = phases.findIndex((i) => i.label === activeStepItem?.label);

  return (
    <div className="flex w-full grow items-baseline justify-between">
      {phases.map((item, index) => {
        return (
          <Fragment key={item.label}>
            <div className="relative mt-px">
              <div
                className={classNames('z-20 mx-auto mt-[-8px] h-[15px] w-[15px] rounded-full', {
                  'bg-primary-400': item.completed,
                  'border border-primary-300 bg-white': !item.completed,
                  'ring-[6px] ring-primary-100': index === activeStep
                })}
                data-testid="progress_circle"
              />
              <p
                className={classNames(
                  'absolute mt-2 text-center text-[9px] font-semibold text-gray-600',
                  index !== phases.length - 1 ? '-left-2' : '-left-4'
                )}
                data-testid={`progress_label_${item.label}`}
              >
                {item.label}
              </p>
            </div>
            {index < phases.length - 1 && (
              <div
                className={classNames('h-px w-full self-start border border-primary-300', {
                  'border-dashed': index >= activeStep
                })}
              ></div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default ShippingProgress;

import classNames from 'classnames';
import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';
import { convertNumToWord, getPaymentNumbers, parseTime } from 'utils/helpers';

import { Props } from './appointmentCard.types';

const AppointmentCard: React.FC<Props> = ({
  copayInfo,
  category,
  providerName = 'doctor',
  time,
  pricePoint,
  discount,
  creditBalance,
  freeAppointmentInfo
}) => {
  const { discountValue, creditUsed, finalPrice } = getPaymentNumbers(
    copayInfo?.copayAmount || pricePoint.subsequentAppointmentCost,
    freeAppointmentInfo?.isFree ? '100%' : discount,
    creditBalance
  );

  const rowClassName = 'mb-1 flex justify-between font-bold md:mb-0.5';
  const rowWrapperClassName = 'mb-4 md:mb-3';
  const subheadingClassName = 'block text-mSm md:text-sm';

  return (
    <div className="rounded-2xl border border-secondary-200 bg-secondary-50 px-4 py-6 md:p-6">
      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName}>
          <span className="block">{!!copayInfo ? 'Copay' : 'Provider appointment'}</span>
          <span className="">{`$${copayInfo?.copayAmount || pricePoint.subsequentAppointmentCost}`}</span>
        </div>
        {time && category && (
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>
            {`Your ${category} visit. With ${providerName} ${parseTime(time)}`}
          </span>
        )}
      </div>
      {freeAppointmentInfo?.isFree && (
        <div className={classNames(rowWrapperClassName, 'text-orange')}>
          <div className={rowClassName}>
            <span className="block">Appointment credit</span>
            <span>{`-$${pricePoint.subsequentAppointmentCost}`}</span>
          </div>
          <span className={classNames(subheadingClassName, 'md:w-5/6')}>{`${dayjs(
            freeAppointmentInfo.periodStart
          ).format(DateFormat.MMM_DD)} - ${dayjs(freeAppointmentInfo.periodEnd).format(
            DateFormat.MMM_DD
          )}`}</span>
        </div>
      )}
      {!!discount && (
        <div className={classNames(rowWrapperClassName, 'text-orange')}>
          <div className={rowClassName}>
            <span className="block">{`Discount: ${discount} off`}</span>
            <span>-${discountValue}</span>
          </div>
        </div>
      )}
      {!!creditBalance && (
        <div className={classNames(rowWrapperClassName, 'text-primary')}>
          {+creditUsed > 0 && (
            <div className={rowClassName}>
              <span className="block">LifeMD Credit applied</span>
              <span>{`$${creditUsed}`}</span>
            </div>
          )}
          {+creditBalance > 0 && (
            <span className={subheadingClassName}>{`Available credit: $${creditBalance}`}</span>
          )}
        </div>
      )}
      <div className="my-4 h-px w-full bg-secondary" />
      <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
        <div className={rowClassName} data-testid="total_due">
          <span>Total due now</span>
          <span>{`$${finalPrice}`}</span>
        </div>
      </div>
      {!copayInfo && (
        <p className="text-mSm md:text-sm">
          {freeAppointmentInfo?.isFree
            ? `You have ${
                convertNumToWord(Number(freeAppointmentInfo.freeAppointmentsAmount)) || ''
              } free appointment available to be scheduled between ${dayjs(
                freeAppointmentInfo.periodStart
              ).format(DateFormat.MMM_DD)} and ${dayjs(freeAppointmentInfo.periodEnd).format(
                DateFormat.MMM_DD
              )}`
            : `Your plan includes access to single-issue visits for $${pricePoint.subsequentAppointmentCost}.`}
        </p>
      )}
    </div>
  );
};

export default AppointmentCard;

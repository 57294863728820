import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { useLazyGetHealthMetricsQuery } from 'services/healthMetrics/healthMetrics';
import { SortField, SortOrder } from 'services/healthMetrics/healthMetrics.types';

import { selectLatestHealthMetrics } from 'store';

import Pagination from 'features/Pagination';
import Table from 'features/Table';
import HeightEdit from 'modals/HeightEdit';
import Loader from 'shared/Loader';
import ButtonAddHealthMetrics from 'widgets/myChart/HealthMetrics/ButtonAddHealthMetrics';
import Collapse from 'widgets/myChart/HealthMetrics/Collapse';

import { useAppSelector } from 'hooks';
import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { HeightProps, TableItem } from './height.types';

const Height: React.FC<HeightProps> = ({ onUpdate }) => {
  const [getHealthMetrics, { isLoading, data }] = useLazyGetHealthMetricsQuery();
  const [tableData, setTableData] = useState<TableItem[]>([]);
  const [elementForEdit, setElementForEdit] = useState<TableItem | undefined>();
  const [params, setParams] = useState<{
    limit: number;
    pageNo: number;
    sortField: SortField;
    sortOrder: SortOrder;
  }>({
    pageNo: 0,
    limit: 5,
    sortField: 'collectedDate',
    sortOrder: 'DESC'
  });
  const [isOpenModal, toggleIsOpenModal] = useToggle(false);
  const [isOpenCollapse, toggleIsOpenCollapse] = useToggle(false);
  const { isMobile } = useWidth();

  const { height } = useAppSelector(selectLatestHealthMetrics);

  const handleDisplayEditModal = (element?: TableItem) => {
    setElementForEdit(element);
    toggleIsOpenModal();
  };

  const handleGetHealthMetrics = () => {
    getHealthMetrics({ ...params, metricType: 'height' });
  };

  const handleCloseModal = (status?: boolean | unknown) => {
    toggleIsOpenModal();
    if (status) {
      handleGetHealthMetrics();
      onUpdate();
    }
  };

  useEffect(() => {
    if (!data?.data) return;

    const mappedData = data.data.map(({ collectedDate, metrics: { height } }) => ({
      collectedDate: dayjs(collectedDate.split('T')[0]).format(DateFormat.MMM_DD_YYYY),
      'metrics.height': height
        ? `${Math.floor(height / 12)}’ ${Math.round((height % 12) * 100) / 100}”`
        : ''
    }));

    setTableData(mappedData);
  }, [data]);

  useEffect(() => {
    isOpenCollapse && handleGetHealthMetrics();
  }, [params, isOpenCollapse]);

  const totalCount = data?.info?.totalCount || 0;

  return (
    <>
      <Loader isVisible={isLoading} />
      <HeightEdit element={elementForEdit} isOpen={isOpenModal} onClose={handleCloseModal} />
      {typeof height === 'number' ? (
        <Collapse
          dataTestId="height_displayed"
          subTitle={`${Math.floor(height / 12)} ft ${Math.round((height % 12) * 100) / 100} in`}
          title="Height"
          onChange={toggleIsOpenCollapse}
        >
          <div className="my-6">
            <Table
              boldCols={isMobile ? [] : ['height']}
              data={tableData}
              dataTestId="height_measurement_row"
              handleSort={({ sortField, sortOrder }) =>
                setParams({ ...params, sortField: sortField as SortField, sortOrder })
              }
              headers={[
                {
                  id: 'collectedDate',
                  label: 'Date'
                },
                {
                  id: 'metrics.height',
                  label: 'Height'
                }
              ]}
              onClickRow={(el) => handleDisplayEditModal(el as TableItem)}
            />
            {totalCount > params.limit && (
              <Pagination
                labelDirection="column"
                params={{ ...params, totalCount }}
                onChange={({ selected }) => setParams({ ...params, pageNo: selected })}
              />
            )}
          </div>
          <Common.Button
            className="ml-auto"
            color={isMobile ? 'white-alt' : 'blue'}
            preIcon="plus"
            size={isMobile ? 'lg' : 'md'}
            fullWidthOnMobile
            onClick={() => handleDisplayEditModal()}
          >
            Add measurement
          </Common.Button>
        </Collapse>
      ) : (
        <ButtonAddHealthMetrics text="Add Height" onClick={() => handleDisplayEditModal()} />
      )}
    </>
  );
};

export default Height;

import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { useGetAppointmentQuery } from 'services/appointments/appointments';

import { selectAppointments, selectUser, selectUserWithProvider } from 'store';

import RescheduleAppointmentPopup from 'modals/RescheduleAppointment';
import ButtonAddToCalendar from 'shared/ButtonAddToCalendar';
import Loader from 'shared/Loader';
import RescheduleAppointment from 'widgets/RescheduleAppointment';

import { useAppSelector, useQuery } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useWidth from 'hooks/useWidth';
import { DateFormat, PathName } from 'utils/enums';
import {
  checkCompletedOnboardingAppt,
  checkWmNotCompletedOnboarding,
  parseUpcomingAppointment
} from 'utils/helpers';

const Appointment = () => {
  const logEvent = useAnalytics();
  const query = useQuery();
  const navigate = useNavigate();
  const { isMobile } = useWidth();
  const { id = '' } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAppointmentQuery(id);
  const patientWithDoctor = useAppSelector(selectUserWithProvider);
  const { activePlanCode } = useAppSelector(selectUser);
  const { appointmentsList } = useAppSelector(selectAppointments);

  const [isOpenRescheduleAppointment, toggleIsOpenRescheduleAppointment] = useToggle(false);
  const isReschedule = !!query.get('reschedule');

  const appointment = data?.data;
  const isCancelled = appointment?.appointmentStatus === 'cancelled';
  const isPending = appointment?.appointmentStatus === 'pending';
  const isQueue = appointment?.category === 'queue';
  const isStartsSoon = appointment && parseUpcomingAppointment(appointment).isStartsSoon;
  const isMissedAppointment = appointment?.missedAppointment;
  const isDisabledMissedAppointment = !appointment?.hasStaffJoined && appointment?.hasPatientJoined;

  const onClickEnterWaitingRoom = () => {
    logEvent('appt_details_waiting_room_btn_click');
    navigate({
      pathname: PathName.ZoomCall,
      search: `callId=${id}`
    });
  };

  const onClickReschedule = () => {
    toggleIsOpenRescheduleAppointment(false);
    navigate({
      pathname: `${PathName.Appointment}/${id}`,
      search: `reschedule=true`
    });
  };

  const handleClickAddToCalendar = () => {
    logEvent('appt_details_add_to_calendar_btn_click');
  };

  const showAddToCalendarButton = !isMissedAppointment && !isStartsSoon && isPending;

  const completedOnboarding = checkCompletedOnboardingAppt(appointmentsList);
  const wmNotCompletedOnboarding = checkWmNotCompletedOnboarding(
    activePlanCode,
    completedOnboarding
  );

  const detailsClassName = 'text-center line-clamp-3 md:line-clamp-1 break-word';

  return (
    <>
      <Loader isVisible={isLoading} />
      {appointment && (
        <RescheduleAppointmentPopup
          isOpen={isOpenRescheduleAppointment}
          reschedule={onClickReschedule}
          onClose={toggleIsOpenRescheduleAppointment}
        />
      )}
      {!isLoading &&
        (isReschedule && appointment ? (
          <RescheduleAppointment appointment={appointment} />
        ) : (
          <div className="flex flex-1 flex-col items-center rounded-xl md:flex-none md:bg-white md:p-8 md:shadow">
            {!isCancelled && (
              <h1
                className="my-4 text-mXl font-bold text-primary md:mb-0 md:text-2xl"
                data-testid="appointment_scheduled_heading"
              >
                {appointment?.appointmentType?.displayName}
              </h1>
            )}
            <Common.Illustration
              className="my-4 md:my-8 md:w-[196px]"
              name={
                !appointment || isCancelled
                  ? 'green-warning'
                  : isMissedAppointment
                    ? 'red-warning'
                    : isMobile
                      ? 'success'
                      : 'thumbs-up'
              }
            />
            <div className="flex w-full flex-col items-center gap-4 md:w-auto">
              {appointment ? (
                isCancelled ? (
                  <p className={detailsClassName}>This appointment has been cancelled.</p>
                ) : (
                  <>
                    {isMissedAppointment && (
                      <p className="text-mLg font-bold text-red md:text-xl">Missed</p>
                    )}
                    <p
                      className="text-mLg font-bold md:text-xl"
                      data-testid="appointment_planned_date"
                    >
                      {appointment.category === 'queue'
                        ? 'ASAP Appointment'
                        : dayjs(appointment.appointmentTime?.startTime).format(
                            DateFormat.dddd_MMMM_D_h_mm_A
                          )}
                    </p>
                    {appointment.appointmentDescription && (
                      <p className={detailsClassName} data-testid="appointment_description">
                        {appointment.appointmentDescription}
                      </p>
                    )}
                    {showAddToCalendarButton && appointment.appointmentTime && (
                      <div className="mt-4">
                        <ButtonAddToCalendar
                          dataTestId="add_to_calendar_btn"
                          endsAt={appointment.appointmentTime.endTime}
                          id={id}
                          name={appointment.appointmentType.displayName}
                          startsAt={appointment.appointmentTime.startTime}
                          onClick={handleClickAddToCalendar}
                        />
                      </div>
                    )}
                  </>
                )
              ) : (
                <p className={detailsClassName}>Appointment is not found or deleted</p>
              )}
            </div>
            {appointment && (
              <div className="mt-auto flex w-full justify-center md:mt-8">
                {isMissedAppointment ? (
                  <Common.Button
                    color="red-alt"
                    dataTestId="reschedule_btn"
                    disabled={isDisabledMissedAppointment}
                    fullWidthOnMobile
                    onClick={onClickReschedule}
                  >
                    Reschedule appointment
                  </Common.Button>
                ) : (
                  <div className="mb-4 flex w-full flex-col items-center justify-center gap-3 md:w-auto md:flex-row">
                    {dayjs().diff(appointment.appointmentTime?.startTime, 'h') <= 24 &&
                      isPending && (
                        <Common.Button
                          color="blue"
                          dataTestId="begin_appointment"
                          fullWidthOnMobile
                          onClick={onClickEnterWaitingRoom}
                        >
                          {isStartsSoon ? 'Join appointment' : 'Enter waiting room'}
                        </Common.Button>
                      )}
                    {appointment.showBackToCall && (
                      <Common.Button
                        color="blue"
                        dataTestId="back_to_call_btn"
                        onClick={onClickEnterWaitingRoom}
                      >
                        Back to call
                      </Common.Button>
                    )}
                    {isPending && !(isQueue && patientWithDoctor) && (
                      <Common.Button
                        className="!bg-gray-800/5"
                        color="white-alt"
                        dataTestId="cancel_or_change_btn"
                        disabled={isStartsSoon}
                        fullWidthOnMobile
                        onClick={
                          appointment.hasStaffJoined
                            ? toggleIsOpenRescheduleAppointment
                            : onClickReschedule
                        }
                      >
                        {wmNotCompletedOnboarding ? 'Reschedule' : 'Reschedule / cancel'}
                      </Common.Button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default Appointment;

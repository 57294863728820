import dayjs from 'dayjs';

import { ASAP_OPTION_DATE } from 'constants/defaults';
import { DateFormat } from 'utils/enums';

export const getDaysList = (
  dates: number | string[] = 7,
  withCalendarOption = true,
  withASAPOption = false
) => {
  const parsedDates =
    typeof dates === 'number'
      ? Array(dates)
          .fill(null)
          .map((_, i) => dayjs().add(i, 'day').format(DateFormat.YYYY_MM_DD))
      : dates;

  return parsedDates.flatMap((elem, i) => {
    const date = dayjs(elem);
    const item = date.format(DateFormat.YYYY_MM_DD);
    if (i === 0) {
      return withASAPOption ? [ASAP_OPTION_DATE, item] : [item];
    }
    if (i === parsedDates.length - 1) {
      return withCalendarOption ? [item, 'calendar'] : [item];
    }
    return [item];
  });
};

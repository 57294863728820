import { SymptomOption } from 'services/symptomChecker/symptomChecker.types';

import { SymptomCheckerSource } from 'containers/SymptomChecker/symptomChecker.types';

import { Gender } from '../PatientInfo/patientInfo.types';
import { StepProps } from '../steps.types';

export interface Props extends StepProps {
  dataTestId?: string;
  source: SymptomCheckerSource;
}

export interface Symptom {
  id: string;
  label: string;
}

export type ClickedRegion = {
  coords?: {
    panelLeft: string;
    panelTop: string;
  };
  dataTestId?: string;
  id?: string;
};

export interface BodyModelProps {
  dataTestId?: string;
  gender: Gender;
  isVisible: boolean;
  onBodyPartClick: (value: object) => void;
  source: SymptomCheckerSource;
}

export interface RemovableTagsProps {
  className?: string;
  dataTestId?: string;
  onTagClick: (tagVal: SymptomOption) => void;
  tags: SymptomOption[];
}

export const BodyRegionSymptoms = {
  m: {
    '0-0': {
      anus: {
        both: ['s_115', 's_351', 's_249', 's_112', 's_71', 's_525', 's_8', 's_329', 's_1937']
      },
      back: {
        both: ['s_1536', 'p_146']
      },
      buttocks: {
        both: ['s_115', 's_351', 's_249', 's_112', 's_71', 's_8', 's_329', 's_525']
      },
      chest_and_abdomen: {
        both: [
          's_13',
          's_329',
          's_8',
          's_71',
          's_112',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136',
          's_2060',
          's_299',
          's_277',
          's_309',
          's_305',
          's_1432',
          's_306',
          's_2124',
          's_2134',
          'p_144'
        ]
      },
      diaper_area: {
        both: [
          's_115',
          's_351',
          's_249',
          's_112',
          's_71',
          's_611',
          's_113',
          's_525',
          's_8',
          's_329',
          's_1937'
        ],
        female: ['s_1999'],
        male: ['s_1798', 's_1173']
      },
      ears: {
        both: ['s_208', 's_297', 's_2193', 's_1577', 's_1994', 'p_209']
      },
      eyes: {
        both: [
          's_492',
          's_1563',
          's_122',
          's_1492',
          's_320',
          's_497',
          's_483',
          's_489',
          's_488',
          's_1442',
          's_698',
          's_536',
          's_537',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_1713',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_325',
          's_174',
          'p_246',
          'p_168'
        ]
      },
      hand: {
        both: ['s_1713', 's_1982', 's_557', 's_553', 's_556', 's_325', 's_174', 'p_250']
      },
      head: {
        both: ['s_98', 's_616', 's_1543', 's_121', 's_1808', 's_2159', 's_1468', 'p_232']
      },
      lower_limb: {
        both: ['s_1713', 's_174', 's_325', 's_1242', 's_1246', 'p_256']
      },
      neck: {
        both: [
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_88',
          's_220',
          's_1562',
          's_363',
          's_418',
          'p_240'
        ]
      },
      nose: {
        both: ['s_1996', 's_107', 's_331', 's_715', 's_1429', 's_1471', 'p_185']
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_172',
          's_111',
          's_607',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          'p_282'
        ]
      },
      upper_limb: {
        both: ['s_1713', 's_1982', 's_557', 's_553', 's_556', 's_325', 's_174', 'p_257']
      }
    },
    '1-6': {
      anus: {
        both: ['s_115', 's_351', 's_249', 's_112', 's_71', 's_525', 's_8', 's_329', 's_1937']
      },
      back: {
        both: ['s_1536', 'p_146']
      },
      buttocks: {
        both: ['s_115', 's_351', 's_249', 's_112', 's_71', 's_525', 's_8', 's_329']
      },
      chest_and_abdomen: {
        both: [
          's_13',
          's_329',
          's_8',
          's_71',
          's_112',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136',
          's_2060',
          's_299',
          's_277',
          's_309',
          's_305',
          's_1432',
          's_306',
          's_2124',
          's_2134',
          'p_144'
        ]
      },
      diaper_area: {
        both: ['s_115', 's_351', 's_249', 's_112', 's_71', 's_525', 's_8', 's_329', 's_1937'],
        female: ['s_1999'],
        male: ['s_1798', 's_1173']
      },
      ears: {
        both: ['s_208', 's_297', 's_2193', 's_1577', 's_1994', 'p_209']
      },
      eyes: {
        both: [
          's_492',
          's_1563',
          's_122',
          's_1492',
          's_320',
          's_497',
          's_483',
          's_489',
          's_488',
          's_1442',
          's_698',
          's_536',
          's_537',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_325',
          's_174',
          'p_246',
          'p_168'
        ]
      },
      hand: {
        both: ['s_1982', 's_557', 's_553', 's_556', 's_1713', 's_325', 's_174', 'p_250']
      },
      head: {
        both: ['s_98', 's_616', 's_1543', 's_121', 's_1808', 's_2159', 's_1468', 'p_232']
      },
      lower_limb: {
        both: ['s_174', 's_1713', 's_325', 's_1242', 's_1246', 'p_256']
      },
      neck: {
        both: [
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_88',
          's_418',
          's_220',
          's_1562',
          'p_240'
        ]
      },
      nose: {
        both: ['s_1996', 's_107', 's_331', 's_715', 's_1429', 's_1471', 'p_185']
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_172',
          's_111',
          's_607',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          'p_282'
        ]
      },
      upper_limb: {
        both: ['s_1982', 's_557', 's_553', 's_556', 's_1713', 's_325', 's_174', 'p_257']
      }
    },
    '7-11': {
      anus: {
        both: ['s_115', 's_351', 's_249', 's_112', 's_71', 's_1937']
      },
      back: {
        both: ['s_1536', 'p_146']
      },
      buttocks: {
        both: ['s_115', 's_351', 's_249', 's_112', 's_71']
      },
      chest_and_abdomen: {
        both: [
          's_13',
          's_1514',
          's_329',
          's_8',
          's_71',
          's_112',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136',
          's_261',
          's_2060',
          's_299',
          's_277',
          's_309',
          's_305',
          's_1432',
          's_306',
          's_2124',
          's_2134',
          'p_144'
        ]
      },
      diaper_area: {
        both: [
          's_1937',
          's_115',
          's_351',
          's_249',
          's_112',
          's_71',
          's_525',
          's_8',
          's_329',
          's_1242',
          's_1246'
        ],
        female: ['s_1999'],
        male: ['s_1798', 's_1173']
      },
      ears: {
        both: ['s_47', 's_208', 's_297', 's_2193', 's_1577', 's_1994', 'p_209']
      },
      eyes: {
        both: [
          's_492',
          's_1563',
          's_122',
          's_1492',
          's_320',
          's_497',
          's_483',
          's_489',
          's_488',
          's_1442',
          's_698',
          's_536',
          's_537',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_325',
          's_174',
          'p_246',
          'p_168'
        ]
      },
      hand: {
        both: ['s_1982', 's_557', 's_553', 's_556', 's_1713', 's_325', 's_174', 'p_250']
      },
      head: {
        both: ['s_98', 's_616', 's_1543', 's_121', 's_1808', 's_2159', 's_1468', 'p_232']
      },
      lower_limb: {
        both: ['s_174', 's_1713', 's_325', 's_1242', 's_1246', 'p_256']
      },
      neck: {
        both: [
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_88',
          's_1483',
          's_418',
          's_220',
          's_1562',
          'p_240'
        ]
      },
      nose: {
        both: ['s_1996', 's_107', 's_331', 's_715', 's_1429', 's_1471', 'p_185']
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_19',
          's_172',
          's_111',
          's_607',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          'p_282'
        ]
      },
      upper_limb: {
        both: ['s_1982', 's_557', 's_553', 's_556', 's_1713', 's_325', 's_174', 'p_257']
      }
    }
  },
  y: {
    '1-1': {
      anus: {
        both: [
          's_436',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_329',
          's_1937'
        ]
      },
      back: {
        both: ['s_1190', 's_1192', 's_1772', 's_1536', 's_186', 'p_146']
      },
      buttocks: {
        both: ['s_11', 's_579', 's_1453', 's_436', 's_115', 's_351', 's_249', 's_112', 's_71']
      },
      chest: {
        both: [
          's_50',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136',
          's_2060',
          's_2124',
          's_2134'
        ]
      },
      ears: {
        both: [
          's_47',
          's_208',
          's_297',
          's_276',
          's_255',
          's_407',
          's_2193',
          's_1577',
          's_1994',
          's_476',
          'p_209'
        ]
      },
      elbow: {
        both: ['s_1713', 's_1452', 's_325', 's_174', 's_44', 's_229', 's_1656', 's_1674']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_497',
          's_483',
          's_489',
          's_606',
          's_488',
          'p_214',
          's_1442',
          's_698',
          's_536',
          's_537'
        ]
      },
      foot: {
        both: [
          's_1312',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          'p_246',
          'p_168'
        ]
      },
      forearm: {
        both: [
          's_1452',
          's_268',
          's_325',
          's_174',
          's_44',
          's_1713',
          's_1656',
          's_1674',
          's_503',
          'p_252'
        ]
      },
      hand: {
        both: ['s_1312', 's_1982', 's_557', 's_553', 's_87', 's_556', 'p_250']
      },
      head: {
        both: [
          's_21',
          's_478',
          's_98',
          's_616',
          's_1543',
          's_370',
          's_156',
          's_121',
          's_1808',
          's_2159',
          's_1425',
          's_1468',
          's_1436',
          's_430',
          'p_232'
        ]
      },
      knee: {
        both: ['s_579', 's_1453', 's_581', 's_1656', 's_170', 'p_244']
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1860',
          's_1531',
          's_1598',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ]
      },
      lower_back: {
        both: ['s_1190', 's_53', 's_1192', 's_186', 's_241', 'p_146']
      },
      lower_leg: {
        both: ['s_579', 's_1453', 's_581', 's_173', 's_325', 's_503', 'p_247']
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1860',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ]
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 's_363', 'p_240']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_418',
          's_513',
          's_220',
          's_1562',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1429',
          's_1471',
          'p_185',
          's_1436'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          'p_282'
        ]
      },
      sexual_organs: {
        both: ['s_215', 's_39', 's_611', 's_113', 's_28', 's_253', 's_1810'],
        female: ['s_328', 's_1999'],
        male: ['s_358', 's_52', 's_176', 's_1798', 's_1173']
      },
      thigh: {
        both: ['s_579', 's_1453', 's_11', 's_173', 's_1242', 's_1246', 'p_248']
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1860',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          'p_144'
        ]
      },
      upper_arm: {
        both: ['s_1654', 's_1713', 's_1452', 's_268', 's_241', 'p_254']
      }
    },
    '12-17': {
      anus: {
        both: [
          's_436',
          's_1918',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_1979',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: [
          's_1190',
          's_1189',
          's_159',
          's_1198',
          's_1192',
          's_1772',
          's_1206',
          's_1205',
          's_257',
          's_1536',
          's_186',
          'p_146'
        ],
        female: ['s_132']
      },
      breasts: {
        female: ['s_1480', 's_609', 's_219', 's_300', 's_547', 's_1422', 's_194', 's_343', 's_342']
      },
      buttocks: {
        both: [
          's_1918',
          's_11',
          's_1823',
          's_579',
          's_1453',
          's_734',
          's_38',
          's_436',
          's_1652',
          's_115',
          's_351',
          's_249',
          's_112',
          's_71'
        ]
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_1925',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136'
        ],
        male: ['s_586']
      },
      ears: {
        both: ['s_47', 's_208', 's_297', 's_276', 's_255', 's_407', 'p_209']
      },
      elbow: {
        both: ['s_580', 's_1935', 's_1632', 's_1634', 's_1635', 'p_253']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_1465',
          's_497',
          's_483',
          's_1442',
          's_192',
          's_489',
          's_606',
          's_488',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_578',
          's_1833',
          's_2005',
          's_1312',
          's_1621',
          's_79',
          's_125',
          's_1995',
          's_1997',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          'p_168',
          's_2092',
          'p_246'
        ]
      },
      forearm: {
        both: [
          's_580',
          's_1799',
          's_1452',
          's_1935',
          's_1447',
          's_1635',
          's_1431',
          's_1430',
          's_1636',
          's_1632',
          's_1634',
          's_268',
          's_973',
          's_1910',
          's_972',
          's_1426',
          'p_252'
        ]
      },
      hand: {
        both: [
          's_1430',
          's_1636',
          's_554',
          's_1313',
          's_1312',
          's_1464',
          's_1431',
          's_1311',
          's_1982',
          's_557',
          's_553',
          's_1125',
          's_555',
          's_87',
          's_86',
          's_564',
          's_258',
          's_556',
          'p_250'
        ]
      },
      head: {
        both: [
          's_21',
          's_478',
          's_1436',
          's_98',
          's_616',
          's_1156',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          'p_232'
        ]
      },
      knee: {
        both: [
          's_579',
          's_1930',
          's_1453',
          's_581',
          's_1610',
          's_1656',
          's_575',
          's_1927',
          's_170',
          's_238',
          's_270',
          'p_244'
        ]
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      lower_back: {
        both: [
          's_1190',
          's_53',
          's_1189',
          's_1198',
          's_1192',
          's_159',
          's_663',
          's_1206',
          's_1205',
          's_38',
          's_1486',
          's_186',
          's_257',
          'p_146'
        ]
      },
      lower_leg: {
        both: [
          's_579',
          's_1453',
          's_1930',
          's_1620',
          's_1622',
          's_232',
          's_581',
          's_173',
          's_175',
          's_2001',
          's_1063',
          's_743',
          'p_247',
          's_117',
          's_273',
          's_313'
        ]
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 'p_240', 'p_145']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_220',
          's_418',
          's_671',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1436',
          's_1429',
          's_1471',
          'p_185'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          's_88',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_153',
          's_182',
          's_28',
          's_253',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_59', 's_158', 's_1457', 's_1440', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1175', 's_1798', 's_1173']
      },
      thigh: {
        both: [
          's_579',
          's_1930',
          's_1893',
          's_1453',
          's_11',
          's_1823',
          's_38',
          's_1927',
          's_734',
          's_1618',
          's_173',
          's_1652',
          's_175',
          's_743',
          's_2001',
          's_1063',
          's_73',
          'p_248'
        ]
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          'p_144'
        ]
      },
      upper_arm: {
        both: [
          's_580',
          's_1935',
          's_1654',
          's_1805',
          's_1713',
          's_1452',
          's_1447',
          's_1655',
          's_268',
          's_1449',
          's_973',
          's_972',
          's_1426',
          'p_254'
        ]
      }
    },
    '18-29': {
      anus: {
        both: [
          's_436',
          's_1918',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_1979',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: [
          's_1190',
          's_1189',
          's_159',
          's_1198',
          's_1192',
          's_1772',
          's_1206',
          's_1205',
          's_257',
          's_1536',
          's_186',
          'p_146'
        ],
        female: ['s_132']
      },
      breasts: {
        female: [
          's_1480',
          's_609',
          's_219',
          's_300',
          's_547',
          's_1422',
          's_194',
          's_343',
          's_342',
          's_1786'
        ]
      },
      buttocks: {
        both: [
          's_1918',
          's_11',
          's_1823',
          's_579',
          's_1453',
          's_734',
          's_38',
          's_436',
          's_1652',
          's_115',
          's_351',
          's_249',
          's_112',
          's_71'
        ]
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_1925',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136'
        ],
        male: ['s_586']
      },
      ears: {
        both: ['s_47', 's_208', 's_297', 's_276', 's_255', 's_407', 'p_209']
      },
      elbow: {
        both: ['s_580', 's_1935', 's_1632', 's_1634', 's_1635', 'p_253', 's_1713']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_1465',
          's_497',
          's_483',
          's_1442',
          's_192',
          's_489',
          's_606',
          's_488',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_578',
          's_1833',
          's_2005',
          's_1312',
          's_1621',
          's_79',
          's_125',
          's_1995',
          's_1997',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          'p_168',
          's_2092',
          'p_246'
        ]
      },
      forearm: {
        both: [
          's_580',
          's_1799',
          's_1452',
          's_1935',
          's_1447',
          's_1635',
          's_1431',
          's_1430',
          's_1636',
          's_1632',
          's_1634',
          's_268',
          's_973',
          's_1910',
          's_972',
          's_1426',
          'p_252'
        ]
      },
      hand: {
        both: [
          's_1430',
          's_1636',
          's_554',
          's_1313',
          's_1312',
          's_1464',
          's_1431',
          's_1311',
          's_1982',
          's_557',
          's_553',
          's_1125',
          's_555',
          's_87',
          's_86',
          's_564',
          's_258',
          's_556',
          'p_250'
        ]
      },
      head: {
        both: [
          's_21',
          's_478',
          's_1436',
          's_98',
          's_616',
          's_1156',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          'p_232'
        ]
      },
      knee: {
        both: [
          's_579',
          's_1930',
          's_1453',
          's_581',
          's_1610',
          's_1656',
          's_575',
          's_1927',
          's_170',
          's_238',
          's_270',
          'p_244'
        ]
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      lower_back: {
        both: [
          's_1190',
          's_53',
          's_1189',
          's_1198',
          's_1192',
          's_159',
          's_663',
          's_1339',
          's_1206',
          's_1205',
          's_38',
          's_1486',
          's_186',
          's_257',
          'p_146'
        ]
      },
      lower_leg: {
        both: [
          's_579',
          's_1453',
          's_1930',
          's_1620',
          's_1622',
          's_232',
          's_581',
          's_173',
          's_175',
          's_2001',
          's_1063',
          's_743',
          'p_247',
          's_117',
          's_273',
          's_313'
        ]
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 's_363', 's_298', 'p_240', 'p_145']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_220',
          's_298',
          's_363',
          's_418',
          's_671',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1436',
          's_1429',
          's_1471',
          'p_185'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          's_88',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_153',
          's_182',
          's_28',
          's_253',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_152', 's_32', 's_59', 's_158', 's_1457', 's_1440', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1175', 's_1798', 's_1173', 's_733']
      },
      thigh: {
        both: [
          's_579',
          's_1930',
          's_1893',
          's_1453',
          's_11',
          's_1823',
          's_38',
          's_1927',
          's_734',
          's_1618',
          's_173',
          's_1652',
          's_175',
          's_743',
          's_2001',
          's_1063',
          's_73',
          'p_248'
        ]
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          's_285',
          'p_144'
        ]
      },
      upper_arm: {
        both: [
          's_580',
          's_1935',
          's_1654',
          's_1805',
          's_1713',
          's_1452',
          's_1447',
          's_1655',
          's_268',
          's_1449',
          's_973',
          's_972',
          's_1426',
          'p_254'
        ]
      }
    },
    '2-3': {
      anus: {
        both: [
          's_436',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_329',
          's_1937'
        ]
      },
      back: {
        both: ['s_1190', 's_1192', 's_1772', 's_1536', 's_186', 'p_146']
      },
      buttocks: {
        both: ['s_11', 's_579', 's_1453', 's_436', 's_115', 's_351', 's_249', 's_112', 's_71']
      },
      chest: {
        both: [
          's_50',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136',
          's_2060',
          's_2124',
          's_2134'
        ]
      },
      ears: {
        both: [
          's_47',
          's_208',
          's_297',
          's_276',
          's_255',
          's_407',
          's_2193',
          's_1577',
          's_1994',
          's_291',
          's_476',
          'p_209'
        ]
      },
      elbow: {
        both: ['s_1713', 's_1452', 's_325', 's_174', 's_44', 's_229', 's_1656', 's_1674']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_497',
          's_483',
          's_489',
          's_606',
          's_488',
          'p_214',
          's_1442',
          's_698',
          's_536',
          's_537'
        ]
      },
      foot: {
        both: [
          's_1312',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          'p_246',
          'p_168'
        ]
      },
      forearm: {
        both: [
          's_1452',
          's_268',
          's_1713',
          's_325',
          's_174',
          's_44',
          's_1656',
          's_1674',
          's_503',
          's_83',
          'p_252'
        ]
      },
      hand: {
        both: ['s_1312', 's_1982', 's_557', 's_553', 's_87', 's_556', 'p_250']
      },
      head: {
        both: [
          's_21',
          's_478',
          's_98',
          's_616',
          's_971',
          's_1543',
          's_370',
          's_156',
          's_121',
          's_1808',
          's_2159',
          's_1425',
          's_1468',
          's_1436',
          's_430',
          'p_232'
        ]
      },
      knee: {
        both: ['s_579', 's_1453', 's_581', 's_1656', 's_170', 'p_244']
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ]
      },
      lower_back: {
        both: ['s_1190', 's_53', 's_1192', 's_186', 's_241', 'p_146']
      },
      lower_leg: {
        both: ['s_579', 's_1453', 's_581', 's_173', 's_325', 's_503', 'p_247']
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ]
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 'p_240']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_418',
          's_513',
          's_220',
          's_1562',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1429',
          's_1471',
          'p_185',
          's_1436'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          'p_282'
        ]
      },
      sexual_organs: {
        both: ['s_215', 's_39', 's_611', 's_113', 's_28', 's_253', 's_1810'],
        female: ['s_328', 's_1999'],
        male: ['s_358', 's_52', 's_176', 's_1798', 's_1173']
      },
      thigh: {
        both: ['s_579', 's_1930', 's_1453', 's_11', 's_173', 's_1242', 's_1246', 'p_248']
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          'p_144'
        ]
      },
      upper_arm: {
        both: ['s_1654', 's_1713', 's_268', 's_241', 'p_254']
      }
    },
    '30-44': {
      anus: {
        both: [
          's_436',
          's_1918',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_1979',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: [
          's_1190',
          's_1189',
          's_159',
          's_1198',
          's_1192',
          's_1772',
          's_1206',
          's_1205',
          's_257',
          's_1536',
          's_186',
          'p_146'
        ],
        female: ['s_132']
      },
      breasts: {
        female: [
          's_1480',
          's_609',
          's_219',
          's_300',
          's_547',
          's_1422',
          's_194',
          's_343',
          's_342',
          's_1786'
        ]
      },
      buttocks: {
        both: [
          's_1918',
          's_11',
          's_1823',
          's_579',
          's_1453',
          's_734',
          's_38',
          's_436',
          's_1652',
          's_115',
          's_351',
          's_249',
          's_112',
          's_71'
        ]
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_1925',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136'
        ],
        male: ['s_586']
      },
      ears: {
        both: ['s_47', 's_208', 's_297', 's_276', 's_255', 's_407', 'p_209']
      },
      elbow: {
        both: ['s_580', 's_1935', 's_1632', 's_1634', 's_1635', 'p_253', 's_1713']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_1465',
          's_497',
          's_483',
          's_1442',
          's_192',
          's_489',
          's_606',
          's_488',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_578',
          's_1833',
          's_2005',
          's_1312',
          's_1621',
          's_79',
          's_125',
          's_1995',
          's_1997',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          'p_168',
          's_2092',
          'p_246'
        ]
      },
      forearm: {
        both: [
          's_580',
          's_1799',
          's_1452',
          's_1935',
          's_1447',
          's_1635',
          's_1431',
          's_1430',
          's_1636',
          's_1632',
          's_1634',
          's_268',
          's_973',
          's_1910',
          's_972',
          's_1426',
          'p_252'
        ]
      },
      hand: {
        both: [
          's_1430',
          's_1636',
          's_554',
          's_1313',
          's_1312',
          's_1464',
          's_1431',
          's_1311',
          's_1982',
          's_557',
          's_553',
          's_1125',
          's_555',
          's_87',
          's_86',
          's_564',
          's_258',
          's_556',
          'p_250'
        ]
      },
      head: {
        both: [
          's_21',
          's_478',
          's_1436',
          's_98',
          's_616',
          's_1156',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          'p_232'
        ]
      },
      knee: {
        both: [
          's_579',
          's_1930',
          's_1453',
          's_581',
          's_1610',
          's_1656',
          's_575',
          's_1927',
          's_170',
          's_238',
          's_270',
          'p_244'
        ]
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      lower_back: {
        both: [
          's_1190',
          's_53',
          's_1189',
          's_1198',
          's_1192',
          's_159',
          's_663',
          's_1339',
          's_1206',
          's_1205',
          's_38',
          's_1486',
          's_186',
          's_257',
          'p_146'
        ]
      },
      lower_leg: {
        both: [
          's_579',
          's_1453',
          's_1930',
          's_1620',
          's_1622',
          's_232',
          's_581',
          's_173',
          's_175',
          's_2001',
          's_1063',
          's_743',
          'p_247',
          's_117',
          's_273',
          's_313'
        ]
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 's_363', 's_298', 'p_240', 'p_145']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_220',
          's_298',
          's_363',
          's_418',
          's_671',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1436',
          's_1429',
          's_1471',
          'p_185'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          's_88',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_153',
          's_182',
          's_28',
          's_253',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_152', 's_32', 's_59', 's_158', 's_1457', 's_1440', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1175', 's_1798', 's_1173', 's_733']
      },
      thigh: {
        both: [
          's_579',
          's_1930',
          's_1893',
          's_1453',
          's_11',
          's_1823',
          's_38',
          's_1927',
          's_734',
          's_1618',
          's_173',
          's_1652',
          's_175',
          's_743',
          's_2001',
          's_1063',
          's_73',
          'p_248'
        ]
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          's_285',
          'p_144'
        ]
      },
      upper_arm: {
        both: [
          's_580',
          's_1935',
          's_1654',
          's_1805',
          's_1713',
          's_1452',
          's_1447',
          's_1655',
          's_268',
          's_1449',
          's_973',
          's_972',
          's_1426',
          'p_254'
        ]
      }
    },
    '4-6': {
      anus: {
        both: [
          's_436',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: ['s_1190', 's_1192', 's_1772', 's_1536', 's_186', 'p_146']
      },
      buttocks: {
        both: ['s_11', 's_579', 's_1453', 's_436', 's_115', 's_351', 's_249', 's_112', 's_71']
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136',
          's_2060',
          's_2124',
          's_2134'
        ]
      },
      ears: {
        both: [
          's_47',
          's_208',
          's_297',
          's_276',
          's_255',
          's_407',
          's_2193',
          's_1577',
          's_1994',
          's_291',
          's_476',
          'p_209'
        ]
      },
      elbow: {
        both: ['s_174', 's_44', 's_1713', 's_229', 's_1656', 's_1674', 's_1452', 's_325', 's_1449']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_497',
          's_483',
          's_489',
          's_606',
          's_488',
          'p_214',
          's_1442',
          's_698',
          's_536',
          's_537'
        ]
      },
      foot: {
        both: [
          's_1312',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          'p_246',
          'p_168'
        ]
      },
      forearm: {
        both: [
          's_1452',
          's_268',
          's_174',
          's_44',
          's_1713',
          's_325',
          's_1656',
          's_1674',
          's_503',
          's_83',
          's_1449',
          'p_252'
        ]
      },
      hand: {
        both: ['s_1312', 's_1982', 's_557', 's_553', 's_87', 's_556', 's_1449', 'p_250']
      },
      head: {
        both: [
          's_21',
          's_478',
          's_98',
          's_616',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          's_1425',
          's_1468',
          's_1436',
          's_430',
          'p_232'
        ]
      },
      knee: {
        both: ['s_579', 's_1453', 's_581', 's_170', 's_1656', 'p_244']
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ]
      },
      lower_back: {
        both: ['s_1190', 's_53', 's_1192', 's_186', 'p_146']
      },
      lower_leg: {
        both: ['s_579', 's_1453', 's_581', 's_173', 's_503', 'p_247']
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ]
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 'p_240']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_418',
          's_513',
          's_220',
          's_1562',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1429',
          's_1471',
          'p_185',
          's_1436'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          's_88',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_253',
          's_153',
          's_182',
          's_28',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1798', 's_1173']
      },
      thigh: {
        both: ['s_579', 's_1453', 's_11', 's_173', 's_1242', 's_1246', 'p_248']
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          'p_144'
        ]
      },
      upper_arm: {
        both: ['s_1654', 's_1713', 's_1452', 's_268', 's_1449', 'p_254']
      }
    },
    '45-59': {
      anus: {
        both: [
          's_436',
          's_1918',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_1979',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: [
          's_1190',
          's_1189',
          's_159',
          's_1198',
          's_1192',
          's_1772',
          's_1206',
          's_1205',
          's_257',
          's_1536',
          's_186',
          'p_146'
        ],
        female: ['s_132']
      },
      breasts: {
        female: [
          's_1480',
          's_609',
          's_219',
          's_300',
          's_547',
          's_1422',
          's_194',
          's_343',
          's_342',
          's_1786'
        ]
      },
      buttocks: {
        both: [
          's_1918',
          's_11',
          's_1823',
          's_579',
          's_1453',
          's_734',
          's_38',
          's_436',
          's_1652',
          's_115',
          's_351',
          's_249',
          's_112',
          's_71'
        ]
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_1925',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136'
        ],
        male: ['s_586']
      },
      ears: {
        both: ['s_47', 's_208', 's_297', 's_276', 's_255', 's_407', 'p_209']
      },
      elbow: {
        both: ['s_580', 's_1935', 's_1632', 's_1634', 's_1635', 'p_253', 's_1713']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_1465',
          's_497',
          's_483',
          's_1442',
          's_192',
          's_489',
          's_606',
          's_488',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_578',
          's_1833',
          's_2005',
          's_1312',
          's_1621',
          's_79',
          's_125',
          's_1995',
          's_1997',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          'p_168',
          's_2092',
          'p_246'
        ]
      },
      forearm: {
        both: [
          's_580',
          's_1799',
          's_1452',
          's_1935',
          's_1447',
          's_1635',
          's_1431',
          's_1430',
          's_1636',
          's_1632',
          's_1634',
          's_268',
          's_973',
          's_1910',
          's_972',
          's_1426',
          'p_252'
        ]
      },
      hand: {
        both: [
          's_1430',
          's_1636',
          's_554',
          's_1313',
          's_1312',
          's_1464',
          's_1431',
          's_1311',
          's_1982',
          's_557',
          's_553',
          's_1125',
          's_555',
          's_87',
          's_86',
          's_564',
          's_258',
          's_556',
          'p_250'
        ]
      },
      head: {
        both: [
          's_21',
          's_478',
          's_1436',
          's_98',
          's_616',
          's_1156',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          'p_232'
        ]
      },
      knee: {
        both: [
          's_579',
          's_1930',
          's_1453',
          's_581',
          's_1610',
          's_1656',
          's_575',
          's_1927',
          's_170',
          's_238',
          's_270',
          'p_244'
        ]
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      lower_back: {
        both: [
          's_1190',
          's_53',
          's_1189',
          's_1198',
          's_1192',
          's_159',
          's_663',
          's_1339',
          's_1206',
          's_1205',
          's_38',
          's_1486',
          's_186',
          's_257',
          'p_146'
        ]
      },
      lower_leg: {
        both: [
          's_579',
          's_1453',
          's_1930',
          's_1620',
          's_1622',
          's_232',
          's_581',
          's_173',
          's_175',
          's_2001',
          's_1063',
          's_743',
          'p_247',
          's_117',
          's_273',
          's_313'
        ]
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 's_363', 's_298', 'p_240', 'p_145']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_220',
          's_298',
          's_363',
          's_418',
          's_671',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1436',
          's_1429',
          's_1471',
          'p_185'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          's_88',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_153',
          's_182',
          's_28',
          's_253',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_152', 's_32', 's_59', 's_158', 's_1457', 's_1440', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1175', 's_1798', 's_1173', 's_733']
      },
      thigh: {
        both: [
          's_579',
          's_1930',
          's_1893',
          's_1453',
          's_11',
          's_1823',
          's_38',
          's_1927',
          's_734',
          's_1618',
          's_173',
          's_1652',
          's_175',
          's_743',
          's_2001',
          's_1063',
          's_73',
          'p_248'
        ]
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          's_285',
          'p_144'
        ]
      },
      upper_arm: {
        both: [
          's_580',
          's_1935',
          's_1654',
          's_1805',
          's_1713',
          's_1452',
          's_1447',
          's_1655',
          's_268',
          's_1449',
          's_973',
          's_972',
          's_1426',
          'p_254'
        ]
      }
    },
    '60-74': {
      anus: {
        both: [
          's_436',
          's_1918',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_1979',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: [
          's_1190',
          's_1189',
          's_159',
          's_1198',
          's_1192',
          's_1772',
          's_1206',
          's_1205',
          's_257',
          's_1536',
          's_186',
          'p_146'
        ],
        female: ['s_132']
      },
      breasts: {
        female: [
          's_1480',
          's_609',
          's_219',
          's_300',
          's_547',
          's_1422',
          's_194',
          's_343',
          's_342',
          's_1786'
        ]
      },
      buttocks: {
        both: [
          's_1918',
          's_11',
          's_1823',
          's_579',
          's_1453',
          's_734',
          's_38',
          's_436',
          's_1652',
          's_115',
          's_351',
          's_249',
          's_112',
          's_71'
        ]
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_1925',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136'
        ],
        male: ['s_586']
      },
      ears: {
        both: ['s_47', 's_208', 's_297', 's_276', 's_255', 's_407', 'p_209']
      },
      elbow: {
        both: ['s_580', 's_1935', 's_1632', 's_1634', 's_1635', 'p_253', 's_1713']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_1465',
          's_497',
          's_483',
          's_1442',
          's_192',
          's_489',
          's_606',
          's_488',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_578',
          's_1833',
          's_2005',
          's_1312',
          's_1621',
          's_79',
          's_125',
          's_1995',
          's_1997',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          'p_168',
          's_2092',
          'p_246'
        ]
      },
      forearm: {
        both: [
          's_580',
          's_1799',
          's_1452',
          's_1935',
          's_1447',
          's_1635',
          's_1431',
          's_1430',
          's_1636',
          's_1632',
          's_1634',
          's_268',
          's_973',
          's_1910',
          's_972',
          's_1426',
          'p_252'
        ]
      },
      hand: {
        both: [
          's_1430',
          's_1636',
          's_554',
          's_1313',
          's_1312',
          's_1464',
          's_1431',
          's_1311',
          's_1982',
          's_557',
          's_553',
          's_1125',
          's_555',
          's_87',
          's_86',
          's_564',
          's_258',
          's_556',
          'p_250'
        ]
      },
      head: {
        both: [
          's_21',
          's_478',
          's_1436',
          's_98',
          's_616',
          's_1156',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          'p_232'
        ]
      },
      knee: {
        both: [
          's_579',
          's_1930',
          's_1453',
          's_581',
          's_1610',
          's_1656',
          's_575',
          's_1927',
          's_170',
          's_238',
          's_270',
          'p_244'
        ]
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      lower_back: {
        both: [
          's_1190',
          's_53',
          's_1189',
          's_1198',
          's_1192',
          's_159',
          's_663',
          's_1339',
          's_1206',
          's_1205',
          's_38',
          's_1486',
          's_186',
          's_257',
          'p_146'
        ]
      },
      lower_leg: {
        both: [
          's_579',
          's_1453',
          's_1930',
          's_1620',
          's_1622',
          's_232',
          's_581',
          's_173',
          's_175',
          's_2001',
          's_1063',
          's_743',
          'p_247',
          's_117',
          's_273',
          's_313'
        ]
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 's_363', 's_298', 'p_240', 'p_145']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_220',
          's_298',
          's_363',
          's_418',
          's_671',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1436',
          's_1429',
          's_1471',
          'p_185'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          's_88',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_153',
          's_182',
          's_28',
          's_253',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_152', 's_32', 's_59', 's_158', 's_1457', 's_1440', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1175', 's_1798', 's_1173', 's_733']
      },
      thigh: {
        both: [
          's_579',
          's_1930',
          's_1893',
          's_1453',
          's_11',
          's_1823',
          's_38',
          's_1927',
          's_734',
          's_1618',
          's_173',
          's_1652',
          's_175',
          's_743',
          's_2001',
          's_1063',
          's_73',
          'p_248'
        ]
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          's_285',
          'p_144'
        ]
      },
      upper_arm: {
        both: [
          's_580',
          's_1935',
          's_1654',
          's_1805',
          's_1713',
          's_1452',
          's_1447',
          's_1655',
          's_268',
          's_1449',
          's_973',
          's_972',
          's_1426',
          'p_254'
        ]
      }
    },
    '7-11': {
      anus: {
        both: [
          's_436',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: ['s_1190', 's_1192', 's_1772', 's_1536', 's_186', 'p_146']
      },
      buttocks: {
        both: ['s_11', 's_579', 's_1453', 's_436', 's_115', 's_351', 's_249', 's_112', 's_71']
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136',
          's_2060',
          's_2124',
          's_2134'
        ]
      },
      ears: {
        both: [
          's_47',
          's_208',
          's_297',
          's_276',
          's_255',
          's_407',
          's_2193',
          's_1577',
          's_1994',
          's_291',
          's_476',
          'p_209'
        ]
      },
      elbow: {
        both: ['s_174', 's_44', 's_1713', 's_229', 's_1656', 's_1674', 's_1452', 's_325', 's_1449']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_497',
          's_483',
          's_489',
          's_606',
          's_488',
          'p_214',
          's_1442',
          's_698',
          's_536',
          's_537'
        ]
      },
      foot: {
        both: [
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          's_1312',
          's_1923',
          's_1790',
          's_603',
          'p_246',
          'p_168'
        ]
      },
      forearm: {
        both: [
          's_1452',
          's_268',
          's_174',
          's_44',
          's_1713',
          's_325',
          's_1656',
          's_1674',
          's_503',
          's_83',
          's_1449',
          'p_252'
        ]
      },
      hand: {
        both: ['s_1312', 's_1982', 's_557', 's_553', 's_87', 's_556', 's_1449', 'p_250']
      },
      head: {
        both: [
          's_21',
          's_478',
          's_98',
          's_616',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          's_1425',
          's_1468',
          's_1436',
          's_430',
          'p_232'
        ]
      },
      knee: {
        both: ['s_579', 's_1453', 's_581', 's_1656', 's_170', 'p_244']
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ]
      },
      lower_back: {
        both: ['s_1190', 's_53', 's_1192', 's_186', 's_241', 'p_146']
      },
      lower_leg: {
        both: ['s_579', 's_1453', 's_581', 's_173', 's_503', 'p_247']
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ]
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 'p_240']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_418',
          's_513',
          's_220',
          's_1562',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1429',
          's_1471',
          'p_185',
          's_1436'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_153',
          's_182',
          's_28',
          's_253',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1798', 's_1173']
      },
      thigh: {
        both: ['s_579', 's_1453', 's_11', 's_173', 's_1242', 's_1246', 'p_248']
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          'p_144'
        ]
      },
      upper_arm: {
        both: ['s_1654', 's_1713', 's_1452', 's_268', 's_1449', 'p_254']
      }
    },
    '75-120': {
      anus: {
        both: [
          's_436',
          's_1918',
          's_1393',
          's_249',
          's_351',
          's_112',
          's_115',
          's_71',
          's_525',
          's_8',
          's_1979',
          's_329',
          's_641',
          's_1937'
        ]
      },
      back: {
        both: [
          's_1190',
          's_1189',
          's_159',
          's_1198',
          's_1192',
          's_1772',
          's_1206',
          's_1205',
          's_257',
          's_1536',
          's_186',
          'p_146'
        ],
        female: ['s_132']
      },
      breasts: {
        female: [
          's_1480',
          's_609',
          's_219',
          's_300',
          's_547',
          's_1422',
          's_194',
          's_343',
          's_342',
          's_1786'
        ]
      },
      buttocks: {
        both: [
          's_1918',
          's_11',
          's_1823',
          's_579',
          's_1453',
          's_734',
          's_38',
          's_436',
          's_1652',
          's_115',
          's_351',
          's_249',
          's_112',
          's_71'
        ]
      },
      chest: {
        both: [
          's_50',
          's_2074',
          's_1925',
          's_338',
          's_110',
          's_261',
          's_534',
          's_102',
          's_88',
          's_1462',
          's_227',
          's_474',
          'p_136'
        ],
        male: ['s_586']
      },
      ears: {
        both: ['s_47', 's_208', 's_297', 's_276', 's_255', 's_407', 'p_209']
      },
      elbow: {
        both: ['s_580', 's_1935', 's_1632', 's_1634', 's_1635', 'p_253', 's_1713']
      },
      eyes: {
        both: [
          's_493',
          's_492',
          's_72',
          's_1563',
          's_122',
          's_1492',
          's_54',
          's_320',
          's_207',
          's_1465',
          's_497',
          's_483',
          's_1442',
          's_192',
          's_489',
          's_606',
          's_488',
          'p_214'
        ]
      },
      foot: {
        both: [
          's_578',
          's_1833',
          's_2005',
          's_1312',
          's_1621',
          's_79',
          's_125',
          's_1995',
          's_1997',
          's_1923',
          's_1790',
          's_603',
          's_1310',
          's_1788',
          's_1789',
          's_408',
          's_1909',
          'p_168',
          's_2092',
          'p_246'
        ]
      },
      forearm: {
        both: [
          's_580',
          's_1799',
          's_1452',
          's_1935',
          's_1447',
          's_1635',
          's_1431',
          's_1430',
          's_1636',
          's_1632',
          's_1634',
          's_268',
          's_973',
          's_1910',
          's_972',
          's_1426',
          'p_252'
        ]
      },
      hand: {
        both: [
          's_1430',
          's_1636',
          's_554',
          's_1313',
          's_1312',
          's_1464',
          's_1431',
          's_1311',
          's_1982',
          's_557',
          's_553',
          's_1125',
          's_555',
          's_87',
          's_86',
          's_564',
          's_258',
          's_556',
          'p_250'
        ]
      },
      head: {
        both: [
          's_21',
          's_478',
          's_1436',
          's_98',
          's_616',
          's_1156',
          's_971',
          's_1543',
          's_316',
          's_370',
          's_156',
          's_121',
          's_1808',
          'p_232'
        ]
      },
      knee: {
        both: [
          's_579',
          's_1930',
          's_1453',
          's_581',
          's_1610',
          's_1656',
          's_575',
          's_1927',
          's_170',
          's_238',
          's_270',
          'p_244'
        ]
      },
      lower_abdomen: {
        both: [
          's_13',
          's_1729',
          's_1848',
          's_1860',
          's_1531',
          's_1598',
          's_1369',
          's_1514',
          's_309',
          's_277',
          's_299',
          's_641',
          's_329',
          's_8',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      lower_back: {
        both: [
          's_1190',
          's_53',
          's_1189',
          's_1198',
          's_1192',
          's_159',
          's_663',
          's_1339',
          's_1206',
          's_1205',
          's_38',
          's_1486',
          's_186',
          's_257',
          'p_146'
        ]
      },
      lower_leg: {
        both: [
          's_579',
          's_1453',
          's_1930',
          's_1620',
          's_1622',
          's_232',
          's_581',
          's_173',
          's_175',
          's_2001',
          's_1063',
          's_743',
          'p_247',
          's_117',
          's_273',
          's_313'
        ]
      },
      mid_abdomen: {
        both: [
          's_13',
          's_1532',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_299',
          's_277',
          's_309',
          's_329',
          's_8',
          's_305',
          's_156',
          's_71',
          's_112',
          'p_144'
        ],
        female: ['s_17']
      },
      nape_of_neck: {
        both: ['s_1483', 's_965', 's_418', 's_186', 's_363', 's_298', 'p_240', 'p_145']
      },
      neck_or_throat: {
        both: [
          's_20',
          's_509',
          's_962',
          's_1367',
          's_102',
          's_1094',
          's_474',
          's_1471',
          's_68',
          's_252',
          's_88',
          's_1483',
          's_220',
          's_298',
          's_363',
          's_418',
          's_671',
          'p_240'
        ]
      },
      nose: {
        both: [
          's_1605',
          's_1996',
          's_107',
          's_331',
          's_252',
          's_1303',
          's_67',
          's_715',
          's_1095',
          's_1436',
          's_1429',
          's_1471',
          'p_185'
        ]
      },
      oral_cavity: {
        both: [
          's_694',
          's_65',
          's_247',
          's_551',
          's_266',
          's_19',
          's_172',
          's_111',
          's_607',
          's_200',
          's_102',
          's_67',
          's_305',
          's_116',
          's_284',
          's_310',
          's_610',
          's_88',
          'p_282'
        ]
      },
      sexual_organs: {
        both: [
          's_215',
          's_39',
          's_611',
          's_113',
          's_153',
          's_182',
          's_28',
          's_253',
          's_690',
          's_689',
          's_1810'
        ],
        female: ['s_328', 's_152', 's_32', 's_59', 's_158', 's_1457', 's_1440', 's_1999'],
        male: ['s_369', 's_358', 's_52', 's_176', 's_1175', 's_1798', 's_1173', 's_733']
      },
      thigh: {
        both: [
          's_579',
          's_1930',
          's_1893',
          's_1453',
          's_11',
          's_1823',
          's_38',
          's_1927',
          's_734',
          's_1618',
          's_173',
          's_1652',
          's_175',
          's_743',
          's_2001',
          's_1063',
          's_73',
          'p_248'
        ]
      },
      upper_abdomen: {
        both: [
          's_13',
          's_1387',
          's_1591',
          's_1528',
          's_1802',
          's_1848',
          's_1860',
          's_1369',
          's_1514',
          's_338',
          's_299',
          's_277',
          's_309',
          's_305',
          's_156',
          's_1432',
          's_306',
          's_285',
          'p_144'
        ]
      },
      upper_arm: {
        both: [
          's_580',
          's_1935',
          's_1654',
          's_1805',
          's_1713',
          's_1452',
          's_1447',
          's_1655',
          's_268',
          's_1449',
          's_973',
          's_972',
          's_1426',
          'p_254'
        ]
      }
    }
  }
};

export type AgeKeys = keyof typeof BodyRegionSymptoms.y & string;

export type BodyRegionKeys = keyof (typeof BodyRegionSymptoms.y)[AgeKeys] & string;

type A = Record<
  AgeKeys,
  Record<BodyRegionKeys, { both?: string[]; female?: string[]; male?: string[] }>
>;

export const AllAgeSymptoms: A = BodyRegionSymptoms.y;

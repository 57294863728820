import { selectUser } from 'store';

import { useAppSelector } from './index';

const useFreemium = () => {
  const { activePlanId } = useAppSelector(selectUser);
  return activePlanId === null;
};

export default useFreemium;

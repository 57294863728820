import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { DOC_EXTENSIONS } from 'utils/constants';

import { FileProps } from './file.types';
import Image from './Image';

const File: React.FC<FileProps> = ({ message, self, mobile, combined }) => {
  const VIDEO_EXTENSIONS = ['.mov', '.qt', '.mp4', '.m4v', '.webm'];
  const extensionIndex = message.fileName?.lastIndexOf('.') as number;
  const fileExtension = message.fileName?.substring(extensionIndex).toLowerCase() as string;
  const isDocFilePreview = DOC_EXTENSIONS.includes(fileExtension);
  const isVideoFilePreview = VIDEO_EXTENSIONS.includes(fileExtension);

  return (
    <>
      {message.filePath && message.fileName && (
        <>
          {isDocFilePreview ? (
            <a
              className={classNames(
                'w-fit max-w-[270px] rounded-xl px-5 py-3',
                self ? 'bg-secondary-100' : 'bg-gray-100',
                !combined && (self ? 'rounded-br-none' : 'rounded-bl-none')
              )}
              href={message.filePath}
              rel="noreferrer"
              target="_blank"
            >
              <Common.Icon className="mx-auto mb-1 size-9" name="articles" />
              <p className="truncate">{message.fileName}</p>
            </a>
          ) : isVideoFilePreview ? (
            <video
              className={classNames('ml-auto h-[150px] max-w-[270px] object-cover', {
                'md:max-w-none': !mobile
              })}
              role="video"
              controls
            >
              <source role="source" src={message.filePath} />
            </video>
          ) : (
            <Image combined={combined} message={message} mobile={mobile} self={self} />
          )}
        </>
      )}
    </>
  );
};

export default File;

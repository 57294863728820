import { Common } from '@thecvlb/design-system';

import { ButtonAddHealthMetricsProps } from './buttonAddHealthMetrics.types';

const ButtonAddHealthMetrics: React.FC<ButtonAddHealthMetricsProps> = ({ text, onClick }) => {
  return (
    <button
      className="flex items-center gap-5 rounded-2xl border border-dashed px-5 py-4 text-base font-semibold text-gray-700 transition-all hover:bg-secondary-50"
      onClick={onClick}
    >
      <Common.Icon
        className="h-10 w-14 rounded-xl bg-secondary-100 px-5 py-3 text-secondary"
        name="plus"
      />
      {text}
    </button>
  );
};

export default ButtonAddHealthMetrics;

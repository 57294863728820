import { Common } from '@thecvlb/design-system';
import { useLottie } from 'lottie-react';

import { FlowTypes } from 'utils/enums';

import illustration from 'assets/animations/wm-onboarding/hand-medical-heart.json';

const options = {
  animationData: illustration,
  loop: 1,
  style: { width: 240 }
};

const YouAreInGoodHands: React.FC<{
  onContinue: () => void;
  selectedFlow: FlowTypes | 'authorized';
}> = ({ onContinue, selectedFlow }) => {
  const { View } = useLottie(options);
  const isTripleTherapy = selectedFlow === FlowTypes.TripleTherapy;

  return (
    <div className="flex h-full flex-col place-items-center gap-2 md:gap-6">
      <div className="-mt-6">{View}</div>
      <h2 className="wm-signup-title" data-testid="header">
        You are in good hands
      </h2>
      <p className="text-mBase text-primary-700">
        {isTripleTherapy
          ? 'Losing weight can feel complicated and daunting. Not anymore. The LifeMD Weight Management Triple Therapy Program brings together three science-backed medications that when combined in carefully selected doses, can help you reach your weight-loss goals.'
          : 'Losing weight — and keeping it off — can feel daunting. Not anymore. Our program brings together groundbreaking medication, lab testing, and the knowledge of leading clinicians to create a truly sustainable weight loss approach for you.'}
      </p>
      <Common.Button className="max-md:mt-auto" color="blue" fullWidthOnMobile onClick={onContinue}>
        Continue
      </Common.Button>
    </div>
  );
};

export default YouAreInGoodHands;

import React, { useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import type { DayEventHandler } from 'react-day-picker/src/types/shared';
import { useClickAway } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import Loader from 'shared/Loader';

import { DateFormat } from 'utils/enums';

import { DatePickerProps } from './calendarInput.types';

const CalendarInput: React.FC<DatePickerProps & { selected?: Date }> = ({
  onDateChange,
  selected,
  dataTestId,
  hint,
  label,
  placeholder = 'Date',
  disabledInput,
  startMonth = new Date(),
  endMonth,
  helper,
  labelDirection,
  className,
  errors,
  loading = false,
  onMonthChange,
  disabled = [
    {
      before: new Date()
    }
  ]
}) => {
  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const handleClick: DayEventHandler<React.MouseEvent> = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }
    onDateChange && onDateChange(day);
    setShowModal(false);
  };

  useClickAway(ref, () => {
    setShowModal(false);
  });

  return (
    <div className="relative" ref={ref}>
      <div className="absolute -top-6 left-1/2">
        <Loader isVisible={loading} relative />
      </div>
      <Common.Input
        className={className}
        dataTestId={dataTestId}
        disabled={disabledInput}
        errors={errors}
        helper={helper}
        hint={hint}
        inputClassName="!pr-3"
        label={label}
        labelDirection={labelDirection}
        placeholder={placeholder}
        preIcon="calendar"
        value={dayjs(selected).format(DateFormat.MM_DD_YYYY)}
        readOnly
        onFocus={() => setShowModal(!showModal)}
      />
      {showModal && (
        <div className="absolute z-10 rounded-lg bg-white shadow md:-left-8 md:-top-5 md:text-sm">
          <DayPicker
            components={{
              Chevron: (props) => (
                <Common.Icon
                  className="size-5 md:size-4"
                  name={props.orientation === 'left' ? 'arrow-left' : `arrow-right`}
                />
              )
            }}
            defaultMonth={selected}
            disabled={disabled}
            endMonth={endMonth}
            mode="single"
            selected={selected}
            startMonth={startMonth}
            onDayClick={handleClick}
            onMonthChange={onMonthChange}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarInput;

import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { EMAIL_REGEXP } from 'utils/regExp';

const EmailInput = <TFormValues extends FieldValues>({
  control,
  name,
  dataTestId,
  placeholder,
  onChange,
  label,
  className,
  inputClassName,
  labelClassName,
  size
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      maxLength: {
        message: 'The text entered exceeds the maximum length of 62 characters',
        value: 62
      },
      pattern: {
        message: 'Email is invalid',
        value: EMAIL_REGEXP
      },
      required: {
        message: 'Email is required',
        value: true
      }
    }
  });

  return (
    <Common.Input
      {...field}
      className={className}
      dataTestId={dataTestId}
      errors={fieldState.error}
      helper={fieldState.error?.message}
      inputClassName={inputClassName}
      label={label}
      labelClassName={labelClassName}
      placeholder={placeholder || 'email@address.com'}
      size={size}
      type="email"
      onChange={(e) => {
        onChange?.(e);
        field.onChange(e);
      }}
    />
  );
};

export default EmailInput;

import { DocumentItem } from 'models/document.types';

import { InsuranceDocsProps } from './insurance.types';

export const setInitialDocsStatus = (
  initialState: InsuranceDocsProps,
  data: DocumentItem[],
  insuranceID?: string
): InsuranceDocsProps => {
  const docs = { ...initialState };
  const frontDoc = data.find((d) =>
    !!insuranceID
      ? d.insuranceCard?.side === 1 && d.insuranceCard.insuranceId === insuranceID
      : d.insuranceCard?.side === 1 && !d.insuranceCard.insuranceId
  );
  const backDoc = data.find((d) =>
    !!insuranceID
      ? d.insuranceCard?.side === 2 && d.insuranceCard.insuranceId === insuranceID
      : d.insuranceCard?.side === 2 && !d.insuranceCard.insuranceId
  );
  if (frontDoc) {
    docs.front = {
      _id: frontDoc._id,
      fileName: frontDoc.fileName,
      filePath: frontDoc.filePath,
      fileStatus: 'success'
    };
  }
  if (backDoc) {
    docs.back = {
      _id: backDoc._id,
      fileName: backDoc.fileName,
      filePath: backDoc.filePath,
      fileStatus: 'success'
    };
  }
  return docs;
};

export const getDocumentIDs = (files: InsuranceDocsProps): string[] =>
  [files.front._id, files.back._id].filter((x): x is string => typeof x === 'string' && !!x);

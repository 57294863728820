import React, { FC, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import dayjs from 'dayjs';

import { useLazyGetHealthMetricsQuery } from 'services/healthMetrics/healthMetrics';
import { SortField, SortOrder } from 'services/healthMetrics/healthMetrics.types';

import { selectLatestHealthMetrics } from 'store';

import Pagination from 'features/Pagination';
import Table from 'features/Table';
import Loader from 'shared/Loader';
import Collapse from 'widgets/myChart/HealthMetrics/Collapse';

import { useAppSelector } from 'hooks';
import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { TableItem } from './bodyMassIndex.types';

const BodyMassIndex: FC = () => {
  const [getHealthMetrics, { isLoading, data }] = useLazyGetHealthMetricsQuery();
  const { bmi } = useAppSelector(selectLatestHealthMetrics);
  const [tableData, setTableData] = useState<TableItem[]>([]);
  const [params, setParams] = useState<{
    limit: number;
    pageNo: number;
    sortField: SortField;
    sortOrder: SortOrder;
  }>({
    pageNo: 0,
    limit: 5,
    sortField: 'collectedDate',
    sortOrder: 'DESC'
  });
  const { isMobile } = useWidth();
  const [isOpenCollapse, toggleIsOpenCollapse] = useToggle(false);

  const handleGetHealthMetrics = () => {
    getHealthMetrics({ ...params, metricType: 'bmi' });
  };

  useEffect(() => {
    if (!data?.data) return;

    const mappedData = data.data.map(({ collectedDate, metrics: { bmi } }) => ({
      'metrics.bmi': `${bmi || 0}`,
      collectedDate: dayjs(collectedDate.split('T')[0]).format(DateFormat.MMM_DD_YYYY)
    }));

    setTableData(mappedData);
  }, [data]);

  useEffect(() => {
    isOpenCollapse && handleGetHealthMetrics();
  }, [params, isOpenCollapse]);

  const totalCount = data?.info?.totalCount || 0;

  return (
    <>
      <Loader isVisible={isLoading} />
      {typeof bmi === 'number' && (
        <Collapse
          subTitle={bmi.toString()}
          title="Body Mass Index (BMI)"
          onChange={toggleIsOpenCollapse}
        >
          <p className="my-6 text-mSm text-gray-700 md:text-sm">
            Body weight is automatically calculated based on your height and weight.
          </p>
          <Table
            boldCols={isMobile ? [] : ['BMI']}
            data={tableData}
            handleSort={({ sortField, sortOrder }) =>
              setParams({ ...params, sortField: sortField as SortField, sortOrder })
            }
            headers={[
              { id: 'collectedDate', label: 'Date' },
              { id: 'metrics.bmi', label: 'BMI' }
            ]}
          />
          {totalCount > params.limit && (
            <Pagination
              labelDirection="column"
              params={{ ...params, totalCount }}
              onChange={({ selected }) => setParams({ ...params, pageNo: selected })}
            />
          )}
        </Collapse>
      )}
    </>
  );
};

export default BodyMassIndex;

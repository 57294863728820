import { useState } from 'react';

import { useAddProfileImageMutation, useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';

export const useUpdateAvatar = () => {
  const [addProfileImage, { isLoading }] = useAddProfileImageMutation();
  const [getMyAccount] = useLazyGetMyAccountQuery();
  const [fileToUseAsAvatar, setFileToUseAsAvatar] = useState<File | null>(null);

  const uploadAvatar = (cb?: () => void) => {
    if (!!fileToUseAsAvatar) {
      const formData = new FormData();
      formData.append('profileImage', fileToUseAsAvatar);
      addProfileImage(formData)
        .unwrap()
        .finally(() => {
          getMyAccount();
          cb?.();
        });
    } else {
      cb?.();
    }
  };

  return { isLoading, setFileToUseAsAvatar, uploadAvatar };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SymptomCheckerState } from './symptomChecker.types';

export const initialState: SymptomCheckerState = {
  allSymptoms: [],
  commonRiskFactors: [],
  conditions: [],
  evidence: [],
  initialSymptoms: [],
  questions: [],
  regions: [],
  specialist: {
    recommended_channel: 'audio_teleconsultation',
    recommended_specialist: {
      id: 'sp_1',
      name: 'General practitioner'
    }
  },
  suggestedEvidence: [],
  triage: {
    root_cause: '',
    serious: [],
    teleconsultation_applicable: false,
    triage_level: ''
  },
  userInfo: {
    age: 30,
    gender: 'male',
    patientSelect: 'myself',
    processAgreement: false,
    termsAgreement: false
  }
};

const symptomCheckerSlice = createSlice({
  initialState,
  name: 'symptomChecker',
  reducers: {
    clearSymptomChecker: (state) => {
      state.userInfo = initialState.userInfo;
      state.commonRiskFactors = initialState.commonRiskFactors;
      state.initialSymptoms = initialState.initialSymptoms;
      state.questions = initialState.questions;
      state.suggestedEvidence = initialState.suggestedEvidence;
      state.evidence = initialState.evidence;
      state.conditions = initialState.conditions;
      state.triage = initialState.triage;
      state.specialist = initialState.specialist;
      state.allSymptoms = initialState.allSymptoms;
    },
    setCommonRiskFactors: (
      state,
      action: PayloadAction<SymptomCheckerState['commonRiskFactors']>
    ) => {
      state.commonRiskFactors = action.payload;
    },
    setConditions: (state, action: PayloadAction<SymptomCheckerState['conditions']>) => {
      state.conditions = action.payload;
    },
    setEvidence: (state, action: PayloadAction<SymptomCheckerState['evidence']>) => {
      state.evidence = action.payload;
    },
    setInitialSymptoms: (state, action: PayloadAction<SymptomCheckerState['initialSymptoms']>) => {
      state.initialSymptoms = action.payload;
    },
    setQuestions: (state, action: PayloadAction<SymptomCheckerState['questions']>) => {
      state.questions = action.payload;
    },
    setRegions: (state, action: PayloadAction<SymptomCheckerState['regions']>) => {
      state.regions = action.payload;
    },
    setSpecialist: (state, action: PayloadAction<SymptomCheckerState['specialist']>) => {
      state.specialist = action.payload;
    },
    setSuggestedEvidence: (
      state,
      action: PayloadAction<SymptomCheckerState['suggestedEvidence']>
    ) => {
      state.suggestedEvidence = action.payload;
    },
    setSymptomList: (state, action: PayloadAction<SymptomCheckerState['allSymptoms']>) => {
      state.allSymptoms = action.payload;
    },
    setTriage: (state, action: PayloadAction<SymptomCheckerState['triage']>) => {
      state.triage = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<Partial<SymptomCheckerState['userInfo']>>) => {
      state.userInfo = { ...state.userInfo, ...action.payload };
    }
  }
});

export const {
  setUserInfo,
  setCommonRiskFactors,
  setQuestions,
  setEvidence,
  setInitialSymptoms,
  setSuggestedEvidence,
  clearSymptomChecker,
  setConditions,
  setTriage,
  setSpecialist,
  setSymptomList,
  setRegions
} = symptomCheckerSlice.actions;

export default symptomCheckerSlice.reducer;

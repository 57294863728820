import { ShippingStatuses } from 'utils/enums';

import { ElationPrescriptionItem } from 'models/prescriptions.types';

const MOCK_DATA = [
  {
    completed: true,
    label: 'Ordered',
    order: 0
  },
  {
    completed: false,
    label: 'Shipped',
    order: 1
  },
  {
    completed: false,
    label: 'Delivered',
    order: 2
  }
];

export const getStatusData = (data?: ElationPrescriptionItem['trackingInfo']) => {
  const currentStepOrder: { [key in ShippingStatuses | 'Ordered']?: number } = {
    Delivered: 2,
    Ordered: 0,
    Shipped: 1
  };
  if (data && data.shippingStatus && currentStepOrder.hasOwnProperty(data.shippingStatus)) {
    const currentStatusNumber = currentStepOrder[data.shippingStatus] || 0;
    return MOCK_DATA.map((item) => ({ ...item, completed: item.order <= currentStatusNumber }));
  }
  return MOCK_DATA;
};

import { ReviewOrigin, ReviewProblemType } from 'utils/enums';

import { BaseModalProps } from '../modal.types';

export interface FeedbackModalProps extends BaseModalProps {
  origin?: ReviewOrigin;
}

export interface ImprovementOption {
  id: ReviewProblemType;
  label: string;
}

export const IMPROVEMENT_OPTIONS: ImprovementOption[] = [
  {
    id: ReviewProblemType.UsingApp,
    label: 'Using the app'
  },
  {
    id: ReviewProblemType.MyProvider,
    label: 'My provider'
  }
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DocumentItem } from 'models/document.types';

import { DocumentsState } from './documents.types';

const initialState: DocumentsState = {
  documents: [],
  totalItems: 0
};

const documentsSlice = createSlice({
  initialState,
  name: 'documents',
  reducers: {
    setDocuments: (state, action: PayloadAction<DocumentItem[]>) => {
      state.documents = action.payload;
    },
    setDocumentsLength: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    }
  }
});

export const { setDocuments, setDocumentsLength } = documentsSlice.actions;

export default documentsSlice.reducer;

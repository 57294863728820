import { Common } from '@thecvlb/design-system';

import useWidth from 'hooks/useWidth';

import { BaseModalProps } from '../modal.types';

import ExampleTwo from 'assets/images/signUp/insurance-example-1.png';
import ExampleOne from 'assets/images/signUp/insurance-example-2.png';

const InsuranceExamples: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  const { isMobile } = useWidth();
  return (
    <Common.Modal close={onClose} isOpen={isOpen} size={isMobile ? 'sm' : 'lg'}>
      <div className="grid gap-4 md:grid-cols-2 md:flex-row" data-testid="insurance_examples">
        <img alt="example-1" src={ExampleOne} />
        <img alt="example-2" src={ExampleTwo} />
      </div>
    </Common.Modal>
  );
};

export default InsuranceExamples;

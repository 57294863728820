import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import { ActiveSpeaker } from '@zoom/videosdk/dist/types/event-callback';
import classNames from 'classnames';

import VoiceIndicator from 'widgets/zoom/VoiceIndicator';

import { useZoomCall } from 'hooks';
import useWidth from 'hooks/useWidth';

const LocalParticipant = () => {
  const { videoEnabled, room, audioEnabled, isMinimize, isOpenChat } = useZoomCall();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { isMobile } = useWidth();
  const isMinimizeOrMobile = isMinimize || (isMobile && isOpenChat);
  const isRenderSelfViewWithVideoElement = room
    ?.getMediaStream()
    ?.isRenderSelfViewWithVideoElement();

  const activeSpeakerHandler = (payload: Array<ActiveSpeaker>) => {
    const user = payload.find((el) => el.userId === room?.getCurrentUserInfo()?.userId);
    if (!user) return;
    setIsSpeaking(true);
    setTimeout(() => setIsSpeaking(false), 1000);
  };

  useEffect(() => {
    room?.on('active-speaker', activeSpeakerHandler);
    return () => {
      room?.off('active-speaker', activeSpeakerHandler);
    };
  }, []);

  const iconClassName = classNames('right-1 top-1 size-7 rounded-full p-1 text-white', {
    absolute: videoEnabled
  });

  return (
    <div
      className={classNames(
        'absolute bottom-[150px] left-4 z-10 h-auto max-w-[148px] overflow-hidden rounded-2xl border bg-white shadow-mXl md:bottom-4',
        {
          hidden: isMinimizeOrMobile,
          'p-[18px]': !videoEnabled
        }
      )}
    >
      <video
        className={classNames('h-auto w-full bg-gray-700', {
          hidden: !videoEnabled || !isRenderSelfViewWithVideoElement
        })}
        height="1080"
        id="local-video"
        width="1920"
      />
      <canvas
        className={classNames('h-auto w-full bg-gray-700', {
          hidden: !videoEnabled || isRenderSelfViewWithVideoElement
        })}
        height="1080"
        id="local-canvas"
        width="1920"
      />
      {(!videoEnabled || isSpeaking) && audioEnabled && (
        <VoiceIndicator isSpeaking={isSpeaking} videoEnabled={videoEnabled} local />
      )}
      {!audioEnabled && (
        <Common.Icon className={`${iconClassName} bg-black/40`} name="microphone-off" />
      )}
    </div>
  );
};

export default LocalParticipant;

import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import InputMask from '@mona-health/react-input-mask';
import { Common } from '@thecvlb/design-system';

const ZipCodeInput = <TFormValues extends FieldValues>({
  control,
  name,
  dataTestId,
  label,
  placeholder,
  size
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      required: {
        message: 'ZIP code is required',
        value: true
      },
      validate: {
        isValid: (v) => v?.trim().length === 5 || 'Invalid ZIP code'
      }
    }
  });

  return (
    <InputMask mask="99999" type="tel" {...field} maskPlaceholder={null} ref={null}>
      <Common.Input
        dataTestId={dataTestId}
        errors={fieldState.error}
        helper={fieldState.error?.message}
        label={label}
        placeholder={placeholder}
        ref={field.ref}
        size={size}
        type="tel"
      />
    </InputMask>
  );
};

export default ZipCodeInput;

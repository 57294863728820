import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import { GetMifStructureRes } from './mifs.types';

export const mifsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getMifStructure: build.query<GetMifStructureRes, string>({
      query: (id) => ({
        url: `/mifs/${id}`
      })
    }),
    sendMifResponse: build.mutation<
      unknown,
      {
        body: unknown[];
        id: string;
      }
    >({
      query: ({ id, body }) => ({
        url: `/mifs/${id}/results`,
        method: 'POST',
        body
      })
    })
  }),
  reducerPath: 'mifsApi',
  tagTypes: ['mifs']
});

export const { useLazyGetMifStructureQuery, useSendMifResponseMutation } = mifsApi;

import { createApi } from '@reduxjs/toolkit/query/react';

import {
  setAdminPrescriptions,
  setMedicalRecords,
  setPrescriptions
} from 'store/patientChart/patientChartSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  GetAdminPrescriptionsReqProps,
  GetAdminPrescriptionsResProps,
  GetMedicalRecordsReqProps,
  GetMedicalRecordsResProps,
  GetMpMagicLinkReqProps,
  GetMpMagicLinkResProps,
  GetPrescriptionsReqProps,
  GetPrescriptionsResProps
} from './patientChart.types';

export const patientChartApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getAdminPrescriptions: build.query<
      GetAdminPrescriptionsResProps,
      GetAdminPrescriptionsReqProps
    >({
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setAdminPrescriptions(data.data.prescriptions));
        }
      },
      query: (patientId) => `patients/${patientId}/prescriptions`
    }),
    getMedicalRecords: build.query<GetMedicalRecordsResProps, GetMedicalRecordsReqProps>({
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setMedicalRecords(data.data));
        }
      },
      query: (patientId) => `patients/${patientId}/my-chart`
    }),
    getMpMagicLink: build.query<GetMpMagicLinkResProps, GetMpMagicLinkReqProps>({
      query: (patientId) => `/patients/${patientId}/mp-magic-link`
    }),
    getPrescriptions: build.query<GetPrescriptionsResProps, GetPrescriptionsReqProps>({
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setPrescriptions(data.data));
        }
      },
      query: () => '/patient-chart/prescriptions'
    })
  }),
  reducerPath: 'patientChartApi',
  tagTypes: ['patientChart']
});

export const {
  useGetPrescriptionsQuery,
  useGetAdminPrescriptionsQuery,
  useGetMedicalRecordsQuery,
  useLazyGetMpMagicLinkQuery
} = patientChartApi;

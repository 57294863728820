import { useEffect } from 'react';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { useLazyGetProviderQuery } from 'services/providers/providers';
import { GetProviderResProps } from 'services/providers/providers.types';

import { selectProvider, selectUser } from 'store';
import { setProvider } from 'store/provider/providerSlice';

import MyChartTitle from 'features/MyChartTitle';
import FeedbackReview from 'modals/FeedbackReview';
import ProviderReview from 'modals/ProviderReview';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Specialty from 'widgets/signUp/ProviderInfoCard/Specialty';

import { useAppDispatch, useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { ReviewOrigin } from 'utils/enums';
import { formatProviderDefinition } from 'utils/helpers';

const Provider: React.FC = () => {
  useTitle('LifeMD - My provider');

  const logEvent = useAnalytics();
  const [isOpenProviderReview, toggleOpenProviderReview] = useToggle(false);
  const [isOpenFeedbackReview, toggleOpenFeedbackReview] = useToggle(false);
  const dispatch = useAppDispatch();
  const [getProvider, { isLoading }] = useLazyGetProviderQuery();
  const { doctorId, isUnlimitedPlan, lastSentRating, reviewedProviders } =
    useAppSelector(selectUser);
  const provider = useAppSelector(selectProvider);

  const handleGetProviderThen = (data: GetProviderResProps) => {
    data?.data && dispatch(setProvider(data.data));
  };

  const handleGetProvider = () => {
    getProvider({
      providerId: doctorId
    })
      .unwrap()
      .then(handleGetProviderThen);
  };

  const handleCloseProviderReview = () => {
    toggleOpenProviderReview();
    lastSentRating !== 0 && toggleOpenFeedbackReview();
  };

  const handleClickReviewProvider = () => {
    logEvent('my_provider_review_btn_click');
    toggleOpenProviderReview();
  };

  useEffect(handleGetProvider, []);

  return (
    <>
      <Loader isVisible={isLoading} />
      {!isLoading && (
        <FadeWrapper className="rounded-2xl border border-gray-200 bg-white p-4 md:border-none md:p-8">
          <ProviderReview
            isOpen={isOpenProviderReview}
            provider={provider}
            onClose={handleCloseProviderReview}
            onSkip={toggleOpenProviderReview}
          />
          <FeedbackReview
            isOpen={isOpenFeedbackReview}
            origin={ReviewOrigin.Provider}
            onClose={toggleOpenFeedbackReview}
          />
          <MyChartTitle icon="doctor" text={formatProviderDefinition(isUnlimitedPlan)} />
          <div
            className="mx-auto flex p-2 max-md:flex-col md:mt-12 md:max-w-[960px] md:gap-8 md:p-0"
            data-testid="provider_section"
          >
            <div className="md:w-1/3">
              <div className="flex items-start gap-4">
                <Common.ProfileImage size="lg" src={provider.profileImage} />
                <div>
                  <p className="text-mLg font-bold md:text-xl" data-testid="doctor_name">
                    {provider.displayName}
                  </p>
                  {provider.availableReview && !reviewedProviders?.includes(provider.userId) && (
                    <button
                      className="mt-1 flex items-center gap-1 text-gray md:mt-2"
                      data-testid="review_your_doctor_btn"
                      onClick={handleClickReviewProvider}
                    >
                      <Common.Icon name="pencil" />
                      Review provider
                    </button>
                  )}
                </div>
              </div>
              <div className="mt-8 md:mt-4">
                <div className="flex flex-wrap gap-2" data-testid="doc_speciality">
                  {provider.specialtyAreas.map(({ name, icon }) => (
                    <Specialty icon={icon} key={name} name={name} />
                  ))}
                  {!provider.specialtyAreas.length && (
                    <span className="text-sm text-gray-700">No specialty areas yet...</span>
                  )}
                </div>
                <div className="my-6 flex flex-col gap-3 md:my-4">
                  {provider.experience
                    .filter((exp) => exp.trim() !== '')
                    .map((exp) => (
                      <div className="flex items-center gap-2" key={exp}>
                        <Common.Icon className="size-4 text-primary md:size-5" name="graduation" />
                        <span data-testid="education">{exp}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="whitespace-pre-line md:w-2/3" data-testid="doctor_biography">
              {provider.aboutDoctor || 'No information about this provider yet'}
            </div>
          </div>
        </FadeWrapper>
      )}
    </>
  );
};

export default Provider;

import { useEffect, useState } from 'react';
import { LiveTranscriptionMessage } from '@zoom/videosdk/dist/types/live-transcription';

import { useZoomCall } from 'hooks';

const Captions = () => {
  const { room, isEnableCaptions } = useZoomCall();
  const [message, setMessage] = useState('');

  const handleCaptionMessage = (payload: LiveTranscriptionMessage) => {
    if (payload.userId === room?.getCurrentUserInfo()?.userId) return;
    setMessage(payload.text);
  };

  useEffect(() => {
    room?.on('caption-message', handleCaptionMessage);
    return () => {
      room?.off('caption-message', handleCaptionMessage);
    };
  }, [room]);

  if (!message || !isEnableCaptions) return null;

  return (
    <div className="absolute inset-x-0 bottom-[220px] mx-auto w-fit max-w-[90%] rounded-lg bg-black/90 p-2 text-white md:bottom-[120px]">
      {message}
    </div>
  );
};

export default Captions;

import { Common } from '@thecvlb/design-system';

const EncryptedBadge = () => {
  return (
    <h3 className="flex justify-center text-sm font-semibold" data-testid="secure_payment">
      <Common.Icon className="mr-2 text-secondary" name="lock" />
      SSL, Encrypted, Secure Payment
    </h3>
  );
};

export default EncryptedBadge;

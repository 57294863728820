import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReportsItemProps, ReportsState } from './reports.types';

const initialState: ReportsState = {
  reports: []
};

const reportsSlice = createSlice({
  initialState,
  name: 'reports',
  reducers: {
    setReports: (state, action: PayloadAction<ReportsItemProps[]>) => {
      state.reports = action.payload;
    }
  }
});

export const { setReports } = reportsSlice.actions;

export default reportsSlice.reducer;

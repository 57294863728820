import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import Address from 'shared/form/Address';
import PhoneInput from 'shared/form/PhoneInput/PhoneInput';
import TextInput from 'shared/form/TextInput';

import { MedicalRecordsFormProps } from './medicalRecordsForm.types';

const MedicalRecordsForm: React.FC<MedicalRecordsFormProps> = ({ onClickCancel }) => {
  const { handleSubmit, control, trigger, setValue } = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const [listOfPhoneNumbers, setListOfPhoneNumbers] = useState<string[]>(['phone_1']);
  const [alternateFirstName, setAlternateFirstName] = useState<string[]>([
    'personalInfo.alternateName_1'
  ]);

  const onFormSubmit = (formData: unknown) => {
    // eslint-disable-next-line no-console
    console.log(formData);
  };

  const handleAlternateFirstName = () => {
    setAlternateFirstName([
      ...alternateFirstName,
      `personalInfo.alternateName_${alternateFirstName.length + 1}`
    ]);
  };
  const handlePreviousAddress = () => {};

  const handlePreviousPhoneNumber = () => {
    setListOfPhoneNumbers([...listOfPhoneNumbers, `phone_${listOfPhoneNumbers.length + 1}`]);
  };

  const titleClassName = 'mt-4 text-mBase font-bold text-gray-700 md:text-xl md:text-primary';
  const subtitleClassName = 'hidden text-sm font-medium text-gray md:block';

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="mb-6 flex max-w-[480px] flex-col gap-4">
        <div>
          <h2 className={titleClassName}>Name</h2>
          <p className={subtitleClassName}>Please list any alternate spellings of your name</p>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <TextInput
            control={control}
            dataTestId="firstname_field"
            invalidErrorMsg="First name is invalid"
            label="First name"
            name="personalInfo.firstName"
            placeholder="Enter first name"
            requiredErrorMsg="First name is required"
          />
          <TextInput
            control={control}
            dataTestId="lastname_field"
            invalidErrorMsg="Last name is invalid"
            label="Last name"
            name="personalInfo.lastName"
            placeholder="Enter last name"
            requiredErrorMsg="First name is required"
          />
        </div>
        {alternateFirstName.map((name) => (
          <TextInput
            control={control}
            dataTestId="alternatename_field"
            invalidErrorMsg="Invalid alternate first name"
            key={name}
            label="Alternate first name"
            name={name}
            placeholder="Alternate first name"
            required={false}
          />
        ))}
        <Common.Button
          color="blue-alt"
          preIcon="plus"
          size="md"
          type="button"
          onClick={handleAlternateFirstName}
        >
          Alternate first name
        </Common.Button>
        <hr className="mt-4" />
        <div>
          <h2 className={titleClassName}>Address</h2>
          <p className={subtitleClassName}>Please list all previous addresses</p>
        </div>
        <Address control={control} setValue={setValue} trigger={trigger} />
        <Common.Button
          color="blue-alt"
          preIcon="plus"
          size="md"
          type="button"
          onClick={handlePreviousAddress}
        >
          Previous address
        </Common.Button>
        <hr className="mt-4" />
        <div>
          <h2 className={titleClassName}>Contact information</h2>
          <p className={subtitleClassName}>Please list all previous phone numbers.</p>
        </div>
        {listOfPhoneNumbers.map((name) => (
          <PhoneInput control={control} key={name} label="Phone number" name={name} />
        ))}
        <Common.Button
          color="blue-alt"
          preIcon="plus"
          size="md"
          type="button"
          onClick={handlePreviousPhoneNumber}
        >
          Previous phone number
        </Common.Button>
      </div>
      <div className="sticky bottom-0 -mx-4 flex gap-4 border-gray-200 bg-gray-50 p-4 md:-mx-8 md:-mb-8 md:rounded-b-2xl md:border-t md:bg-white md:px-8 md:py-6">
        <Common.Button color="blue" dataTestId="save_btn" fullWidthOnMobile>
          Save
        </Common.Button>
        <Common.Button
          className="hidden md:block"
          color="white-alt"
          dataTestId="discard_btn"
          type="button"
          onClick={onClickCancel}
        >
          Cancel
        </Common.Button>
      </div>
    </form>
  );
};

export default MedicalRecordsForm;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Cart } from 'models/shop';

import { ShopState } from './shop.types';

const initialState: ShopState = {
  activeProductName: 'Product details',
  cart: {
    items: [],
    total: 0,
    subTotal: 0
  },
  shippingAddress: {
    address: '',
    city: '',
    state: '',
    zipCode: ''
  }
};

const shopSlice = createSlice({
  initialState,
  name: 'settings',
  reducers: {
    setActiveProductName: (state, action: PayloadAction<string>) => {
      state.activeProductName = action.payload;
    },
    setCart: (state, action: PayloadAction<Cart>) => {
      state.cart = action.payload;
    },
    setShippingAddress: (state, action: PayloadAction<ShopState['shippingAddress']>) => {
      state.shippingAddress = action.payload;
    },
    resetStore: () => initialState
  }
});

export const { setActiveProductName, setCart, setShippingAddress, resetStore } = shopSlice.actions;

export default shopSlice.reducer;

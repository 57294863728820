import { useEffect, useState } from 'react';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { AnimatePresence } from 'framer-motion';
import fileDownload from 'js-file-download';

import { useDeleteDocumentMutation, useLazyGetDocumentsQuery } from 'services/documents/documents';
import { SourceTypes } from 'services/documents/documents.types';

import { selectDocuments, selectToken } from 'store';
import { setDocuments } from 'store/documents/documentsSlice';

import Document from 'features/Document';
import MyChartTitle from 'features/MyChartTitle';
import DeleteDocument from 'modals/DeleteDocument';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import { notifyError } from 'shared/Toast/Toast';
import DocumentUploader from 'widgets/DocumentUploader';

import { useAppDispatch, useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useWidth from 'hooks/useWidth';

import { DocumentItem } from 'models/document.types';
import { Option } from 'models/forms.types';

import { downloadFile } from './documents.settings';

const Documents = () => {
  useTitle('LifeMD - Documents');
  const logEvent = useAnalytics();
  const { isMobile } = useWidth();
  const tabs: Option<SourceTypes | undefined>[] = [
    { label: 'All', value: undefined },
    { label: 'Shared with me', value: 'uploaded_by_staff' },
    { label: 'Uploaded', value: 'uploaded_by_patient' }
  ];
  const [deletingIDsList, setDeletingIDsList] = useState<string[]>([]);
  const [tab, setTab] = useState(tabs[0].label);
  const [page, setPage] = useState(0);
  const [filesCount, setFilesCount] = useState(0);
  const [documentToDelete, setDocumentToDelete] = useState<DocumentItem | null>(null);
  const [displayUploader, toggleDisplayUploader] = useToggle(false);
  const { documents } = useAppSelector(selectDocuments);
  const accessToken = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [getDocuments, { isFetching: getDocumentsLoading }] = useLazyGetDocumentsQuery();
  const [deleteDocument, { isLoading: deleteDocumentsLoading }] = useDeleteDocumentMutation();

  const handleDownloadDocument = async (doc: DocumentItem) => {
    try {
      const file = await downloadFile(accessToken, doc._id);
      fileDownload(file, doc.fileName);
    } catch (e) {
      notifyError((e as Error).message || 'Error, please try again');
    }
  };

  const handleGetDocuments = (pageNo = 0, isLoadingMore = false, docID?: string) => {
    const source = tabs.find((t) => t.label === tab)?.value;
    getDocuments({ pageNo, source })
      .unwrap()
      .then((res) => {
        if (!res) return;
        const newDocuments = isLoadingMore ? [...documents, ...res.data] : res.data;
        dispatch(setDocuments(newDocuments));
        setFilesCount(res.info.totalCount || 0);
        if (docID) setDeletingIDsList(deletingIDsList.filter((id) => id !== docID));
        if (!res.data.length && tab === 'All') toggleDisplayUploader(true);
      });
  };

  const handleLoadMore = () => {
    handleGetDocuments(page + 1, true);
    setPage(page + 1);
  };

  const handleDeleteDocument = (id: string) => {
    setDeletingIDsList([...deletingIDsList, id]);
    deleteDocument({ documentId: id })
      .unwrap()
      .then(() => {
        setDocumentToDelete(null);
        setPage(0);
        handleGetDocuments(0, false, id);
      });
  };

  const showLoadMore =
    documents.length > 0 && filesCount > documents.length && !getDocumentsLoading;

  const scrollFn = () => {
    const documentHeight = document.body.scrollHeight;
    const currentScroll = window.scrollY + window.innerHeight;
    // gap is for px from the bottom
    const GAP = 10;
    if (
      currentScroll + GAP >= documentHeight &&
      documents.length > 0 &&
      filesCount > documents.length
    ) {
      handleLoadMore();
    }
  };

  const handleCancelUpload = () => {
    logEvent(displayUploader ? 'documents_cancel_btn_click' : 'documents_upload_btn_click');
    toggleDisplayUploader();
  };

  const handleSetTab = (label: string) => {
    setTab(label);
    switch (label) {
      case 'All':
        return logEvent('documents_all_tab_click');
      case 'Shared with me':
        return logEvent('documents_shared_tab_click');
      case 'Uploaded':
        return logEvent('documents_uploaded_tab_click');
    }
  };

  const handleClickDocument = (document: DocumentItem) => {
    logEvent('documents_document_item_click');
    window.open(document.filePath, '_blank');
  };

  const handleClickDeleteDocument = (document: DocumentItem) => {
    logEvent('documents_delete_btn_click');
    document.category !== 'user-identity' && setDocumentToDelete(document);
  };

  const handleChangedTab = () => {
    dispatch(setDocuments([]));
    setPage(0);
    handleGetDocuments(0);
    toggleDisplayUploader(false);
  };

  useEffect(handleChangedTab, [tab]);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', scrollFn);
      return () => {
        window.removeEventListener('scroll', scrollFn);
      };
    }
  }, [documents.length]);

  return (
    <FadeWrapper className="md:p-8">
      {!!documentToDelete && (
        <DeleteDocument
          document={documentToDelete}
          isLoading={deleteDocumentsLoading}
          isOpen={!!documentToDelete}
          onClose={() => setDocumentToDelete(null)}
          onConfirm={() => {
            handleDeleteDocument(documentToDelete._id);
          }}
        />
      )}
      <MyChartTitle icon="articles" text="Documents" />
      <div className="flex items-center justify-between md:mb-4">
        <h2 className="hidden text-xl font-bold text-gray-700 md:block">Documents</h2>
        <Common.Button
          className="inset-x-0 bottom-6 z-10 mx-auto max-md:!fixed md:static md:m-0"
          color={displayUploader ? 'white-alt' : 'blue'}
          dataTestId={`${displayUploader ? 'cancel' : 'upload'}_btn`}
          preIcon={displayUploader ? 'close' : 'plus'}
          style={isMobile ? 'pill' : undefined}
          onClick={handleCancelUpload}
        >
          {displayUploader ? 'Cancel' : 'Upload'}
        </Common.Button>
      </div>
      <Common.Tabs data={tabs} type="bar" onChange={(e) => handleSetTab(e.label)} />
      <AnimatePresence mode="wait">
        {displayUploader ? (
          <DocumentUploader getDocuments={handleGetDocuments} onToggle={toggleDisplayUploader} />
        ) : (
          <>
            {documents.length ? (
              <FadeWrapper className="mt-4 divide-y rounded-2xl border border-gray-200 bg-white px-4 md:m-0 md:border-none md:p-0">
                {documents.map((document) => (
                  <Document
                    dataTestId="uploaded_document"
                    date={document.createdAt}
                    isLoadingDelete={
                      deletingIDsList.includes(document._id) && deleteDocumentsLoading
                    }
                    isUploadedByCurrentUser={document.isUploadedByCurrentUser}
                    key={document._id}
                    loading={
                      (deleteDocumentsLoading || getDocumentsLoading) &&
                      deletingIDsList.includes(document._id)
                    }
                    title={document.fileName}
                    uploadedBy={document.uploadedBy}
                    onClick={() => handleClickDocument(document)}
                    onDelete={() => handleClickDeleteDocument(document)}
                    onDownload={() => handleDownloadDocument(document)}
                  />
                ))}
              </FadeWrapper>
            ) : (
              <span className="block p-4 text-base text-gray">
                {getDocumentsLoading ? 'Loading...' : 'No uploaded documents...'}
              </span>
            )}
            {showLoadMore && (
              <Common.Button
                className="mx-auto my-4 hidden text-center md:flex"
                color="white-alt"
                dataTestId="load_more_btn"
                disabled={getDocumentsLoading}
                onClick={handleLoadMore}
              >
                Load more...
              </Common.Button>
            )}
          </>
        )}
      </AnimatePresence>
    </FadeWrapper>
  );
};

export default Documents;

import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { NAME_REGEXP } from 'utils/regExp';

const TextInput = ({
  name,
  control,
  placeholder,
  label,
  labelDirection,
  defaultValue = '',
  requiredErrorMsg,
  invalidErrorMsg,
  disabled,
  isRequired = true,
  regExp = NAME_REGEXP,
  maxLength = 40,
  dataTestId,
  className,
  labelClassName,
  postText,
  preIcon,
  hint,
  size
}: FieldValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
    rules: {
      maxLength: {
        message: 'The text entered exceeds the maximum length',
        value: maxLength
      },
      pattern: {
        message: invalidErrorMsg || `${placeholder} is invalid`,
        value: regExp
      },
      required: {
        message: requiredErrorMsg || `${placeholder} is required`,
        value: isRequired
      }
    }
  });

  return (
    <Common.Input
      {...field}
      className={className}
      dataTestId={dataTestId}
      disabled={disabled}
      errors={fieldState.error}
      helper={fieldState.error?.message}
      hint={hint}
      label={label}
      labelClassName={labelClassName}
      labelDirection={labelDirection}
      placeholder={placeholder}
      postText={postText}
      preIcon={preIcon}
      size={size}
      tabIndex={0}
    />
  );
};

export default TextInput;

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { PathName } from 'utils/enums';

import { ButtonsGroupProps } from './buttonsGroup.types';

const ButtonsGroup: React.FC<ButtonsGroupProps> = ({
  onClickNext,
  onClickPrev,
  hideNext,
  hidePrev,
  nextBtnText = 'Next',
  isDisabledNextBtn,
  loading = false
}) => {
  const isSignUp = location.pathname.includes(PathName.SignUp);
  const prevBtnClassName = classNames('md:flex pl-2 md:pl-5', { hidden: isSignUp });
  return (
    <div className="mt-auto flex flex-col items-center justify-center gap-3 pt-8 md:mt-6 md:flex-row md:gap-2 md:pt-0">
      {!hidePrev && (
        <Common.Button
          className={prevBtnClassName}
          color="blue-alt"
          preIcon="arrow-left"
          fullWidthOnMobile
          onClick={onClickPrev}
        >
          Back
        </Common.Button>
      )}
      {!hideNext && (
        <Common.Button
          color="blue"
          disabled={isDisabledNextBtn}
          isLoading={loading}
          postIcon="arrow-right"
          fullWidthOnMobile
          onClick={onClickNext}
        >
          {nextBtnText}
        </Common.Button>
      )}
    </div>
  );
};

export default ButtonsGroup;

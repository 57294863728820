import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useNetworkState } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common/Icon';
import classNames from 'classnames';

import { usePoorConnectionMutation } from 'services/appointments/appointments';

import { useZoomCall } from 'hooks';

const getConnectionParams = (level: number) => {
  const getConnectionParamsByLevel = (className: string, icon: string) => ({
    className,
    icon
  });

  switch (level) {
    case 1:
    case 2:
      return getConnectionParamsByLevel('text-yellow-300', 'connection-1-3');
    case 3:
    case 4:
      return getConnectionParamsByLevel('text-green-300', 'connection-2-3');
    case 5:
      return getConnectionParamsByLevel('text-green-300', 'connection-3-3');
    default:
      return getConnectionParamsByLevel('text-white', 'connection-0-3');
  }
};

const NetworkQualityLevel = () => {
  const { room, toggleConnectionLost, isOpenChat, appointment } = useZoomCall();
  const [poorConnection] = usePoorConnectionMutation();
  const { online } = useNetworkState();
  const [localNetworkQualityLevel, setLocalNetworkQualityLevel] = useState<number>(5);
  const handleUpdatedLocalNetworkQualityLevel = () => {
    if (localNetworkQualityLevel <= 2) {
      appointment?._id && poorConnection({ appointmentId: appointment?._id });
    }
    if (localNetworkQualityLevel < 1) {
      const timerFunc = setTimeout(toggleConnectionLost, 20000);
      return () => {
        clearTimeout(timerFunc);
        toggleConnectionLost(false);
      };
    }
  };

  useEffect(handleUpdatedLocalNetworkQualityLevel, [localNetworkQualityLevel]);

  useEffect(() => {
    setLocalNetworkQualityLevel(online ? 5 : 0);
  }, [online]);

  const handleNetworkQualityChange = ({ level }: { level: number }) =>
    setLocalNetworkQualityLevel(level);

  useEffect(() => {
    room?.on('network-quality-change', handleNetworkQualityChange);
    return () => {
      room?.off('network-quality-change', handleNetworkQualityChange);
    };
  }, []);

  const connectionParams = getConnectionParams(localNetworkQualityLevel);

  return (
    <>
      <Tooltip
        className="!rounded-xl !p-3 !text-sm !shadow-sm"
        classNameArrow="!w-4 !h-4"
        id="network-quality"
        isOpen={localNetworkQualityLevel === 0}
        place="bottom-end"
        variant="light"
      />
      <div
        className={classNames(
          'flex h-fit items-center justify-center gap-1 rounded-lg p-2 shadow-lg',
          localNetworkQualityLevel === 0 ? 'bg-red' : 'bg-black/60',
          { 'max-md:hidden': isOpenChat }
        )}
        data-tooltip-content="Poor network quality"
        data-tooltip-id="network-quality"
      >
        <Common.Icon
          className={`size-4 ${connectionParams.className}`}
          name={connectionParams.icon as IconProps['name']}
        />
      </div>
    </>
  );
};

export default NetworkQualityLevel;

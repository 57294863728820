import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { BLUE_LINE_ONBOARDING_PRICE_POINTS } from 'constants/onboarding';

import { Props } from './planCardItem.types';

const PlanCardItem: React.FC<Props> = ({
  isSelected,
  pricePoint,
  estimatedWL,
  showScales,
  subheaderText,
  discountTag,
  totalValue,
  additionalText
}) => {
  const wrapperClassName = classNames(
    'p-4 rounded-2xl border flex flex-col border-2 gap-[10px] transition-all max-md:min-h-[102px]',
    isSelected ? 'border-secondary outline-2 bg-secondary-50' : 'bg-white border-gray-200'
  );
  const itemClassName =
    'flex items-start gap-1 text-mSm leading-3 text-primary-700 md:text-sm items-center';

  const isBlueLinePP = BLUE_LINE_ONBOARDING_PRICE_POINTS.includes(pricePoint.planPricePointId);
  const isInsurancePP = pricePoint.categories?.includes('insurance');

  const getWLText = (qty: number): string => {
    if (qty === 1) {
      return `${estimatedWL} lbs per month`;
    }
    return `${estimatedWL} lbs in ${qty} months`;
  };

  return (
    <div className={wrapperClassName}>
      <div className="flex w-full flex-col gap-1">
        <div className="flex justify-between text-primary-700">
          <div className="flex min-w-fit items-center gap-2 font-bold md:flex-col md:items-start">
            <h3 className="text-base md:text-lg">
              {`${pricePoint.paymentInterval.qty} Month ${isInsurancePP ? 'Membership' : 'GLP-1 Program'}`}
            </h3>
            {discountTag}
          </div>
          {isInsurancePP && Number(pricePoint.trialInterval?.qty) > 0 ? (
            <span className="text-base font-bold">Free</span>
          ) : (
            <span className="text-base font-bold">
              {(Number(pricePoint.totalCost) > Number(totalValue) || isBlueLinePP) && (
                <span className="mr-1 font-medium text-gray line-through">
                  ${isBlueLinePP ? Number(pricePoint.totalCost) + 90 : pricePoint.totalCost}
                </span>
              )}
              ${totalValue}
            </span>
          )}
        </div>
        {pricePoint.paymentInterval.qty === 12 && showScales && (
          <Common.ColorTag color="violet" size="sm" text="Includes free scale" />
        )}
        <span className="block text-left text-sm text-primary">{subheaderText}</span>
        {additionalText && (
          <span className="text-left text-mXs text-gray md:text-xs">{additionalText}</span>
        )}
        {/* <span className="text-left text-m2xs text-gray md:text-xs">
          Cost of medication not included.
        </span> */}
      </div>
      {!!estimatedWL && (
        <div className={itemClassName}>
          <Common.Icon className="w-[18px]" name="scale-outline" />
          <p className="font-bold">Estimated weight loss:</p>
          <span className="ml-0.5">{getWLText(pricePoint.paymentInterval.qty)}</span>
        </div>
      )}
    </div>
  );
};

export default PlanCardItem;

import React from 'react';
import { Common } from '@thecvlb/design-system';

import { WITHINGS_BODY_PRO_2_SCALE_PRICE } from 'constants/defaults';

import Collapse from './Collapse';
import { ScaleDetailsProps } from './scaleDetails.types';

import ScaleSvg from 'assets/images/signUp/scale.svg?react';

const ScaleDetails: React.FC<ScaleDetailsProps> = ({
  isOpen,
  onClose,
  onClickAdd,
  isAddedToCart,
  priceWithDiscount
}) => {
  const parsePriceWithDiscount = priceWithDiscount === '0' ? 'Free' : `$${priceWithDiscount}`;

  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="lg">
      <div className="grid gap-6 md:grid-cols-2">
        <div>
          <ScaleSvg className="mx-auto md:mb-6 md:size-[240px] md:rounded-2xl md:bg-primary-100 md:px-10 md:py-1" />
          <div className="flex flex-col-reverse gap-3 bg-white pb-2 max-md:hidden">
            <Common.Button
              className="!w-full"
              color="white-alt"
              fullWidthOnMobile
              onClick={() => onClose()}
            >
              Close
            </Common.Button>
            <Common.Button
              className="!w-full"
              color={isAddedToCart ? 'green-alt' : 'blue'}
              preIcon={isAddedToCart ? 'check' : undefined}
              fullWidthOnMobile
              onClick={onClickAdd}
            >
              {isAddedToCart ? 'Added' : 'Add to order'}
            </Common.Button>
          </div>
        </div>
        <div className="flex flex-col max-md:gap-6">
          <div>
            <Common.ColorTag color="violet" size="sm" text="Add-on" />
            <h2 className="my-2 text-mLg font-bold text-primary-700">Withings Body Pro 2 Scale</h2>
            <p className="text-mSm text-gray">
              Track progress and stay motivated with the Withings Smart Scale, designed for seamless
              integration with your LifeMD program.
            </p>
            <p className="mt-5 text-mLg font-semibold">
              {!!parsePriceWithDiscount && !!priceWithDiscount && `${parsePriceWithDiscount}`}{' '}
              <s className="opacity-50">${WITHINGS_BODY_PRO_2_SCALE_PRICE}</s>
            </p>
          </div>
          <div className="md:col-start-2 md:col-end-2">
            <Collapse title="Features">
              <>
                <div className="flex gap-2">
                  <div className="flex-none self-start rounded-xl border border-gray p-2">
                    <Common.Icon className="size-5" name="accessibility" />
                  </div>
                  <div className="flex flex-col gap-1 text-mSm md:text-base">
                    <span className="font-bold">Full body composition analysis</span>
                    <span>
                      Monitors weight (kg, lb., st lb.), body fat & water percentage, plus muscle &
                      bone mass and for the first time, visceral fat.
                    </span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="flex-none self-start rounded-xl border border-gray p-2">
                    <Common.Icon className="size-5" name="heartbeat" />
                  </div>
                  <div className="flex flex-col gap-1 text-mSm md:text-base">
                    <span className="font-bold">Heart health</span>
                    <span>
                      Indication of your cardiovascular health via Vascular Age and standing heart
                      rate.
                    </span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="flex-none self-start rounded-xl border border-gray p-2">
                    <Common.Icon className="size-5" name="link" />
                  </div>
                  <div className="flex flex-col gap-1 text-mSm md:text-base">
                    <span className="font-bold">Automatic synchronization</span>
                    <span>
                      Data from every weigh-in appears in the LifeMD app automatically via Wi-Fi.
                    </span>
                  </div>
                </div>
              </>
            </Collapse>
            <Collapse title="Why it works">
              <>
                <div>
                  <p className="text-mSm font-bold">Track your progress</p>
                  <p className="mt-1 text-mSm">
                    Accurately track your body fat percentage to see the effectiveness of your GLP-1
                    medications.
                  </p>
                </div>
                <div>
                  <p className="text-mSm font-bold">Real-time adjustments</p>
                  <p className="mt-1 text-mSm">
                    Allow your LifeMD physicians to adjust your treatment plan in real-time based on
                    your progress.
                  </p>
                </div>
                <div>
                  <p className="text-mSm font-bold">Stay engaged and motivated</p>
                  <p className="mt-1 text-mSm">
                    Stay motivated by visualizing your progress and seeing real-time changes in your
                    weight loss journey.
                  </p>
                </div>
              </>
            </Collapse>
            <Collapse title="Personalization">
              <>
                <div>
                  <p className="text-mSm font-bold">Tailored Health Insights Just for You</p>
                  <p className="mt-1 text-mSm">
                    Your plan evolves with you. With the Withings Body Pro 2 Scale, your weight,
                    BMI, fat composition, and other metrics are updated to the LifeMD app in
                    real-time, allowing your physician and health coach to personalize your program
                    instantly. Experience a bespoke health management plan that adapts to your every
                    step.
                  </p>
                </div>
                <div>
                  <p className="text-mSm font-bold">Instant Data, Infinite Personalization</p>
                  <p className="mt-1 text-mSm">
                    Step on the scale and step into a world of personalized health. The Withings
                    Body Pro 2 Scale immediately uploads your body metrics to the LifeMD app, giving
                    your health professionals the data they need to fine-tune your program in
                    real-time.
                  </p>
                  <p className="mt-1 text-mSm">
                    Your health journey is a team effort. With the Withings Body Pro 2 Scale, your
                    real-time data is instantly shared with your LifeMD physician, allowing them to
                    provide immediate, personalized adjustments to your program.
                  </p>
                </div>
              </>
            </Collapse>
            <Collapse title="Motivation">
              <>
                <div>
                  <p className="text-mSm font-bold">See Your Progress, Shape Your Plan</p>
                  <p className="mt-1 text-mSm">
                    Witness your progress and shape your health journey with instant updates to the
                    LifeMD app.
                  </p>
                </div>
                <div>
                  <p className="text-mSm font-bold">Stay Motivated with Live Feedback</p>
                  <p className="mt-1 text-mSm">
                    Keep the momentum going with live feedback on your health progress. With instant
                    updates on your metrics, your health plan is continuously personalized to
                    reflect your current state.
                  </p>
                </div>
              </>
            </Collapse>
          </div>
        </div>
        <div className="flex flex-col-reverse gap-4 bg-white pb-2 md:hidden">
          <Common.Button
            className="!w-full"
            color="white-alt"
            fullWidthOnMobile
            onClick={() => onClose()}
          >
            Close
          </Common.Button>
          <Common.Button
            className="!w-full"
            color={isAddedToCart ? 'green-alt' : 'blue'}
            preIcon={isAddedToCart ? 'check' : undefined}
            fullWidthOnMobile
            onClick={onClickAdd}
          >
            {isAddedToCart ? 'Added' : 'Add to order'}
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default ScaleDetails;

import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';

import { OrderItemProps } from './orderItem.types';

const OrderItem: React.FC<OrderItemProps> = ({ order, onClick }) => {
  return (
    <button className="flex flex-col p-4 pb-2" onClick={onClick}>
      <div className="flex w-full justify-between">
        <div className="flex flex-col items-start gap-1">
          <p className="font-bold">{order.orderNumber}</p>
          <p>Tracking: {order.trackingNumber}</p>
          <p className="text-gray">
            Ordered: {dayjs(order.createdAt).format(DateFormat.MMMM_DD_YYYY)} •{' '}
            {order.items?.length} Item{order.items?.length > 1 ? 's' : ''}
          </p>
        </div>
        <span className="text-mBase font-bold">${order.total}</span>
      </div>
      <div className="flex w-full items-center justify-between py-4">
        <span className="font-semibold">Receipt</span>
        <Common.Icon className="text-gray" name="arrow-right" />
      </div>
    </button>
  );
};

export default OrderItem;

import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

const VARIANTS = {
  animate: { opacity: 1, transition: { duration: 0.3 }, translateX: 0 },
  exit: { opacity: 0, transition: { duration: 0.3 }, translateX: -20 },
  initial: { opacity: 0, translateX: 20 }
};

const FadeSlideWrapper: React.FC<PropsWithChildren> = ({ children }, dataTestId) => {
  return (
    <motion.div
      animate="animate"
      data-testid={dataTestId}
      exit="exit"
      initial="initial"
      variants={VARIANTS}
    >
      {children}
    </motion.div>
  );
};

export default FadeSlideWrapper;

import { useState } from 'react';
import { Common } from '@thecvlb/design-system';

import Content from 'containers/IntakeForm/Content';

import { SignUpSteps } from 'utils/enums';

import { CompleteYourProfileProps } from './completeYourProfile.types';

const CompleteYourProfile: React.FC<CompleteYourProfileProps> = ({ isOpen, onClose, keys }) => {
  const [step, setStep] = useState<number>(0);
  const [stepsList, updateStepsList] = useState<SignUpSteps[]>([]);
  const handleClose = () => {
    setStep(0);
    onClose();
  };
  const handleMoveToStep = (type: 'prev' | 'next' | number) => {
    const newStep = type === 'prev' ? step - 1 : type === 'next' ? step + 1 : type;
    if (newStep >= stepsList.length) {
      handleClose();
    } else {
      setStep(newStep);
    }
  };

  return (
    <Common.Modal close={handleClose} isOpen={isOpen} size="xl">
      <Content
        moveToStep={handleMoveToStep}
        showPreConfirmationStep={false}
        step={step}
        updateStepsList={updateStepsList}
        verificationBannerKeys={isOpen ? keys : undefined}
        isPopupFlow
      />
    </Common.Modal>
  );
};

export default CompleteYourProfile;

import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import ResendEmailButton from 'shared/ButtonResendEmail';
import TextInput from 'shared/form/TextInput';

import useAnalytics from 'hooks/useAnalytics';
import { DateFormat } from 'utils/enums';
import { onFormError } from 'utils/helpers';
import { EMAIL_CODE_REGEX } from 'utils/regExp';

import { EmailVerificationForm, VerifyCodeFormProps } from './verifyCodeForm.types';

const VerifyCodeForm: React.FC<VerifyCodeFormProps> = ({
  email,
  changePassword,
  onSubmit,
  onResendClick,
  isLoading
}) => {
  const logEvent = useAnalytics();

  const {
    formState: { isValid },
    control,
    getValues,
    handleSubmit
  } = useForm<EmailVerificationForm>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const isSentCodeExpired = changePassword
    ? dayjs().diff(dayjs(changePassword?.expiresAt), 'minutes') > 0
    : false;
  const hint = `Code sent to ${email} at ${dayjs(changePassword?.sentAt).format(
    DateFormat.h_mma_z
  )}`;

  const handleClickResend = () => {
    logEvent('acc_information_resend_email_btn_click');
    onResendClick(getValues('code'));
  };

  return (
    <form role="form" onSubmit={handleSubmit(onSubmit, onFormError)}>
      <div className="mx-auto py-4 md:max-w-[480px] md:py-8">
        <div className="flex flex-col gap-2 md:gap-1">
          <h2 className="text-mXl font-bold text-primary-700 md:text-xl">Verify your email</h2>
          <p>We sent a code to your email account to verify that it’s you.</p>
        </div>
        <TextInput
          className="mb-4 mt-8"
          control={control}
          dataTestId="code_input"
          hint={hint}
          invalidErrorMsg="Code is invalid"
          label="Enter code"
          name="code"
          regExp={EMAIL_CODE_REGEX}
          requiredErrorMsg="Code is required"
        />
        <ResendEmailButton
          className="ml-auto flex px-2 py-[14px] text-base font-bold"
          initDisabled={isSentCodeExpired}
          onClick={handleClickResend}
        >
          Resend code
        </ResendEmailButton>
      </div>
      <div className="fixed inset-x-4 bottom-4 flex flex-wrap justify-center gap-4 border-gray-200 md:static md:border-t md:px-8 md:py-6">
        <Common.Button
          color="blue"
          dataTestId="continue_btn"
          disabled={!isValid || isLoading}
          isLoading={isLoading}
          fullWidthOnMobile
        >
          Continue
        </Common.Button>
      </div>
    </form>
  );
};

export default VerifyCodeForm;

import dayjs from 'dayjs';

import { selectPatientChart } from 'store';

import { checkIfPrescriptionIsGLP } from 'utils/helpers';

import { useAppSelector } from './index';

const useGLPPrescription = () => {
  const { prescriptions } = useAppSelector(selectPatientChart);
  return [...prescriptions]
    .sort((a, b) => dayjs(b.createdDate).diff(a.createdDate))
    .find((p) => checkIfPrescriptionIsGLP(p));
};

export default useGLPPrescription;

import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';

import { SEX_AT_BIRTH_OPTIONS } from 'constants/formFields';

const SexAtBirthSelect = <TFormValues extends FieldValues>({
  control,
  name,
  dataTestId,
  label = '',
  hideSuccessState = false
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name: name,
    rules: {
      required: {
        message: 'Sex at birth is required',
        value: true
      }
    }
  });

  return (
    <Common.SelectAlt
      {...field}
      dataTestId={dataTestId}
      error={fieldState.error}
      hideSuccessState={hideSuccessState}
      label={label}
      options={SEX_AT_BIRTH_OPTIONS}
      placeholder="Sex at birth"
      readOnly
    />
  );
};

export default SexAtBirthSelect;

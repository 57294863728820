import { Common } from '@thecvlb/design-system/lib/src';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';

import Heading from '../../WM/parts/Heading';

const WhyTT: React.FC<AltSignupStepProps> = ({ onContinue }) => {
  return (
    <div className="flex flex-col gap-6 max-md:h-full">
      <Heading category="Eligibility" title="Why choose Triple Therapy?" />
      <p className="text-mLg md:text-lg">
        No needles, no complicated routines — just{' '}
        <span className="font-bold text-primary">convenient oral medication</span> that fits
        seamlessly into your daily life for effective weight loss support.
      </p>
      <p className="text-mLg md:text-lg">
        Our program offers <span className="font-bold text-primary">affordable</span> medication
        that is significantly less expensive than GLP-1 medications, making effective weight loss
        more accessible.
      </p>
      <Common.Button
        className="mt-auto md:mx-auto"
        color="blue"
        fullWidthOnMobile
        onClick={onContinue}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default WhyTT;

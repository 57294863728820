export type SrcOfAppointmentScheduling = 'checker' | 'appMobile' | 'default';

import { PricePoint } from 'models/plans.types';

export type StepName = PossibleStepsType[number];

export const CREATE_APPOINTMENT_STEPS = [
  'intro',
  'medical-care-near',
  'category',
  'files',
  'date-time',
  'membership',
  'checkout',
  'confirmation'
] as const;

export type PossibleStepsType = typeof CREATE_APPOINTMENT_STEPS;

export interface MembershipData {
  freeAppointmentInfo: {
    bookedFreeAppointmentDate?: string;
    freeAppointmentsAmount?: number;
    isFree: boolean;
    periodEnd: string;
    periodStart: string;
  };
  newPP: PricePoint | null;
  newPlanID: string;
}

export type FillAppointmentDataProps =
  | {
      data: {
        appointmentDescription: string;
        appointmentTypeId: string;
        displayName?: string;
        mifCode?: string;
        uploadRequired?: boolean;
      };
      step: 'category';
    }
  | {
      data: {
        displayName?: string;
        doctorId?: string;
        endTime: string;
        startTime: string;
      };
      step: 'date-time';
    }
  | {
      data: {
        files: File[];
      };
      step: 'files';
    }
  | {
      data: Partial<MembershipData>;
      step: 'membership';
    };

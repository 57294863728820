import { Common } from '@thecvlb/design-system';

import { ActionButtonsProps } from './actionButtons.types';

const ActionButtons: React.FC<ActionButtonsProps> = ({
  dataTestId,
  onClickBack,
  onClickNext,
  isNextBtnDisabled = false,
  isLoading
}) => {
  return (
    <div className="mt-auto flex flex-col-reverse justify-between gap-4 border-t border-gray-200 py-6 md:flex-row md:p-8">
      <Common.Button
        className="text-primary"
        color="white-alt"
        dataTestId={dataTestId}
        preIcon="arrow-left"
        fullWidthOnMobile
        onClick={onClickBack}
      >
        <span className="pr-5 md:pr-0">Back</span>
      </Common.Button>
      <Common.Button
        color="blue"
        dataTestId={dataTestId}
        disabled={isNextBtnDisabled}
        isLoading={isLoading}
        postIcon="arrow-right"
        fullWidthOnMobile
        onClick={onClickNext}
      >
        <span className="pl-5 md:pl-0">Next</span>
      </Common.Button>
    </div>
  );
};

export default ActionButtons;

import { Common } from '@thecvlb/design-system';

import useWeightManagement from 'hooks/useWeightManagement';

import { MyDeviceProps } from './myDevice.types';

const MyDevice: React.FC<MyDeviceProps> = ({ isOpen, onClose, deviceName }) => {
  const { isRWEPatient } = useWeightManagement();

  return (
    <Common.Modal isOpen={isOpen} size="sm" persist>
      <div className="flex flex-col items-center gap-4">
        <div className="text-center">
          <h2 className="text-m2xl font-bold">My device</h2>
          <h3 className="mt-2 text-mLg font-semibold text-primary-400">
            {deviceName || 'Withings Body Pro 2'}
          </h3>
        </div>
        <p className="text-center">Step on the scale again to create a new measurement.</p>
        <Common.Alert className="text-sm text-gray-600" type="plain" colorableBackground>
          It may take a few minutes for your data to sync. We’ll check in to confirm it was you.
        </Common.Alert>
        {isRWEPatient && (
          <div className="flex items-center justify-center gap-4">
            <span className="font-semibold max-md:text-mSm">Need help?</span>
            <a
              className="flex w-fit items-center gap-1 rounded-xl bg-gray-800/5 px-5 py-[9px] text-base font-bold text-gray-700 transition-all duration-300 hover:text-black"
              href="tel:8008051779"
            >
              <Common.Icon name="info" />
              Call 800-805-1779
            </a>
          </div>
        )}
        <Common.Button className="flex w-full justify-center" color="blue" onClick={onClose}>
          Close
        </Common.Button>
      </div>
    </Common.Modal>
  );
};

export default MyDevice;

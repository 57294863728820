export const appendScript = (scriptToAppend: string) => {
  const script = document.createElement('script');
  // script.async = true;
  script.type = 'text/javascript';
  script.src = scriptToAppend;
  document.body.appendChild(script);
};

export const appendAppBanner = () => {
  if (document.querySelector('meta[name="apple-itunes-app"]')) {
    return;
  }
  const meta = document.createElement('meta');
  meta.name = 'apple-itunes-app';
  meta.content = 'app-id=1584079757';
  document.head.appendChild(meta);
};

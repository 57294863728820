import { PharmacyItem } from 'services/pharmacies/pharmacies.types';

import PointSvg from 'assets/images/map/point.svg';
import PointChosenSvg from 'assets/images/map/point-chosen.svg';
import PointChosenOpenSvg from 'assets/images/map/point-chosen-open.svg';
import PointOpenSvg from 'assets/images/map/point-open.svg';

export const getIcon = (
  pharmacy: PharmacyItem,
  isOpen: boolean,
  userPharmacy?: PharmacyItem,
  pharmacyId?: number,
  isMobile?: boolean
) => {
  let size: number;
  if (pharmacyId === pharmacy.pharmacyId && isOpen) {
    size = isMobile ? 48 : 38;
  } else {
    size = isMobile ? 32 : 28;
  }

  return {
    scaledSize: new google.maps.Size(size, size),
    size: new google.maps.Size(size, size),
    url:
      pharmacyId === pharmacy.pharmacyId && isOpen
        ? pharmacy.pharmacyId === userPharmacy?.pharmacyId
          ? PointChosenOpenSvg
          : PointOpenSvg
        : pharmacy.pharmacyId === userPharmacy?.pharmacyId
          ? PointChosenSvg
          : PointSvg
  };
};

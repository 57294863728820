import { useToggle } from 'react-use';
import classNames from 'classnames';

import LearnMoreAboutBMI from 'modals/LearnMoreAboutBMI';

const LearnMoreAboutBMILink: React.FC<{ className?: string }> = ({ className = '' }) => {
  const [isOpenBMIPopup, toggleBMIPopup] = useToggle(false);

  return (
    <>
      <LearnMoreAboutBMI isOpen={isOpenBMIPopup} onClose={toggleBMIPopup} />

      <a
        className={classNames('underline', className)}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          toggleBMIPopup(true);
        }}
      >
        Learn More
      </a>
    </>
  );
};

export default LearnMoreAboutBMILink;

import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { DataItemProps } from '@thecvlb/design-system/lib/src/common';
import { nanoid } from 'nanoid';

import { useLazyGetMemberPricingQuery } from 'services/labTests/labTests';

import { MemberPricingItemProps } from 'store/labTests/labTests.types';

import Loader from 'shared/Loader';

import { BaseModalProps } from '../modal.types';

const MemberPricing: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  const [getMemberPricing, { data, isLoading, isFetching }] = useLazyGetMemberPricingQuery();
  const [searchKey, setSearchKey] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const tabs = [{ label: 'Quest' }];
  const scrollBlock = useRef<HTMLDivElement | null>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [memberPricing, setMemberPricing] = useState<MemberPricingItemProps[]>([]);

  const handleGetData = () => {
    if (!isOpen) return;

    getMemberPricing({ labCompany: tabs[0].label, limit: 20, pageNo, searchKey });
    setPageNo(pageNo + 1);
  };

  const handleUpdatedScrollBottom = () => {
    const offsetHeight = scrollBlock.current?.offsetHeight || 0;
    const scrollHeight = Math.abs(scrollTop) + offsetHeight;
    if (scrollHeight === scrollBlock.current?.scrollHeight && scrollHeight !== 0) {
      handleGetData();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPageNo(0);
    setSearchKey(event.target.value);
  };

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  const handleTabChange = (item: DataItemProps) => {
    // eslint-disable-next-line no-console
    console.log(item);
  };

  const handleUpdatedData = () => {
    if (!isFetching && data) {
      setMemberPricing((prev) => prev.concat(data.data));
    }
  };

  const handleUpdatedSearchKey = () => {
    setMemberPricing([]);
    handleGetData();
  };

  const handleUpdatedIsOpen = () => {
    if (isOpen) {
      setMemberPricing([]);
    } else {
      setPageNo(0);
      setSearchKey('');
    }
    handleGetData();
  };

  useDebounce(handleUpdatedSearchKey, 500, [searchKey]);

  useEffect(handleUpdatedIsOpen, [isOpen]);

  useEffect(handleUpdatedData, [data, isFetching]);

  useEffect(handleUpdatedScrollBottom, [scrollTop]);

  const tableHeaderColumnClassName =
    'hidden w-[130px] p-2 text-center text-sm font-semibold text-primary first:w-[212px] first:flex-none first:text-left md:block';

  return (
    <Common.Modal
      // dataTestId="member_pricing_modal"
      close={onClose}
      isOpen={isOpen}
      size="xl"
      zIndex={30}
    >
      <Loader isVisible={isLoading || isFetching} />
      <div className="mb-5 hidden items-center gap-4 md:flex">
        <h1 className="flex-1 text-2xl font-semibold text-primary" data-testid="modal_heading">
          LifeMD member pricing
        </h1>
        {/*<Patient.Button variant="alt">Learn more</Patient.Button>*/}
      </div>
      <div className="flex flex-col justify-between gap-4 pr-0.5 md:flex-row md:items-center">
        <Common.Tabs data={tabs} type="line" onChange={handleTabChange} />
        <div>
          <Common.SearchInput
            dataTestId="search_input"
            placeholder="Search list"
            value={searchKey}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div
        className="relative top-[-2px] mt-4 before:absolute before:h-px before:w-full before:bg-white"
        data-testid="member_pricing_content"
      >
        <div className="h-[448px] overflow-auto" ref={scrollBlock} onScroll={handleScroll}>
          <div className="sticky inset-x-0 top-0 flex bg-gray-50">
            <div className={tableHeaderColumnClassName}>Test name</div>
            <div className="flex-1 p-2 text-right text-base font-medium text-gray-700 md:w-[130px] md:flex-initial md:text-center md:text-sm md:font-semibold md:text-primary">
              Standard price
            </div>
            <div className="w-[112px] p-2 text-left text-base font-bold text-secondary md:w-[130px] md:bg-secondary-100 md:text-center md:text-sm md:font-semibold md:text-primary">
              Member price
            </div>
            <div className={tableHeaderColumnClassName}>Savings</div>
            <div className={tableHeaderColumnClassName}>Details</div>
          </div>
          {memberPricing.map((item) => (
            <a
              className="flex items-center gap-0.5 px-2 md:pointer-events-none md:gap-0 md:p-0"
              data-testid="member_pricing_item"
              href={item.infoLink}
              key={nanoid()}
              target="_blank"
            >
              <div
                className="flex-auto py-3 text-left text-base font-bold text-gray-700 md:w-[212px] md:flex-none md:p-2 md:text-sm md:font-semibold"
                data-testid="item_name"
              >
                {item.name}
              </div>
              <div
                className="w-[60px] flex-none px-2 py-3 text-base text-gray-700 line-through md:w-[130px] md:flex-initial md:p-2 md:text-center md:text-sm md:font-medium md:no-underline"
                data-testid="item_standard_price"
              >
                ${item.standardPrice.toFixed(2)}
              </div>
              <div
                className="w-[36px] flex-none py-3 text-sm font-bold text-secondary md:w-[130px] md:flex-initial md:bg-secondary-50 md:p-2 md:text-center md:text-sm md:font-semibold"
                data-testid="item_member_price"
              >
                ${item.memberPrice}
              </div>
              <div
                className="w-[36px] flex-none py-3 text-sm text-secondary md:w-[130px] md:flex-initial md:p-2 md:text-center md:text-sm md:font-medium"
                data-testid="item_savings"
              >
                <span className="md:hidden">-</span>
                {item.savings}%
              </div>
              <div
                className="w-5 flex-none py-3 text-center text-sm font-semibold text-primary md:pointer-events-auto md:w-[130px] md:flex-initial md:p-2"
                data-testid="view_info"
              >
                <Common.Icon className="text-gray md:hidden" name="arrow-right" />
                <span className="hidden md:block">View info</span>
              </div>
            </a>
          ))}
          {memberPricing.length === 0 && (
            <p className="p-2 text-center text-sm font-semibold">No results</p>
          )}
        </div>
      </div>
    </Common.Modal>
  );
};

export default MemberPricing;

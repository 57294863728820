import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { useGetSettingsQuery, useUpdateSettingsMutation } from 'services/settings/settings';
import { GetSettingsNotificationProps } from 'services/settings/settings.types';

import { selectNotificationPreferences, selectUser } from 'store';
import { NotificationPreferencesProps } from 'store/settings/settings.types';
import { setSettings, toggleSetting } from 'store/settings/settingsSlice';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { NotificationNames, NotificationTypes } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const Notifications: React.FC = () => {
  const logEvent = useAnalytics();
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector(selectUser);
  const { emailNotification, browserNotification, textNotification } = useAppSelector(
    selectNotificationPreferences
  );
  const { data, isLoading } = useGetSettingsQuery();
  const [updateSettings, { isLoading: updateSettingsLoading }] = useUpdateSettingsMutation();

  const getNotificationsData = (settingsNotification: GetSettingsNotificationProps) => ({
    [NotificationNames.Messages]: {
      isChecked: settingsNotification.messages,
      label: 'Messages'
    },
    [NotificationNames.AppointmentReminder]: {
      isChecked: settingsNotification.appointmentReminder,
      label: 'Appointment reminder'
    },
    [NotificationNames.AppointmentChanges]: {
      isChecked: settingsNotification.appointmentChanges,
      label: 'Appointment changes'
    },
    [NotificationNames.newOffers]: {
      isChecked: settingsNotification.newOffers,
      label: 'News & offers'
    }
  });
  const initSettings = {
    [NotificationTypes.EmailNotification]: getNotificationsData(emailNotification),
    [NotificationTypes.TextNotification]: getNotificationsData(textNotification),
    [NotificationTypes.BrowserNotification]: getNotificationsData(browserNotification)
  };
  const [notificationSettings, setNotificationSettings] = useState(initSettings);

  const handleUpdateSettingsThen = (
    settingType: NotificationTypes,
    settingName: NotificationNames
  ) => {
    dispatch(
      toggleSetting({
        notificationName: settingName,
        notificationType: settingType
      })
    );
  };

  const handleChange = (
    settingType: NotificationTypes,
    settingName: NotificationNames,
    isChecked: boolean
  ) => {
    switch (settingName) {
      case 'appointmentChanges':
        logEvent('notifications_appt_changes_switch_click');
        break;
      case 'appointmentReminder':
        logEvent('notifications_appt_reminders_switch_click');
        break;
      case 'messages':
        logEvent('notifications_messages_switch_click');
        break;
      case 'newOffers':
        logEvent('notifications_new_offers_switch_click');
        break;
    }
    updateSettings({
      notificationPreferences: {
        [settingType]: {
          [settingName]: !isChecked
        }
      }
    })
      .unwrap()
      .then(() => handleUpdateSettingsThen(settingType, settingName))
      .catch(handleRequestCatch);
  };
  const handleChangeDesktop = (settingType: NotificationTypes, settingName: NotificationNames) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [settingType]: {
        ...prevSettings[settingType],
        [settingName]: {
          ...prevSettings[settingType][settingName],
          isChecked: !prevSettings[settingType][settingName].isChecked
        }
      }
    }));
  };
  const handleCancel = () => {
    setNotificationSettings(initSettings);
  };
  const handleSave = () => {
    const notificationPreferences = Object.fromEntries(
      Object.entries(notificationSettings).map(([settingType, typeVal]) => [
        settingType,
        Object.fromEntries(
          Object.entries(typeVal).map(([settingName, { isChecked }]) => [settingName, isChecked])
        )
      ])
    ) as Partial<NotificationPreferencesProps>;

    updateSettings({ notificationPreferences })
      .unwrap()
      .then(() => {
        dispatch(
          setSettings({
            notificationPreferences: notificationPreferences as NotificationPreferencesProps,
            userId
          })
        );
        notifySuccess('Notification settings updated successfully.');
      })
      .catch(handleRequestCatch);
  };

  const wasUpdated = !isEqual(initSettings, notificationSettings);

  const handleUpdatedData = () => {
    data && dispatch(setSettings(data.data));
  };

  useEffect(handleUpdatedData, [data]);
  useEffect(
    () => setNotificationSettings(initSettings),
    [emailNotification, browserNotification, textNotification]
  );

  const subheaderWrapperClassName = 'mt-8 flex items-center gap-2';
  const subheaderClassName = 'text-mBase font-bold text-gray-700';
  const desktopItemClassName = 'h-12 border-b py-4';
  const thClassName = 'mb-2 text-sm font-bold';
  const tdClassName = 'flex w-[60px] flex-col items-center';
  const trClassName = `${desktopItemClassName} text-sm font-medium`;
  const checkBoxWrapperClassName = `${desktopItemClassName} ${tdClassName} last:border-none`;

  const getMobileItems = (
    settingsData: GetSettingsNotificationProps,
    settingType: NotificationTypes
  ) => (
    <div className="mt-4 flex flex-col gap-5 rounded-lg border border-gray-200 bg-white p-4">
      {Object.entries(getNotificationsData(settingsData)).map(
        ([settingName, { label, isChecked = false }], index) => (
          <div
            className="flex items-center"
            data-testid={`notification_toggle_${isChecked ? 'enabled' : 'disabled'}`}
            key={settingName + index}
          >
            <p className="flex-1 text-mBase font-medium">{label}</p>
            <Common.Toggle
              checked={isChecked}
              color="blue"
              onClick={() => handleChange(settingType, settingName as NotificationNames, isChecked)}
            />
          </div>
        )
      )}
    </div>
  );
  const getDesktopItems = (settingType: NotificationTypes) =>
    Object.entries(notificationSettings[settingType]).map(([settingName, { isChecked }], index) => (
      <div
        className={checkBoxWrapperClassName}
        data-testid="notification_checkbox"
        key={settingType + index}
      >
        <Common.Checkbox
          checked={isChecked}
          color="blue"
          data-testid={`notification_toggle_${isChecked ? 'enabled' : 'disabled'}`}
          onChange={() => handleChangeDesktop(settingType, settingName as NotificationNames)}
        />
      </div>
    ));
  return (
    <FadeWrapper>
      <Loader isVisible={isLoading} />
      <div className="hidden flex-col md:flex">
        <div className="flex w-full p-8">
          <div className="flex flex-1 flex-col">
            <span className={classNames(thClassName, 'md:mb-[5px] md:text-lg')}>Notifications</span>
            <span className={trClassName} data-testid="messages_notifications">
              Messages
            </span>
            <span className={trClassName} data-testid="appo_reminders_notifications">
              Appointment reminders
            </span>
            <span className={trClassName} data-testid="appo_changes_notifications">
              Appointment changes
            </span>
            <span className={`${trClassName} border-none`} data-testid="news_offers_notifications">
              News & offers
            </span>
          </div>
          <div className={tdClassName} data-testid="email_notifications">
            <span className={thClassName}>Email</span>
            {getDesktopItems(NotificationTypes.EmailNotification)}
          </div>
          <div className={tdClassName} data-testid="text_notifications">
            <span className={thClassName}>SMS</span>
            {getDesktopItems(NotificationTypes.TextNotification)}
          </div>
          <div className={tdClassName} data-testid="browser_notifications">
            <span className={thClassName}>Browser</span>
            {getDesktopItems(NotificationTypes.BrowserNotification)}
          </div>
        </div>
        <div className="flex w-full gap-4 border-t border-gray-200 p-6">
          <Common.Button
            color="blue"
            disabled={!wasUpdated}
            isLoading={updateSettingsLoading}
            onClick={handleSave}
          >
            Save notifications
          </Common.Button>
          <Common.Button color="white-alt" disabled={!wasUpdated} onClick={handleCancel}>
            Cancel
          </Common.Button>
        </div>
      </div>
      <div className="md:hidden">
        <div className={`${subheaderWrapperClassName} mt-0`}>
          <Common.Icon name="email" />
          <h2 className={subheaderClassName}>Email notifications</h2>
        </div>
        {getMobileItems(emailNotification, NotificationTypes.EmailNotification)}
        <div className={subheaderWrapperClassName}>
          <Common.Icon name="chat-outline" />
          <h2 className={subheaderClassName}>Text notifications</h2>
        </div>
        {getMobileItems(textNotification, NotificationTypes.TextNotification)}
        <div className={subheaderWrapperClassName}>
          <Common.Icon name="list-view-outline" />
          <h2 className={subheaderClassName}>App notifications</h2>
        </div>
        {getMobileItems(browserNotification, NotificationTypes.BrowserNotification)}
      </div>
    </FadeWrapper>
  );
};

export default Notifications;

const Privacy = () => {
  return (
    <>
      <p data-testid="privacy_policy_body">
        Thank you for visiting the LifeMD™ website located at lifemd.com (the “<u>Site</u>”).&nbsp;
        The Site is an Internet property of LifeMD, Inc. (collectively, “<u>LifeMD™</u>” “<u>we</u>
        ,” “<u>our</u>” or “<u>us</u>”).&nbsp; This LifeMD™ Privacy Policy (“<u>Privacy Policy</u>
        ”) covers our treatment of personal information and other information that we collect when
        end-users (“<u>user</u>,” “<u>you</u>” or “<u>your</u>”): (a) access or use the Site; (b)
        access and/or download any of the: (i) blog posts, text, audio, video, photographs,
        graphics, artwork, testimonials and/or other content featured on the Site(collectively, “
        <u>Informational Content</u>”); and/or (ii) links to third party websites, products and/or
        services (“<u>Third-Party Links</u>,” and together with the Informational Content, the “
        <u>Content</u>”); (c) access links to LifeMD’s™ social media pages/accounts (collectively,
        “<u>Social Media Pages</u>”) on third-party social media websites, such as Facebook®,
        Instagram®, Twitter® and YouTube® (“<u>Social Media Websites</u>”); (d) access certain
        product review sections, message boards, comments sections, customer ratings and other
        interactive features of the Site (collectively, “<u>Interactive Services</u>”); (e) register
        to receive the LifeMD™ e-mail newsletter (“<u>Newsletter</u>”); (f) purchase any of the
        non-prescription health and wellness items made available by and through the Site
        (collectively, the “<u>LifeMD</u>
        <u>™ </u>
        <u>Products</u>”) as provided by LifeMD™; (g) purchase diagnostic tests and other medical
        services via the Site (“<u>Medical Purchases</u>”), as same are provided by third-party
        licensed medical providers or other healthcare professionals (“<u>Healthcare Providers</u>”)
        who provide telemedicine-related services in connection with the Site (“
        <u>Telemedicine Services</u>”); (h) purchase prescription medications via the Site (“
        <u>Prescription Medications</u>”), as same are prescribed by certain third-party Healthcare
        Providers who provide Telemedicine Services in connection with the Site; (i) register, for a
        fee, to receive ongoing general practitioner medical services, as same are provided by
        third-party Healthcare Providers who provide Telemedicine Services in connection with the
        Site (“<u>Medical Services</u>,” and together with the LifeMD™ Products, Medical Purchases
        and Prescription Medications, the “<u>Products and Services</u>”); and/or (j) utilize the
        various contact forms and/or contact information made available on the Site as a means to
        contact directly, or request to be contacted by, LifeMD™ (collectively, the “
        <u>Contact Services</u>,” and together with the Site, Content, Social Media Pages, LifeMD™
        Products, Newsletter, Medical Purchases, Medical Services, Prescription Medications and
        Telemedicine Services, the “<u>Site Offerings</u>”).&nbsp;{' '}
        <strong>
          Please note, if you are a resident of a European Union Member State, you are not permitted
          to use the Site and/or Site Offerings.
        </strong>
      </p>

      <p>
        Users with disabilities who wish to access this Privacy Policy in an alternative format can
        contact us by e-mailing us at:{' '}
        <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; by calling us at:{' '}
        <a href="tel:800-852-1575">(800) 852-1575</a> or by sending us U.S. Mail to: LifeMD, Inc.,
        236 Fifth Avenue, Suite 400, New York, NY 10001. &nbsp;
      </p>

      <p>
        Capitalized terms not defined herein shall have the meanings set forth in the LifeMD™
        Website Terms and Conditions (“<u>Terms and Conditions</u>”).&nbsp;{' '}
        <strong>
          IF YOU DO NOT AGREE TO THE TERMS OF THIS PRIVACY POLICY IN THEIR ENTIRETY, YOU MAY NOT
          ACCESS OR OTHERWISE USE THE{' '}
        </strong>
        <strong>SITE OFFERINGS</strong>
        <strong>.</strong>
      </p>

      <p>
        <em>Instagram® and </em>
        <em>Facebook® are registered trademarks of Facebook, Inc.</em>
        <em>
          {' '}
          (“<u>Facebook</u>”).&nbsp; Twitter® is a registered trademark of Twitter, Inc. (“
          <u>Twitter</u>”).
        </em>
        &nbsp;
        <em>
          YouTube® is a registered trademark of Google, Inc.&nbsp; (“Google”).&nbsp; Please be
          advised that LifeMD™ is not in any way affiliated with Facebook, Google, or Twitter, nor
          are the{' '}
        </em>
        <em>Site Offerings</em>
        <em> endorsed, administered, or sponsored by any of the aforementioned entities.</em>
      </p>

      <p>
        <strong>Your California Privacy Rights</strong>
      </p>
      <p>
        <em>
          <u>Shine the Light</u>
        </em>
        .&nbsp; If you are a resident of the State of California and would like to learn how your
        “personal information” (as defined in the Shine the Light Law, Cal. Civ. Code § 1798.83) is
        shared with third parties, what categories of personal information we have shared with third
        parties in the preceding year, as well as the names and addresses of those third parties,
        please e-mail us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>;
        call us at: <a href="tel:800-852-1575">(800) 852-1575</a> or send us U.S. Mail to: LifeMD,
        Inc. 236 Fifth Avenue, Suite 400, New York, NY 10001.
      </p>

      <p>
        Further, if you are a resident of the State of California and would like to opt-out from the
        disclosure of your personal information to any third party for marketing purposes, please
        e-mail us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; call us
        at: <a href="tel:800-852-1575">(800) 852-1575</a> or send us U.S. Mail to: LifeMD, Inc., 236
        Fifth Avenue, Suite 400, New York, NY 10001.&nbsp; Please be advised that where California
        State residents opt-out from permitting their personal information to be shared, such
        individuals may still receive selected offers directly from us, in accordance with
        applicable law.{' '}
      </p>

      <p>
        <em>
          <u>California Consumer Privacy Act of 2018 (“CCPA”)</u>
        </em>
        .&nbsp; In addition to the foregoing, if you are a resident of the State of California
        certain other privacy-related rights may apply to you in accordance with the CCPA, including
        the right to opt-out of our sale of your personal information, as well as the right to know
        what personal information about you we have collected, whether your personal information was
        shared with third-parties in the preceding year and, if so, what categories of personal
        information were shared, as well as the categories of third parties with whom we shared that
        personal information.&nbsp; Please see our “Privacy Provisions for California Residents”{' '}
        <a href="src/modals/TermsAndRules/Privacy#California_residents">below</a> for a more
        complete description of your rights under the CCPA as a California State resident.{' '}
      </p>
      <p>
        <strong>HIPAA/Protected Health Information</strong>
      </p>

      <p>
        When you set up an account with LifeMD™, you are creating a direct customer relationship
        with LifeMD™ that enables you to access and/or utilize the various functions of the Site
        Offerings as a user. As part of that relationship, you may provide certain personal
        information to LifeMD™ that we do not consider to be “protected health information” or
        “medical information,” such as your name, email address, mailing/billing address and
        telephone number.{' '}
      </p>

      <p>
        However, where you request Medical Services and/or Prescription Medications or otherwise
        request certain LifeMD™ Products and/or Medical Products, you may also be asked to provide
        certain health or medical information that may be protected under applicable laws, rules,
        and regulations, such the Health Insurance Portability and Accountability Act of 1996,
        Public Law 104-191, and its related regulations and amendments (collectively, “<u>HIPAA</u>
        ”). LifeMD™ <strong>is not</strong> considered a “covered entity” under HIPAA in certain
        scenarios where you utilize the services of third-party Healthcare Providers or pharmacies;{' '}
        <em>provided, however, </em>that, in all instances, the Healthcare Providers and pharmacies
        are typically “covered entities” under HIPAA and, in connection with third-party Healthcare
        Providers and/or pharmacies, LifeMD™ may, in some cases, be a “business associate” of the
        Healthcare Providers and/or pharmacies. It is important to note that HIPAA does not
        necessarily apply to an entity or person simply because there is health information
        involved, and HIPAA may not apply to all of your transactions or communications with
        LifeMD™. &nbsp;To the extent LifeMD™ is deemed a “business associate” however, LifeMD™
        may be subject to certain provisions of HIPAA with respect to “protected health information
        (“PHI”),” as defined under HIPAA, that you provide to the Healthcare Providers. In addition,
        any medical or health information that you provide that is subject to specific protections
        under applicable state laws (collectively, with PHI, “<u>Protected Information</u>”) will be
        used and disclosed only in accordance with such applicable laws. Please note, however, that
        any information that does not constitute Protected Information under applicable laws may be
        used or disclosed in any manner permitted under this Privacy Policy. &nbsp;Protected
        Information does not include information that has been de-identified in accordance with
        applicable laws.
      </p>

      <p>
        The Healthcare Providers may have adopted separate Notices of Privacy Practices that
        describe how they use and disclose Protected Information. By accessing or using any part of
        the Site Offerings, you acknowledge and agree that you have read all such applicable Notices
        of Privacy Practices.
      </p>

      <p>
        <strong>Your Nevada Privacy Rights</strong>
        <br />
        If you are a resident of the State of Nevada and would like to opt-out from the sale of your
        personal information to any third-party data broker, please e-mail us at:{' '}
        <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; call us at:{' '}
        <a href="tel:800-852-1575">(800) 852-1575</a> or send us U.S. Mail to: LifeMD, Inc., 236
        Fifth Avenue, Suite 400, New York, NY 10001.
      </p>

      <h3>Privacy Policy Quick Links</h3>

      <p>Below are links to key sections of our Privacy Policy:</p>

      <ul>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Personal_info_collect">
            <u>Personal Information Collected</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Use_sharing_personal_info">
            <u>Use and Sharing of Personal Information</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Non_personal_info_collect">
            <u>Non-Personal Information Collection and Use</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Credit_debit">
            <u>Credit and/or Debit Card Transactions</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Security_your_info">
            <u>Security of Your Personal Information</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Chlidrens_privacy">
            <u>Children&apos;s Privacy</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Opt_out_unsubscribe">
            <u>Opt-Out/Unsubscribe</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Deleting_your_info">
            <u>Deleting, Modifying and Updating Your Information</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Chages_privacy">
            <u>Changes to this Privacy Policy</u>
          </a>
        </li>
        <li>
          <a href="src/modals/TermsAndRules/Privacy#Contact_us">
            <u>Contact Us</u>
          </a>
        </li>
      </ul>
      <br />
      <br />

      <p id="Personal_info_collect">
        <strong>Personal Information Collected</strong>
        <br />
        Please see our Privacy Provisions for California Residents{' '}
        <a href="src/modals/TermsAndRules/Privacy#California_residents">below</a> for additional
        details regarding the categories of personal information collected. &nbsp;
        <br />
        For the purposes of this Privacy Policy, “<u>personal information</u>” shall mean
        individually identifiable information from or about an individual.&nbsp; We collect personal
        information when you access certain of the Site Offerings and complete the required
        information request Form(s) and/or otherwise provide such information to us.&nbsp; The
        information that you must supply on the Form(s) may include, without limitation: (a) your
        full name; (b) your mailing/billing address; (c) your e-mail address; (d) your telephone
        number; (e) your gender; (f) your credit card information (where purchasing Products and
        Services); (g) answers to questions regarding your health and well-being, as asked via
        medical intake forms; and/or (h) any other information requested by us on the applicable
        registration Form(s).&nbsp; <br />
        For purposes of this Privacy Policy, items (f) and (g) in the preceding paragraph, as well
        as any Protected Information, will be considered “<u>Sensitive Information</u>.”
      </p>

      <p id="Use_sharing_personal_info">
        <strong>Use and Sharing of Personal Information</strong>
      </p>

      <p>
        Please see our Privacy Provisions for California Residents{' '}
        <a href="src/modals/TermsAndRules/Privacy#California_residents">below</a> for details
        regarding our use and sharing of personal information. &nbsp;
      </p>

      <p>
        LifeMD™ will never share, sell, rent, exchange, or barter your personal information to or
        with any third-party for financial gain or marketing purposes.&nbsp; Please be advised,
        however, that where you submit a request for Medical Services, Medical Products and/or
        Prescription Medication, we may share your personal information (including Sensitive
        Information and Protected Information) with third-party Healthcare Providers to enable them
        to evaluate your suitability for the applicable Medical Services, Medical Products and/or
        Prescription Medication.
        <br />
        <br />
        By making your personal information available to LifeMD™, you grant LifeMD™ the right,
        subject to applicable law, to use that personal information to contact you by telephone and
        email regarding your use of the Site Offerings (including in connection with any purchase of
        Products and Services).&nbsp; If you wish to stop receiving future communications from us,
        please follow the instructions at the end of each such marketing message or see the
        “Opt-Out/Unsubscribe” section below.&nbsp;
      </p>

      <p>
        Please be advised further that, by making your personal information available to LifeMD™,
        you also grant LifeMD™ the right to verify your identification via third party verification
        service providers (such as Woolly Labs, Inc. d/b/a Vouched).&nbsp; You acknowledge and agree
        that LifeMD™ may share your personal information with such third-party verification service
        providers for such purposes.
      </p>

      <p>
        We may also use your personal information to send you promotional messages regarding various
        LifeMD™ products (as well as products and/or services offered by other LifeMD owned
        Internet properties such as Shapiro MD™, Rex MD™, NAVA MD™, and Cleared™) and/or
        services, as well as third-party products and/or services that we think may be of interest
        to you. If you wish to stop receiving future communications from us, please follow the
        instructions at the end of each such marketing message or use any of the other options
        available in the “<u>Opt-Out/Unsubscribe</u>” section below.&nbsp;{' '}
      </p>

      <p>
        Where you submit personal information, we use the personal information that you make
        available to facilitate the delivery of the applicable Site Offerings to you, including the
        Products and Services and to respond to any inquiries made by you.&nbsp; You also agree that
        we may contact you at any time with updates and/or any other information that we may deem
        appropriate for you to receive in connection with your continued use of the Site Offerings,
        and to keep you informed of our other products and services.&nbsp;&nbsp;&nbsp;{' '}
      </p>

      <p>
        We may also employ other companies and individuals to perform certain functions on our
        behalf.&nbsp; Examples include processing credit card and other payment transactions,
        sending direct and electronic mail, removing duplicate information from user lists,
        analyzing data, and providing marketing analysis.&nbsp; The agents performing these limited
        functions on our behalf shall have access to our users’ personal information (including
        Sensitive Information, where applicable) as needed to perform these functions for us, but we
        do not permit them to use user personal information for other purposes.
      </p>

      <p>
        We will use your personal information for customer service, to provide you with information
        that you may request, to customize your experience with the Site Offerings and/or to contact
        you for market research purposes.&nbsp; We may also use your personal information for
        internal business purposes, such as analyzing and managing our service offerings including,
        without limitation, the Site Offerings and combine the information we have gathered about
        you with information from other sources.&nbsp;&nbsp;&nbsp;{' '}
      </p>

      <p>
        By submitting your personal information by and through the Site Offerings, you agree that
        such act constitutes an inquiry and/or application for purposes of the Amended Telemarketing
        Sales Rule (16 CFR §310 et seq.), as amended from time to time (the “<u>Rule</u>”) and
        applicable state do-not-call regulations.&nbsp; As such, notwithstanding that your telephone
        number may be listed on the Federal Trade Commission’s Do-Not-Call List, and/or on
        applicable state do-not-call lists, we retain the right to contact you via telemarketing in
        accordance with the Rule and applicable state do-not-call regulations.
      </p>

      <p>
        Where you provide “<u>prior express consent</u>” within the meaning of the Telephone
        Consumer Protection Act (47 USC § 227), and its implementing regulations adopted by the
        Federal Communications Commission (47 CFR § 64.1200), as amended from time-to-time (“
        <u>TCPA</u>”), you consent to receive, from LifeMD™, telephone calls, including artificial
        voice calls, pre-recorded messages and/or calls (including text alerts via SMS text messages
        delivered via automated technology, to the telephone number(s) that you provided.&nbsp;
        Please note that you are not required to provide this consent in order to obtain access to
        the Site Offerings, and your consent simply allows LifeMD™ to contact you via these
        means.&nbsp;
        <strong>
          Please be advised that by agreeing to this Privacy Policy, you are obligated to
          immediately inform us if and when the telephone number that you have previously provided
          to us changes.&nbsp;Without limiting the foregoing, if you: (a) have your telephone number
          reassigned to another person or entity; (b) give up your telephone number so that it is no
          longer used by you; (c) port your telephone number to a landline or vice versa; or (d)
          otherwise stop using that telephone number for any reason (collectively “
          <u>Phone Number Change</u>”), you agree that you shall promptly notify LifeMD™ of the
          Phone Number Change via e-mail at: patientcare@lifemd.com, or by{' '}
          <strong>
            using one of the methods set forth in the “<u>Contact Us</u>” section below.
          </strong>
        </strong>
      </p>

      <p>
        We reserve the right to release current or past personal information (including Sensitive
        Information and Protected Information): (i) in the event that we believe that the Site
        Offerings are being or have been used in violation of the Terms and Conditions or to commit
        unlawful acts; (ii) if the information is subpoenaed; <em>provided, however,</em> that,
        where permitted by applicable law, we shall provide you with e-mail notice, and opportunity
        to challenge the subpoena, prior to disclosure of any personal information pursuant to a
        subpoena; (iii) if we are sold, merge with a third-party or are acquired by a third-party
        (collectively, “<u>M&amp;A Transactions</u>”) (including where we share your personal
        information, including Sensitive Information and Protected Information, in connection with
        the due diligence process associated with a potential M&amp;A Transaction); or (iv) if we
        are the subject of bankruptcy proceedings; <em>provided, however</em>, that if LifeMD™ is
        involved in a bankruptcy proceeding, merger, acquisition or sale of all or a portion of its
        assets, you will be notified via e-mail and/or a prominent notice on the Site of any change
        in ownership or uses of your personal information, as well as any choices that you may have
        regarding your personal information.&nbsp;{' '}
      </p>

      <p>
        You hereby consent to the disclosure of any record or communication to any third-party when
        we, in our sole discretion, determine the disclosure to be required by applicable law,
        including sharing your e-mail address with third parties for suppression purposes in
        compliance with the CAN-SPAM Act of 2003, as amended from time to time, and other e-mail
        marketing laws.&nbsp; Users should also be aware that courts of equity, such as U.S.
        Bankruptcy Courts, might have the authority under certain circumstances to permit personal
        information to be shared or transferred to third parties without permission.&nbsp;{' '}
      </p>

      <p id="Credit_debit">
        <strong>Credit and/or Debit Card Transactions</strong>
      </p>

      <p>
        LifeMD™ utilizes third-party service providers to provide credit and debit card payment
        processing services.&nbsp; If you choose to purchase Products and Services, the credit or
        debit card information provided will be shared with our contracted third-party payment
        processing service provider(s).&nbsp; LifeMD™ requires that its third-party payment
        processing service provider(s) has/have in place privacy policies and practices consistent
        with this Privacy Policy; <em>provided, however,</em> that we cannot guarantee the privacy
        practices of our third-party payment processing service provider(s).
      </p>

      <p id="Non_personal_info_collect">
        <strong>Non-Personal Information Collection and Use</strong>
        <br />
        Please see our Privacy Provisions for California Residents{' '}
        <a href="src/modals/TermsAndRules/Privacy#California_residents">below</a> for more details
        regarding the categories and types of non-personal information collected.
      </p>

      <p>
        <em>
          <u>Computer IP Addresses/Browser Type</u>
        </em>
        <br />
        We may collect certain non-personally identifiable information about you and your desktop
        computer when you visit many of the pages of the Site.&nbsp; This non-personally
        identifiable information includes, without limitation, the type of browser that you use
        (e.g., Safari, Chrome, Internet Explorer), your IP address, the type of operating system
        that you use (e.g., Windows or iOS) and the domain name of your Internet service provider
        (e.g., Verizon, AT&amp;T).&nbsp; We use the non-personally identifiable information that we
        collect to improve the design and content of the Site Offerings and to enable us to
        personalize your Internet experience.&nbsp; We also may use this information in the
        aggregate to analyze usage of the Site Offerings.&nbsp;&nbsp;
      </p>

      <p>
        <em>
          <u>Cookies</u>
        </em>{' '}
        <br />
        When a user visits the Site and/or interacts with one of our commercial e-mail messages, we
        send one (1) or more cookies and/or gif files (collectively, “<u>Cookies</u>”) to assign an
        anonymous, unique identifier to the applicable user’s computer and/or mobile device, as
        applicable.&nbsp; A Cookie is a piece of data stored on your hard drive containing
        non-personally identifiable information about you.&nbsp; Cookies have many benefits to
        enhance your experience on the Site, as applicable.&nbsp; To find out more about Cookies,
        please visit www.cookiecentral.com.&nbsp; We use Cookies to improve the quality of the Site
        Offerings, including for storing user preferences and tracking Site usage (such as pages
        opened and length of stay at the Site, as applicable).
      </p>

      <p>
        Most Internet browsers are initially set up to accept Cookies, but you can reset your
        browser to refuse all Cookies or to indicate when a Cookie is being sent. To disable and
        reject certain Cookies, follow the instructions associated with your Internet browser.&nbsp;
        Even in the case where a user rejects a Cookie, he or she may still use the Site Offerings;{' '}
        <em>provided, however,</em> that certain functions of the Site Offerings may be impaired or
        rendered inoperable if the use of Cookies is disabled.&nbsp; We reserve the right to retain
        Cookie data indefinitely.{' '}
      </p>

      <p>
        <em>
          <u>Behavioral Tracking</u>
        </em>{' '}
        <br />
        LifeMD™ and its third-party partners, such as Google®, use Cookies, pixels, and other
        tracking technology (collectively, “<u>Tracking Technology</u>”) to analyze trends,
        administer the Site, track users’ movements around the Site and to gather demographic
        information about our user base as a whole.&nbsp; In addition, we partner with third parties
        such as Google® to use Tracking Technology in connection with the display of advertising on
        our Site, and to manage the serving of our advertising to you on other websites when you
        exit the Site. Our third-party partners may use Tracking Technology to gather information
        about your activities on the Site and other websites in order to provide you with certain
        LifeMD™ advertising based upon your browsing activities and interests. To the greatest
        extent permissible under applicable law, we are not responsible for the tracking practices
        of third parties in connection with the Site.
      </p>

      <p>
        Users can opt-out of certain Google®-related tracking technology and customize the Google®
        Display Network ads that they receive, by visiting the Google® Ads Settings at:
        http://www.google.com/settings/ads.&nbsp; Google® also recommends installing the Google®
        Analytics Opt-out Browser Add-on for your web browser, which is available here:
        https://tools.google.com/dlpage/gaoptout.&nbsp;{' '}
      </p>

      <p>
        In addition, users may be able to disable some, but not all, of this tracking activity by
        utilizing the “Do Not Track” setting or similar options within most major Internet
        browsers.&nbsp; Further, users may be able to opt-out of this form of tracking utilizing the
        options made available by the{' '}
        <a href="http://www.networkadvertising.org/choices/">
          <u>Network Advertising Initiative</u>
        </a>
        ,{' '}
        <a href="https://www.facebook.com/settings/?tab=ads">
          <u>Facebook</u>
        </a>{' '}
        or{' '}
        <a href="http://www.aboutads.info/choices/">
          <u>Digital Advertising Alliance</u>
        </a>
        .&nbsp; Please note that opting out of this tracking activity does not opt you out of being
        served advertising. Even where you opt out of this tracking activity, you will continue to
        receive generic ads.
      </p>

      <p>
        <em>
          <u>Cross Device Tracking</u>
        </em>
        <br />
        LifeMD™ tracks users’ use of the Site Offerings across various devices, including your
        personal computer and mobile device, in order to optimize and personalize your Site
        Offerings experience.&nbsp; LifeMD™ may collect certain of your personal information across
        various devices.&nbsp; Please be advised that where you opt-out of having your use of the
        Site Offerings tracked across devices, you may need to upload certain information multiple
        times and/or input your log-in information multiple times.
      </p>

      <p>
        <strong>
          <em>Aggregate Data</em>
        </strong>
        <br />
        LifeMD™ reserves the right to transfer and/or sell aggregate or group data about users of
        the Site Offerings for lawful purposes.&nbsp; Aggregate or group data is data that describes
        the demographics, usage, and other characteristics of users as a group, without disclosing
        personally identifiable information.
        <br />
        <strong>Interactive Services, Social Media Websites and Widgets</strong>
      </p>

      <p>
        If you engage in any interaction with LifeMD™, other users or any third-party via the
        Interactive Services and/or by and through any Social Media Pages, you should be aware that:
        (a) the personal information that you submit by and through such Interactive Services and/or
        Social Media Pages can be read, collected and/or used by other users of these
        websites/services (depending on your privacy settings associated with your accounts with the
        applicable Social Media Websites), and could be used to send you unsolicited messages or
        otherwise to contact you without your consent or desire; and (b) where LifeMD™ responds to
        any interaction on such Social Media Pages, your account name/handle may be viewable by any
        and all members/users of LifeMD’s™&nbsp; social media accounts.&nbsp; We are not
        responsible for the personal information that you choose to submit via Interactive Services
        and/or any Social Media Websites.&nbsp;{' '}
      </p>

      <p>
        In addition, the Site contains Social Media Website widgets and buttons (“<u>Widgets</u>”),
        such as the Facebook® and Twitter® share buttons.&nbsp; These Widgets may collect your IP
        address, as well as which page you are visiting on our Site, and may set a Cookie on your
        browser to enable the Widget to function properly.&nbsp; The Widgets are owned and operated
        by the applicable Social Media Websites. The Social Media Websites operate independently
        from LifeMD™, and we are not responsible for such Social Media Websites’ interfaces or
        privacy or security practices.&nbsp;{' '}
      </p>

      <p>
        Your interactions with the Social Media Pages and Widgets are governed by the privacy
        policies of the applicable Social Media Websites.&nbsp; We encourage you to review the
        privacy policies and settings of the Social Media Pages with which you interact to help you
        understand those Social Media Websites’ respective privacy practices.&nbsp; If you have
        questions about the security and privacy settings of any Social Media Pages and/or Widgets
        that you use, please refer to the applicable Social Media Website’s privacy notices or
        policies.{' '}
      </p>

      <p>
        <strong>Third-Party Websites</strong>
      </p>

      <p>
        This Site may contain links to third-party owned and/or operated websites including, without
        limitation, Social Media Websites, as well as the websites of third parties, such as
        Healthcare Providers.&nbsp; LifeMD™ is not responsible for the privacy practices or the
        content of such websites.&nbsp; In some cases, you may be able to make a purchase through
        one of these third-party websites.&nbsp; In these instances, you may be required to provide
        certain information, such as a credit card number, to register or complete a transaction at
        such website.&nbsp; These third-party websites have separate privacy and data collection
        practices and LifeMD™ has no responsibility or liability relating to them.
      </p>

      <p id="Security_your_info">
        <strong>Security of Your Personal Information&nbsp; </strong>
      </p>

      <p>
        We endeavor to safeguard and protect our users’ personal information.&nbsp; When users make
        personal information available to us, their personal information is protected both online
        and offline (to the extent that we maintain any personal information offline).&nbsp; Where
        our registration/application process prompts users to enter Sensitive Information (such as
        Protected Information, driver’s license/ID card number, health insurance information, data
        collected from an automated license plate recognition system, Social Security Number, and
        credit card information), and when we store and transmit such Sensitive Information, that
        information is encrypted with industry standard encryption technology.{' '}
      </p>

      <p>
        Access to your personal information is strictly limited, and we take reasonable security
        measures to ensure that your personal information is not accessible to the public.&nbsp; All
        of our users’ personal information is restricted in our offices, as well as the offices of
        our third-party service providers.&nbsp; Only employees or third-party agents who need user
        personal information to perform a specific job are granted access to user personal
        information.&nbsp; Our employees are dedicated to ensuring the security and privacy of all
        users’ personal information.&nbsp; Employees not adhering to our firm policies are subject
        to disciplinary action.&nbsp; The servers that we store user personal information on are
        kept in a secure physical environment.&nbsp; We also have security measures in place to
        protect the loss, misuse, and alteration of personal information under our control.&nbsp;{' '}
      </p>

      <p>
        Please be advised, however, that while we take every reasonable precaution available to
        protect your data, no storage facility, technology, software, security protocols or data
        transmission over the Internet or via wireless networks can be guaranteed to be 100%
        secure.&nbsp; Computer hackers that circumvent our security measures may gain access to
        certain portions of your personal information, and technological bugs, errors and glitches
        may cause inadvertent disclosures of your personal information; <em>provided, however, </em>
        that any attempt to breach the security of the network, our servers, databases or other
        hardware or software may constitute a crime punishable by law.&nbsp; For the reasons
        mentioned above, we cannot warrant that your personal information will be absolutely
        secure.&nbsp; Any transmission of data at or through the Site, other Site Offerings or
        otherwise via the Internet or wireless networks, is done at your own risk.{' '}
      </p>

      <p>
        In compliance with applicable federal and state laws, we shall notify you and any applicable
        regulatory agencies in the event that we learn of an information security breach with
        respect to your personal information.&nbsp; You will be notified via e-mail in the event of
        such a breach.&nbsp; Please be advised that notice may be delayed in order to address the
        needs of law enforcement, determine the scope of network damage, and to engage in remedial
        measures.
      </p>

      <p id="Chlidrens_privacy">
        <strong>Children’s Privacy </strong>
      </p>
      <p>
        Please see our Privacy Provisions for California Residents{' '}
        <a href="src/modals/TermsAndRules/Privacy#California_residents">below</a>
        for more details regarding the personal information of minors.
      </p>

      <p>
        Visitors under eighteen (18) years of age are not permitted to use and/or submit their
        personal information at the Site.&nbsp;LifeMD™ does not knowingly solicit or collect
        information from visitors under eighteen (18) years of age.
      </p>

      <p id="Opt_out_unsubscribe">
        <strong>Opt-Out/Unsubscribe</strong>
      </p>

      <p>
        Please see our Privacy Provisions for California Residents{' '}
        <a href="src/modals/TermsAndRules/Privacy#California_residents">below</a> for instructions
        on how California Residents can opt-out of the sale of their personal information to third
        parties.{' '}
      </p>

      <p>
        To opt-out of receiving e-mail and other forms of communication from us, you can: (a) follow
        the instructions included in the applicable e-mail message or other communication; or (b)
        e-mail us at: patientcare@lifemd.com.
      </p>

      <p>
        Notwithstanding the foregoing, we may continue to contact you for the purpose of
        communicating information relating to your request for Site Offerings, as well as to respond
        to any inquiry or request made by you.&nbsp; To opt-out of receiving Site Offerings-related
        and/or inquiry response-related messages from LifeMD™, you must cease requesting and/or
        utilizing the Site Offerings and/or cease submitting inquiries to LifeMD™, as applicable.
        <strong />
      </p>

      <p id="Deleting_your_info">
        <strong>Deleting, Modifying and Updating Your Information</strong>
      </p>

      <p>
        Please see our Privacy Provisions for California Residents{' '}
        <a href="src/modals/TermsAndRules/Privacy#California_residents">below</a> for instructions
        on how California Residents can access and/or delete personal information that we have
        collected.{' '}
      </p>

      <p>
        At your request, we will: (a) inform you of what personal information we have on file for
        you; (b) amend the personal information that we have on file for you; and/or (c) remove
        personal information that you have provided to us, or that we have otherwise
        collected.&nbsp; You may do so by e-mailing us at: patientcare@lifemd.com.&nbsp; We ask
        individual users to identify themselves and the information requested to be accessed,
        corrected, or removed before processing such requests, and, to the extent permitted by
        applicable law, we may decline to process requests that are unreasonably repetitive or
        systematic, require disproportionate technical effort, jeopardize the privacy of others or
        would be extremely impractical (for instance, requests concerning information residing on
        backup tapes).
      </p>

      <p>
        Please be advised that deleting your personal information may terminate your access to
        certain of the Site Offerings.&nbsp; If you wish to continue using the full complement of
        Site Offerings, you may not be able to delete all of the personal information that we have
        on file for you.
      </p>

      <p>
        Please be further advised that, after you delete your personal information, residual copies
        may take a period of time before they are deleted from our active servers and may remain in
        our backup systems.{' '}
      </p>

      <p>
        <strong>Transfer of Personal Information Internationally</strong>
      </p>

      <p>
        If you are visiting the Site from a country other than the country in which our servers are
        located, your communications with us may result in the transfer of information across
        international boundaries. By visiting the Site and/or otherwise communicating electronically
        with us, you consent to such transfers.&nbsp; Even if your jurisdiction does not have the
        same privacy laws as the jurisdiction where our servers are located, we will treat your
        information as subject to the protections described in this Privacy Policy.{' '}
      </p>

      <p id="Chages_privacy">
        <strong>Changes to this Privacy Policy</strong>
      </p>

      <p>
        LifeMD™ reserves the right to change or update this Privacy Policy at any time by posting a
        notice on the Site that we are changing our Privacy Policy.&nbsp; If the manner in which we
        use personal information changes, LifeMD™ will notify users by: (a) sending the modified
        policy to our users via e-mail; and/or (b) by any other reasonable means acceptable under
        applicable state and federal law.&nbsp; You will have a choice as to whether or not we use
        your information in this different manner and we will only use your information in this
        different manner where you opt-in to such use.&nbsp;&nbsp;{' '}
      </p>

      <p id="Contact_us">
        <strong>Contact Us</strong>
      </p>

      <p>
        If you have any questions about this Privacy Policy or our privacy practices in general, you
        may e-mail us as at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>;
        call us at: <a href="tel:800-852-1575">(800) 852-1575</a>; or send us U.S. Mail to: LifeMD,
        Inc., 236 Fifth Avenue, Suite 400, New York, NY 10001.
      </p>

      <p>&nbsp;</p>

      <h4 id="California_residents">
        <strong>
          <u>Privacy Provisions for California Residents</u>
        </strong>
        <strong>
          <u> </u>
        </strong>
      </h4>
      <br />

      <p>
        These Privacy Provisions for California Residents (“<u>Privacy Provisions</u>”) supplement,
        and do not limit in any way, the Privacy Policy set forth above.&nbsp; These Privacy
        Provisions apply solely to residents of the State of California (“<u>CA Users</u>”).&nbsp;
        We adopt these Privacy Provisions in compliance with the California Consumer Privacy Act of
        2018 (“<u>CCPA</u>”).&nbsp; Any terms defined in the CCPA have the same meaning when used in
        these Privacy Provisions.&nbsp; CA Users with disabilities who wish to access these Privacy
        Provisions in an alternative format can contact us by emailing us at:{' '}
        <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; calling us at:{' '}
        <a href="tel:800-852-1575">(800) 852-1575</a>; or sending us U.S. Mail to: LifeMD, Inc., 236
        Fifth Avenue, Suite 400, New York, NY 10001. <br />
      </p>

      <p>
        <u>Categories of Information We Collect</u>
        <br />
        <br />
        We collect information that identifies, relates to, describes, references, is capable of
        being associated with, or could reasonably be linked, directly or indirectly, with a
        particular CA User or device (“<u>personal information</u>”). In particular, we have
        collected the following categories of personal information from CA Users within the last
        twelve (12) months:
      </p>

      <table cellPadding="0" cellSpacing="0" width="100%">
        <tbody>
          <tr>
            <td width="27%">
              <br />
              <strong>Category</strong>
            </td>
            <td width="61%">
              <p>
                <strong>Examples</strong>
              </p>
            </td>
            <td width="11%">
              <p>
                <strong>Collected</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="27%">
              <p>A. Identifiers.</p>
            </td>
            <td width="61%">
              <p>
                A real name, alias, postal address, unique personal identifier, online identifier,
                Internet Protocol address, email address, account name, telephone number, or other
                similar identifiers.
              </p>
            </td>
            <td width="11%">
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td width="27%">
              <p>
                B. Personal information categories listed in the California Customer Records statute
                (Cal. Civ. Code § 1798.80(e)).
              </p>
            </td>
            <td width="61%">
              <p>
                A name, signature, Social Security number, physical characteristics or description,
                postal address, telephone number, passport number, driver&apos;s license or State
                identification card number, insurance policy number, education, employment,
                employment history, bank account number, credit card number, debit card number, or
                any other financial information, medical information, or health insurance
                information. Some personal information included in this category may overlap with
                other categories.
              </p>
            </td>
            <td width="11%">
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td width="27%">
              <p>C. Protected classification characteristics under California or federal law.</p>
            </td>
            <td width="61%">
              <p>
                Age (40 years or older), race, color, ancestry, national origin, citizenship,
                religion or creed, marital status, medical condition, physical or mental disability,
                sex (including gender, gender identity, gender expression, pregnancy or childbirth
                and related medical conditions), sexual orientation, veteran or military status,
                genetic information (including familial genetic information).
              </p>
            </td>
            <td width="11%">
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td width="27%">
              <p>D. Commercial information.</p>
            </td>
            <td width="61%">
              <p>
                Records of personal property, products or services purchased, obtained, or
                considered, or other purchasing or consuming histories or tendencies.
              </p>
            </td>
            <td width="11%">
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td width="27%">
              <p>E. Biometric information.</p>
            </td>
            <td width="61%">
              <p>
                Genetic, physiological, behavioral, and biological characteristics, or activity
                patterns used to extract a template or other identifier or identifying information,
                such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke,
                gait, or other physical patterns, and sleep, health, or exercise data.
              </p>
            </td>
            <td width="11%">
              <p>YES</p>
            </td>
          </tr>
          <tr>
            <td width="27%">
              <p>F. Internet or other similar network activity.</p>
            </td>
            <td width="61%">
              <p>
                Browsing history, search history, information on a CA User&apos;s interaction with a
                website, application, or advertisement.
              </p>
            </td>
            <td width="11%">
              <p>YES</p>
            </td>
          </tr>
        </tbody>
      </table>

      <br />

      <p>Personal information does not include:</p>

      <ul>
        <li>Publicly available information from government records.</li>
        <li>De-identified or aggregated CA User information.</li>
        <li>
          Information excluded from the CCPA’s scope, such as:
          <ul>
            <li>
              health or medical information covered by HIPAA and the California Confidentiality of
              Medical Information Act (CMIA) or clinical trial data; and
            </li>
            <li>
              personal information covered by certain sector-specific privacy laws, including the
              Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California
              Financial Information Privacy Act (FIPA), and the Driver&apos;s Privacy Protection Act
              of 1994.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        We obtain the categories of personal information listed above from the following categories
        of sources (with the specific categories of personal information indicated in parenthesis):
      </p>

      <ul>
        <li>
          Directly from our CA Users. For example, from online registration forms that our CA Users
          submit to us in connection with the products and/or services that we offer by and through
          the Site. (Categories A, B, C, D and E)
        </li>
        <li>
          Indirectly from our CA Users. For example, through information we collect from our CA
          Users in the course of providing our products and/or services to them. (Categories A, B,
          C, D, E and F)
        </li>
        <li>
          Directly and indirectly from activity on the Site. This includes the type of browser that
          the CA User uses (e.g., Safari, Chrome, Internet Explorer), CA User IP address, the type
          of operating system used by the CA User (e.g., Windows or iOS) and the domain name of the
          CA User’s Internet Service Provider.&nbsp; In addition, we obtain certain Site usage
          details and analytics as same are collected automatically by us and our third-party
          partners. (Category F)
        </li>
        <li>
          When our CA Users interact with us on our social media accounts, including commenting on
          and/or liking our posts. (Categories A, B, C, D, E and F)
        </li>
        <li>
          From third parties that interact with us in connection with the products and/or services
          that we offer to our CA Users. For example, third party entities that assist us in sending
          direct and electronic mail, removing duplicate information from CA User lists, analyzing
          data, and providing marketing analysis. (Categories A, B, C, D, E and F)
        </li>
      </ul>

      <p>
        <u>Use of Personal Information</u>
        <br />
        <br />
        Subject to your right to limit our ability to use the sensitive personal information that we
        collect from you, we may use or disclose the personal information that we collect
        (including: (a) name; (b) mailing/billing address; (c) e-mail address; (d) telephone number;
        (e) your gender; (f) credit card information (where purchasing Products and Services); and
        (g) answers to health and well-being related questions, as asked via the medical intake
        form) for one or more of the following business purposes (with the specific categories of
        personal information indicated in parenthesis):
      </p>

      <ul>
        <li>
          To fulfill or meet the reason for which the information is provided. For example, if you
          provide us with personal information in connection with your request for products and/or
          services, we will use that information to process your request. (Categories A, B, C, D, E
          and F)
        </li>
        <li>
          To provide you with information, products, or services that you request from us.
          (Categories A, B, C, D, E and F)
        </li>
        <li>
          To create, maintain, customize, and secure your account with us. (Categories A, B, C, D, E
          and F)
        </li>
        <li>
          To provide you with e-mail, direct mail and telemarketing messages concerning certain
          LifeMD, Inc. products and/or services, as well as third-party products and/or services,
          that we believe may be of interest to you. (Categories A, B, C, D, E and F)
        </li>
        <li>
          To deliver relevant Site content and advertisements to you and measure or understand the
          effectiveness of the advertising we serve to you. (Categories A, B, C, D, E and F)
        </li>
        <li>
          To carry out our obligations and enforce our rights arising from any contracts entered
          into between you and us, including the Site’s Terms and Conditions. (Categories A, B, C,
          D, E and F)
        </li>
        <li>
          To improve the Site and better present its contents to you. (Categories A, B, C, D, E and
          F)
        </li>
        <li>
          For customer service purposes and to respond to inquiries from you. (Categories A, B, C,
          D, E and F)
        </li>
        <li>
          For testing, research, analysis, and product development. (Categories A, B, C, D, E and F)
        </li>
        <li>
          As necessary or appropriate to protect our rights, property, or safety, and that of our
          customers or others. (Categories A, B, C, D, E and F)
        </li>
        <li>
          To respond to law enforcement requests and as required by applicable law, court order, or
          governmental regulations. (Categories A, B, C, D, E and F)
        </li>
        <li>
          As described to you when collecting your personal information or as otherwise set forth in
          the CCPA. (Categories A, B, C, D, E and F)
        </li>
        <li>
          To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution
          or other sale or transfer of some or all of our assets, whether as a going concern or as
          part of bankruptcy, liquidation, or similar proceeding, in which personal information held
          by us is among the assets transferred. (Categories A, B, C, D, E and F)
        </li>
      </ul>

      <p>
        We will not collect additional categories of personal information or use the personal
        information we collected for materially different, unrelated, or incompatible purposes
        without providing you with notice.
      </p>

      <p>
        <u>Sharing Personal Information</u>
        <br />
        <br />
        Subject to your right to opt-out of such sharing/sale, we may share your personal
        information (including: (a) name; (b) mailing/billing address; (c) e-mail address; (d)
        telephone number; (e) your gender; (f) credit card information (where purchasing Products
        and Services); and (g) answers to health and well-being related questions, as asked via the
        medical intake form) for the business purposes set forth above.
        <br />
        <br />
        When we disclose personal information to a third-party service provider or other entity, we
        enter into a contractual relationship that describes the purpose for which such third party
        may use the personal information and requires that third party to both keep the personal
        information confidential and not use it for any purpose other than the performance of its
        services under the applicable contract.&nbsp; Please note, we do not collect information
        from CA Users that we actually know are less than eighteen (18) years of age and we do not
        share or sell the personal information of CA Users that we actually know are less than
        eighteen (18) years of age.&nbsp; Without limiting the foregoing, we have not shared or sold
        the personal information of CA Users that we actually know are less than sixteen (16) years
        of age in the preceding twelve (12) months.
        <br />
        <br />
        In the preceding twelve (12) months, we have disclosed the following categories of personal
        information (including: (a) name; (b) mailing/billing address; (c) e-mail address; (e)
        telephone number; (f) your gender; (g) credit card information (where purchasing Products
        and Services); and (h) answers to health and well-being related questions, as asked via the
        medical intake form) for a business purpose :
        <br />
        <br />
        Category A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Identifiers.
        <br />
        Category B&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; California Customer Records personal
        information categories. <br />
        Category C&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Protected classification characteristics
        under California or federal law.
        <br />
        Category D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Commercial information. <br />
        Category E&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Biometric information.
        <br />
        Category F&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Internet or other similar network
        activity.
      </p>

      <p>
        We disclose your personal information (including: (a) name; (b) mailing/billing address; (c)
        e-mail address; (d) telephone number; (e) your gender; (f) credit card information (where
        purchasing Products and Services); and (g) answers to health and well-being related
        questions, as asked via the medical intake form) for a business purpose to the following
        categories of third parties (with the specific categories of personal information indicated
        in parenthesis):
      </p>

      <ul>
        <li>Our affiliates. (Categories A, B, C, D, E and F)</li>
        <li>Service providers. (Categories A, B, C, D, E and F)</li>
        <li>
          Third parties (including Healthcare Providers) to whom you authorize us to disclose your
          personal information in connection with the products and/or services that we provide to
          you. (Categories A, B, C, D, E and F)
        </li>
      </ul>

      <p>
        In the preceding twelve (12) months, we have not sold personal information to third parties.
      </p>

      <p>
        <u>Retention of Personal Information</u>
        <br />
        <br />
        LifeMD may retain the categories of personal information set forth below for the period that
        is the greater of: (a) as long as LifeMD believes necessary; (b) as long as necessary to
        comply with LifeMD’s legal obligations, resolve disputes and/or enforce its agreements;
        and/or (c) as long as needed to provide CA Users with the products and/or services requested
        by such CA Users on the Site:
        <br />
        <br />
        Category A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Identifiers.
        <br />
        Category B&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; California Customer Records personal
        information categories. <br />
        Category C&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Protected classification characteristics
        under California or federal law.
        <br />
        Category D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Commercial information. <br />
        Category E&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Biometric information.
        <br />
        Category F&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Internet or other similar network
        activity. <br />
      </p>

      <p>
        LifeMD may dispose of or delete any such information at any time, except as required by law,
        or as otherwise restricted by the terms and conditions set forth in any other agreement or
        document that is binding upon LifeMD.
        <br />
      </p>

      <p>
        <u>Your Rights and Choices</u>
        <br />
        <br />
        The CCPA provides CA Users (California residents) with specific rights regarding their
        personal information. This section describes your CCPA rights and explains how to exercise
        those rights.
        <br />
        <br />
        <em>Opt-Out from the Sale of Your Personal Information</em>
        <br />
        <br />
        You have the right to opt-out of our sale of your personal information to third
        parties.&nbsp; To exercise your right to opt-out of our sale of your personal information to
        third parties, please submit a verifiable CA User request to us by either:
      </p>

      <ul>
        <li>
          <a href="https://lifemd.com/ccpa-opt-out.php">Clicking here</a>
        </li>
        <li>
          Calling us at: <a href="tel:800-852-1575">(800) 852-1575</a>
        </li>
        <li>
          Emailing us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; or
        </li>
        <li>Sending us mail to: LifeMD, Inc., 236 Fifth Avenue, Suite 400, New York, NY 10001.</li>
      </ul>

      <p>
        We endeavor to act on all opt-out requests as soon as practicable, but in all cases within
        fifteen (15) business days of the receipt of your request.
        <br />
        <br />
        <em>Opt-Out from the Sharing of Your Personal Information</em>
        <br />
        <br />
        You have the right to opt-out of our sharing your personal information with third
        parties.&nbsp; To exercise your right to opt-out of our sharing your personal information
        with third parties, please submit a verifiable CA User request to us by either:
      </p>

      <ul>
        <li>
          <a href="https://lifemd.com/ccpa-opt-out-si.php">Clicking here</a>
        </li>
        <li>
          Calling us at: <a href="tel:800-852-1575">(800) 852-1575</a>
        </li>
        <li>
          Emailing us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; or
        </li>
        <li>Sending us mail to: LifeMD, Inc., 236 Fifth Avenue, Suite 400, New York, NY 10001.</li>
      </ul>

      <p>
        We endeavor to act on all opt-out requests as soon as practicable, but in all cases within
        fifteen (15) business days of the receipt of your request.
      </p>

      <p>
        <em>Opt-Out from the Use and/or Sharing of Your Sensitive Personal Information</em>
        <br />
        <br />
        You have the right to limit our use of your sensitive personal information, including any
        sensitive information from Category B (California Customer Records personal information),
        Category C (Protected classification characteristics under California or federal law) and/or
        Category E (Biometric information), to those uses which: (i) are necessary to perform the
        services or provide the goods requested by you; (ii) help us to ensure security and
        integrity of your personal information to the extent the use of your sensitive personal
        information is reasonably necessary and proportionate for these purposes; (iii) are
        short-term, transient uses including, but not limited to, non-personalized advertising shown
        as part of your then-current interaction with the Site, provided that your personal
        information is not disclosed to another third party and is not used to build a profile about
        you or otherwise alter your experience outside the then-current interaction with the Site;
        (iv) involve performing services on behalf of LifeMD™, including maintaining or servicing
        accounts, providing customer service, processing or fulfilling orders and transactions,
        verifying customer information, processing payments, providing financing, providing analytic
        services, providing storage, or providing similar services on behalf of LifeMD™; and (v)
        include activities to verify or maintain the quality or safety of a service or device that
        is owned, manufactured, manufactured for, or controlled by, LifeMD™, and to improve,
        upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or
        controlled by, LifeMD™.
        <br />
        <br />
        You also have the right opt-out of our sharing your sensitive personal information with
        third parties, including any sensitive information from Category B (California Customer
        Records personal information), Category C (Protected classification characteristics under
        California or federal law) and/or Category E (Biometric information).&nbsp;
        <br />
        <br />
        To exercise your right to limit our use of your sensitive personal information and/or
        opt-out of our sharing your sensitive personal information with third parties, please submit
        a verifiable CA User request to us by either:
      </p>

      <ul>
        <li>
          <a href="https://lifemd.com/ccpa-opt-out-si.php">Clicking here</a>
        </li>
        <li>
          Calling us at: <a href="tel:800-852-1575">(800) 852-1575</a>
        </li>
        <li>
          Emailing us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; or
        </li>
        <li>Sending us mail to: LifeMD, Inc., 236 Fifth Avenue, Suite 400, New York, NY 10001.</li>
      </ul>

      <p>
        We endeavor to act on all such requests as soon as practicable, but in all cases within
        fifteen (15) business days of the receipt of your request.
      </p>

      <p>
        <em>Access to Specific Information and Data Portability Rights</em>
        <br />
        <br />
        You have the right to request that we disclose certain information to you about our
        collection and use of your personal information over the past twelve (12) months. Once we
        receive and confirm your verifiable CA User request, we will disclose to you:
      </p>

      <ul>
        <li>The categories of personal information we collected about you.</li>
        <li>The categories of sources for the personal information we collected about you.</li>
        <li>
          Our business or commercial purpose for collecting or selling that personal information.
        </li>
        <li>The categories of third parties with whom we have shared that personal information.</li>
        <li>
          The specific pieces of personal information we collected about you (also called a data
          portability request).
        </li>
        <li>
          If we sold or disclosed your personal information for a business purpose, two separate
          lists disclosing:
          <ul>
            <li>
              sales, identifying the personal information categories that each category of recipient
              purchased; and
            </li>
            <li>
              disclosures for a business purpose, identifying the personal information categories
              that each category of recipient obtained.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        <em>Deletion Request Rights</em>
        <br />
        <br />
        You have the right to request that we delete any of your personal information that we
        collected from you and retained, subject to certain exceptions. Once we receive and confirm
        your verifiable CA User request, we will delete (and direct our service providers to delete)
        your personal information from our (their) records, unless an exception applies;{' '}
        <em>provided, however, </em>that in some cases, strictly for regulatory compliance purposes
        and to better evidence/honor opt-out/unsubscribe requests (and for no other purposes), we
        may retain certain items of your personal information on a de-identified and aggregated
        basis in such a manner that the data no longer identifies you.&nbsp;
        <br />
        <br />
        We may deny your deletion request if retaining the information is necessary for us or our
        service providers to:
      </p>

      <ul>
        <li>
          Complete the transaction for which we collected the personal information, provide a good
          or service that you requested, take actions reasonably anticipated within the context of
          our ongoing business relationship with you, or otherwise perform our obligations in
          connection with our contract/relationship with you.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
          activity, or prosecute those responsible for such activities.
        </li>
        <li>
          Debug products to identify and repair errors that impair existing intended functionality.
        </li>
        <li>
          Exercise free speech rights, ensure the right of another CA User to exercise her/his free
          speech rights or exercise another right provided for by law.
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act (Cal. Penal Code §
          1546&nbsp;<em>seq.</em>).
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or statistical research in the
          public interest that adheres to all other applicable ethics and privacy laws, when the
          information&apos;s deletion may likely render impossible or seriously impair the
          research&apos;s achievement, but only if you previously provided informed consent.
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with CA User expectations based on
          your relationship with us.
        </li>
        <li>Comply with a legal obligation.</li>
        <li>
          Make other internal and lawful uses of that information that are compatible with the
          context in which you provided it.
        </li>
      </ul>

      <p>
        <em>Exercising Access, Data Portability and Deletion Rights</em>
        <br />
        <br />
        To exercise your access, data portability and/or deletion rights described above, please
        submit a verifiable CA User request to us by either:
      </p>

      <ul>
        <li>
          <a href="https://lifemd.com/ccpa-privacy-rights.php">Clicking here</a>
        </li>
        <li>
          Calling us at: <a href="tel:800-852-1575">(800) 852-1575</a>
        </li>
        <li>
          Emailing us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; or
        </li>
        <li>Sending us mail to: LifeMD, Inc., 236 Fifth Avenue, Suite 400, New York, NY 10001.</li>
      </ul>

      <p>
        Only you or a person registered with the California Secretary of State that you authorize to
        act on your behalf, may make a verifiable CA User request related to your personal
        information.
      </p>

      <p>
        {' '}
        You may only make a verifiable CA User request for access or data portability twice within a
        12-month period. The verifiable CA User request must:
      </p>

      <ul>
        <li>
          Provide sufficient information that allows us to reasonably verify that you are: (1) the
          person about whom we collected personal information; or (2) an authorized representative.
        </li>
        <li>
          Describe your request with sufficient detail that allows us to properly understand,
          evaluate, and respond to it.
        </li>
      </ul>

      <p>
        We cannot respond to your request or provide you with personal information if we cannot
        verify your identity or authority to make the request and confirm that the personal
        information relates to you.&nbsp; Making a verifiable CA User request does not require that
        you create an account with us.&nbsp; We will only use personal information provided in a
        verifiable CA User request to verify the requestor&apos;s identity or authority to make the
        request.
      </p>

      <p>
        <em>Response Timing and Format</em>
        <br />
        <br />
        We endeavor to respond to all verifiable CA User requests within forty-five (45) days of the
        receipt thereof.&nbsp; If we require more time (up to ninety (90) days), we will inform you
        of the reason and extension period in writing.&nbsp; We will deliver our written response by
        mail or electronically, at your option.&nbsp; Any disclosures that we provide will only
        cover the twelve (12) month period preceding the receipt of your verifiable request.&nbsp;
        The response that we provide will also explain the reasons that we cannot comply with a
        request, if applicable.&nbsp; For data portability requests, we will select a format to
        provide your personal information that is readily useable and should allow you to transmit
        the information from one entity to another entity without hindrance.
      </p>

      <p>
        We do not charge a fee to process or respond to your verifiable CA User request unless it is
        excessive, repetitive, or manifestly unfounded.&nbsp; If we determine that the request
        warrants a fee, we will tell you why we made that decision and provide you with a cost
        estimate before completing your request.
      </p>

      <p>
        <u>Non-Discrimination</u>
        <br />
        <br />
        We will not discriminate against you for exercising any of your CCPA rights. Unless
        permitted by the CCPA, we will not:
      </p>

      <ul>
        <li>Deny you goods or services;</li>
        <li>
          Charge you different prices or rates for goods or services, including through granting
          discounts or other benefits, or imposing penalties;
        </li>
        <li>Provide you a different level or quality of goods or services; and/or</li>
        <li>
          Suggest that you may receive a different price or rate for goods or services or a
          different level or quality of goods or services.
        </li>
      </ul>

      <p>
        <u>Changes to these Privacy Provisions</u>
        <br />
        <br />
        We reserve the right to amend these Privacy Provisions in our discretion and at any time.
        When we make changes to these Privacy Provisions, we will notify you by email or through a
        notice on the Site’s homepage.
      </p>

      <p>
        <u>Contact Information</u>
        <br />
        <br />
        If you have any questions or comments about these Privacy Provisions, our Privacy Policy,
        the ways in which we collect and use your personal information, your choices and rights
        regarding such use, or wish to exercise your rights under California law, please do not
        hesitate to contact us by either:
      </p>
      <ul>
        <li>
          Calling us at: <a href="tel:800-852-1575">(800) 852-1575</a>;
        </li>
        <li>
          Emailing us at: <a href="mailto:patientcare@lifemd.com">patientcare@lifemd.com</a>; or
        </li>
        <li>Sending us mail to: LifeMD, Inc., 236 Fifth Avenue, Suite 400, New York, NY 10001.</li>
      </ul>
    </>
  );
};

export default Privacy;

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { EvidenceItem } from 'services/symptomChecker/symptomChecker.types';

import useWidth from 'hooks/useWidth';

import { GroupMultipleProps } from './groupMultiple.types';

const GroupMultiple: React.FC<GroupMultipleProps<EvidenceItem['choice_id']>> = ({
  items,
  onSelect,
  answers
}) => {
  const { isMobile } = useWidth();
  const getSelectedValue = (id: string) => answers.find((a) => a.id === id)?.value ?? null;

  return (
    <>
      <h4 className="mb-8 text-mSm text-gray md:mb-6 md:text-base">
        Select one answer in each statement
      </h4>
      {items.map((item) => (
        <div
          className="my-6 flex flex-col gap-2 rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:flex-row md:items-center md:justify-between md:px-8 md:py-4"
          data-testid="statement_block"
          key={item.id}
        >
          <span
            className="mb-4 text-mBase text-gray md:mb-0 md:text-base md:text-gray-700"
            data-testid="statement_name"
          >
            {item.title}
          </span>
          <div
            className="flex flex-col md:flex-row md:items-center md:gap-8"
            data-testid="answer_options"
          >
            {item.options.map((option) => (
              <Common.RadioButton
                checked={getSelectedValue(item.id) === option.value}
                className={classNames({ '!w-full py-2 first:pt-0 last:pb-0': isMobile })}
                color="blue"
                dataTestId="rich_radio_btn_"
                key={option.value}
                onChange={() => onSelect(item.id, option.value)}
              >
                <span className="first-letter:uppercase">{option.label}</span>
              </Common.RadioButton>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default GroupMultiple;

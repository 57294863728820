import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import { GetMemberPricingReqProps, GetMemberPricingResProps } from './labTests.types';

export const labTestsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getMemberPricing: build.query<GetMemberPricingResProps, GetMemberPricingReqProps>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        params,
        url: 'lab-tests/member-pricing'
      })
    })
  }),
  reducerPath: 'labTestsApi',
  tagTypes: ['LabTests']
});

export const { useLazyGetMemberPricingQuery } = labTestsApi;

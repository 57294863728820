import { MouseEvent, useState } from 'react';
import classNames from 'classnames';

import TermsAndRulesPopup from 'modals/TermsAndRules';

import { SIGNUP_CONFIRM_LINKS } from 'constants/externalLinks';

const CheckboxLabel: React.FC<{ isChecked?: boolean; isError?: boolean; placement?: string }> = ({
  placement = '',
  isError = false,
  isChecked = false
}) => {
  const [tab, setTab] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const textClasses = classNames(
    { 'font-bold': isChecked && placement === 'wm-flow' },
    { '!text-red shake': isError },
    placement === 'payment-card' ? 'text-center text-gray max-md:text-mSm' : 'text-gray-700'
  );
  const handleClick = (e: MouseEvent, value: string) => {
    e.preventDefault();
    if (value === 'notice on privacy practices') {
      window.open('https://lifemd.com/notice-of-privacy-practices/', '_blank');
    } else if (value === 'consent') {
      window.open(SIGNUP_CONFIRM_LINKS.telehealth, '_blank');
    } else {
      setTab(value);
      setIsOpen(true);
    }
  };

  const linkClassName = classNames(
    placement === 'wm-flow' ? 'underline' : 'font-bold cursor-pointer',
    {
      'text-center text-gray max-md:text-mSm': placement === 'payment-card'
    }
  );

  return (
    <>
      <TermsAndRulesPopup
        isOpen={isOpen}
        tab={tab}
        withTeleHealthTab
        onClose={() => setIsOpen(false)}
      />
      <p className={textClasses}>
        {placement === 'wm-flow'
          ? 'I agree to the '
          : placement !== 'payment-card'
            ? 'By clicking "Continue", you agree to the LifeMD'
            : 'By clicking "Submit payment", you agree to the LifeMD'}
        &nbsp;
        <a className={linkClassName} onClick={(e) => handleClick(e, 'terms')}>
          {placement === 'wm-flow' ? 'Terms' : 'Terms of Use'}
        </a>
        {', '}
        <a className={linkClassName} onClick={(e) => handleClick(e, 'privacy')}>
          Privacy Policy
        </a>
        {', '}
        <a className={linkClassName} onClick={(e) => handleClick(e, 'notice on privacy practices')}>
          Notice of Privacy Practices
        </a>
        <span>{placement === 'wm-flow' ? ' and ' : ' & '}</span>
        <a className={linkClassName} onClick={(e) => handleClick(e, 'consent')}>
          {placement === 'wm-flow' ? 'Consent to Telehealth' : 'Telehealth Consent Policy'}
        </a>
        .
      </p>
    </>
  );
};

export default CheckboxLabel;

import Lottie from 'lottie-react';

import { NewWeightGraphProps } from './newWeightGraph.types';

import illustration from 'assets/animations/wm-onboarding/year-chart.json';

const NewWeightGraph: React.FC<NewWeightGraphProps> = ({ weightPoints }) => {
  return (
    <div className="relative">
      <Lottie animationData={illustration} loop={false} width={300} />
      <div className="absolute -top-1 left-10 rounded-lg border border-dashed border-gray-300 bg-gray-50 p-2 text-xs font-bold leading-normal text-gray md:-top-3 md:left-10 md:text-sm">
        <span>Current</span>
        <p>{weightPoints[0]} lbs</p>
      </div>

      <div className="absolute bottom-[22%] right-8 flex flex-col place-content-center rounded-lg border border-dashed border-yellow-300 bg-yellow-100 p-2 text-xs font-bold leading-normal text-yellow md:bottom-[21%] md:right-[10%] md:text-sm">
        <span>Target</span>
        <p data-testid="target_weight_graph">{weightPoints[1]} lbs</p>
      </div>
    </div>
  );
};

export default NewWeightGraph;

export const useDelayedScroll = (cb: () => void, time = 0) => {
  const delayedExec = (after: number | undefined, fn: { (): void; (): void }) => {
    let timer: NodeJS.Timeout;
    return () => {
      timer && clearTimeout(timer);
      timer = setTimeout(fn, after);
    };
  };
  return delayedExec(time, () => {
    cb();
  });
};

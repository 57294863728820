import { Common } from '@thecvlb/design-system';

import { ImageProps } from './image.types';

const Image: React.FC<ImageProps> = ({ isOpen, onClose, filePath }) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="lg" zIndex={40}>
      <img alt="" className="m-auto" src={filePath} />
    </Common.Modal>
  );
};

export default Image;

import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import PlanCard from 'widgets/PlanSelector/PlanCard';
import Switcher from 'widgets/signUp/SwitchToApp/Switcher';

import useWidth from 'hooks/useWidth';
import { PlanCodes } from 'utils/enums';
import { getPricePoint } from 'utils/helpers';

import { PricePoint } from 'models/plans.types';

import { PlanSelectorProps } from './planSelector.types';

const PlanSelector: React.FC<PlanSelectorProps> = ({
  onUpdate,
  plans,
  currentPlan,
  flow,
  showSwitcher = true
}) => {
  const { isMobile } = useWidth();
  const [isAnnual, toggle] = useToggle(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const handlePlanSelect = (planID: string, pricePoint: PricePoint) => {
    onUpdate(planID, pricePoint);
  };

  const getCardClassName = (i: number) =>
    classNames('mx-1.5 h-full w-auto md:max-w-[312px]', {
      'ml-4': i === 0,
      'mr-4': i === plans.length - 1
    });

  const mappedPlans = plans.map((p, i) => ({ label: p.planName, value: i }));

  return (
    <div className="flex max-w-full flex-col flex-wrap items-center">
      <div className="mb-4 flex items-center gap-2 max-md:flex-col md:mb-8 md:gap-8">
        {flow === 'default' && showSwitcher && (
          <>
            <div className="mt-0.5 flex items-center justify-center gap-4 text-inherit">
              <button
                className={classNames('text-inherit', { 'font-bold': !isAnnual })}
                onClick={() => toggle(false)}
              >
                Quarterly
              </button>
              <Common.Toggle checked={isAnnual} color="blue" onClick={toggle} />
              <button
                className={classNames('text-inherit', { 'font-bold': isAnnual })}
                onClick={() => toggle(true)}
              >
                Annual
              </button>
            </div>
            <span className="font-semibold text-gray max-md:mb-6 max-md:text-mSm">
              Get 3 months free when paid annually
            </span>
          </>
        )}
        <div className="md:hidden">
          <Switcher activeItemIndex={activeIndex} tabs={mappedPlans} onUpdate={setActiveIndex} />
        </div>
      </div>

      <div className="mx-auto max-w-full md:max-w-screen-lg" data-testid="plans">
        <Carousel
          centerSlidePercentage={isMobile ? 90 : 33}
          className="alt-scroll max-md:-mx-4"
          selectedItem={activeIndex}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          swipeable={isMobile}
          swipeScrollTolerance={80}
          centerMode
          preventMovementUntilSwipeScrollTolerance
          onChange={setActiveIndex}
        >
          {plans.map((plan, i) => {
            const selectedPP = getPricePoint(
              plan.pricePoints,
              plan.planCode === PlanCodes.FlexCare ? undefined : isAnnual ? 12 : 3
            );

            return (
              <div className={getCardClassName(i)} key={plan._id}>
                {selectedPP ? (
                  <PlanCard
                    currentPlan={currentPlan}
                    flow={flow}
                    isActive={plan.planCode === PlanCodes.TotalCareMembership}
                    planInfo={plan}
                    pricePoint={selectedPP}
                    onChoosePlan={handlePlanSelect}
                  />
                ) : (
                  <span>Oops, something went wrong, we are working on it</span>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default PlanSelector;

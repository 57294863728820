import { createApi } from '@reduxjs/toolkit/query/react';

import { setDocumentsLength } from 'store/documents/documentsSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  DeleteDocumentsReqProps,
  DeleteDocumentsResProps,
  GetDocumentsReqProps,
  GetDocumentsResProps,
  UploadDocumentsReqProps,
  UploadDocumentsResProps
} from './documents.types';

export const documentsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    deleteDocument: build.mutation<DeleteDocumentsResProps, DeleteDocumentsReqProps>({
      query: ({ documentId, accessToken }) => ({
        method: 'DELETE',
        ...(accessToken && {
          headers: {
            Authorization: accessToken
          }
        }),
        url: `documents/${documentId}/mark-deleted`
      })
    }),
    getDocuments: build.query<GetDocumentsResProps, GetDocumentsReqProps>({
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data.info.totalCount && !arg?.source) {
          dispatch(setDocumentsLength(data.info.totalCount));
        }
      },
      query: (params) => ({
        ...(params?.accessToken && {
          headers: {
            Authorization: params.accessToken
          }
        }),
        params: {
          limit: 10,
          ...params
        },
        url: 'documents/list'
      })
    }),
    uploadDocuments: build.mutation<UploadDocumentsResProps, UploadDocumentsReqProps>({
      query: ({ body, accessToken }) => ({
        ...(accessToken && {
          headers: {
            Authorization: accessToken
          }
        }),
        body,
        method: 'POST',
        url: 'documents/upload'
      })
    })
  }),
  reducerPath: 'documentsApi',
  tagTypes: ['Documents']
});

export const {
  useLazyGetDocumentsQuery,
  useGetDocumentsQuery,
  useDeleteDocumentMutation,
  useUploadDocumentsMutation
} = documentsApi;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import {
  useLazyGetCreditCardInformationQuery,
  useUpdateMyAccountMutation
} from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import FailedPayment from 'modals/FailedPayment';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import TransitionWithDelayWrapper from 'shared/animationWrappers/TransitionWithDelayWrapper';
import Loader from 'shared/Loader';

import { useAppSelector } from 'hooks';
import useWidth from 'hooks/useWidth';
import { PathName, PlanCodes } from 'utils/enums';
import { handleRequestCatch, isUpgradeToAnnualAlreadyShown } from 'utils/helpers';

import PlusTwoMonthsSvg from 'assets/images/postConsult/plus-two-months.svg?react';

const ANNUAL_PLAN_ADVANTAGES = [
  'Follow-up care: messaging with your doctor and medical care team',
  'Up to 95% off lab work',
  'Up to 92% off prescriptions with the LifeMD Rx Discount Card',
  'Unlimited use of the LifeMD Symptom Checker',
  '24/7 messaging with an on-call night nurse',
  'And more!'
];

const UpgradeToAnnualPlan = () => {
  const navigate = useNavigate();
  const { isMobile } = useWidth();
  const [isPaymentFailedOpen, toggleIsPaymentFailedOpen] = useToggle(false);
  const [upgradePlan, { isLoading }] = useUpdateMyAccountMutation();
  const [getCreditCardInformation, { isSuccess }] = useLazyGetCreditCardInformationQuery();
  const user = useAppSelector(selectUser);
  const { data: plansList, isLoading: isMembershipPricePointLoading } = useGetMembershipPlansQuery({
    isComponentPlan: false
  });

  const lifeMDMembershipPlan = plansList?.data.find(
    (p) => p.planCode === PlanCodes.LifeMDMembership
  );
  const monthlyPricePoint = lifeMDMembershipPlan?.pricePoints?.find(
    (pp) => Number(pp.paymentInterval.qty) === 1
  );
  const annualPricePoint = lifeMDMembershipPlan?.pricePoints?.find(
    (pp) => Number(pp.paymentInterval.qty) === 12
  );

  const currentPricePerYear = Number(monthlyPricePoint?.totalCost) * 12;

  const plans =
    annualPricePoint && monthlyPricePoint
      ? [
          {
            advantages: [`$${monthlyPricePoint.monthlyPrice} per month`, 'Billed monthly'],
            label: 'Current plan',
            name: 'Monthly plan',
            pricingPerYear: (
              <p className="mb-4 text-center font-semibold">${currentPricePerYear} per year</p>
            )
          },
          {
            advantages: [
              <span>
                Save on <span className="text-orange">{2} months</span> of access, that's{' '}
                <span className="text-orange">${annualPricePoint.monthlyPrice}</span> per month
              </span>,
              'Billed annually'
            ],
            label: 'Upgrade',
            name: 'Annual plan',
            pricingPerYear: (
              <p className="mb-4 text-center font-semibold">
                <span className={'line-through decoration-red'}>${currentPricePerYear}</span>
                {' $'}
                {annualPricePoint?.totalCost} per year
              </p>
            )
          }
        ]
      : [];

  const navigateToDashboard = (isUpgradedToAnnualPlan = false) =>
    navigate(PathName.Dashboard, {
      state: { isUpgradedToAnnualPlan }
    });

  const handleUpgradePlan = () => {
    upgradePlan({
      planId: lifeMDMembershipPlan?._id,
      planPricePointId: annualPricePoint?.planPricePointId
    })
      .unwrap()
      .then(() => navigateToDashboard(true))
      .catch(() => toggleIsPaymentFailedOpen(true));
  };

  const handleGetCreditCardInformationThen = (e: MessageEvent) => {
    handleRequestCatch(e);
    toggleIsPaymentFailedOpen(true);
  };

  const handleUpgradePlanButtonClick = () => {
    getCreditCardInformation()
      .unwrap()
      .then(handleUpgradePlan)
      .catch(handleGetCreditCardInformationThen);
  };

  useEffect(() => {
    if (
      user.isAnnualMembership ||
      user.isUnlimitedPlan ||
      isUpgradeToAnnualAlreadyShown(user.userId)
    ) {
      navigateToDashboard();
    }
  }, [annualPricePoint]);

  const renderPlanCards = () => {
    return plans.map((plan) => {
      const isAnnualPlan = plan.label === 'Upgrade';
      const pricingCardClassName = classNames(
        'p-4 pt-0 pb-6 rounded-2xl border h-full min-h-[179px] w-full lg:w-[317px]',
        isAnnualPlan ? 'bg-secondary-50 border-secondary-400' : 'bg-gray-50 border-gray-200'
      );
      const cardBadgeClassName = classNames(
        'relative bottom-3 mx-auto rounded w-fit py-1 px-2',
        isAnnualPlan ? 'bg-secondary' : 'bg-gray'
      );
      const advantagesListClassName = classNames('flex flex-col gap-1', {
        'items-center justify-center': !isAnnualPlan
      });
      return (
        <div className={pricingCardClassName} key={plan.label}>
          <div className={cardBadgeClassName}>
            <div className="text-mSm font-semibold text-white md:text-sm">{plan.label}</div>
          </div>
          <p className="mb-2 text-center text-mXl font-bold text-primary md:text-xl">{plan.name}</p>
          {plan.pricingPerYear}
          <div className={advantagesListClassName}>
            {plan.advantages.map((advantage, i) => (
              <TransitionWithDelayWrapper
                className="flex items-center justify-start gap-1 font-semibold text-gray"
                custom={i}
                key={nanoid()}
              >
                <Common.Icon className="flex size-4 shrink-0 text-secondary" name="check" />
                {advantage}
              </TransitionWithDelayWrapper>
            ))}
          </div>
        </div>
      );
    });
  };

  const renderButtonsBlock = () => (
    <div
      className={classNames(
        'flex w-full flex-col items-center justify-center gap-5 md:mt-6 md:gap-2',
        {
          '!fixed inset-x-0 bottom-0 h-[142px] bg-gray-50 p-4 pb-9': isMobile
        }
      )}
    >
      <Common.Button
        color="blue"
        disabled={isLoading}
        isLoading={isLoading}
        postIcon="arrow-right"
        fullWidthOnMobile
        onClick={handleUpgradePlanButtonClick}
      >
        Yes, upgrade my account
      </Common.Button>
      <Common.Button
        className="!p-0 text-base font-bold text-gray md:!p-2 md:text-sm"
        color="white"
        style="text-only"
        fullWidthOnMobile
        onClick={() => navigateToDashboard()}
      >
        No thanks. I’m not interested right now.
      </Common.Button>
    </div>
  );

  return (
    <>
      <Loader isVisible={isMembershipPricePointLoading} />
      <FailedPayment
        hasCardAlready={isSuccess}
        isOpenByDefault={isPaymentFailedOpen}
        onClose={toggleIsPaymentFailedOpen}
        onResubmit={handleUpgradePlanButtonClick}
      />
      <SlideAnimateWrapper>
        <div className={isMobile ? 'pb-[142px]' : ''}>
          <div
            className={classNames(
              'flex items-center justify-center rounded-xl bg-white p-4 shadow md:p-8',
              {
                'border border-gray-200 shadow-none': isMobile
              }
            )}
          >
            <div className="flex min-h-full max-w-[650px] flex-col items-center justify-center gap-6 md:gap-4">
              <PlusTwoMonthsSvg />
              <h3 className="text-mBase font-bold text-primary md:mt-4 md:text-center md:text-2xl">
                We’re glad you’re enjoying LifeMD!
                <br />
                By upgrading to an annual plan today, you could save on{' '}
                <span className="text-orange">two months</span> of access.
              </h3>
              <div className="flex flex-col gap-8 md:my-6 md:flex-row md:gap-4">
                {renderPlanCards()}
              </div>
              <p>
                Locking in a 12-month plan is the smart way to ensure you have access to quality
                healthcare from board-certified doctors, when and where you need it. Urgent,
                primary, and chronic care needs will arise. Make sure that the diagnosis,
                prescription, lab order, or medical advice you need is as close as your phone.
              </p>
              <ul className="flex w-full flex-col gap-1">
                {ANNUAL_PLAN_ADVANTAGES.map((advantage) => (
                  <li className="flex" key={nanoid()}>
                    <span className="-mt-0.5 ml-2 mr-3.5">•</span>
                    <span className="text-mBase font-semibold md:text-sm">{advantage}</span>
                  </li>
                ))}
              </ul>
              {!isMobile && renderButtonsBlock()}
            </div>
          </div>
        </div>
        {isMobile && renderButtonsBlock()}
      </SlideAnimateWrapper>
    </>
  );
};

export default UpgradeToAnnualPlan;

import React, { PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

const Collapse: React.FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div className="border-b pb-4">
      <button
        className={`flex w-full items-center justify-between pt-4 ${isOpen && 'pb-1'}`}
        onClick={toggleIsOpen}
      >
        <span className="text-start font-bold text-primary-700">{title}</span>
        <Common.Icon
          className="size-4 flex-none text-primary"
          name={isOpen ? 'arrow-down' : 'arrow-right'}
        />
      </button>
      {isOpen && <p>{children}</p>}
    </div>
  );
};

export default Collapse;

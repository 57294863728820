import { CreditCardAttributesProps } from 'services/myAccount/myAccount.types';

import { PaypalFormInfo } from 'widgets/signUp/PaymentForm/paymentForm.types';

import { PaymentFormFields } from './paymentFormNew.types';

export const buildCreditCardAttributesFromForm = (
  formInfo: PaymentFormFields | Omit<PaypalFormInfo, 'dob' | 'email'>,
  paymentMethod: 'credit_card' | 'paypal_account' | 'bank_account' = 'credit_card'
): CreditCardAttributesProps => {
  // create body for paypal
  if ('token' in formInfo) {
    return {
      chargifyToken: formInfo.token,
      firstName: formInfo?.firstName,
      lastName: formInfo?.lastName,
      paymentMethod
    };
  }
  // create body for credit card
  const regex = new RegExp(' ', 'g');
  const body: CreditCardAttributesProps = {
    cvv: formInfo.cvc,
    expirationMonth: formInfo.expDate.substring(0, 2).replace(/^0+/, ''),
    expirationYear: formInfo.expDate.substring(3, 5),
    fullNumber: formInfo.fullNumber?.replace(regex, ''),
    paymentMethod
  };

  return body;
};

import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { AT_LEAST_ONE_NUMBER_OR_SPEC_CHAR, SECURE_PASSWORD_REGEXP } from 'utils/regExp';

const NewPasswordInput = <TFormValues extends FieldValues>({
  name,
  control,
  placeholder = 'New password',
  label,
  labelClassName,
  size
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      validate: {
        minLength: (value) => value.length > 7,
        maxLength: (value) => value.length < 65,
        specChar: (value) => AT_LEAST_ONE_NUMBER_OR_SPEC_CHAR.test(value),
        secure: (value) => SECURE_PASSWORD_REGEXP.test(value)
      }
    }
  });
  const newPasswordErrorTypes = fieldState.error?.types;
  const newPasswordIsDirty = fieldState.isDirty;

  const isMinMax =
    !newPasswordErrorTypes?.minLength && !newPasswordErrorTypes?.maxLength && newPasswordIsDirty;
  const isSpecChar = !newPasswordErrorTypes?.specChar && newPasswordIsDirty;
  const isSecure = !newPasswordErrorTypes?.secure && newPasswordIsDirty;

  const getClassName = (isValid: boolean) => {
    return classNames(
      'flex items-center gap-1 text-mSm md:text-sm',
      isValid ? 'text-green' : !fieldState.error ? 'text-gray' : 'text-red'
    );
  };

  return (
    <div className="w-full">
      <Common.Input
        dataTestId="password_field"
        {...field}
        errors={fieldState.error}
        inputClassName="text-gray-700"
        label={label}
        labelClassName={labelClassName}
        maxLength={100}
        placeholder={placeholder}
        size={size}
        type="password"
      />
      <div className="mt-4 flex flex-col gap-1 md:mt-2" data-testid="password_validation_msgs">
        <p className={getClassName(isSpecChar)} data-testid={`green=_${isSpecChar}`}>
          {isSpecChar && <Common.Icon className="size-4" name="check" />}
          Includes a number or special character
        </p>
        <p className={getClassName(isMinMax)} data-testid={`green=_${isMinMax}`}>
          {isMinMax && <Common.Icon className="size-4" name="check" />}
          8–64 characters
        </p>
        {isMinMax && isSpecChar && (
          <p className={getClassName(isSecure)}>Password strength: {isSecure ? 'Good' : 'Poor'}</p>
        )}
        {isMinMax && isSpecChar && !isSecure && (
          <Common.Alert className="mt-2" type="warning" colorableBackground>
            <p className="text-mSm font-bold">Avoid the following:</p>
            <ul className="ml-1 text-mSm">
              <li className="flex gap-2">
                <span>•</span> Repetitive or sequential characters (e.g. ‘aaaaaa’, ‘1234abcd’).
              </li>
              <li className="flex gap-2">
                <span>•</span> Using your name or LifeMD in the password.
              </li>
            </ul>
          </Common.Alert>
        )}
      </div>
    </div>
  );
};

export default NewPasswordInput;

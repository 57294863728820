import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import {
  GetSettingsReqProps,
  GetSettingsResProps,
  UpdateSettingsReqProps,
  UpdateSettingsResProps
} from './settings.types';

export const settingsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getSettings: build.query<GetSettingsResProps, GetSettingsReqProps>({
      keepUnusedDataFor: 0,
      query: () => 'notifications/settings'
    }),
    updateSettings: build.mutation<UpdateSettingsResProps, UpdateSettingsReqProps>({
      query: (body) => ({
        body,
        method: 'PATCH',
        url: 'notifications/settings'
      })
    })
  }),
  reducerPath: 'settingsApi',
  tagTypes: ['Settings']
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;

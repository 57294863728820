import { useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import DropContainer from 'features/DropContainer';

import useWidth from 'hooks/useWidth';

import { AttachFilesProps } from './attachFiles.types';

const AttachFiles: React.FC<AttachFilesProps> = ({ files, setFiles, onContinue, onBack }) => {
  const [dragOverlay, setDragOverlay] = useState(false);
  const { isMobile } = useWidth();

  const handleClick = (items: FileList | null) => {
    if (!items) {
      return;
    }
    const newItems = [...items].filter(
      (f) => !files.some((fi) => fi.lastModified === f.lastModified && fi.name === f.name)
    );
    items && setFiles([...files, ...newItems]);
  };

  const removeFile = (file: File) => {
    setFiles(files.filter((f) => f.name !== file.name && f.lastModified !== file.lastModified));
  };

  return (
    <>
      <Common.Illustration className="mx-auto hidden w-36 md:block" name="large-calendar" />
      <h1
        className="mb-8 mt-4 text-center text-mXl font-bold text-primary-700 md:mt-8 md:text-2xl"
        data-testid="add_additional_info_heading"
      >
        Add any additional information for your appointment.
      </h1>
      <div>
        <DropContainer
          className={classNames(
            'target-animate-div mb-4 w-full cursor-pointer rounded-2xl border p-8 transition-all duration-300 md:mb-8',
            dragOverlay ? 'border-2 border-solid border-primary bg-primary-400' : 'border-dashed'
          )}
          setDragOverlay={setDragOverlay}
          onChange={handleClick}
        >
          {
            <Common.Icon
              className={classNames(
                'mx-auto mb-6 mt-2 size-12',
                dragOverlay ? 'text-white' : 'text-gray-400'
              )}
              name="camera"
            />
          }
          {isMobile ? (
            <>
              <span className="mb-4 block text-center text-mLg font-bold text-gray">
                Upload a photo or video
              </span>
              <span className="mb-4 block text-center text-mBase font-medium text-gray">
                Your care team will be able to see any documents you upload here.
              </span>
            </>
          ) : (
            <>
              <span
                className={classNames(
                  'block text-center text-base font-medium',
                  dragOverlay ? 'text-white' : 'text-gray'
                )}
              >
                Drag your photos or videos here to start uploading.
              </span>
              <span
                className={classNames(
                  'my-4 block w-full text-center text-base font-bold',
                  dragOverlay ? 'text-white/30' : 'text-gray'
                )}
              >
                Or
              </span>
            </>
          )}
          <Common.Button
            className={classNames('mx-auto', { 'opacity-30': dragOverlay })}
            color="blue-alt"
            fullWidthOnMobile
          >
            Browse files
          </Common.Button>
        </DropContainer>
        <div className="mx-auto mb-8 w-full max-w-[385px]" data-testid="uploaded_files_block">
          {files.map((file: File) => (
            <div
              className="mb-2 flex items-center gap-2 rounded-2xl bg-gray-100 p-4 text-mSm md:py-2 md:text-base"
              key={file.lastModified + file.name}
            >
              <Common.Icon className="size-[37px] text-primary" name="image" />
              <span className="flex-1 truncate">{file.name}</span>
              <button data-testid="remove_button" onClick={() => removeFile(file)}>
                <Common.Icon className="text-gray" name="close" />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center gap-4 max-md:mt-auto">
        <Common.Button className="max-md:hidden" color="white-alt" onClick={onBack}>
          Back
        </Common.Button>
        <Common.Button color="blue" postIcon="arrow-right" fullWidthOnMobile onClick={onContinue}>
          Next
        </Common.Button>
      </div>
    </>
  );
};

export default AttachFiles;
